import {Link} from "react-router-dom"
import { Helmet } from "react-helmet";
import Signature from "../../signature/Signature";
export default function Cookies() {

    return (
        <>
            <Helmet>
            <title>TBP | Almacenamiento Local vs Cookies</title>
            </Helmet>
            <div className="container-3">
                <div className="main-container">
                    <div className="box-title">
                        <h1>Storage Local vs Cookies</h1>
                    </div>
                    <div className="topic-links">
                        <div className="card-topic-link">
                            <Link to="/topics/classnotes">
                                <h4><i className="fa-sharp fa-solid fa-circle"></i>&nbsp;&nbsp;&nbsp;Classnotes</h4>
                            </Link>
                        </div>
                        <div className="card-topic-link">
                            <Link to="">
                            <h4><i className="fa-sharp fa-solid fa-circle"></i>&nbsp;&nbsp;&nbsp;Apr 27, 2022</h4>
                            </Link>
                        </div>
                    </div>
                    <div className="sub-container">
                        <h2>Cookies</h2>
                        <p>Generalmente son establecidas por los servidores utilizando el encabezado HTTP para almacenar información como la configuración y el inicio de sesión del usuario.</p>
                        <p><b>Código</b></p>
                        <ul>
                            <li>Set-Cookie: prefer_theme=dark</li>
                        </ul>
                        <p>Las cookies se envían de vuelta al servidor en cada petición del navegador al dominio del que proviene.</p>
                        <p>Las cookies también tienen un tiempo de expiración (por defecto 30m)</p>
                        <p><b>Código</b></p>
                        <ul>
                            <li>Set-Cookie: prefer_theme=dark</li>
                            <li>Expires=Mon, 28 Feb 2100 23:59:59GMT;</li>
                        </ul>
                        <p>Un dominio sólo puede establecer 150 cookies de tamaño 4096 bytes.</p>
                        <p>También se puede acceder a las cookies mediante JavaScript.</p>
                        <p><b>Código JS</b></p>
                        {/* <div className="codeblock">
                            <CopyBlock
                            language="js"
                            text={`sessionStorage.setItem('strawberry', 'pancake');
    sessionStorage.getItems('strawberry'); // pancake`}
                            codeBlock
                            theme={zenburn}
                            showLineNumbers={false}
                            />
                        </div> */}
                        <h2>LocalStorage</h2>
                        <p>Es similar a las cookies pero sólo es accesible desde JavaScript, no se envían al servidor en cada petición (en su lugar se utiliza Ajax)</p>
                        <p>El límite de tamaño de Local Storage es de 5mb.</p>
                        <p>A diferencia de las cookies, el Local Storage no tiene fecha de caducidad.</p>
                        <p>Tanto las Cookies como el Almacenamiento Local tienen un ámbito de dominio, lo que significa que example.com tiene acceso a las Cookies y a los datos del Almacenamiento Local establecidos sólo por example.com y no por cualquier otro dominio.</p>
                    </div>
                    <Signature/>
                </div>
            </div>
        </>
    )
}