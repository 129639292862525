import {Link} from "react-router-dom"
import { Helmet } from "react-helmet";
import Signature from "../../signature/Signature";
export default function W_DBMS() {

    return (
        <>
            <Helmet>
                <title>TBP | ¿Qué es DBMS?</title>
            </Helmet>
            <div className="container-3">
                <div className="main-container">
                    <div className="box-title">
                        <h1>¿Qué es DBMS?</h1>
                    </div>
                    <div className="topic-links">
                        <div className="card-topic-link">
                            <Link to="/topics/classnotes">
                                <h4><i className="fa-sharp fa-solid fa-circle"></i>&nbsp;&nbsp;&nbsp;Classnotes</h4>
                            </Link>
                        </div>
                        <div className="card-topic-link">
                            <Link to="">
                            <h4><i className="fa-sharp fa-solid fa-circle"></i>&nbsp;&nbsp;&nbsp;Apr 01, 2023</h4>
                            </Link>
                        </div>
                    </div>
                    <div className="sub-container">
                        <p>Un Sistema de Gestión de Bases de Datos &#40;SGBD&#41; es un sistema de software que permite gestionar y organizar bases de datos. En esta entrada del blog, exploraremos los conceptos básicos de DBMS, su importancia y algunos tipos populares de DBMS.</p>
                        <p>Los SGBD son esenciales para gestionar los datos de forma eficiente y eficaz. Proporciona una plataforma centralizada en la que se puede acceder, gestionar y actualizar fácilmente los datos. Garantiza la integridad, coherencia y seguridad de los datos.</p>
                        <p>El SGBD consta de tres componentes principales: el lenguaje de definición de datos &#40;DDL&#41;, el lenguaje de manipulación de datos &#40;DML&#41; y el lenguaje de control de datos &#40;DCL&#41;.</p>
                        <p>El DDL se encarga de definir el esquema de la base de datos, que incluye la definición de las tablas, las columnas y las relaciones entre ellas. El DML se encarga de insertar, actualizar y eliminar datos de la base de datos. La DCL se encarga de controlar el acceso a la base de datos, incluidos los permisos y privilegios de los usuarios.</p>
                        <p>Existen varios tipos de SGBD, cada uno con sus propias características y ventajas. Algunos de los tipos más populares son:</p>
                        <ol>
                            <li>SGBD relacional: Este tipo de SGBD almacena los datos en tablas, cada una de las cuales consta de filas y columnas. Los SGBD relacionales se utilizan para gestionar datos estructurados y suelen emplearse en aplicaciones empresariales.</li>
                            <li>SGBD NoSQL: Este tipo de SGBD se utiliza para gestionar datos no estructurados o semiestructurados. Las bases de datos NoSQL están diseñadas para una alta escalabilidad y se utilizan habitualmente en aplicaciones de big data.</li>
                            <li>SGBD orientado a objetos: este tipo de SGBD se utiliza para gestionar estructuras de datos complejas, incluidos objetos, clases y herencia. Se suele utilizar en aplicaciones científicas y de ingeniería.</li>
                            <li>SGBD en memoria: este tipo de SGBD almacena los datos en memoria en lugar de en disco, lo que permite un acceso a los datos de alta velocidad. Las bases de datos en memoria suelen utilizarse en aplicaciones y análisis en tiempo real.</li>
                        </ol>
                        <p>Los SGBD son un componente esencial de los sistemas de software modernos, ya que permiten gestionar y organizar los datos de forma eficiente. Mediante el uso de DBMS, las organizaciones pueden gestionar mejor sus datos, lo que conduce a una mejor toma de decisiones, una mayor eficiencia y una mayor productividad.</p>
                    </div>
                    <Signature/>
                </div>
            </div>
        </>
    )
}