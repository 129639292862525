import {Link} from "react-router-dom";
import { Helmet } from 'react-helmet';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { paraisoLight } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import Signature from "../../signature/Signature";
import "../Posts.css";

export default function Axios() {
    const code01 = `// Importar Axios en un entorno Node.js
const axios = require('axios');
    
// Realizar una petición GET a una API
axios.get('https://api.example.com/users')
    .then(response => {
        // Manejo de la respuesta exitosa
        console.log(response.data); // Acceder a los datos de la respuesta
    })
    .catch(error => {
        // Tratamiento del error
        console.error(error);
    });`;
    return (
        <>
            <Helmet>
                <title>TBP | ¿Qué es Axios?</title>
            </Helmet>
            <div className="container-3">
                <div className="main-container">
                    <div className="box-title">
                        <h1>¿Qué es Axios? Un potente cliente JavaScript HTTP para realizar peticiones API</h1>
                    </div>
                    <div className="topic-links">
                        <div className="card-topic-link">
                            <Link to="/topics/javascript">
                                <h4><i className="fa-sharp fa-solid fa-circle"></i>&nbsp;&nbsp;&nbsp;JavaScript</h4>
                            </Link>
                        </div>
                        <div className="card-topic-link">
                            <Link to="">
                            <h4><i className="fa-sharp fa-solid fa-circle"></i>&nbsp;&nbsp;&nbsp;May 17, 2023</h4>
                            </Link>
                        </div>
                    </div>
                    <div className="sub-container">
                        <p>Cuando se trata de hacer peticiones HTTP en JavaScript, Axios se ha convertido en una opción popular entre los desarrolladores. Se trata de un cliente HTTP ligero y basado en promesas que simplifica el proceso de realizar peticiones API y gestionar las respuestas. En este post exploraremos qué es Axios, sus características clave y algunos ejemplos para ayudarnos a entender su uso.</p>
                        <h2>¿Qué es Axios?</h2>
                        <p>Axios es una librería JavaScript que permite enviar peticiones HTTP desde un navegador o un entorno Node.js. Proporciona una API sencilla e intuitiva para realizar peticiones HTTP asíncronas a servidores, obtener datos y manejar respuestas. Estas son algunas de las características clave de Axios:</p>
                        <ol>
                            <li><b>Basado en promesas:</b> Axios utiliza promesas para manejar operaciones asíncronas, lo que facilita el manejo de escenarios de éxito y error de una manera más estructurada y legible.</li>
                            <li><b>Compatibilidad con navegadores y Node.js:</b> Axios se puede utilizar tanto en aplicaciones JavaScript basadas en navegador como en entornos Node.js, proporcionando una API consistente para realizar peticiones HTTP a través de diferentes plataformas.</li>
                            <li><b>Interceptación de peticiones y respuestas:</b> Axios permite interceptar y modificar peticiones y respuestas HTTP mediante interceptores. Esto es útil para añadir cabeceras, gestionar la autenticación o registrar las peticiones y respuestas.</li>
                            <li><b>Análisis JSON automático:</b> Axios analiza automáticamente las respuestas JSON, lo que le permite trabajar con los datos de respuesta directamente sin necesidad de análisis manual.</li>
                        </ol>
                        <h2>Ejemplos de uso:</h2>
                        <p>Para demostrar el uso de Axios, veamos un ejemplo de hacer una petición GET a una API y manejar la respuesta:</p>
                        <div className="code-text">
                            <SyntaxHighlighter className="codes" language="js" style={paraisoLight} customStyle={{borderRadius: "1.5em", backgroundColor: "#f4e7e5", border: "2px solid #222222", padding: "2em"}}>
                            {code01}
                            </SyntaxHighlighter>
                        </div>
                        <p>En el ejemplo anterior, importamos Axios en un entorno Node.js y utilizamos el método <b>axios.get()</b> para enviar una petición GET al endpoint de la API especificado. A continuación, manejamos la respuesta utilizando promesas, accediendo a los datos de respuesta a través de <b>response.data</b>. En caso de error, capturamos y registramos el error utilizando el método <b>.catch()</b>.</p>
                        <hr />
                        <h2>Conclusión</h2>
                        <p>Axios es una potente librería JavaScript que simplifica el proceso de realizar peticiones HTTP tanto en aplicaciones JavaScript basadas en navegador como en entornos Node.js. Con su intuitiva API, estructura basada en promesas y soporte para interceptores, Axios proporciona una forma eficiente y conveniente de manejar las peticiones y respuestas de la API. Tanto si está obteniendo datos de una API, enviándolos a un servidor o gestionando escenarios de error, Axios es una opción fiable que puede mejorar enormemente su experiencia de desarrollo en JavaScript. Pruébelo y vea cómo agiliza su flujo de trabajo de peticiones HTTP.</p>
                    </div>
                    <Signature/>
                </div>
            </div>
        </>
    )
}