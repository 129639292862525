import {Link} from "react-router-dom"
import { Helmet } from "react-helmet";
import Signature from "../../signature/Signature";
export default function Hardware() {

    return (
        <>
            <Helmet>
                <title>TBP | Hardware</title>
            </Helmet>
            <div className="container-3">
                <div className="main-container">
                    <div className="box-title">
                        <h1>Hardware</h1>
                    </div>
                    <div className="topic-links">
                        <div className="card-topic-link">
                            <Link to="/topics/classnotes">
                                <h4><i className="fa-sharp fa-solid fa-circle"></i>&nbsp;&nbsp;&nbsp;Classnotes</h4>
                            </Link>
                        </div>
                        <div className="card-topic-link">
                            <Link to="">
                            <h4><i className="fa-sharp fa-solid fa-circle"></i>&nbsp;&nbsp;&nbsp;Feb 01, 2022</h4>
                            </Link>
                        </div>
                    </div>
                    <div className="sub-container">
                        <p>Usamos computadoras todos los días</p>
                        <p>Dentro de la computadora hay "0 y 1"</p>
                        <ul>
                            <li>Las computadoras usan el sistema numérico binario para representar información.</li>
                        </ul>
                        <p>Considere el número decimal (lo que normalmente usamos los humanos) 123</p>
                        <ul>
                            <li>La columna más a la derecha es la columna 1s</li>
                            <li>El medio, los 10</li>
                            <li>El más a la izquierda, los 100</li>
                        </ul>
                        <table>
                            <tr>
                                <th>100</th>
                                <th>1</th>
                            </tr>
                            <tr>
                                <th>10</th>
                                <td>2</td>
                            </tr>
                            <tr>
                                <th>1</th>
                                <td>3</td>
                            </tr>
                        </table>
                        <ul>
                            <li>Así tenemos 100 x 1 + 10 x 2 + 1 x 3 = 100 + 20 + 3 = 123</li>
                        </ul>
                        <p>Dentro de una computadora, el 000 binario representaría 0, ¡igual que en nuestro mundo humano!</p>
                        <p>Sin embargo, en este caso estamos tratando con binario por lo que:</p>
                        <ul>
                            <li>La columna más a la derecha es el lugar 1s</li>
                            <li>El medio, los 2</li>
                            <li>El más a la izquierda, los 4s</li>
                        </ul>
                        <table>
                            <tr>
                                <th>4</th>
                                <th>0</th>
                            </tr>
                            <tr>
                                <th>2</th>
                                <td>0</td>
                            </tr>
                            <tr>
                                <th>1</th>
                                <td>0</td>
                            </tr>
                        </table>
                        <p>En el mundo humano (decimal) usamos potencias de 10 para valores posicionales</p>
                        <ul>
                            <li>100 = 1, 101 = 10, 102 = 100, 103 = 1000, etc.</li>
                        </ul>
                        <p>En el mundo de la informática (binario) usamos potencias de 2 para valores posicionales</p>
                        <ul>
                            <li>20 = 1, 21 = 2, 22 = 4, 23 = 8, etc.</li>
                        </ul>
                        <p>La diferencia entre números decimales y números binarios está cambiando la base</p>
                        <ul>
                            <li>¡Para el número binario 000, tenemos 4 x 0 + 2 x 0 + 1 x 0 = 0 + 0 + 0 = 0!</li>
                        </ul>
                    </div>
                    <Signature/>
                </div>
            </div>
        </>
    )
}