import React from "react";
import {Link} from "react-router-dom"
import { Helmet } from 'react-helmet';
import Signature from "../../signature/Signature";
import "../Posts.css"

export default function Aprender_Python_2023() {
    return (
        <>
            <Helmet>
                <title>TBP | ¿Por qué aprender Python en 2023?</title>
            </Helmet>
            <div className="container-3">
                <div className="main-container">
                    <div className="box-title">
                        <h1>¿Por qué aprender Python en 2023?</h1>
                    </div>
                    <div className="topic-links">
                        <div className="card-topic-link">
                            <Link to="/topics/python">
                                <h4><i className="fa-sharp fa-solid fa-circle"></i>&nbsp;&nbsp;&nbsp;Python</h4>
                            </Link>
                        </div>
                        <div className="card-topic-link">
                            <Link to="">
                            <h4><i className="fa-sharp fa-solid fa-circle"></i>&nbsp;&nbsp;&nbsp;Jan 13, 2023</h4>
                            </Link>
                        </div>
                    </div>
                    <div className="sub-container">
                        <h3>1. Amigable para principiantes y fácil de aprender</h3>
                        <p>La sintaxis corta y nítida de Python hace que sea más fácil de entender para el principiante y apreciar</p>
                        <h3>Código limpio y sintaxis simple</h3>
                        <p>Python hace que el código sea más limpio y la sintaxis tan simple como podría ser.</p>
                        <h3>Poder supremo</h3>
                        <p>Con Python, uno puede obtener desarrollo práctico de Web/App/Desktop, Ciencia de datos y aprendizaje automático, automatización, IoT, programación integrada y mucho más.</p>
                        <h3>Funciones modernas</h3>
                        <p>Las funciones como conjuntos y mapas, comprensión de listas, funciones de lambda y más hacen que Python sea emocionante y valga la pena probarlo para los desarrolladores.</p>
                        <h3>Increible soporte para programación orientada a objetos</h3>
                        <p>POO, es uno de los paradigmas de programación más utilizados, amados y poderosos.</p>
                        <h3>Toneladas de bibliotecas</h3>
                        <p>Las bibliotecas de Python son poderosas, están bien documentadas, son fáciles de implementar y más.</p>
                        <h3>Demanda y oportunidades laborales</h3>
                        <p>Con un salario promedio de $109 000 por año y 10 000 oportunidades laborales en todo el mundo, Python es prometedor y es la necesidad del momento.</p>
                    </div>
                    <Signature/>
                </div>
            </div>
        </>
    )
}