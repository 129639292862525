import React from "react";
import {Link} from "react-router-dom"
import { Helmet } from 'react-helmet';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { paraisoLight } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import Signature from "../../signature/Signature";
import "../Posts.css"

export default function WebAppFlask() {
    const code01 = `pip install flask`;
    const code02 = `from flask import Flask`;
    const code03 = `app = Flask(__name__)`;
    const code04 = `@app.route('/')
def home():
    return 'Hello, World!'`;
    const code05 = `if __name__ == '__main__':
    app.run()`;
    const code06 = `python app.py`;

    return (
        <>
            <Helmet>
                <title>TBP | ¿Cómo crear una Web App sencilla con Python Flask?</title>
            </Helmet>
            <div className="container-3">
                <div className="main-container">
                    <div className="box-title">
                        <h1>¿Cómo crear una Web App sencilla con Python Flask?</h1>
                    </div>
                    <div className="topic-links">
                        <div className="card-topic-link">
                            <Link to="/topics/python">
                                <h4><i className="fa-sharp fa-solid fa-circle"></i>&nbsp;&nbsp;&nbsp;Python</h4>
                            </Link>
                        </div>
                        <div className="card-topic-link">
                            <Link to="">
                            <h4><i className="fa-sharp fa-solid fa-circle"></i>&nbsp;&nbsp;&nbsp;Apr 28, 2023</h4>
                            </Link>
                        </div>
                    </div>
                    <div className="sub-container">
                        <p>En este post vamos a ver una guía de paso a paso sobre cómo construir una aplicación web sencilla utilizando Python Flask.</p>
                        <h2>¿Qué es Flask?</h2>
                        <p>Flask es un framework web ligero y potente que permite a los desarrolladores crear aplicaciones web de forma rápida y sencilla. Se basa en el conjunto de herramientas Werkzeug y el motor de plantillas Jinja2, que proporcionan un entorno flexible y extensible para crear aplicaciones web.</p>
                        <h2>Step by step</h2>
                        <p>Antes de empezar a construir la aplicación web, asegúrate de que tienes Python y Flask instalados en tu sistema. Puedes instalar Flask usando pip, que es un instalador de paquetes para Python. Simplemente abre tu símbolo del sistema y escribe el siguiente comando:</p>
                        <div className="code-text">
                            <SyntaxHighlighter className="codes" language="shell" style={paraisoLight} customStyle={{borderRadius: "1.5em", backgroundColor: "#f4e7e5", border: "2px solid #222222", padding: "2em"}}>
                            {code01}
                            </SyntaxHighlighter>
                        </div>
                        <p>Una vez que tengas Flask instalado, podemos empezar a construir la aplicación web. Estos son los pasos:</p>
                        <h3>Paso 1: Importar Flask</h3>
                        <p>Lo primero que tenemos que hacer es importar el módulo Flask. Esto se hace añadiendo la siguiente línea de código:</p>
                        <div className="code-text">
                            <SyntaxHighlighter className="codes" language="python" style={paraisoLight} customStyle={{borderRadius: "1.5em", backgroundColor: "#f4e7e5", border: "2px solid #222222", padding: "2em"}}>
                            {code02}
                            </SyntaxHighlighter>
                        </div>
                        <p>Esta línea de código importa la clase Flask del módulo Flask.</p>
                        <h3>Paso 2: Crear la aplicación Flask</h3>
                        <p>El siguiente paso es crear un objeto de aplicación Flask. Esto se hace creando una instancia de la clase Flask:</p>
                        <div className="code-text">
                            <SyntaxHighlighter className="codes" language="python" style={paraisoLight} customStyle={{borderRadius: "1.5em", backgroundColor: "#f4e7e5", border: "2px solid #222222", padding: "2em"}}>
                            {code03}
                            </SyntaxHighlighter>
                        </div>
                        <p>El parámetro name es una variable especial de Python que representa el nombre del módulo actual. Esto es necesario para que Flask sepa dónde encontrar otros archivos como las plantillas.</p>
                        <h3>Paso 3: Definir las rutas</h3>
                        <p>Una ruta es una URL que el usuario puede visitar. En Flask, las rutas se definen utilizando el decorador <b>@app.route</b>. Aquí tienes un ejemplo:</p>
                        <div className="code-text">
                            <SyntaxHighlighter className="codes" language="python" style={paraisoLight} customStyle={{borderRadius: "1.5em", backgroundColor: "#f4e7e5", border: "2px solid #222222", padding: "2em"}}>
                            {code04}
                            </SyntaxHighlighter>
                        </div>
                        <p>Esta ruta mostrará el texto "¡Hola, mundo!" cuando el usuario visite la página de inicio de la aplicación web.</p>
                        <h3>Paso 4: Ejecutar la aplicación</h3>
                        <p>Para ejecutar la aplicación, tenemos que añadir el siguiente código al final del archivo:</p>
                        <div className="code-text">
                            <SyntaxHighlighter className="codes" language="python" style={paraisoLight} customStyle={{borderRadius: "1.5em", backgroundColor: "#f4e7e5", border: "2px solid #222222", padding: "2em"}}>
                            {code05}
                            </SyntaxHighlighter>
                        </div>
                        <p>Este código comprueba si el archivo actual es el programa principal y arranca el servidor de desarrollo Flask.</p>
                        <h3>Paso 5: Iniciar la aplicación web</h3>
                        <p>Para iniciar la aplicación web, guarde el archivo con extensión .py (por ejemplo, app.py) y ejecútelo utilizando el símbolo del sistema:</p>
                        <div className="code-text">
                            <SyntaxHighlighter className="codes" language="python" style={paraisoLight} customStyle={{borderRadius: "1.5em", backgroundColor: "#f4e7e5", border: "2px solid #222222", padding: "2em"}}>
                            {code06}
                            </SyntaxHighlighter>
                        </div>
                        <p>Esto iniciará el servidor de desarrollo Flask y la aplicación web estará disponible en <b>http://localhost:5000/</b>.</p>
                        <p>¡Enhorabuena! Ya has creado una aplicación web sencilla utilizando Python Flask.</p>
                        <p>Para ampliar esta aplicación web, puedes añadir más rutas, plantillas y funcionalidades utilizando la extensa documentación de Flask y los recursos de la comunidad.</p>
                    </div>
                    <Signature/>
                </div>
            </div>
        </>
    )
}