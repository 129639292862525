import {Link} from "react-router-dom"
import { Helmet } from "react-helmet";
import Signature from "../../signature/Signature";
export default function Pesti() {

    return (
        <>
            <Helmet>
                <title>TBP | PESTI</title>
            </Helmet>
            <div className="container-3">
                <div className="main-container">
                    <div className="box-title">
                        <h1>plan estratégico de sistemas y TI</h1>
                    </div>
                    <div className="topic-links">
                        <div className="card-topic-link">
                            <Link to="/topics/classnotes">
                                <h4><i className="fa-sharp fa-solid fa-circle"></i>&nbsp;&nbsp;&nbsp;Classnotes</h4>
                            </Link>
                        </div>
                        <div className="card-topic-link">
                            <Link to="">
                            <h4><i className="fa-sharp fa-solid fa-circle"></i>&nbsp;&nbsp;&nbsp;May 24, 2020</h4>
                            </Link>
                        </div>
                    </div>
                    <hr/>
                    <div className="sub-container">
                        <h3>Definición</h3>
                        <p>Es el proceso y la documentación en la que se identifica la cartera de aplicaciones y la infraestructura tecnológica que debe desarrollar la empresa para obtener ventajas sostenibles sobre sus competidores, de acuerdo con la estrategia de negocio.</p>
                        <h3>Objetivos</h3>
                        <ul>
                            <li>Alinear los sistemas y las tecnologías de la información con el negocio para identificar cómo y donde pueden contribuir más y mejor y determinar en función de esto, las propiedades de inversión.</li>
                            <li>Ayudar a que la empresa logre los objetivos de negocio, es decir, obtener ventajas competitivas sostenibles para la empresa mediante los sistemas y tecnologías de la información.</li>
                            <li>Construir un modelo de información y una infraestructura tecnológica para el futuro que sean competitivos, es decir, eficientes (coste-efectivos) y flexibles.</li>
                            <li>Identificar y desarrollar las capacidades y los recursos necesarios para desplegar el SI / TI definido.</li>
                            <li>Establecer los planes y programas de implantación, es decir, la hoja de ruta para desplegar la estrategia de SI / TI dentro de la empresa.</li>
                        </ul>
                        <p className="upper">Configuración de la organización</p>
                        <h3>Estructura Organizativa</h3>
                        <ul>
                            <li>Toda empresa tiene una estructura organizacional.</li>
                            <li>Son los diferentes patrones de diseño para organizar una empresa y lograr el objetivo deseado.</li>
                        </ul>
                        <p className="upper">Ejercicio: Proponer una estructura organizativa</p>
                        <h3>Mecanismos de organización</h3>
                        <ol>
                            <li>Ajuste Mutuo: Logra la coordinación del trabajo por medio de la comunicación informal. </li>
                            <li>Supervisión directa: Coordinación cuando una persona toma la responsabilidad del trabajo emitiendo instrucciones supervisando acciones.</li>
                            <li>Estandarización de procesos de trabajo: Regula mediante normas escritas los contenidos de trabajo.</li>
                            <li>Estandarización de productos o de resultados: Conjunto de normas escritas que regulan el producto final de un  trabajo o actividad.</li>
                            <li>Estandarización de destrezas o conocimientos: Preestablecer los conocimientos o habilidades que debe poseer quien se incorpora al puesto.</li>
                        </ol>
                        <h3>Funcionamiento de la organización</h3>
                        <ul>
                            <li>Sistema de autoridad formal: Representación por medio del organigrama del flujo de autoridad formal, de las posiciones existentes dentro de la organización y de la agrupación de las distintas áreas. Supervisión directa.</li>
                            <li>Red de flujos regulados: Es una visión de la organización compatible con las nociones tradicionales de autoridad y jerarquía.</li>
                            <li>Sistema de comunicaciones informales: No observan divisiones de áreas ni jerarquías, sino que se representan los flujos de comunicaciones informales por lo cual se enfatiza el ajuste mutuo.</li>
                            <li>Sistema de constelaciones de trabajo: La base de la visión es que la gente se agrupa con sus pares para realizar su trabajo, sin importar la jerarquía.</li>
                            <li>Sistema de procesos de decisión ad hoc: Es una representación de la corriente de una decisión estratégica. Se destacan más que nada los niveles e influencia de cada sector.</li>
                        </ul>
                        <h3>Tipos de estructuras organizativas</h3>
                        <ul>
                            <li>Lineal</li>
                            <li>Matricial</li>
                            <li>Departamental</li>
                            <li>Circular</li>
                            <li>Híbrida</li>
                        </ul>
                    </div>
                    <Signature/>
                </div>
            </div>
        </>
    )
}