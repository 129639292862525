import {Link} from "react-router-dom";
import { Helmet } from 'react-helmet';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { paraisoLight } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import Signature from "../../signature/Signature";
import "../Posts.css";

export default function UseEffect() {
    const code01 = `useEffect(() => {
    // Lógica del effect aquí
}, [dependencia1, dependencia2]);`;
    const code02 = `useEffect(() => {
    // Lógica del effect aquí
}, []);`;
    const code03 = `useEffect(() => {
    // Lógica del effect aquí

    return () => {
        // Lógica de limpieza aquí
    };
}, [dependencia]);`;
    return (
        <>
            <Helmet>
                <title>TBP | ¿Cómo evitar la doble ejecución de useEffect en React? Trucos y Consejos</title>
            </Helmet>
            <div className="container-3">
                <div className="main-container">
                    <div className="box-title">
                        <h1>¿Cómo evitar la doble ejecución de useEffect en React? Trucos y Consejos</h1>
                    </div>
                    <div className="topic-links">
                        <div className="card-topic-link">
                            <Link to="/topics/javascript">
                                <h4><i className="fa-sharp fa-solid fa-circle"></i>&nbsp;&nbsp;&nbsp;JavaScript</h4>
                            </Link>
                        </div>
                        <div className="card-topic-link">
                            <Link to="">
                            <h4><i className="fa-sharp fa-solid fa-circle"></i>&nbsp;&nbsp;&nbsp;May 22, 2023</h4>
                            </Link>
                        </div>
                    </div>
                    <div className="sub-container">
                        <p>El hook <b>useEffect</b> en React es una potente herramienta para gestionar efectos secundarios en componentes funcionales. Sin embargo, hay situaciones en las que el callback <b>useEffect</b> puede ejecutarse varias veces sin querer, dando lugar a comportamientos inesperados y problemas de rendimiento. En esta entrada del blog, exploraremos las razones más comunes para la doble ejecución de <b>useEffect</b> y discutiremos consejos y trucos para evitarlo. Proporcionaremos explicaciones detalladas y ejemplos para ayudarte a entender y superar este desafío en tus aplicaciones React.</p>
                        <h2>Entendiendo la doble ejecución de useEffect:</h2>
                        <ol>
                            <li><b>Cambios en el array de dependencias: </b>Cuando se utiliza la matriz de dependencia en <b>useEffect</b>, si los valores de la matriz cambian con frecuencia, el callback del effect se ejecutará cada vez que cambie un valor. Esto puede llevar a una doble ejecución no intencionada.</li>
                            <li><b>Array de dependencias incorrecta: </b>Si el Dependency Array no se especifica correctamente, o si falta por completo, el effect se ejecutará en cada nueva renderización. Esto puede provocar una doble ejecución y degradar el rendimiento.</li>
                            <li><b>Remontaje de componentes: </b>Cuando un componente se desmonta y se vuelve a montar, el <b>useEffect</b> se ejecutará de nuevo. Esto puede ocurrir cuando el componente se renderiza condicionalmente o cuando el componente padre se vuelve a renderizar.</li>
                        </ol>
                        <h2>Prevención de la doble ejecución de usEffect:</h2>
                        <h3>Especifique correctamente las dependencias:</h3>
                        <p>Revise cuidadosamente las dependencias en el array de dependencias. Asegúrese de que sólo se incluyen los valores necesarios y evite incluir valores que cambien con frecuencia. Esto garantiza que el efecto se ejecute sólo cuando cambien las dependencias relevantes.</p>
                        <h3>Ejemplo</h3>
                        <div className="code-text">
                            <SyntaxHighlighter className="codes" language="jsx" style={paraisoLight} customStyle={{borderRadius: "1.5em", backgroundColor: "#f4e7e5", border: "2px solid #222222", padding: "2em"}}>
                            {code01}
                            </SyntaxHighlighter>
                        </div>
                        <p>En este ejemplo, sólo <b>dependencia1</b> y <b>dependencia2</b> activarán el effect cuando cambien. Otros valores fuera del array de dependencias no harán que se ejecute el effect.</p>
                        <h3>Utiliza un array de dependencias vacía para una ejecucíon única:</h3>
                        <p>Si quieres que el effect se ejecute sólo una vez, durante el renderizado inicial, pasa un array de dependencias vacío.</p>
                        <h3>Ejemplo</h3>
                        <div className="code-text">
                            <SyntaxHighlighter className="codes" language="jsx" style={paraisoLight} customStyle={{borderRadius: "1.5em", backgroundColor: "#f4e7e5", border: "2px solid #222222", padding: "2em"}}>
                            {code02}
                            </SyntaxHighlighter>
                        </div>
                        <p>En este caso, el effect se ejecutará sólo cuando se monte el componente y no se verá afectado por ningún cambio de dependencia.</p>
                        <h3>Limpiar effect anterior:</h3>
                        <p>Si el effect tiene lógica de limpieza, asegúrate de incluirla en la función de limpieza del effect anterior. Esto evita cualquier efecto secundario no deseado del effect anterior cuando el componente se vuelve a renderizar.</p>
                        <div className="code-text">
                            <SyntaxHighlighter className="codes" language="jsx" style={paraisoLight} customStyle={{borderRadius: "1.5em", backgroundColor: "#f4e7e5", border: "2px solid #222222", padding: "2em"}}>
                            {code03}
                            </SyntaxHighlighter>
                        </div>
                        <p>Al especificar la lógica de limpieza en el effect anterior, te aseguras de que se ejecute antes de que se active el nuevo effect.</p>
                        <hr />
                        <h2>Conclusión</h2>
                        <p>Evitar la doble ejecución de <b>useEffect</b> es crucial para mantener un comportamiento y rendimiento correctos en las aplicaciones React. Entendiendo las causas comunes de la doble ejecución y aplicando los consejos y trucos discutidos en este post puedes prevenir este problema de forma efectiva. Recuerda revisar tu array de dependencias, gestionar adecuadamente el remontaje de componentes y limpiar los effects anteriores cuando sea necesario. Siguiendo estas prácticas recomendadas, puedes asegurarte de que tus effects se ejecuten solo cuando sea necesario, lo que dará lugar a componentes React más eficientes y predecibles.</p>
                    </div>
                    <Signature/>
                </div>
            </div>
        </>
    )
}