import {Link} from "react-router-dom"
import { Helmet } from "react-helmet";
import Signature from "../../signature/Signature";
export default function Cap1() {

    return (
        <>
            <Helmet>
                <title>TBP | Capítulo 01: Introducción a Java</title>
            </Helmet>
            <div className="container-3">
                <div className="main-container">
                    <div className="box-title">
                        <h1>Capítulo 01: Introducción a Java</h1>
                    </div>
                    <div className="topic-links">
                        <div className="card-topic-link">
                            <Link to="/topics/java">
                                <h4><i className="fa-sharp fa-solid fa-circle"></i>&nbsp;&nbsp;&nbsp;Java</h4>
                            </Link>
                        </div>
                        <div className="card-topic-link">
                            <Link to="">
                            <h4><i className="fa-sharp fa-solid fa-circle"></i>&nbsp;&nbsp;&nbsp;Feb 05, 2022</h4>
                            </Link>
                        </div>
                    </div>
                    <div className="sub-container">
                        <h2>¿Qué es Java?</h2>
                        <p>Java es un lenguaje de programación popular, creado en 1995.</p>
                        <p>Es propiedad de Oracle, y más de 3 billones de dispositivos corren Java.</p>
                        <p>Utilizado para:</p>
                        <ul>
                            <li>Aplicaciones móviles</li>
                            <li>Aplicaciones de escritorio</li>
                            <li>Aplicaciones web</li>
                            <li>Servidores web y servidores de aplicaciones</li>
                            <li>Juegos</li>
                            <li>Conexiones a base de datos</li>
                        </ul>
                        <h2>¿Por qué usar Java?</h2>
                        <ul>
                            <li>Java trabaja en diferentes plataformas (Windows, Mac, Linux, Raspberry Pi, etc.)</li>
                            <li>Es fácil de aprender y simple de usar</li>
                            <li>Es seguro, rápido y potente</li>
                            <li>Tiene una gran comunidad de soporte</li>
                            <li>Java es un lenguaje orientado a objetos lo que da una estructura clara al programar.</li>
                            <li>Como Java es cercano a C++ y C#, facilita a los programadores el cambio a Java.</li>
                        </ul>
                        <p><b>Características de Java</b></p>

                        <h2>Historia de Java</h2>
                        <p>La historia de Java empieza desde el equipo verde, los miembros del equipo de Java iniciaron una evolucionaria tarea de desarrollar un lenguaje para dispositivos digitales.</p>
                        <ol>
                            <li>James Gosling, Mike Sheridan y Patrick Naughton iniciaron el lenguaje Java en 1991. El pequeño equipo de ingenieros de Sun se llamaron Green Team.</li>
                            <li>En primer lugar, James Gosling lo llamó "Greentalk" y la extensión del archivo era .gt.</li>
                        </ol>
                        <h2>Versiones de Java</h2>
                        <ol>
                            <li>JDK Alfa y Beta (1995)</li>
                            <li>JDK 1.0 (23 de enero de 1996)</li>
                            <li>JDK 1.1 (19 de febrero de 1997)</li>
                            <li>J2SE 1.2 (8 de diciembre de 1998)</li>
                            <li>J2SE 1.3 (8 de mayo de 2000)</li>
                            <li>J2SE 1.4 (6 de febrero de 2002)</li>
                            <li>J2SE 5.0 (30 de septiembre de 2004)</li>
                            <li>Java SE 6 (11 de diciembre de 2006)</li>
                            <li>Java SE 7 (28 de julio de 2011)</li>
                            <li>Java SE 8 (18 de marzo de 2014)</li>
                            <li>Java SE 9 (21 de septiembre de 2017)</li>
                            <li>Java SE 10 (20 de marzo de 2018)</li>
                            <li>Java SE 11 (septiembre de 2018)</li>
                            <li>Java SE 12 (marzo de 2019)</li>
                            <li>Java SE 13 (septiembre de 2019)</li>
                            <li>Java SE 14 (marzo de 2020)</li>
                            <li>Java SE 15 (septiembre de 2020)</li>
                            <li>Java SE 16 (marzo de 2021)</li>
                            <li>Java SE 17 (septiembre de 2021)</li>
                            <li>Java SE 18 (se lanzará en marzo de 2022)</li>
                        </ol>
                    </div>
                    <Signature/>
                </div>
            </div>
        </>
    )
}