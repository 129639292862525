import {Link} from "react-router-dom";
import { Helmet } from 'react-helmet';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { paraisoLight } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import Signature from "../../signature/Signature";
import "../Posts.css";

export default function ApiRestful() {
    const code01 = `mvn archetype:generate -DgroupId=com.example -DartifactId=myapi -DarchetypeArtifactId=maven-archetype-quickstart -DinteractiveMode=false`;
    const code02 = `@RestController
@RequestMapping("/api")
public class MyController {
    
    @GetMapping("/users")
    public List<User> getUsers() {
        // code to get users
    }
    
    @PostMapping("/users")
    public User createUser(@RequestBody User user) {
        // code to create a user
    }

    @PutMapping("/users/{id}")
    public User updateUser(@PathVariable Long id, @RequestBody User user) {
        // code to update a user
    }

    @DeleteMapping("/users/{id}")
    public void deleteUser(@PathVariable Long id) {
        // code to delete a user
    }
    }`;
    const code03 = `mvn spring-boot:run`;

    return (
        <>
            <Helmet>
                <title>TBP | ¿Cómo crear una API RESTful con Java Spring Boot?</title>
            </Helmet>
            <div className="container-3">
                <div className="main-container">
                    <div className="box-title">
                        <h1>¿Cómo crear una API RESTful con Java Spring Boot?</h1>
                    </div>
                    <div className="topic-links">
                        <div className="card-topic-link">
                            <Link to="/topics/java">
                                <h4><i className="fa-sharp fa-solid fa-circle"></i>&nbsp;&nbsp;&nbsp;Java</h4>
                            </Link>
                        </div>
                        <div className="card-topic-link">
                            <Link to="">
                            <h4><i className="fa-sharp fa-solid fa-circle"></i>&nbsp;&nbsp;&nbsp;Apr 22, 2023</h4>
                            </Link>
                        </div>
                    </div>
                    <div className="sub-container">
                        <p>En el mundo del desarrollo de software, crear una API RESTful se ha convertido en una necesidad. Las API RESTful son un componente esencial de las aplicaciones web que permiten que diferentes sistemas interactúen entre sí. En este post veremos cómo construir una API RESTful utilizando Java Spring Boot.</p>
                        <h2>¿Qué es una API RESTful?</h2>
                        <p>Una API RESTful es un estilo arquitectónico para servicios web que permite a diferentes sistemas comunicarse entre sí a través de protocolos HTTP. Las API RESTful utilizan métodos HTTP estándar, como GET, POST, PUT y DELETE, para realizar operaciones CRUD (Crear, Leer, Actualizar y Eliminar) en los recursos.</p>
                        <h2>Creación de una API RESTful con Java Spring Boot</h2>
                        <p>Java Spring Boot es un framework popular para crear aplicaciones web y API RESTful. Proporciona un montón de características que facilitan la creación y despliegue de aplicaciones rápidamente. Veamos los pasos para crear una API RESTful utilizando Java Spring Boot.</p>
                        <h3>Paso 1: Configurar el entorno</h3>
                        <p>Para empezar, necesitamos configurar el entorno de desarrollo. Necesitamos instalar Java 8 o superior, Maven y un entorno de desarrollo integrado (IDE) como Eclipse, IntelliJ IDEA o NetBeans.</p>
                        <h3>Paso 2: Crear un nuevo proyecto</h3>
                        <p>Después de configurar el entorno, podemos crear un nuevo proyecto Spring Boot utilizando Maven. Podemos utilizar la herramienta web Spring Initializr para generar una estructura de proyecto o utilizar la línea de comandos para crear una estructura de proyecto. A continuación se muestra un ejemplo de cómo crear un nuevo proyecto utilizando la línea de comandos:</p>
                        <div className="code-text">
                            <SyntaxHighlighter className="codes" language="bash" style={paraisoLight} customStyle={{borderRadius: "1.5em", backgroundColor: "#f4e7e5", border: "2px solid #222222", padding: "2em"}}>
                            {code01}
                            </SyntaxHighlighter>
                        </div>
                        <h3>Paso 3: Añadir dependencias de Spring Boot</h3>
                        <p>En el archivo pom.xml del proyecto, debemos añadir las dependencias de Spring Boot, incluida la dependencia Spring Boot Starter Web. Esto nos proporcionará los componentes necesarios para crear una API RESTful.</p>
                        <h3>Paso 4: Definir los puntos finales de la API</h3>
                        <p>A continuación, tenemos que definir los puntos finales de la API que gestionarán las solicitudes HTTP. Podemos utilizar el framework Spring MVC para definir los puntos finales. Podemos crear una clase controladora y definir los puntos finales utilizando anotaciones, como se muestra a continuación:</p>
                        <div className="code-text">
                            <SyntaxHighlighter className="codes" language="jsx" style={paraisoLight} customStyle={{borderRadius: "1.5em", backgroundColor: "#f4e7e5", border: "2px solid #222222", padding: "2em"}}>
                            {code02}
                            </SyntaxHighlighter>
                        </div>
                        <p>En el código anterior, hemos definido cuatro puntos finales de API para obtener todos los usuarios, crear un nuevo usuario, actualizar un usuario existente y eliminar un usuario.</p>
                        <h3>Paso 5: Crear y ejecutar la aplicación</h3>
                        <p>Después de definir los puntos finales de la API, podemos construir y ejecutar la aplicación utilizando Maven. Podemos ejecutar la aplicación utilizando el plugin de Spring Boot Maven, como se muestra a continuación:</p>
                        <div className="code-text">
                            <SyntaxHighlighter className="codes" language="jsx" style={paraisoLight} customStyle={{borderRadius: "1.5em", backgroundColor: "#f4e7e5", border: "2px solid #222222", padding: "2em"}}>
                            {code03}
                            </SyntaxHighlighter>
                        </div>
                        <p>Una vez que la aplicación se está ejecutando, podemos probar los puntos finales de la API utilizando una herramienta como Postman o curl.</p>
                        <hr />
                        <h2>Conclusión</h2>
                        <p>Crear una API RESTful con Java Spring Boot es fácil y sencillo. En este post hemos visto los pasos para crear una API RESTful utilizando Java Spring Boot. También hemos aprendido acerca de los fundamentos de las API RESTful y cómo funcionan.</p>
                    </div>
                    <Signature/>
                </div>
            </div>
        </>
    )
}