import {Link} from "react-router-dom"
import { Helmet } from "react-helmet";
import Signature from "../../signature/Signature";
export default function SQL() {

    return (
        <>
            <Helmet>
                <title>TBP | ¿Qué es SQL?</title>
            </Helmet>
            <div className="container-3">
                <div className="main-container">
                    <div className="box-title">
                        <h1>¿Qué es SQL?</h1>
                    </div>
                    <div className="topic-links">
                        <div className="card-topic-link">
                            <Link to="/topics/classnotes">
                                <h4><i className="fa-sharp fa-solid fa-circle"></i>&nbsp;&nbsp;&nbsp;Classnotes</h4>
                            </Link>
                        </div>
                        <div className="card-topic-link">
                            <Link to="">
                            <h4><i className="fa-sharp fa-solid fa-circle"></i>&nbsp;&nbsp;&nbsp;Feb 12, 2022</h4>
                            </Link>
                        </div>
                    </div>
                    <div className="sub-container">
                        <p><b>S </b>→ Structured (estructurado)</p>
                        <p><b>Q </b>→ Query (consulta)</p>
                        <p><b>L </b>→ Language (lenguaje)</p>
                        <p>SQL es una forma abreviada del lenguaje de consulta estructurado, y se pronuncia como S-Q-L o a veces como See-Quell.</p>
                        <p>SQL se utiliza para realizar operaciones sobre los registros almacenados en la base de datos, como la actualización de registros, la inserción de registros, la eliminación de registros, la creación y modificación de tablas de la base de datos, las vistas, etc.</p>
                        <p>SQL no es un sistema de base de datos, sino un lenguaje de consulta.</p>
                        <p>Este lenguaje de base de datos está diseñado principalmente para mantener los datos en los sistemas de gestión de bases de datos relacionales.</p>
                        <p>Si quieres conseguir un trabajo en el campo de la ciencia de los datos, entonces es el lenguaje de consulta más importante que debes aprender. Grandes empresas como Facebook, Instagram y LinkedIn utilizan SQL para almacenar los datos en el back-end.</p>
                        <h2>¿Por qué SQL?</h2>
                        <p>Hoy en día, SQL se usa ampliamente en ciencia de datos y análisis. Las siguientes son las razones que explican por qué es ampliamente utilizado:</p>
                        <ul>
                            <li>El uso básico de SQL para profesionales de datos y usuarios de SQL es insertar, actualizar y eliminar datos de la base de datos relacional.</li>
                            <li>SQL permite a los profesionales y usuarios de datos recuperar los datos de los sistemas de gestión de bases de datos relacionales.</li>
                            <li>También les ayuda a describir los datos estructurados.</li>
                            <li>Permite a los usuarios de SQL crear, eliminar y manipular la base de datos y sus tablas.</li>
                            <li>También ayuda a crear la vista, el procedimiento almacenado y las funciones en la base de datos relacional.</li>
                            <li>Le permite definir los datos y modificar los datos almacenados en la base de datos relacional.</li>
                            <li>También permite a los usuarios de SQL establecer los permisos o restricciones en las columnas de la tabla, las vistas y los procedimientos almacenados.</li>
                        </ul>
                        <h2>Tipos de Comandos SQL</h2>
                        <h3>Data Definition Language (DDL)</h3>
                        <ul>
                            <li>Create</li>
                            <li>Alter</li>
                            <li>Drop</li>
                            <li>Truncate</li>
                            <li>Rename</li>
                        </ul>
                        <h3>Data Manipulation Language (DML)</h3>
                        <ul>
                            <li>Insert</li>
                            <li>Update</li>
                            <li>Delete</li>
                            <li>Merge</li>
                        </ul>
                        <h3>Data Control Language (DCL)</h3>
                        <ul>
                            <li>Grant</li>
                            <li>Revoke</li>
                        </ul>
                        <h3>Transaction Control Language (TCL)</h3>
                        <ul>
                            <li>Commit</li>
                            <li>Rollback</li>
                            <li>Save Point</li>
                        </ul>
                        <h3>Data Query Language (DQL)</h3>
                        <ul>
                            <li>Select</li>
                        </ul>
                    </div>
                    <Signature/>
                </div>
            </div>
        </>
    )
}