import {Link} from "react-router-dom";
import { Helmet } from 'react-helmet';
import Signature from "../../signature/Signature";
import "../Posts.css"

export default function Pruebas_Casos_uso() {
    return (
        <>
            <Helmet>
                <title>TBP | Pruebas de Caso de Uso</title>
            </Helmet>
            <div className="container-3">
                <div className="main-container">
                    <div className="box-title">
                        <h1>Pruebas de Caso de Uso</h1>
                    </div>
                    <div className="topic-links">
                        <div className="card-topic-link">
                            <Link to="/topics/testing">
                                <h4><i className="fa-sharp fa-solid fa-circle"></i>&nbsp;&nbsp;&nbsp;Testing</h4>
                            </Link>
                        </div>
                        <div className="card-topic-link">
                            <Link to="">
                            <h4><i className="fa-sharp fa-solid fa-circle"></i>&nbsp;&nbsp;&nbsp;May 24, 2020</h4>
                            </Link>
                        </div>
                    </div>
                    <div className="sub-container">
                        <h2>¿Qué es un caso de test?</h2>
                        <p>Un caso de uso es una descripción de un uso particular del sistema por parte de un actor o usuario. Se utiliza ampliamente en el desarrollo de pruebas a nivel de sistema o aceptación.</p>
                        <h2>¿Qué son las pruebas de caso de uso?</h2>
                        <p>La prueba de casos de uso se define cómo una técnica de prueba de software que ayuda a identificar casos de prueba que cubren todo el sistema, transacción por transacción, desde el inicio hasta el punto final.</p>
                    </div>
                    <Signature/>
                </div>
            </div>
        </>
    )
}