import {Link} from "react-router-dom"
import { Helmet } from "react-helmet";
import Signature from "../../signature/Signature";
import feb2022 from "../../pix/books/February-2022.png";
import mar2022 from "../../pix/books/March-2022.png";
import may2022 from "../../pix/books/May-2022.png";
import jun2022 from "../../pix/books/June-2022.png";
import jul2022 from "../../pix/books/July-2022.png";
import aug2022 from "../../pix/books/August-2022.png";
import sep2022 from "../../pix/books/September-2022.png";
import oct2022 from "../../pix/books/October-2022.png";
import nov2022 from "../../pix/books/November-2022.png";
import dec2022 from "../../pix/books/December-2022.png";

export default function Booksread2022() {

    return (
        <>
            <Helmet>
                <title>TBP | Libros leidos 2022</title>
            </Helmet>
            <div className="container-3">
                <div className="main-container">
                    <div className="box-title">
                        <h1>Libros leidos 2022</h1>
                    </div>
                    <div className="topic-links">
                        <div className="card-topic-link">
                            <Link to="/topics/books">
                                <h4><i className="fa-sharp fa-solid fa-circle"></i>&nbsp;&nbsp;&nbsp;Books</h4>
                            </Link>
                        </div>
                        <div className="card-topic-link">
                            <Link to="">
                            <h4><i className="fa-sharp fa-solid fa-circle"></i>&nbsp;&nbsp;&nbsp;Jan 01, 2023</h4>
                            </Link>
                        </div>
                    </div>
                    <div className="sub-container">
                        <p>Este 2022 retomé la lectura, empecé en febrero con 3 libros: <q>A Vow of Hate</q> and <q>A Vow of Forever</q> by Lylah James, enamorada de estos libros seguí con <q>Ugly Love</q> by Colleen Hoover.</p>
                        <div className="pic-post">
                            <img src={feb2022} alt="libros leidos febrero 2022" />
                        </div>
                        <p>En marzo leí 4 libros: <q>The Kiss Thief</q> by L. J. Shen; <q>Vendetta</q> by Andrea Adrich; <q>Harry Potter and The Philospher's Stone</q> by J. K. Rowling y <q>One night, Two babies</q> by Kathie Denosky</p>
                        <div className="pic-post">
                            <img src={mar2022} alt="libros leidos febrero 2022" />
                        </div>
                        <p>En mayo leí 18 libros: <q>Frenzy</q> by Margotte Channing; <q>Reasonable doubt</q> by Whitney G.; <q>Amante por despecho</q> by Tamara I'hara; <q>The Love Hypothesis</q> by Ali Hazelwood; <q>A thousand boy kisses</q> by Tillie Cole; <q>The Mister</q> by E. L. James, <q>The Protector</q> by Josi Ellen Malpas, <q>Corrupt</q>, <q>Hideway</q>, <q>Kill Swicth</q>, <q>Conclave</q>, <q>Credence</q> y <q>Bully</q> by Penelope Douglas &#40;amo los libros de Penelope Douglas&#41;; <q>Beauty's beast</q>, <q>Beauty & The Thorns</q>, <q>Beauty & The Rose</q> by Lee Savino & Stasia Black; <q>The Deal</q> and <q>The Mistake</q> by Elle Kennedy</p>
                        <div className="pic-post">
                            <img src={may2022} alt="libros leidos febrero 2022" />
                        </div>
                        <p>En junio leí 15 libros: <q>Stinger</q> by Mia Sheridan; <q>Maybe Someday</q> by Colleen Hoover; <q>The Savage & The Swan</q> by Ella fields; <q>The hating game</q> by Sally Thorne; <q>The invisible life of Addie LaRue</q> by V. E. Schwab; <q>Alex, approximately</q> by Jenn Bennet; <q>Slammed</q> by Colleen Hoover; <q>Archer's voice</q> by Mia Sheridan; <q>Frigid</q> by Jennifer L. Armentrout; <q>Culpable</q> by Patricia Geller; <q>On Dublin Street</q> y <q>Until Fountain Bridge</q> by Samatha Young; <q>Killian</q> by Margotte Channing; el mejor libro que he leído <q>From Blood and Ash</q> by Jennifer L. Armentrout; <q>It ends with us</q> by Colleen Hoover.</p>
                        <div className="pic-post">
                            <img src={jun2022} alt="libros leidos febrero 2022" />
                        </div>
                        <p>En julio leí 15 libros: <q>Cian</q> by Margotte Channing; <q>Antes de diciembre</q> y <q>Después de diciembre</q> by Joana Marcus; <q>Shatter Me</q>, <q>Unravel Me</q> y <q>Ignite Me</q> by Tahereh Mafi; <q>The office party</q>, <q>Late night kisses</q>, <q>Sincerely, Carter</q>/ <q>Sincerely, Arizona</q>, <q>Over us, Over you</q>, <q>Thirty day Boyfriend</q> by Whitney G.; <q>Fool me twice</q> by Carrie Aarons; <q>Accidental love</q> by Ajme Williams; <q>Ciatro veces tuya</q> by Eleanor Rigby; <q>Her private avenger</q> by Elle Kennedy.</p>
                        <div className="pic-post">
                            <img src={jul2022} alt="libros leidos febrero 2022" />
                        </div>
                        <p>En agosto leí 7 libros: <q>Todo lo que nunca fuimos</q> by Alice Kellen; <q>Twisted Love</q> by Ana Huang; <q>A court of thorns and roses</q> by Sarah J. Maas; <q>Their virgin's secret</q> by Shayla Black & Lexi Blake; <q>Punk 57</q> by Penelope Douglas; <q>A court of mist and fury</q> by Sarah J. Maas; <q>Destroy Me</q> by Tahereh Mafi.</p>
                        <div className="pic-post">
                            <img src={aug2022} alt="libros leidos febrero 2022" />
                        </div>
                        <p>En Septiembre leí 14 libros: <q>Tragic</q> by Devney Perry; el segundo y tercer libro de mi saga favorita <q>A Kingdom of Flesh and Fire</q>, <q>The Crown of Gilded Bones</q> by Jennifer L. Armentout; <q>It happend one summer</q> by Tessa Bailey; <q>serpent & Dove</q> by Shelby Mahurin; <q>The Krinar captive</q> by Anna Zaires; <q>Birthday girl</q> by Penelope Douglas; <q>Your dad will do</q> by Katee Robert; <q>Close Liaisons</q> by Anna Zaires; <q>Suplícame esclava</q> by Alba Duro; <q>In bed with the devil</q> by Lorraine Heath; <q>The Villain</q> by L. J. Shen; <q>Chase Me</q> by Tessa Bailey; el Spin Off de mi saga favorita <q>A Shadow in The Ember</q> by Jennifer L. Armentrout.</p>
                        <div className="pic-post">
                            <img src={sep2022} alt="libros leidos febrero 2022" />
                        </div>
                        <p>En octubre leí 4 libros: <q>Before Jamaica Lane</q> by Samatha Young; <q>The unhoneymooners</q> by Christina Lauren; <q>Stolen heir</q> by Sophie Lark; <q>Gifting Me to his Best Friend</q> by Katee Robert.</p>
                        <div className="pic-post">
                            <img src={oct2022} alt="libros leidos febrero 2022" />
                        </div>
                        <p>En noviembre leí 9 libros: <q>Bound by Vengeance</q> by Cora Reilly; <q>The Greek's Chosen Wife</q> by Lynne Graham; <q>Kitby</q> by Margotter Channing; <q>Tease</q> by Melanie Harlow; <q>Hook, line and sinker</q> by Tessa Bailey; <q>Insatiable</q> by Melanie Harlow; <q>Empire of Desire</q> by Rina Kent; <q>Mr. October</q> by Bethany Weaver y <q>Devious Lies</q> by Parker S. Huntington.</p>
                        <div className="pic-post">
                            <img src={nov2022} alt="libros leidos febrero 2022" />
                        </div>
                        <p>En diciembre leí 15 libros: <q>Consecuencias de retar a un conde</q> by Lola P. Nieva; <q>Toasty</q> by Alexa Riley; <q>Broken Whispers</q> by Neva Altaj; <q>Branding the virgin</q> by Alexa Riley; <q>Duke of Manhattan</q> by Louise Bay; <q>Brutal Intentions</q> by Lilith Vincent; <q>The risk</q>, <q>Sidetracked</q>, <q>Scarlet Angel</q>, <q>All the lies</q> y <q>Painted all red</q> by A. T. Abby; <q>The Italian's Wife</q> by Lynne Graham; <q>One Moment Please</q> by Amy Daws; <q>Tempt</q> by Melanie Harlow; <q>Ruthlessly Bedded, Forcibly Wedded</q> by Abby Green.</p>
                        <div className="pic-post">
                            <img src={dec2022} alt="libros leidos febrero 2022" />
                        </div>
                        <h2 className="center">¡Leí 104 libros este 2022!</h2>
                    </div>
                    <Signature/>
                </div>
            </div>
        </>
    )
}