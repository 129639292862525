import React from "react";
import {Link} from "react-router-dom";
import { Helmet } from "react-helmet";
import Signature from "../../signature/Signature";
import "../Posts.css";

export default function JavaScript_Rest_Api() {
    return (
        <>
            <Helmet>
                <title>TBP | REST API en JavaScript</title>
            </Helmet>
            <div className="container-3">
                <div className="main-container">
                    <div className="box-title">
                        <h1>REST API en JavaScript</h1>
                    </div>
                    <div className="topic-links">
                        <div className="card-topic-link">
                            <Link to="/topics/javascript">
                                <h4><i className="fa-sharp fa-solid fa-circle"></i>&nbsp;&nbsp;&nbsp;JavaScript</h4>
                            </Link>
                        </div>
                        <div className="card-topic-link">
                            <Link to="">
                            <h4><i className="fa-sharp fa-solid fa-circle"></i>&nbsp;&nbsp;&nbsp;Aug 19, 2022</h4>
                            </Link>
                        </div>
                    </div>
                    <div className="sub-container">
                        <h2>¿Qué es Rest Api?</h2>
                        <p>Las siglas REST significan REpresentational State Transfer (Transferencia de Estado Representativa). Rest es una arquitectura basada en estándares web y utiliza el protocolo HTTP.</p>
                        <ul>
                            <li>Una API REST es una forma de acceder fácilmente a los servicios web sin tener un exceso de procesamiento.</li>
                            <li>Cada vez que se llama a un RESTful, el servidor transfiere al cliente una representación del estado del recurso solicitado.</li>
                        </ul>
                        <h2>Arquitectura Restful</h2>
                        <p>¿Cuáles son las características básicas de REST?</p>
                        <ul>
                            <li>Sin estado: Significa que los datos del cliente no se almacenan en el servidor, la sesión se almacena del lado del cliente (normalmente en el almacenamiento de la sesión).</li>
                            <li>Cliente &#60; - &#62; Servidor: Hay una separación de preocupaciones entre el front-end (cliente) y el back-end (servidor). Funcionan independientemente el uno del otro y ambos son reemplazables.</li>
                            <li>Caché: Los datos del servidor se pueden almacenar en caché en el cliente, lo que puede mejorar la velocidad de rendimiento.</li>
                            <li>Composición de URLs: Utilizamos un enfoque estandarizado para la composición de las URLs base. Por ejemplo, una petición GET a /cities, debería dar lugar a todas las ciudades de la base de datos, mientras que una petición GET a /cities/seattle mostraría la ciudad con un ID de Seattle. Del mismo modo, REST utiliza métodos estándar como GET, PUT, DELETE y POST para realizar acciones. En la siguiente sección veremos estos métodos.</li>
                        </ul>
                        <h2>Rest en acción</h2>
                        <p>HTTP tiene cinco métodos que se utilizan habitualmente en una arquitectura basada en REST: POST, GET, PUT, PATCH y DELETE.</p>
                        <ul>
                            <li>GET: Este método se utiliza para leer (o recuperar) una representación de un recurso.</li>
                            <li>POST: Este método suele utilizarse para crear nuevos recursos. </li>
                            <li>PUT: Se utiliza para actualizar las capacidades y también para crear un recurso.</li>
                            <li>PATCH: Se utiliza para modificar capacidades.</li>
                            <li>DELETE: Se utiliza para eliminar un recurso.</li>
                        </ul>
                        <h2>Forma de interactuar con REST</h2>
                        <p>JavaScript proporciona varias formas de enviar 'peticiones' y obtener 'respuestas'.</p>
                        <ul>
                            <li>Existen algunas librerías nativas así como muchas de terceros que te ayudan a enviar peticiones HTTP o a analizar una respuesta JSON.</li>
                            <ol>
                                <li>XMLHttpRequest</li>
                                <li>Fetch</li>
                                <li>Axios</li>
                                <li>SuperAgent</li>
                            </ol>
                        </ul>
                        <h2>Cómo funciona la API REST</h2>
                        <p>Cuando se realiza una solicitud de cliente a través de una API RESTful, ésta transfiere una representación del estado del recurso al solicitante o endpoint.</p>
                        <ul>
                            <li>Esta información, o representación, se entrega en uno de varios formatos a través de HTTP: JSON (Javascript Object Notation), HTML, XLT, Python, PHP o texto plano.</li>
                            <li>JSON es el formato de archivo más popular porque, a pesar de su nombre, es agnóstico al lenguaje, así como legible tanto por humanos como por máquinas.</li>
                        </ul>
                        <blockquote>REST es un conjunto de directrices esencialmente, mientras que Fetch API es una API para realizar peticiones web.</blockquote>
                        <h2>Ejemplo con Fetch API</h2>
                        <p>La API Fetch es una interfaz moderna que permite realizar peticiones HTTP a servidores desde los navegadores web.</p>
                        <p>Ejemplo de implementación del método POST:</p>
                        {/* <div className="codeblock">
                            <CopyBlock
                            language="javascript"
                            text={`async function postData(url = '', data = {}) {
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });
        return response.json();
    }
                                
    postData('https://example.com/answer', { answer: 42 })
        .then(data => {
        console.log(data);
        });`}
                            codeBlock
                            theme={zenburn}
                            showLineNumbers={false}
                            />
                        </div> */}
                    </div>
                    <Signature/>
                </div>
            </div>
        </>
    )
}