import {Link} from "react-router-dom"
import { Helmet } from "react-helmet";
import Signature from "../../signature/Signature";
import "../Posts.css"
export default function CompilandoC() {

    return (
        <>
            <Helmet>
                <title>TBP | Compilando en C</title>
            </Helmet>
            <div className="container-3">
                <div className="main-container">
                    <div className="box-title">
                        <h1>Compilando en C</h1>
                    </div>
                    <div className="topic-links">
                        <div className="card-topic-link">
                            <Link to="/topics/computer-science">
                                <h4><i className="fa-sharp fa-solid fa-circle"></i>&nbsp;&nbsp;&nbsp;Computer Science</h4>
                            </Link>
                        </div>
                        <div className="card-topic-link">
                            <Link to="">
                            <h4><i className="fa-sharp fa-solid fa-circle"></i>&nbsp;&nbsp;&nbsp;Jan 25, 2021</h4>
                            </Link>
                        </div>
                    </div>
                    <div className="sub-container">
                    <p>Anteriormente se vio cómo escribir nuestro primer programa en C, imprimiendo "Hello, world" en la pantalla:</p>
                        {/* <div className="codeblock">
                            <CopyBlock
                            language="c"
                            text={`#include <stdio.h>;
        int main(void){
            printf("Hello, world\\n");
    }`}
                            codeBlock
                            theme={zenburn}
                            showLineNumbers={false}
                            />
                        </div> */}
                        <p>Se ha compilado con <i>make hello</i> primero, convirtiendo nuestro código fuente en código máquina antes de poder ejecutar el programa compilado con <i>./hello</i>.</p>
                        <p><i>make</i> en realidad es solo un programa que llama a clang un compilador llamado así por el "lenguaje C".</p>
                        <p>Se puede compilar nuestro código fuente, <i>hello.c</i> directamente ejecutando el comando <i>clang hello.c</i> en la terminal: </p>
                        {/* <div className="codeblock">
                            <CopyBlock
                            language="shell"
                            text={`$ clang hello.c
    $`}
                            codeBlock
                            theme={zenburn}
                            showLineNumbers={false}
                            />
                        </div> */}
                        <p>Parece que no pasa nada, lo que significa que no hubo errores. Y si ejecutamos list, con <i>ls</i>, vemos un <i>a.out</i> archivo en nuestro directorio. el nombre de archivo es solo el predeterminado para la salida de <i>clang</i>, y podemos ejecutarlo:</p>
                        {/* <div className="codeblock">
                            <CopyBlock
                            language="shell"
                            text={`$ ./a.out
    hello, world`}
                            codeBlock
                            theme={zenburn}
                            showLineNumbers={false}
                            />
                        </div> */}
                        <p>Podemos agregar un <i>command-line argument</i> o una entrada a un programa en la línea de comandos como palabras adicionales después del nombre del programa. Podemos ejecutar <i>clang -o hello hello.c</i>, donde <i>clang</i> es el nombre del programa <i>-o hello</i> y <i>hello.c</i> son argumentos adicionales. Le estamos diciendo a <i>clang</i> que use <i>hello</i> como nombre de archivo de salida y use <i>hello.c</i> como código fuente. Ahora, podemos ver que <i>hello</i> se crea como salida.</p>
                        <p>Intentemos compilar la segunda versión del programa, donde se pedirá un nombre:</p>
                        {/* <div className="codeblock">
                            <CopyBlock
                            language="c"
                            text={`#include <cs50.h>
    #include <stdio.h>
    int main(void){
        string name = get_string("What's your name?");
        printf("hello, %s\\n, name");
    }`}
                            codeBlock
                            theme={zenburn}
                            showLineNumbers={false}
                            />
                        </div> */}
                        {/* <div className="codeblock">
                            <CopyBlock
                            language="shell"
                            text={`$ clang hello.c
    /usr/bin/ld: /tmp/hello-733e0f.o: in function 'main':
    hello.c:(.text+0x19): undefined reference to 'get_string'
    clang: error: linker command failed with exit code 1 (use -v to see invocation)`}
                            codeBlock
                            theme={zenburn}
                            showLineNumbers={false}
                            />
                        </div> */}
                        <p>Parece que <i>clang</i> no se puede encontrar <i>get_string</i>, pero incluimos <i>cs50.h</i>. Resulta que también le decimos a nuestro compilador que vincule el <i>cs50</i> código de la máquina con las funciones descritas por <i>cs50.h</i>. Tendremos que agregar otro argumento con:</p>
                        {/* <div className="codeblock">
                            <CopyBlock
                            language="shell"
                            text={`$ clang -o hello hello.c -lcs50`}
                            codeBlock
                            theme={zenburn}
                            showLineNumbers={false}
                            />
                        </div> */}
                        <p>Con <i>make</i>, estos comandos se generan automáticamente para nosotros. La cmpilación del código fuente en el código de la máquina en realidad se compone de cuatro pasos más pequeños:</p>
                        <ul>
                            <li>preprocesamiento</li>
                            <li>compilando</li>
                            <li>montaje</li>
                            <li>enlace</li>
                        </ul>
                        <p>El <i>preprocesamiento</i> implica reemplazar líneas que comienzan con #, como #incluye. Por ejemplo, #incluye <i>&#60;cs50.h&#62;</i> le dirá a <i>clang</i> que busque ese archivo de cabecera, ya que contiene contenido, como prototipos de funciones, que queremos incluir en nuestro programa. Luego, <i>clang</i> esencialmente copiará y pegará el contenido de esos archivos de encabezado en nuestro programa.</p>
                        <p>Por ejemplo ...</p>
                        {/* <div className="codeblock">
                            <CopyBlock
                            language="c"
                            text={`...
    string get_string(string prompt);
    ...
    int printf(string format, ...);
    ...
    int main(void){
        string name = get_string("Name: ");
        printf("hello, %s\\n, name");
    }
                            `}
                            codeBlock
                            theme={zenburn}
                            showLineNumbers={false}
                            />
                        </div> */}
                        <p>... será procesado en :</p>
                        {/* <div className="codeblock">
                            <CopyBlock
                            language="c"
                            text={`#include <cs50.h>
    #include <stdio.h>                        
    int main(void){
        string name = get_string("What's your name? ");
        printf("hello, %s\\n", name);
    }
                            `}
                            codeBlock
                            theme={zenburn}
                            showLineNumbers={false}
                            />
                        </div> */}
                        <p><i>string get_string(string prompt);</i> es un prototipo de una función de la <i>cs50.h</i> que queremos usar. La función se llama <i>get_string</i>, y toma a <i>string</i> como argumento, se llama <i>prompt</i> y devuelve un valor del tipo <i>string</i>.</p>
                        <p><i>int printf(string format, ...);</i> es un prototipo de <i>stdio.h</i>, que incluye una serie de argumentos, incluido un <i>string</i> para <i>format</i>.</p>
                        <p><i>La compilación</i> toma nuestro código fuente, en C, y lo convierte a otro lenguaje llamado <i>lenguaje ensamblador</i>, que se ve así:</p>
                        {/* <div className="codeblock">
                            <CopyBlock
                            language="shell"
                            text={`...
    main:                         # @main
        .cfi_startproc
    # BB#0:
        pushq    %rbp
    .Ltmp0:
        .cfi_def_cfa_offset 16
    .Ltmp1:
        .cfi_offset %rbp, -16
        movq    %rsp, %rbp
    .Ltmp2:
        .cfi_def_cfa_register %rbp
        subq    $16, %rsp
        xorl    %eax, %eax
        movl    %eax, %edi
        movabsq    $.L.str, %rsi
        movb    $0, %al
        callq    get_string
        movabsq    $.L.str.1, %rdi
        movq    %rax, -8(%rbp)
        movq    -8(%rbp), %rsi
        movb    $0, %al
        callq    printf
        ...
                            `}
                            codeBlock
                            theme={zenburn}
                            showLineNumbers={false}
                            />
                        </div> */}
                        <p>Tener en cuenta que vemos algunas cadenas reconocibles, como <i>main</i>, <i>get_string</i>, y <i>printf</i>. Pero las otras líneas son instrucciones para operaciones básicas en memoria y valores, más cercanas a las instrucciones binarias que el procesador de una computadora puede entender directamentes.</p>
                        <p>El siguiente paso es tomar el código ensamblado y traducirlo a binario <i>ensamblandolo</i>. Las instrucciones en binario son código de máquina, que la CPU de una computadora puede ejecutar directamente.</p>
                        <p>El último paso es <i>vincular</i>, donde las versiones previamente compiladas de las bibliotecas que incluimos anteriormente, como <i>cs50.c</i>, en realidad se combinan con el binario compilado de nuestro programa. En otras palabras, la vinculación es el proceso de combinar todo el código máquina para <i>hello.c</i>, <i>cs50.c</i> y <i>stdio.c</i> en nuestro archivo binario, <i>a.out</i> o <i>hello</i>.</p>
                        <p>Estos pasos han sido abstraidos o simplificados por <i>make</i>, por lo que podemos pensar en todo el proceso como una compilación.</p>
                    </div>
                    <Signature/>
                </div>
            </div>
        </>
    )
}