import {Link} from "react-router-dom"
import { Helmet } from "react-helmet";
import Signature from "../../signature/Signature";
export default function Reactjs() {

    return (
        <>
            <Helmet>
                <title>TBP | ¿Qué es React js?</title>
            </Helmet>
            <div className="container-3">
                <div className="main-container">
                    <div className="box-title">
                        <h1>¿Qué es React js?</h1>
                    </div>
                    <div className="topic-links">
                        <div className="card-topic-link">
                            <Link to="/topics/classnotes">
                                <h4><i className="fa-sharp fa-solid fa-circle"></i>&nbsp;&nbsp;&nbsp;Classnotes</h4>
                            </Link>
                        </div>
                        <div className="card-topic-link">
                            <Link to="">
                            <h4><i className="fa-sharp fa-solid fa-circle"></i>&nbsp;&nbsp;&nbsp;Mar 21, 2022</h4>
                            </Link>
                        </div>
                    </div>
                    <div className="sub-container">
                        <h2>Construcción de Interfaces de Usuario</h2>
                        <ul>
                            <li>React es una librería de JavaScript gratuita y de código abierto.</li>
                            <li>Se utiliza para construir interfaces de usuario basadas en componentes UI.</li>
                            <li>Fue desarrollada y mantenida por Meta (antes Facebook).</li>
                            <li>Puede utilizarse como base para aplicaciones web de una sola página o para aplicaciones móviles.</li>
                        </ul>
                        <h2>Basado en Componentes</h2>
                        <p>React permite a los desarrolladores construir componentes encapsulados que gestionan su propio estado.</p>
                        <p>A su vez, pueden componerlos para crear interfaces de usuario complejas.</p>
                        <p>La lógica de los componentes se escribe en JavaScript en lugar de plantillas.</p>
                        <p>Se puede pasar fácilmente datos ricos a través de la aplicación y mantener el estado fuera del DOM.</p>
                        <h2>Características de React.js</h2>
                        <ul>
                            <li>Las vistas declarativas hacen que tu código sea más predecible y más fácil de depurar.</li>
                            <li>React puede renderizar en el servidor usando Node.js.</li>
                            <li>React Native ayuda a potenciar las aplicaciones móviles.</li>
                            <li>Un DOM virtual proporciona un gran aumento de rendimiento.</li>
                            <li>Los métodos del ciclo de vida utilizan ganchos que permiten la ejecución de código en determinados puntos del ciclo de vida.</li>
                        </ul>
                        <h2>Usuarios corporativos de React.js</h2>
                        <ul>
                            <li>Facebook</li>
                            <li>Instagram</li>
                            <li>Uber</li>
                            <li>Discord</li>
                            <li>Airbnb</li>
                        </ul>
                    </div>
                    <Signature/>
                </div>
            </div>
        </>
    )
}