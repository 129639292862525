import {Link} from "react-router-dom"
import { Helmet } from 'react-helmet';
import pic1 from "../../pix/python/cap1/py-cap1.png";
import Signature from "../../signature/Signature";
import "../Posts.css"

export default function Cap01_ModulosPip() {
    return (
        <>
            <Helmet>
                <title>TBP | Capítulo 01: Módulos, comentarios y PIP</title>
            </Helmet>
            <div className="container-3">
                <div className="main-container">
                    <div className="box-title">
                        <h1>Capítulo 01: Módulos, comentarios y PIP</h1>
                    </div>
                    <div className="topic-links">
                        <div className="card-topic-link">
                            <Link to="/topics/python">
                                <h4><i className="fa-sharp fa-solid fa-circle"></i>&nbsp;&nbsp;&nbsp;Python</h4>
                            </Link>
                        </div>
                        <div className="card-topic-link">
                            <Link to="">
                            <h4><i className="fa-sharp fa-solid fa-circle"></i>&nbsp;&nbsp;&nbsp;Feb 12, 2022</h4>
                            </Link>
                        </div>
                    </div>
                    <div className="sub-container">
                        <p><b>Escribir el primer programa en python: el programa Hello World.</b></p>
                        <ul>
                            <li>Crear un archivo con el nombre "Hello.py"</li>
                            <li>Imprimir ("¡Hello World!")</li>
                            <li>Ejecutar el archivo (file.py)</li>
                        </ul>
                        <p>Veamos lo que va a ejecutar este código en la parte posterior, en primer lugar vamos a crear un archivo llamado como "hello.py" (puede escribir cualquier nombre según su elección, pero .py es obligatorio) mediante la ejecución de este archivo, verá -&#62; ¡Hola Mundo! impreso en la pantalla.</p>
                        <h2>Modulos</h2>
                        <p>Un módulo es un archivo que contiene un código escrito por otra persona (normalmente) que puede ser importado y utilizado en nuestros programas.</p>
                        <p>Los módulos proporcionan una manera fácil de organizar los componentes en un sistema, sirviendo como paquetes autocontenidos de variables conocidos como espacios de nombres.</p>
                        <h6>tipos de módulos</h6>
                        <div className="picinpost">
                            <img className="" src={pic1} alt="" />
                        </div>
                        <h6>Utilizar Python como calculadora</h6>
                        <p>Podemos utilizar python como calculadora escribiendo "python" + ↵ en el terminal (esto abre REPL).</p>
                        <h6>¿Qué es REPL?</h6>
                        <p>REPL significa Read Evaluate Print Loop</p>
                        <h2>Comentarios</h2>
                        <p>Los comentarios son utilizados por el programador y escribe algo que el programador no quiere ejecutar.</p>
                        <p>''' ''', "#" se utilizan para indicar los comentarios.</p>
                        <p>Esto puede ser usado para marcar el nombre del autor, la fecha, etc.</p>
                        <h6>Ejemplo</h6>
                        <p># author = theblinkpanda.blogspot.com</p>
                        <h6>Tipos de comentarios</h6>
                        <p>Single line → escrito com "#"</p>
                        <p>Multi line → escrito con '''comando'''</p>
                        <h2>PIP</h2>
                        <p>Pip es el gestor de paquetes para Python. Puedes usar pip para instalar un módulo en tu sistema.</p>
                        <h6>Ejemplo</h6>
                        <p>pip install flask → Instala el módulo de Flask</p>
                        <h2>Practicando</h2>
                        <ol>
                            <li>Escribir un programa que imprima I am proud of myself for all the times I kept going.</li>
                            {/* <div className="codeblock">
                                <CopyBlock
                                language="python"
                                text={`print ("I'm proud of myself for all the times I kept going")`}
                                codeBlock
                                theme={zenburn}
                                showLineNumbers={false}
                                />
                            </div>
                            <li>Usar REPL e imprimir la tabla de 2  con ella</li>
                            <div className="codeblock">
                                <CopyBlock
                                language="shell"
                                text={`2 * 1
    >> 2
    2 * 2
    >> 4
    2 * 3
    >> 6`}
                                codeBlock
                                theme={zenburn}
                                showLineNumbers={false}
                                />
                            </div> */}
                            <li>Escribe un programa en Python para imprimir el contenido de un directorio usando el módulo os. Busca en internet la función que lo hace.</li>
                            {/* <div className="codeblock">
                                <CopyBlock
                                language="python"
                                text={`# theblinkpanda.com
    import os
    print (os.listdric)`}
                                codeBlock
                                theme={zenburn}
                                showLineNumbers={false}
                                />
                            </div> */}
                        </ol>
                    </div>
                    <Signature/>
                </div>
            </div>
        </>
    )
}