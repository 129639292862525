import {Link} from "react-router-dom";
import { Helmet } from 'react-helmet';
import Signature from "../../signature/Signature";
import "../Posts.css";

export default function IntroSoftware() {
    return (
        <>
            <Helmet>
                <title>TBP | Introducción a la ingeniería del software</title>
            </Helmet>
            <div className="container-3">
                <div className="main-container">
                    <div className="box-title">
                        <h1>Introducción a la ingeniería del software</h1>
                    </div>
                    <div className="topic-links">
                        <div className="card-topic-link">
                            <Link to="/topics/computer-science">
                                <h4><i className="fa-sharp fa-solid fa-circle"></i>&nbsp;&nbsp;&nbsp;Computer Science</h4>
                            </Link>
                        </div>
                        <div className="card-topic-link">
                            <Link to="">
                            <h4><i className="fa-sharp fa-solid fa-circle"></i>&nbsp;&nbsp;&nbsp;May 07, 2023</h4>
                            </Link>
                        </div>
                    </div>
                    <div className="sub-container">
                        <p>La ingeniería del software es una disciplina que implica el desarrollo de sistemas de software fiables y de alta calidad. Se trata de la aplicación de principios de ingeniería al diseño, desarrollo, mantenimiento y comprobación de software. La ingeniería de software es un aspecto esencial de la tecnología moderna, ya que los sistemas de software están integrados en casi todos los aspectos de nuestra vida cotidiana.</p>
                        <p>En este post veremos una introducción a la ingeniería de software, cubriendo sus conceptos y principios clave.</p>
                        <h2>Conceptos clave de la ingeniería de software</h2>
                        <ol>
                            <li>Ingeniería de requisitos: Es el proceso de recopilación, documentación y análisis de los requisitos de un sistema de software. Implica comprender las necesidades de las partes interesadas en el sistema y traducirlas en un conjunto de requisitos específicos que puedan utilizarse para guiar el proceso de desarrollo.</li>
                            <li>Diseño del software: Una vez establecidos los requisitos de un sistema, el diseño del software implica la creación de un plan detallado sobre cómo se desarrollará el sistema. Esto incluye determinar la arquitectura del sistema, definir sus componentes e interfaces y crear una especificación de diseño detallada.</li>
                            <li>Pruebas de software: Las pruebas son una parte esencial del proceso de desarrollo de software, ya que garantizan que el sistema cumple los requisitos especificados y funciona como se espera. Las pruebas consisten en crear casos de prueba, ejecutarlos y analizar los resultados para identificar y corregir cualquier defecto o error.</li>
                            <li>Mantenimiento del software: Una vez implantado un sistema informático, necesitará un mantenimiento continuo para garantizar que sigue funcionando correctamente y satisface las necesidades cambiantes de sus usuarios. Las actividades de mantenimiento incluyen la corrección de errores, la adición de nuevas funciones y la optimización del rendimiento del sistema.</li>
                        </ol>
                        <h2>Principios de la Ingeniería de Software</h2>
                        <ol>
                            <li>Modularidad: La modularidad se refiere a la práctica de dividir un sistema de software en módulos o componentes más pequeños y manejables. Esto hace que el sistema sea más fácil de entender, desarrollar, probar y mantener.</li>
                            <li>Abstracción: La abstracción consiste en ocultar los detalles de la implementación de un sistema de software tras una interfaz más simple y abstracta. Esto facilita el uso y la comprensión del sistema, así como la reutilización del software.</li>
                            <li>Encapsulación: La encapsulación consiste en agrupar datos y funciones relacionados en una sola unidad u objeto. Esto mejora la organización y el mantenimiento del sistema y facilita la creación de componentes de software reutilizables.</li>
                            <li>Separación de intereses: Este principio consiste en separar un sistema de software en módulos o componentes distintos y no superpuestos, cada uno de los cuales es responsable de una preocupación o función específica. Esto hace que el sistema sea más fácil de entender, probar y mantener, y también mejora su flexibilidad y adaptabilidad.</li>
                        </ol>
                        <h2>Ejemplos prácticos de Ingeniería de Software</h2>
                        <ol>
                            <li>El desarrollo de una nueva aplicación móvil: El proceso de ingeniería de software para el desarrollo de una aplicación móvil implicaría la recopilación de requisitos para determinar la funcionalidad y la interfaz de usuario de la aplicación, el diseño de software para crear un plan detallado para el desarrollo de la aplicación, las pruebas de software para garantizar que la aplicación funciona como se espera, y el mantenimiento continuo del software para hacer frente a cualquier error o problema que surja.</li>
                            <li>El desarrollo de un nuevo sitio web: El proceso de ingeniería de software para el desarrollo de un sitio web implicaría la recopilación de requisitos para determinar el contenido y la experiencia de usuario del sitio, el diseño de software para crear un plan detallado para el desarrollo del sitio, las pruebas de software para garantizar que el sitio funciona como se espera y el mantenimiento continuo del software para solucionar cualquier error o problema que surja y añadir nuevas funciones.</li>
                        </ol>
                        <hr />
                        <p>La ingeniería de software es una disciplina fundamental que desempeña un papel crucial en el desarrollo de sistemas de software fiables y de alta calidad. Al comprender los conceptos y principios clave de la ingeniería de software, los desarrolladores pueden crear sistemas de software que satisfagan las necesidades de sus usuarios, sean fáciles de mantener y evolucionen con el tiempo, y aporten valor a las empresas y organizaciones.</p>
                    </div>
                    <Signature/>
                </div>
            </div>
        </>
    )
}