import {Link} from "react-router-dom"
import { Helmet } from "react-helmet";
import pic1 from "../../pix/java/c3/c3-1.png";
import Signature from "../../signature/Signature";
import "../Posts.css"
export default function Cap3() {

    return (
        <>
            <Helmet>
                <title>TBP | Capítulo 03: Tipos de Datos ... Variables</title>
            </Helmet>
            <div className="container-3">
                <div className="main-container">
                    <div className="box-title">
                        <h1>Capítulo 03: Tipos de Datos ... Variables</h1>
                    </div>
                    <div className="topic-links">
                        <div className="card-topic-link">
                            <Link to="/topics/java">
                                <h4><i className="fa-sharp fa-solid fa-circle"></i>&nbsp;&nbsp;&nbsp;Java</h4>
                            </Link>
                        </div>
                        <div className="card-topic-link">
                            <Link to="">
                            <h4><i className="fa-sharp fa-solid fa-circle"></i>&nbsp;&nbsp;&nbsp;Feb 05, 2022</h4>
                            </Link>
                        </div>
                    </div>
                    <div className="sub-container">
                        <div className="picsminpost">
                            <img className="" src={pic1} alt="" />
                        </div>
                        {/* <div className="codeblock">
                            <CopyBlock
                            language="java"
                            text={`class Simple {
                                public static void main(String[] args) {
                                    int x = 10;
                                    int y = 20;
                                    int z = x + y;
                                    System.out.println(z);
                                }
    }
    ---
    output: 30`}
                            codeBlock
                            theme={zenburn}
                            showLineNumbers={false}
                            />
                        </div> */}
                    </div>
                    <Signature/>
                </div>
            </div>
        </>
    )
}