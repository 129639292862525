import {Link} from "react-router-dom";
import { Helmet } from 'react-helmet';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { paraisoLight } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import Signature from "../../signature/Signature";
import "../Posts.css";

export default function JsxReactjs() {
    const code01 = `const name = 'John';
const greeting = <h1>Hello, {name}!</h1>;
`;
    const code02 = `const element = <div className="container">Hello, world!</div>;`;
    const code03 = `const image = <img src="image.jpg" alt="An image" />;`;
    const code04 = `const button = <button onClick={handleClick}>Click me</button>;`;
    const code05 = `import React from 'react';

function MiComponente() {
    return <h1>¡Hola, JSX!</h1>;
}
    
    export default MyComponent;`;
    return (
        <>
            <Helmet>
                <title>TBP | Entender JSX en React.js</title>
            </Helmet>
            <div className="container-3">
                <div className="main-container">
                    <div className="box-title">
                        <h1>Entender JSX en React.js</h1>
                    </div>
                    <div className="topic-links">
                        <div className="card-topic-link">
                            <Link to="/topics/javascript">
                                <h4><i className="fa-sharp fa-solid fa-circle"></i>&nbsp;&nbsp;&nbsp;JavaScript</h4>
                            </Link>
                        </div>
                        <div className="card-topic-link">
                            <Link to="">
                            <h4><i className="fa-sharp fa-solid fa-circle"></i>&nbsp;&nbsp;&nbsp;May 26, 2023</h4>
                            </Link>
                        </div>
                    </div>
                    <div className="sub-container">
                        <p>JSX (JavaScript XML) es una potente extensión de sintaxis utilizada en React.js que permite escribir código similar a HTML dentro de JavaScript. Sirve como un bloque de construcción fundamental para crear interfaces de usuario dinámicas e interactivas en React. En este post exploraremos JSX para comprender los conceptos básicos de JSX y su integración con React.</p>
                        <h2>¿Qué es JSX?</h2>
                        <p>JSX es una extensión de sintaxis para JavaScript que permite la creación de elementos React. Proporciona una forma concisa y expresiva de describir la estructura y el aspecto de los componentes de la interfaz de usuario. JSX combina la potencia de JavaScript y la sintaxis similar a HTML, facilitando la escritura y el razonamiento sobre componentes React.</p>
                        <h2>Características clave y sintaxis</h2>
                        <h3>1. Incrustación de expresiones</h3>
                        <p>JSX permite incrustar expresiones JavaScript entre llaves `{}`. Esto permite la representación dinámica de valores, variables y llamadas a funciones dentro de su código JSX.</p>
                        <div className="code-text">
                            <SyntaxHighlighter className="codes" language="jsx" style={paraisoLight} customStyle={{borderRadius: "1.5em", backgroundColor: "#f4e7e5", border: "2px solid #222222", padding: "2em"}}>
                            {code01}
                            </SyntaxHighlighter>
                        </div>
                        <p>En este ejemplo, el valor de la variable <b>name</b> se renderiza dinámicamente dentro del código JSX</p>
                        <h3>2. Elementos similares a HTML</h3>
                        <p>JSX se asemeja a la sintaxis HTML, permitiendo definir elementos con etiquetas similares a HTML. Sin embargo, las etiquetas JSX representan componentes React en lugar de elementos HTML.</p>
                        <div className="code-text">
                            <SyntaxHighlighter className="codes" language="jsx" style={paraisoLight} customStyle={{borderRadius: "1.5em", backgroundColor: "#f4e7e5", border: "2px solid #222222", padding: "2em"}}>
                            {code02}
                            </SyntaxHighlighter>
                        </div>
                        <p>En este ejemplo, creamos un componente React usando la etiqueta <b>&#60;div&#62;</b>, especificando el nombre de la clase y el contenido.</p>
                        <h3>3. Etiquetas de cierre automático</h3>
                        <p>Al igual que HTML, JSX permite etiquetas de autocierre para elementos que no tienen hijos.</p>
                        <div className="code-text">
                            <SyntaxHighlighter className="codes" language="jsx" style={paraisoLight} customStyle={{borderRadius: "1.5em", backgroundColor: "#f4e7e5", border: "2px solid #222222", padding: "2em"}}>
                            {code03}
                            </SyntaxHighlighter>
                        </div>
                        <p>En este ejemplo, la etiqueta <b>&#60;img&#62;</b> es autocerrada, ya que no tiene ningún hijo.</p>
                        <h3>4. Atributos y props</h3>
                        <p>JSX soporta atributos y props, que se pasan a los componentes React para configurar su comportamiento.</p>
                        <div className="code-text">
                            <SyntaxHighlighter className="codes" language="jsx" style={paraisoLight} customStyle={{borderRadius: "1.5em", backgroundColor: "#f4e7e5", border: "2px solid #222222", padding: "2em"}}>
                            {code04}
                            </SyntaxHighlighter>
                        </div>
                        <p>En este ejemplo, el atributo <b>onClick</b> se pasa al componente <b>&#60;button&#62;</b>, asociándolo con la función <b>handleClick</b>.</p>
                        <h2>Beneficios de JSX</h2>
                        <ol>
                            <li><b>Legibilidad y familiaridad: </b>JSX combina una sintaxis similar a HTML con JavaScript, lo que lo hace familiar e intuitivo para los desarrolladores, especialmente para aquellos con experiencia en desarrollo web. Mejora la legibilidad del código y reduce la curva de aprendizaje para crear componentes React.</li>
                            <li><b>Expresividad y potencia: </b>JSX permite expresar estructuras complejas de interfaz de usuario y lógica de renderización dinámica de forma concisa y expresiva. Permite el uso de expresiones JavaScript, renderizado condicional y mapeo sobre arrays dentro del código JSX.</li>
                            <li><b>Desarrollo basado en componentes: </b>JSX es una piedra angular de la arquitectura basada en componentes de React. Facilita la composición y reutilización de componentes de interfaz de usuario, permitiendo la creación de bases de código modulares y mantenibles.</li>
                        </ol>
                        <h2>Integración con React</h2>
                        <p>Para utilizar JSX en React, es necesario transpilarlo a JavaScript normal utilizando una herramienta como Babel. Los componentes React se definen utilizando la sintaxis JSX, y las expresiones JSX se evalúan en elementos React durante el tiempo de ejecución.</p>
                        <div className="code-text">
                            <SyntaxHighlighter className="codes" language="jsx" style={paraisoLight} customStyle={{borderRadius: "1.5em", backgroundColor: "#f4e7e5", border: "2px solid #222222", padding: "2em"}}>
                            {code05}
                            </SyntaxHighlighter>
                        </div>
                        <p>En este ejemplo, la función <b>MyComponent</b> define un componente React utilizando la sintaxis JSX, que luego se exporta para su uso en otras partes de la aplicación.</p>
                        <hr />
                        <h2>Conclusión</h2>
                        <p>JSX es un concepto fundamental en React.js que permite a los desarrolladores construir interfaces de usuario ricas y dinámicas. Entendiendo su sintaxis, características e integración con React, puedes aprovechar JSX para escribir código expresivo y mantenible. Con JSX, puede combinar la potencia de JavaScript y la sintaxis similar a HTML, lo que resulta en un desarrollo de React más eficiente y agradable.</p>
                    </div>
                    <Signature/>
                </div>
            </div>
        </>
    )
}