import {Link} from "react-router-dom"
import { Helmet } from "react-helmet";
import Signature from "../../signature/Signature";

export default function MayoWU() {

    return (
        <>
            <Helmet>
                <title>TBP | Mayo Wrap Up</title>
            </Helmet>
            <div className="container-3">
                <div className="main-container">
                    <div className="box-title">
                        <h1>Mayo Wrap Up</h1>
                    </div>
                    <div className="topic-links">
                        <div className="card-topic-link">
                            <Link to="/topics/books">
                                <h4><i className="fa-sharp fa-solid fa-circle"></i>&nbsp;&nbsp;&nbsp;Books</h4>
                            </Link>
                        </div>
                        <div className="card-topic-link">
                            <Link to="">
                            <h4><i className="fa-sharp fa-solid fa-circle"></i>&nbsp;&nbsp;&nbsp;May 31, 2023</h4>
                            </Link>
                        </div>
                    </div>
                    <div className="sub-container">
                        <p>Este ha sido el mes más flojo que he tenido en lo que vá de año. En total leí 5 libros este més, sin contar Wattpad ni Booknet entre otras plataformas. El motivo . . . ciertos libros no me motivaban a leer y demoraba en empezar un libro, así que solo 5 libros.</p>
                        <h2>Defiant Queen</h2>
                        <div className="book-wu">
                            <img src={process.env.PUBLIC_URL + "/books/23-may-01.jpg"} alt="Defiant Queen" />
                        </div>
                        <p>Este libro es el segundo de la trilogía Mount (el primer libro lo leí en abril), como no tenía muchas esperanzas con el primer libro, pensé que una vez terminado el primero, dejaría la trilogía para después, pero me quedé con la intriga del final del primer libro, por lo que tuve que leer este, y me encantó, aun que me dejó con ganas de más por lo que pasé al siguiente.</p>
                        <h2>Sinful Empire</h2>
                        <div className="book-wu">
                            <img src={process.env.PUBLIC_URL + "/books/23-may-02.jpg"} alt="Sinful empire" />
                        </div>
                        <p>Este es el tercero y último libro de la trilogía Mount, con este libro. Concluida esta trilogía quise leer más de este género, donde hay dominantes y sumisos, pero no al extremo.</p>
                        <h2>Vidas Cruzadas</h2>
                        <div className="book-wu">
                            <img src={process.env.PUBLIC_URL + "/books/23-may-03.jpg"} alt="Vidas cruzadas" />
                        </div>
                        <p>Este libro lo tenía guardado desde hace mucho tiempo, pero no me atrevía a leerlo, tenía miedo, soy muy sensible por lo que ciertas escenas me dan coraje o me pongo a llorar, por lo general me lo tomo muy enserio el mundo de la historia dentro del libro, tanta que la siento real. Realmente me hubiese gustado que la chica nunca perdonara a todos los que le dieron la espalda por una confución, aunque pidieran perdón de rodillas o con sangre, me dió mucho coraje pero lo superé y también acepté el final y llegué a un acuerdo con mi cerebro.</p>
                        <h2>One last secret, baby</h2>
                        <div className="book-wu">
                            <img src={process.env.PUBLIC_URL + "/books/23-may-04.jpg"} alt="One last secret baby" />
                        </div>
                        <p>Este es un libro con el que me relajé, fue algo entretenido pero me demoré en leerlo, es la primera vez que leo donde el hombre tiene una hija y se lo oculta a la madre de esta niña que por motivos muy razonables decidió darla en adopción, no me molesté con ella por las decisiones que tomó porque son justificables, las de el no mucho, porque solo se basa en posibilidades, en la incertidumbre de que pueda pasar debido a la decisión que ella tomó en el pasado (muy confuza mi explicación creo yo), pero bueno, al final todo resultó bien, y todos fueron felices, tal y como me gustan, libros con finales felices, aunque a veces soy masoquista y quiero sufrir con un libro.</p>
                        <h2>On a Tuesday</h2>
                        <div className="book-wu">
                            <img src={process.env.PUBLIC_URL + "/books/23-may-05.jpg"} alt="On a tuesday" />
                        </div>
                        <p>Este es el último libro que leí este mes, es un libro mmm dulce por así decirlo, me gustó que fuera alternada la narración con el pasado y presente, para así entender los problemas o circunstancias que sucedieron. Fue un buen libro, justo para terminar el mes.</p>
                    </div>
                    <Signature/>
                </div>
            </div>
        </>
    )
}