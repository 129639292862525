import {Link} from "react-router-dom";
import { Helmet } from 'react-helmet';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { paraisoLight } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import Signature from "../../signature/Signature";
import "../Posts.css";

export default function Interceptor() {
    const code01 = `import axios from 'axios';

// Crear una instancia de Axios
const instance = axios.create();

// Añadir un interceptor de peticiones
instance.interceptors.request.use(
    config => {
    // Modificar la configuración de petición
    // Añadir cabeceras, tokens de autenticación, etc.
    return config;
    },
    error => {
    // Manejar errores de petición
    return Promise.reject(error);
    }
);

// Añadir un interceptor de respuesta
instance.interceptors.response.use(
    response => {
    // Procesar la respuesta
    // Modificar los datos de respuesta, gestionar los errores, etc
    return response;
    },
    error => {
    // Manejar errores de respuesta
    return Promise.reject(error);
    }
);

// Hacer peticiones HTTP usando la instancia
instance.get('https://api.example.com/data')
    .then(response => {
    // Manejar la respuesta
    })
    .catch(error => {
    // Manejar errores de petición o respuesta
    });`;
    return (
        <>
            <Helmet>
                <title>TBP | Entendiendo los Interceptores HTTP</title>
            </Helmet>
            <div className="container-3">
                <div className="main-container">
                    <div className="box-title">
                        <h1>Entendiendo los Interceptores HTTP</h1>
                    </div>
                    <div className="topic-links">
                        <div className="card-topic-link">
                            <Link to="/topics/javascript">
                                <h4><i className="fa-sharp fa-solid fa-circle"></i>&nbsp;&nbsp;&nbsp;JavaScript</h4>
                            </Link>
                        </div>
                        <div className="card-topic-link">
                            <Link to="">
                            <h4><i className="fa-sharp fa-solid fa-circle"></i>&nbsp;&nbsp;&nbsp;Jun 01, 2023</h4>
                            </Link>
                        </div>
                    </div>
                    <div className="sub-container">
                        <p>En el desarrollo web moderno, interactuar con APIs y realizar peticiones HTTP es una tarea común. Para agilizar y mejorar este proceso, muchos frameworks y librerías JavaScript proporcionan una característica llamada interceptores HTTP. En este post vamos a profundizar en el concepto de interceptores HTTP, su propósito, y cómo pueden ser utilizados para mejorar sus peticiones HTTP. Exploraremos algunos ejemplos para comprender la potencia y versatilidad de los interceptores HTTP.</p>
                        <h2>¿Qué son los interceptores HTTP?</h2>
                        <p>Los interceptores HTTP son funciones o clases tipo middleware que interceptan las peticiones HTTP salientes y las respuestas HTTP entrantes. Permiten modificar o mejorar el comportamiento de la solicitud/respuesta, añadir cabeceras, gestionar errores o realizar otras operaciones antes de que la solicitud llegue al servidor o después de recibir la respuesta.</p>
                        <h2>Ventajas de los interceptores HTTP</h2>
                        <ol>
                            <li><b>Lógica centralizada: </b>Los interceptores HTTP proporcionan una ubicación centralizada para manejar las tareas comunes relacionadas con las peticiones y respuestas HTTP. Esto elimina la necesidad de duplicar código a través de diferentes partes de su aplicación y promueve la reutilización de código.</li>
                            <li><b>Autenticación y autorización: </b>Los interceptores pueden utilizarse para añadir automáticamente tokens o cabeceras de autenticación a cada solicitud saliente, garantizando que el usuario está autorizado a acceder a recursos protegidos. Esto simplifica el proceso de gestión de la autenticación en su aplicación.</li>
                            <li><b>Gestión de errores: </b>Al interceptar las respuestas HTTP, puede implementar mecanismos de gestión de errores de forma centralizada. Por ejemplo, puede capturar códigos de error específicos, redirigir a los usuarios a las páginas de error apropiadas o mostrar mensajes de error al usuario.</li>
                            <li><b>Transformación Petición/Respuesta: </b>Los interceptores le permiten transformar los datos de solicitud o respuesta antes de enviarlos o recibirlos. Puede modificar la carga útil, añadir metadatos o transformar el formato de los datos en función de los requisitos de su aplicación.</li>
                        </ol>
                        <h2>Ejemplos de casos de uso</h2>
                        <ol>
                            <li><b>Interceptor de autenticación: </b>Un interceptor de autenticación puede añadir una cabecera de autorización a cada petición saliente, asegurando que el usuario está autenticado antes de acceder a rutas protegidas.</li>
                            <li><b>Interceptor de registro: </b>Un interceptor de registro puede registrar detalles de cada petición y respuesta, como la URL, el método, el código de estado y el tiempo de respuesta. Esto puede ayudar a depurar y supervisar la actividad de la red.</li>
                            <li><b>Interceptr de gestión de errores: </b>Un interceptor de gestión de errores puede interceptar las respuestas de error y gestionarlas de forma coherente, como mostrar mensajes de error al usuario o redirigir a una página de error.</li>
                        </ol>
                        <h2>Implementación de Axios (una librería popular Cliente HTTP)</h2>
                        <p>Axios es una librería cliente HTTP ampliamente utilizada en JavaScript que soporta interceptores. Veamos cómo implementar un interceptor usando Axios:</p>
                        <div className="code-text">
                            <SyntaxHighlighter className="codes" language="jsx" style={paraisoLight} customStyle={{borderRadius: "1.5em", backgroundColor: "#f4e7e5", border: "2px solid #222222", padding: "2em"}}>
                            {code01}
                            </SyntaxHighlighter>
                        </div>
                        <p>En este ejemplo, creamos una instancia de Axios y añadimos un interceptor de petición y otro de respuesta. El interceptor de petición nos permite modificar la configuración de la petición, mientras que el interceptor de respuesta nos permite manejar los datos o errores de la respuesta.</p>
                        <hr />
                        <h2>Conclusión</h2>
                        <p>Los interceptores HTTP proporcionan un potente mecanismo para mejorar y personalizar tus peticiones HTTP en aplicaciones JavaScript. Permiten centralizar la lógica común de solicitud/respuesta, gestionar la autenticación, implementar la gestión de errores y transformar los datos. Al aprovechar los interceptores, puede mejorar la eficiencia, el mantenimiento y la escalabilidad de sus aplicaciones.</p>
                        <p>Recuerda que las distintas bibliotecas y frameworks pueden tener sus propias implementaciones de interceptores, así que asegúrate de consultar la documentación de las herramientas específicas que estés utilizando.</p>
                        <p>Así que, adelante, explora las posibilidades de los interceptores HTTP en tus proyectos, y comprueba cómo pueden agilizar tus interacciones HTTP y mejorar la funcionalidad de tu aplicación y la experiencia del usuario.</p>
                    </div>
                    <Signature/>
                </div>
            </div>
        </>
    )
}