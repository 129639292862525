import {Link} from "react-router-dom"
import { Helmet } from "react-helmet";
import pic1 from "../../pix/classnotes/cps/cps-1.png";
import pic2 from "../../pix/classnotes/cps/cps-2.png";
import pic3 from "../../pix/classnotes/cps/cps-3.png";
import pic4 from "../../pix/classnotes/cps/cps-4.jpg";
import pic5 from "../../pix/classnotes/cps/cps-5.png";
import pic6 from "../../pix/classnotes/cps/cps-6.png";
import pic7 from "../../pix/classnotes/cps/cps-7.png";
import Signature from "../../signature/Signature";
import "../Posts.css";
export default function Calidad_Producto_Software() {

    return (
        <>
            <Helmet>
            <title>TBP | Calidad de Producto de Software</title>
            </Helmet>
            <div className="container-3">
                <div className="main-container">
                    <div className="box-title">
                        <h1>Calidad de Producto de Software</h1>
                    </div>
                    <div className="topic-links">
                        <div className="card-topic-link">
                            <Link to="/topics/classnotes">
                                <h4><i className="fa-sharp fa-solid fa-circle"></i>&nbsp;&nbsp;&nbsp;Classnotes</h4>
                            </Link>
                        </div>
                        <div className="card-topic-link">
                            <Link to="">
                            <h4><i className="fa-sharp fa-solid fa-circle"></i>&nbsp;&nbsp;&nbsp;Jan 28, 2021</h4>
                            </Link>
                        </div>
                    </div>
                    <div className="sub-container">
                        <div className="picsminpost">
                            <img className="" src={pic1} alt="calidad de producto vs calidad de proceso" />
                        </div>
                        <div className="picinpost">
                            <img className="" src={pic2} alt="software de calidad" />
                        </div>
                        <h2>Calidad de Producto de Software</h2>
                        <ul>
                            <li>Diferencia con los procesos del ámbito industrial.</li>
                            <ul>
                                <li>Se desarrollan, no se fabrican (costos y errores en el diseño no en la producción).</li>
                                <li>El software con errores no se rechaza.</li>
                                <li>Es abstracto, no físico (su calidad también).</li>
                                <li>No se degrada con el uso (los errores ya estaban allí y en todas las copias).</li>
                            </ul>
                            <li>Calidad del producto en todos sus estados.</li>
                        </ul>
                        <h2>Modelo de Calidad</h2>
                        <p>Un conjunto de características y la relación entre las mismas, que conforman la base para especificar requerimientos de la calidad y la evaluación de la calidad</p>
                        <p><i>NTP - ISO / IEC 14598</i></p>
                        <div className="picinpost">
                            <img className="" src={pic3} alt="modelo de calidad" />
                        </div>
                        <h2>Modelo de Calidad de Software</h2>
                        <p>Existen varios modelos de calidad de producto:</p>
                        <ul>
                            <li>Modelo de McCall (1997)</li>
                            <li>Modelo de Boehm (1978)</li>
                            <li>Modelo de FURPS+ (1987)</li>
                            <li>Modelo de calidad según ISO / IEC 9126 (1991, 2001)</li>
                            <li>Modelo según IEEE 1061 (1992, 1998)</li>
                            <li>Modelo de Dromey (1995)</li>
                            <li>Modelo de QEST (1999)</li>
                            <li>ISO / IEC 25000 (SQUARE) (2014)</li>
                        </ul>
                        <h2>Modelo de Calidad de McCall</h2>
                        <p>Estructura de los Modelos de Calidad</p>
                        <p><b>Factores: </b>Punto de vista del usuario. Atributos de calidad externos.</p>
                        <p><b>Criterios: </b>Punto de vista del producto. Atributos de calidad internos.</p>
                        <p><b>Métricas: </b>Medidas cuantitativas que agrupan factores y criterios.</p>
                        <p><b>Modelo de Calidad: Factores (Atributos)</b></p>
                        <div className="picinpost">
                            <img className="" src={pic4} alt="modelo de calidad de mccall" />
                        </div>
                        <ul>
                            <li>El Modelo de McCall se enfocó en 3 elementos clave: Revisión, Transición y Operación del Producto.</li>
                            <li>El Modelo de McCall y de Boehm fueron la base para hablar de calidad de producto de software.</li>
                            <li>La evaluación de la calidad de los productos de software se basan en sus características.</li>
                            <li>El modelo que se sigue para la calidad de producto se basa en la ISO / IEC 9126.</li>
                        </ul>
                        <h2>Modelo de Calidad de Producto de Software</h2>
                        <ul>
                            <li>Modelo McCall</li>
                            <ul>
                                <li>3 ejes o puntos de vista</li>
                                <li>11 factores</li>
                                <li>23 criterios</li>
                                <li>41 métricas</li>
                            </ul>
                            <li>Modelo Boehm</li>
                            <ul>
                                <li>7 factores</li>
                                <li>12 criterios</li>
                            </ul>
                            <li>Modelo ISO / IEC 9126 (Modelo de calidad interno/ externo)</li>
                            <ul>
                                <li>6 características</li>
                                <li>27 subcaracterísticas</li>
                                <li>x métricas (gran cantidad)</li>
                            </ul>
                        </ul>
                        <h2>Modelo de Calidad de la ISO / IEC 9126</h2>
                        <p>Desarrollado por la JTC / SC7 de la ISO / IEC</p>
                        <ul>
                            <li>Primera versión: 1991 Modelo de Calidad, 1 norma.</li>
                            <li>Segunda versión: 1988 a 2004</li>
                            <ul>
                                <li>Serie de normas 9126 Modelo de calidad - 4 partes</li>
                                <li>Serie de normas 14598 Evaluación de producto de software</li>
                            </ul>
                            <li>Futuro:</li>
                            <ul>
                                <li>SQUARE: 2005 a 202x</li>
                                <li>Serie de normas 25yyy</li>
                            </ul>
                        </ul>
                        <p><b>Serie ISO / IEC 9126 e ISO / IEC 14598</b></p>
                        <div className="picinpost">
                            <img className="" src={pic5} alt="Serie ISO / IEC 9126 e ISO / IEC 14598" />
                        </div>
                        <p><b>Serie ISO 9126 y Serie ISO 14598</b></p>
                        <div className="picinpost">
                            <img className="" src={pic6} alt="Serie ISO 9126 and Serie ISO 14598" />
                        </div>
                        <h2>NTP - ISO / IEC 9126</h2>
                        <h5>1ra Parte: Modelo de Calidad</h5>
                        <p><b>Anexo A: </b>Métricas (para el SW, para la calidad en uso; selección de métricas y criterio de medición, métricas usadas para acompañar).</p>
                        <p><b>Anexo B: </b>Definiciones de otros estándares.</p>
                        <p><b>Anexo C: </b>Historia del trabajo.</p>
                        <h5>2da Parte: Métricas Externas</h5>
                        <p>Anexo A: Consideraciones cuando se usan las métricas.</p>
                        <p><b>Anexo B: </b>Uso de calidad en uso, métricas externas e internas (ejemplo marco).</p>
                        <p><b>Anexo C: </b>Explicación detallada de los tipos de escalas de métricas y los tipos de mediciones.</p>
                        <h5>3ra Parte: Métricas Internas</h5>
                        <h5>4ta Parte: Métricas de Calidad en Uso</h5>
                        <h3>NTP - ISO / IEC 9126</h3>
                        <ul>
                            <li>Calidad Interna:</li>
                            <ul>
                                <li>Totalidad de las características de un producto de software desde una perspectiva interna.</li>
                            </ul>
                            <li>Calidad Externa:</li>
                            <ul>
                                <li>Totalidad de las características de un producto de software desde una perspectiva interna.</li>
                            </ul>
                            <li>Calidad en Uso:</li>
                            <ul>
                                <li>Perspectiva del usuario de la calidad del producto cuando éste es usado en un ambiente y contexto específico.</li>
                            </ul>
                        </ul>
                        <div className="picinpost">
                            <img className="" src={pic7} alt="calidad del producto" />
                        </div>
                    </div>
                    <Signature/>
                </div>
            </div>
        </>
    )
}