import {Link} from "react-router-dom"
import { Helmet } from 'react-helmet';
import Signature from "../../signature/Signature";
import "../Posts.css"

export default function Preguntas_Python() {
    return (
        <>
            <Helmet>
                <title>TBP | Preguntas de Python</title>
            </Helmet>
            <div className="container-3">
                <div className="main-container">
                    <div className="box-title">
                        <h1>Preguntas de Python</h1>
                    </div>
                    <div className="topic-links">
                        <div className="card-topic-link">
                            <Link to="/topics/python">
                                <h4><i className="fa-sharp fa-solid fa-circle"></i>&nbsp;&nbsp;&nbsp;Python</h4>
                            </Link>
                        </div>
                        <div className="card-topic-link">
                            <Link to="">
                            <h4><i className="fa-sharp fa-solid fa-circle"></i>&nbsp;&nbsp;&nbsp;Apr 11, 2022</h4>
                            </Link>
                        </div>
                    </div>
                    <div className="sub-container">
                        <h5>¿Qué tipo de lenguaje es python? ¿Programación o Scripting?</h5>
                        <p>Python es capaz de Scripting, pero lenguaje de programación como propósito general.</p>
                        <h5>¿Cómo es python un lenguaje interpretado?</h5>
                        <p>Un lenguaje interpretado es cualquier lenguaje de programación que no está en código de nivel de máquina antes del tiempo de ejecución. Por lo tanto, Python es un lenguaje interpretado.</p>
                        <h5>¿Qué es pep8?</h5>
                        <p>El nombre PEP significa Propuesta de Mejora de Python (Python Enhancement Proposal). Es un conjunto de reglas que especifican cómo formatear el código de Python para obtener la máxima legibilidad.</p>
                        <h5>¿Cómo se gestiona la memoria en python?</h5>
                        <ul>
                            <li>La gestión de la memoria en Python se realiza mediante el montón privado de Python. Todos los objetos y estructuras de datos de python se encuentran en el montón privado. Los programadores no tienen acceso a este montón privada. El intérprete de python se encarga de ello.</li>
                            <li>La asignación de espacio en el montón para los objetos de python. Objects se realiza mediante el gestor de memoria de python. La API del núcleo da acceso a algunas herramientas para que el programador codifique.</li>
                            <li>Python también tiene un recolector de basura incorporado que recicla toda la memoria no utilizada y por lo que se puede poner a disposición del espacio de la pila.</li>
                        </ul>
                        <h5>¿Qué es el namespace en python?</h5>
                        <p>Un namespace es un sistema de nomenclatura que se utiliza para asegurarse de que los nombres son únicos para evitar conflictos de nomenclatura.</p>
                        <h5>¿Qué son los módulos de python? Nombre algunos módulos incorporados de uso común en python</h5>
                        <p>Los módulos de Python son archivos que contienen código de Python. Este código puede ser clases de funciones o variables. Un módulo python es un archivo .py que contiene código ejecutable.</p>
                        <p>Algunos de los módulos incorporados más utilizados son:</p>
                        <ul>
                            <li>os</li>
                            <li>sys</li>
                            <li>data time</li>
                            <li>json</li>
                        </ul>
                        <h5>¿Qué son las variables locales y globales en python?</h5>
                        <p><b>Variables globales: </b>Las variables declaradas fuera de una función o en el espacio global se llaman variables globales. Estas variables pueden ser accedidas por cualquier función del programa.</p>
                        <p><b>Variables locales: </b>Cualquier variable declarada dentro de una función se conoce como variable local. Esta variable está presente en el espacio local y no en el global.</p>
                        <h5>¿Python distingue entre mayúsculas y minúsculas?</h5>
                        <p>Sí. Python es un lenguaje que distingue entre mayúsculas y minúsculas.</p>
                        <h5>¿Qué es la conversión de tipos en Python?</h5>
                        <p>La conversión de tipos se refiere a la conversión de un tipo de datos en otro.</p>
                        <p>Por ejemplo:</p>
                        <ul>
                            <li>int()</li>
                            <li>float()</li>
                            <li>set()</li>
                            <li>list()</li>
                        </ul>
                        <h5>¿Es necesario indentar en Python?</h5>
                        <p>La indentación es necesaria en python. Especifica un bloque de código. Todo el código dentro de bucles, clases, etc. dentro de un bloque indentado. Por lo general, se hace utilizando cuatro caracteres de espacio. Si su código no está indentado necesariamente, no se ejecutará con precisión y arrojará errores también.</p>
                        <h5>¿Qué son las funciones en python?</h5>
                        <p>Una función es un bloque de código que se ejecuta sólo cuando es llamado para definir una función en python, se utiliza la palabra clave def.</p>
                        {/* <div className="codeblock">
                            <CopyBlock
                                language="python"
                                text={`def NewFunc():
        print("Hi!")`}
                                codeBlock
                                theme={zenburn}
                                showLineNumbers={false}
                                />
                        </div> */}
                        <h5>¿Qué es __init__?</h5>
                        <p>__init__ es un método o constructor en python. Este método se llama automáticamente para asignar memoria cuando se crea un nuevo objeto de una clase. Todas las clases tienen el método __init__ por Ejemplo:</p>
                        {/* <div className="codeblock">
                            <CopyBlock
                                language="python"
                                text={`Class Example:
        def __init__(self, name):
            self.name = name
    E1 = Example("xyz")
    print(E1.name)`}
                                codeBlock
                                theme={zenburn}
                                showLineNumbers={false}
                                />
                        </div> */}
                        <h5>¿Qué es la función lambda?</h5>
                        <p>Una función anónima se conoce como función lambda. Esta función puede tener cualquier número de parámetros pero sólo tiene una declaración.</p>
                        <p>Ejemplo:</p>
                        {/* <div className="codeblock">
                            <CopyBlock
                                language="python"
                                text={`a = lambda x,y : x + y
    print(a(5,6))`}
                                codeBlock
                                theme={zenburn}
                                showLineNumbers={false}
                                />
                        </div> */}
                        <h5>¿Qué es self en python?</h5>
                        <p>Self es una instancia o un objeto de una clase. En python, se incluye explícitamente como primer parámetro. Este no es el caso en java donde es opcional. Ayuda a diferenciar los métodos y atributos de una clase con variables locales. La variable self en el método init se refiere al objeto recién creado mientras que en otros métodos, se refiere al objeto cuyo método fue llamado.</p>
                        <h5>¿Qué son los iteradores de python?</h5>
                        <p>Los iteradores son objetos que pueden ser recorridos o iterados.</p>
                    </div>
                    <Signature/>
                </div>
            </div>
        </>
    )
}