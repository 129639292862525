import React from "react";
import {Link} from "react-router-dom";
import angular from "../../pix/expic/angular.png";
import react from "../../pix/expic/React.png";
import vue from "../../pix/expic/vue.png";
import node from "../../pix/expic/node.png";
import ember from "../../pix/expic/emberlogo.png";
import Signature from "../../signature/Signature";
import "../Posts.css";
import { Helmet } from "react-helmet";

export default function JavaScript_Framework() {
    return (
        <>
            <Helmet>
                <title>TBP | JavaScript Frameworks</title>
            </Helmet>
            <div className="container-3">
                <div className="main-container">
                    <div className="box-title">
                        <h1>JavaScript Frameworks</h1>
                    </div>
                    <div className="topic-links">
                        <div className="card-topic-link">
                            <Link to="/topics/javascript">
                                <h4><i className="fa-sharp fa-solid fa-circle"></i>&nbsp;&nbsp;&nbsp;JavaScript</h4>
                            </Link>
                        </div>
                        <div className="card-topic-link">
                            <Link to="">
                            <h4><i className="fa-sharp fa-solid fa-circle"></i>&nbsp;&nbsp;&nbsp;Jan 26, 2022</h4>
                            </Link>
                        </div>
                    </div>
                    <div className="sub-container">
                        <h2>Angular</h2>
                        <p>Uno de los más potentes y eficientes frameworks de JavaScript, Angular es un framework de código abierto que se utiliza para desarrollar una Single Page Aplication (SPA). Extiende el HTML en la aplicación e interpreta los atributos para realizar la vinculación de datos.</p>
                        <div className="picxinpost">
                            <img src={angular} alt="" />
                        </div>
                        <h2>React</h2>
                        <p>El framework React ha ganado popularidad muy rápidamente. Se utiliza para desarrollar y operar la interfaz de usuario dinámica de las páginas web de un DOM virtual, y por lo tanto, la integración con cualquier aplicación es más sencilla.</p>
                        
                        <div className="picxinpost">
                            <img src={react} alt="" />
                        </div>
                        <h2>Vue.js</h2>
                        <p>Este framework de JavaScript ya se ha hecho un hueco en el mercado y ha demostrado su valía al ofrecer numerosas funcionalidades. Su modo de integración dual es una de las características más atractivas para crear una SPA de alto nivel.</p>
                        <div className="picxinpost">
                            <img src={vue} alt="" />
                        </div>
                        <h2>Node.js</h2>
                        <p>Node.js es un entorno de ejecución JavaScript del lado del servidor, que funciona en plataformas cruzadas y es de código abierto. El marco es capaz de conducir la arquitectura. Funciona en el entorno de ejecución de JavaScript.</p>
                        <div className="picxinpost">
                            <img src={node} alt="" />
                        </div>
                        <h2>Ember</h2>
                        <p>Ember.js llegó en 2015, y desde entonces, ha ganado popularidad con su amplia área de aplicación. Las características de Ember.js soportan la vinculación de datos de dos vías para el manejo de complicadas Interfaces de Usuario.</p>
                        <div className="picxinpost">
                            <img src={ember} alt="" />
                        </div>
                    </div>
                    <Signature/>
                </div>
            </div>
        </>
    )
}