import {Link} from "react-router-dom"
import { Helmet } from "react-helmet";
import SyntaxHighlighter from 'react-syntax-highlighter';
import { paraisoLight } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import Signature from "../../signature/Signature";

export default function SqlVsMongo() {
    const code01 = `SELECT *
FROM customers
WHERE age > 25
ORDER BY last_name;`;
    const code02 = `db.customers.find({ age: { $gt: 25 } }).sort({ last_name: 1 });`;

    return (
        <>
            <Helmet>
                <title>TBP | SQL vs MongoDB: Elección de la base de datos adecuada para su aplicación</title>
            </Helmet>
            <div className="container-3">
                <div className="main-container">
                    <div className="box-title">
                        <h1>SQL vs MongoDB: Elección de la base de datos adecuada para su aplicación</h1>
                    </div>
                    <div className="topic-links">
                        <div className="card-topic-link">
                            <Link to="/topics/classnotes">
                                <h4><i className="fa-sharp fa-solid fa-circle"></i>&nbsp;&nbsp;&nbsp;Classnotes</h4>
                            </Link>
                        </div>
                        <div className="card-topic-link">
                            <Link to="">
                            <h4><i className="fa-sharp fa-solid fa-circle"></i>&nbsp;&nbsp;&nbsp;May 15, 2023</h4>
                            </Link>
                        </div>
                    </div>
                    <div className="sub-container">
                        <p>Cuando se trata de elegir una base de datos para su aplicación, hay varias opciones disponibles. Dos opciones populares son las bases de datos SQL (Structured Query Language) y MongoDB, una base de datos NoSQL. En este post exploraremos las diferencias entre SQL y MongoDB, sus características, casos de uso y proporcionaremos ejemplos para ayudarle a tomar una decisión informada.</p>
                        <h2>Bases de datos SQL</h2>
                        <p>Las bases de datos SQL se basan en un modelo relacional y utilizan un lenguaje de consulta estructurado (SQL) para almacenar y gestionar datos. Estas son algunas de las características clave de las bases de datos SQL:</p>
                        <ol>
                            <li><b>Estructura: </b>Las bases de datos SQL utilizan tablas con esquemas predefinidos para almacenar datos. Cada tabla consta de filas (registros) y columnas (campos), y las relaciones entre tablas se establecen mediante claves primarias y externas.</li>
                            <li><b>Conformidad ACID: </b>Las bases de datos SQL garantizan el cumplimiento de las normas ACID (Atomicity, Consistency, Isolation, Durability), que aseguran la integridad y fiabilidad de los datos. La conformidad con ACID es fundamental para las aplicaciones que requieren una gran coherencia de los datos.</li>
                            <li><b>Cumplimiento del esquema: </b>Las bases de datos SQL aplican un esquema predefinido, lo que significa que los datos deben ajustarse a la estructura especificada. Los cambios en el esquema exigen modificar la estructura de las tablas, lo que puede llevar mucho tiempo.</li>
                            <li><b>Consultas complejas: </b>Las bases de datos SQL admiten consultas complejas utilizando el lenguaje SQL, lo que permite unir tablas, realizar agregaciones y aplicar diversas condiciones para recuperar datos.</li>
                        </ol>
                        <h2>MongoDB</h2>
                        <p>MongoDB es una base de datos NoSQL orientada a documentos que almacena datos en documentos flexibles similares a JSON. Estas son algunas de las características clave de MongoDB:</p>
                        <ol>
                            <li><b>Flexibilidad de esquemas: </b>MongoDB ofrece flexibilidad de esquema, lo que le permite almacenar diferentes tipos de datos dentro de la misma colección. Esto lo hace adecuado para aplicaciones con estructuras de datos cambiantes o impredecibles.</li>
                            <li><b>Escalabilidad: </b>MongoDB está diseñado para escalar horizontalmente, lo que significa que puede distribuir datos a través de múltiples servidores para manejar altos volúmenes de tráfico y grandes conjuntos de datos.</li>
                            <li><b>Sin joins: </b>MongoDB no admite uniones como las bases de datos SQL. En su lugar, fomenta las estructuras de datos desnormalizadas y la incrustación de datos relacionados dentro de un único documento.</li>
                            <li><b>Lenguaje de consulta: </b>MongoDB utiliza un lenguaje de consulta similar a SQL pero con algunas diferencias. Proporciona amplias capacidades de consulta, incluyendo operaciones de filtrado, ordenación y agregación.</li>
                        </ol>
                        <h2>SQL frente a MongoDB: Casos de uso</h2>
                        <h3>Bases de datos SQL</h3>
                        <ul>
                            <li>Aplicaciones con relaciones complejas entre entidades, como plataformas de comercio electrónico con productos, pedidos y clientes.</li>
                            <li>Aplicaciones que requieren una fuerte consistencia de los datos, como los sistemas financieros o la gestión de inventarios.</li>
                        </ul>
                        <h3>MongoDB</h3>
                        <ul>
                            <li>Aplicaciones con estructuras de datos flexibles y evolutivas, como sistemas de gestión de contenidos o plataformas de redes sociales.</li>
                            <li>Aplicaciones que requieren escalabilidad horizontal y alto rendimiento, como análisis en tiempo real o almacenamiento de datos IoT.</li>
                        </ul>
                        <h2>Ejemplo: Recuperación de datos</h2>
                        <h3>SQL:</h3>
                        <div className="code-text">
                            <SyntaxHighlighter className="codes" language="sql" style={paraisoLight} customStyle={{borderRadius: "1.5em", backgroundColor: "#f4e7e5", border: "2px solid #222222", padding: "2em"}}>
                            {code01}
                            </SyntaxHighlighter>
                        </div>
                        <h3>MongoDB:</h3>
                        <div className="code-text">
                            <SyntaxHighlighter className="codes" language="js" style={paraisoLight} customStyle={{borderRadius: "1.5em", backgroundColor: "#f4e7e5", border: "2px solid #222222", padding: "2em"}}>
                            {code02}
                            </SyntaxHighlighter>
                        </div>
                        <hr />
                        <h2>Conclusión</h2>
                        <p>Elegir entre SQL y MongoDB depende de los requisitos específicos de su aplicación. Las bases de datos SQL ofrecen una gran consistencia de datos, capacidades de consulta complejas y son adecuadas para aplicaciones con datos estructurados. Por otro lado, MongoDB proporciona flexibilidad de esquema, escalabilidad horizontal y es muy adecuada para aplicaciones con estructuras de datos en evolución o necesidades de alta escalabilidad.</p>
                        <p>Considere factores como la estructura de los datos, los requisitos de escalabilidad y el nivel de coherencia de los datos necesario para tomar una decisión informada. Si conoce las diferencias entre SQL y MongoDB, podrá elegir la base de datos adecuada que se ajuste a las necesidades de su aplicación y garantice un rendimiento y una gestión de datos óptimos.</p>
                    </div>
                    <Signature/>
                </div>
            </div>
        </>
    )
}