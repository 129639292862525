import {Link} from "react-router-dom"
import { Helmet } from "react-helmet";
import Signature from "../../signature/Signature";
export default function Shortcuts() {

    return (
        <>
            <Helmet>
                <title>TBP | Dominio de los atajos de macOS para desarrolladores</title>
            </Helmet>
            <div className="container-3">
                <div className="main-container">
                    <div className="box-title">
                        <h1>Dominio de los atajos de macOS para desarrolladores</h1>
                    </div>
                    <div className="topic-links">
                        <div className="card-topic-link">
                            <Link to="/topics/classnotes">
                                <h4><i className="fa-sharp fa-solid fa-circle"></i>&nbsp;&nbsp;&nbsp;Classnotes</h4>
                            </Link>
                        </div>
                        <div className="card-topic-link">
                            <Link to="">
                            <h4><i className="fa-sharp fa-solid fa-circle"></i>&nbsp;&nbsp;&nbsp;May 17, 2023</h4>
                            </Link>
                        </div>
                    </div>
                    <div className="sub-container">
                        <p>Como desarrolladores, nuestro tiempo es valioso, y encontrar formas de trabajar de manera más eficiente es crucial. Una forma de conseguirlo es aprovechar el poder de los atajos de teclado. En este post veremos los métodos abreviados esenciales de macOS que todo desarrollador debería conocer. Estos atajos ayudarán a agilizar tu flujo de trabajo, mejorar la productividad y hacer que navegar por macOS sea pan comido.</p>
                        <h2>1. Comando + Barra espaciadora: Búsqueda Spotlight</h2>
                        <p>La búsqueda en Spotlight es una potente herramienta para acceder rápidamente a aplicaciones, archivos y configuraciones del sistema. Al pulsar Comando + Barra espaciadora se abre Spotlight Search, lo que le permite escribir palabras clave para buscar e iniciar aplicaciones, encontrar archivos, realizar cálculos y mucho más. Ahorra tiempo dedicado a localizar manualmente elementos en tu Mac.</p>
                        <h2>2. Comando + Tabulador: Cambio de aplicación</h2>
                        <p>Pasar de una aplicación a otra no supone ningún esfuerzo con Comando + Tabulador. Al pulsar estas teclas se muestra una lista de las aplicaciones abiertas en ese momento, y puedes pasar de una a otra pulsando Tab mientras mantienes pulsado Comando. Suelta ambas teclas para cambiar a la aplicación seleccionada.</p>
                        <h2>3. Comando + Opción + Esc: Forzar salida</h2>
                        <p>Ocasionalmente, una aplicación puede dejar de responder. Para forzar el cierre de una aplicación, pulse Comando + Opción + Esc. Este atajo abre la ventana de Forzar Salida de Aplicaciones, permitiéndote seleccionar la aplicación problemática y terminarla sin necesidad de navegar por los menús.</p>
                        <h2>4. Comando + C, Comando + V, Comando + X: Copiar, Pegar, Cortar</h2>
                        <p>Estos tres atajos son universales en muchas aplicaciones. Comando + C copia el texto o los archivos seleccionados, Comando + V pega los elementos copiados y Comando + X corta el texto o los archivos seleccionados (los elimina y los copia en el portapapeles). Estos atajos ahorran tiempo a la hora de mover o duplicar contenidos.</p>
                        <h2>5. Comando + Z, Comando + Mayúsculas + Z: Deshacer, Rehacer</h2>
                        <p>Comando + Z deshace la acción anterior, mientras que Comando + Mayúsculas + Z rehace la acción que se deshizo. Estos atajos son inestimables cuando se cometen errores o se necesita retroceder a través de cambios recientes en código o texto.</p>
                        <h2>6. Comando + Mayúsculas + 3, Comando + Mayúsculas + 4: Capturas de pantalla</h2>
                        <p>A menudo es necesario hacer capturas de pantalla para documentar problemas o compartir información visual. Comando + Mayúsculas + 3 realiza una captura de pantalla de toda la pantalla, mientras que Comando + Mayúsculas + 4 permite seleccionar una parte específica de la pantalla para capturarla. Las capturas de pantalla se guardan como archivos de imagen en tu escritorio.</p>
                        <h2>7. Comando + `: Cambiar entre ventanas de la misma aplicación</h2>
                        <p>Cuando tengas abiertas varias ventanas de la misma aplicación, utiliza Comando + ` (backtick) para cambiar rápidamente entre ellas. Ahorra tiempo en comparación con localizar manualmente y hacer clic en la ventana deseada.</p>
                        <h2>8. Comando + Mayúsculas + T: Reabrir pestaña cerrada</h2>
                        <p>En los navegadores web y en algunos editores de texto, Comando + Mayúsculas + T reabre la última pestaña cerrada. Es un atajo práctico cuando cierras accidentalmente una pestaña y quieres restaurarla sin navegar por el historial de navegación.</p>
                        <h2>9. Comando + Barra espaciadora, seguido de Terminal: Iniciar Terminal</h2>
                        <p>Accede rápidamente a la aplicación Terminal pulsando Comando + Barra espaciadora para abrir la búsqueda en Spotlight y escribiendo "Terminal". Pulsa Intro para lanzar Terminal sin necesidad de navegar manualmente por la carpeta Aplicaciones.</p>
                        <h2>10. Comando + Opción + D: Mostrar/Ocultar Dock</h2>
                        <p>Si necesitas más espacio en la pantalla o prefieres un entorno libre de distracciones, utiliza Comando + Opción + D para ocultar o mostrar el Dock. Ayuda a maximizar el espacio de trabajo disponible y minimiza las distracciones.</p>
                        <hr />
                        <h2>Conclusión</h2>
                        <p>Dominar los atajos de macOS puede mejorar significativamente tu productividad como desarrollador. Si incorporas estos atajos esenciales a tu flujo de trabajo, podrás navegar por tu Mac con rapidez, cambiar entre aplicaciones sin esfuerzo, realizar acciones comunes de forma eficiente y agilizar tu proceso de desarrollo. Tómate tu tiempo para practicar e interiorizar estos atajos y verás cómo tu productividad alcanza nuevas cotas.</p>
                    </div>
                    <Signature/>
                </div>
            </div>
        </>
    )
}