import {Link} from "react-router-dom";
import { Helmet } from 'react-helmet';
import Signature from "../../signature/Signature";
import "../Posts.css"

export default function Intro_Testing() {
    return (
        <>
            <Helmet>
                <title>TBP | Introducción al Testing</title>
            </Helmet>
            <div className="container-3">
                <div className="main-container">
                    <div className="box-title">
                        <h1>Introducción al Testing</h1>
                    </div>
                    <div className="topic-links">
                        <div className="card-topic-link">
                            <Link to="/topics/testing">
                                <h4><i className="fa-sharp fa-solid fa-circle"></i>&nbsp;&nbsp;&nbsp;Testing</h4>
                            </Link>
                        </div>
                        <div className="card-topic-link">
                            <Link to="">
                            <h4><i className="fa-sharp fa-solid fa-circle"></i>&nbsp;&nbsp;&nbsp;May 24, 2020</h4>
                            </Link>
                        </div>
                    </div>
                    <div className="sub-container">
                        <p>Es el proceso de evaluación de un sistema o de su componente con la intención de encontrar si cumple los requisitos especificados. El testing se ejecuta en un sistema con el fin de identificar los vacios, errores, o los requisitos faltantes en contra de las exigencias reales.</p>
                        <p>De acuerdo con la norma ANSI / IEEE 1059 el Testing se puede definir como:</p>
                        <ul>
                            <li>Un proceso de análisis de un elemento de software para detectar las diferencias entre las condiciones existentes y las requeridas (es decir defectos / errores / bugs) y para evaluar las características del elemento de software. </li>
                        </ul>
                        <h2>¿Quién hace testing?</h2>
                        <p>Depende del proceso y de los grupos de interés asociados al proyecto.</p>
                        <p>En la industria de TI, las grandes empresas tienen un equipo con responsabilidades para evaluar el software desarrollado en el contexto de los requisitos citados.</p>
                        <p>Los desarrolladores también llevan a cabo las pruebas llamadas Pruebas unitarias.</p>
                        <p>En la mayoría de los casos, los siguientes profesionales participan en la prueba de un sistema dentro de sus respectivas capacidades:</p>
                        <ul>
                            <li>Testes de software</li>
                            <li>Desarrollador de Software</li>
                            <li>Líder de proyecto / Gerente</li>
                            <li>Usuario final</li>
                        </ul>
                        <h2>¿Cuando se inicia el Testing?</h2>
                        <p>Un inicio temprano de las pruebas reduce el costo y el tiempo para corregir y producir el software libre de errores que se entrega al cliente.</p>
                        <p>Sin embargo, en el ciclo de vida de desarrollo de software (SDLC), las pruebas se puede iniciar desde la fase de recopilación de requisitos y continuar hasta el despliegue del software.</p>
                        <p>También depende del modelo de desarrollo que se utiliza. Por ejemplo, en el modelo de cascada, las pruebas formales se lleva a cabo en la fase de pruebas; pero en los modelos incrementales, las pruebas se realizan al final de cada iteración / incremento y toda la aplicación se prueba en el extremo.</p>
                        <p>Las pruebas se realizan en las diferentes formas en cada fase del CVDS:</p>
                        <ul>
                            <li>Durante las fases de recopilación de requisitos, análisis y verificación de requisitos también son consideradas como pruebas.</li>
                            <li>Revisar el diseño en la fase de diseño con la intención de mejorar el diseño también se considera como prueba.</li>
                            <li>Las pruebas realizadas por un desarrollador al finalizar el código también se clasifican como pruebas.</li>
                        </ul>
                        <h2>¿Cuando finaliza el testing?</h2>
                        <p>Es difícil determinar cuando se terminan las pruebas de software, como las pruebas son un proceso que nunca termina y nadie puede pretender que un software este probado al 100%.</p>
                        <p>Los siguientes aspectos son para ser considerados en la finalización del proceso de pruebas:</p>
                        <ul>
                            <li>Plazos de pruebas</li>
                            <li>La finalización de la ejecución de los casos prueba.</li>
                            <li>La finalización de la cobertura funcional y de código en cierto punto determinado.</li>
                            <li>La tasa de Bug's cae por debajo de cierto nivel y no hay errores de alta prioridad que se identifiquen.</li>
                            <li>Decisiones de gestión.</li>
                        </ul>
                    </div>
                    <Signature/>
                </div>
            </div>
        </>
    )
}