import "./TopBar.css";
import { useState } from "react";
import {Link} from "react-router-dom";
import logo from "../pix/TheBlinkPanda-Simple.png"

export default function TopBar() {
    const[click, setClick] = useState(false)
    const handleClick = () => setClick(!click)
    return (
        <>
            <div className="top">
                <div className="topLeft">
                    <Link className="link" to="/"><img className="tbp-logo" src={logo} alt="the blink panda" /></Link>
                </div>
                <div className="topRight">
                    <ul className={click ? "topList active" : "topList"} onClick={handleClick}>
                        <li className="topListItem">
                            <Link className="link" to="/about">About</Link>
                        </li>
                        <li className="topListItem">
                            <Link className="link" to="/topics">Topics</Link>
                        </li>
                        <li className="topListItem">
                            <Link className="link" to="/freebies">Freebies</Link>
                        </li>
                        <li className="topListItem">
                            <Link className="link" to="/books">Books</Link>
                        </li>
                    </ul>
                    <div className="ham" onClick={handleClick}>
                        {click ? (<i className="topIcon fa-solid fa-xmark"></i>):(<i className="topIcon fa-solid fa-bars"></i>)}
                    </div>
                </div>
            </div>
        </>
    )
}