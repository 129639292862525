import {Link} from "react-router-dom";
import { Helmet } from 'react-helmet';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { paraisoLight } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import Signature from "../../signature/Signature";
import "../Posts.css";

export default function CleanCode10() {
    const code01 = `'use strict';`;
    const code02 = `const multiplicar = (a, b) => {
        return a * b;
        };`;
    return (
        <>
            <Helmet>
                <title>TBP | Tips para escribir código JavaScript limpio y eficiente</title>
            </Helmet>
            <div className="container-3">
                <div className="main-container">
                    <div className="box-title">
                        <h1>Tips para escribir código JavaScript limpio y eficiente</h1>
                    </div>
                    <div className="topic-links">
                        <div className="card-topic-link">
                            <Link to="/topics/javascript">
                                <h4><i className="fa-sharp fa-solid fa-circle"></i>&nbsp;&nbsp;&nbsp;JavaScript</h4>
                            </Link>
                        </div>
                        <div className="card-topic-link">
                            <Link to="">
                            <h4><i className="fa-sharp fa-solid fa-circle"></i>&nbsp;&nbsp;&nbsp;Apr 20, 2023</h4>
                            </Link>
                        </div>
                    </div>
                    <div className="sub-container">
                        <p>JavaScript es un lenguaje de programación ampliamente utilizado para el desarrollo web, y es esencial escribir código limpio y eficiente para asegurarse de que su aplicación web funciona sin problemas. En este post veremos diez tips para escribir código JavaScript limpio y eficiente.</p>
                        <h2>1. Utiliza nombres de variables descriptivos</h2>
                        <p>Al escribir código JavaScript, es importante utilizar nombres de variables descriptivos. Los nombres descriptivos de variables facilitan a otros desarrolladores la comprensión de su código y hacen que el mantenimiento y la depuración sean más manejables. Por ejemplo, en lugar de utilizar un nombre de variable como "x", utilice un nombre que describa lo que representa la variable, como "contador" o "precioTotal".</p>
                        <h2>2. Evite las variables globales</h2>
                        <p>Se puede acceder a las variables globales desde cualquier parte del código, lo que puede dificultar la localización de errores y la realización de cambios en la base de código. En su lugar, utilice variables locales siempre que sea posible. Esto te ayudará a evitar colisiones de nombres y a mantener tu código organizado.</p>
                        <h2>3. Minimiza la manipulación del DOM</h2>
                        <p>El Document Object Model (DOM) es una interfaz de programación que representa la estructura de los documentos HTML y XML. Manipular el DOM puede ser lento y consumir muchos recursos, por lo que es esencial minimizar la manipulación del DOM tanto como sea posible. Una forma de hacerlo es utilizando frameworks JavaScript como React o Vue, que utilizan un DOM virtual para optimizar el rendimiento.</p>
                        <h2>4. Utiliza el modo estricto</h2>
                        <p>El modo estricto de JavaScript es una forma de hacer cumplir reglas más estrictas para tu código. Activar el modo estricto puede ayudarle a detectar errores y fallos en las primeras fases del proceso de desarrollo. Para activar el modo estricto, añada la siguiente línea de código al principio de su archivo JavaScript:
                            use strict';</p>
                        <div className="code-text">
                            <SyntaxHighlighter className="codes" language="javascript" style={paraisoLight} customStyle={{borderRadius: "1.5em", backgroundColor: "#f4e7e5", border: "2px solid #222222", padding: "2em"}}>
                            {code01}
                            </SyntaxHighlighter>
                        </div>
                        <h2>5. Evita los bucles anidados</h2>
                        <p>Los bucles anidados pueden convertirse rápidamente en un cuello de botella para el rendimiento del código. En lugar de utilizar bucles anidados, intente encontrar soluciones alternativas, como utilizar una función map o filter. Estas funciones son más rápidas y eficientes, especialmente cuando se trata de grandes conjuntos de datos.</p>
                        <h2>6. Utiliza funciones de flecha</h2>
                        <p>Las funciones en flecha son una forma concisa y limpia de escribir funciones en JavaScript. También son más rápidas que las funciones tradicionales porque no crean un nuevo contexto de ejecución. He aquí un ejemplo de una función de flecha:</p>
                        <div className="code-text">
                            <SyntaxHighlighter className="codes" language="javascript" style={paraisoLight} customStyle={{borderRadius: "1.5em", backgroundColor: "#f4e7e5", border: "2px solid #222222", padding: "2em"}}>
                            {code02}
                            </SyntaxHighlighter>
                        </div>
                        <h2>7. Utiliza Let y Const en lugar de Var</h2>
                        <p>En versiones anteriores de JavaScript, la única forma de declarar variables era con la palabra clave "var". Sin embargo, con la introducción de ES6, ahora tenemos dos nuevas formas de declarar variables: "let" y "const". Estas nuevas palabras clave tienen reglas de ámbito más predecibles, por lo que es más fácil evitar colisiones de nombres y escribir código limpio.</p>
                        <h2>8. No repetir (DRY don't repeat yourself)</h2>
                        <p>DRY es un concepto fundamental en el desarrollo de software. Se trata de escribir código reutilizable y modular. Esto significa evitar duplicar código en la medida de lo posible y escribir funciones que puedan utilizarse en múltiples lugares de tu código base.</p>
                        <h2>9. Utiliza Promises y Async/Await</h2>
                        <p>Promises y async/await son dos características de JavaScript que pueden hacer que la programación asíncrona sea mucho más manejable. Las promesas permiten gestionar operaciones asíncronas como peticiones de red o E/S de archivos, mientras que async/await proporciona una sintaxis más limpia para escribir código asíncrono.</p>
                        <h2>10. Utilizar un linter</h2>
                        <p>Un linter es una herramienta que analiza el código y busca posibles errores e incoherencias. El uso de un linter puede ayudarle a detectar errores y a escribir código más coherente. Algunos linters de JavaScript populares son ESLint y JSHint.</p>
                        <hr />
                        <p>En conclusión, escribir código JavaScript limpio y eficiente es esencial para crear aplicaciones web de alta calidad. Siguiendo estos diez tips, puedes escribir código más fácil de leer, mantener y depurar, al tiempo que mejora el rendimiento y la experiencia de usuario de tu aplicación.</p>
                    </div>
                    <Signature/>
                </div>
            </div>
        </>
    )
}