import {Link} from "react-router-dom"
import { Helmet } from "react-helmet";
import Signature from "../../signature/Signature";
export default function Java_Python() {

    return (
        <>
            <Helmet>
                <title>TBP | Java vs Python</title>
            </Helmet>
            <div className="container-3">
                <div className="main-container">
                    <div className="box-title">
                        <h1>Java vs Python</h1>
                    </div>
                    <div className="topic-links">
                        <div className="card-topic-link">
                            <Link to="/topics/classnotes">
                                <h4><i className="fa-sharp fa-solid fa-circle"></i>&nbsp;&nbsp;&nbsp;Classnotes</h4>
                            </Link>
                        </div>
                        <div className="card-topic-link">
                            <Link to="">
                            <h4><i className="fa-sharp fa-solid fa-circle"></i>&nbsp;&nbsp;&nbsp;Apr 16, 2022</h4>
                            </Link>
                        </div>
                    </div>
                    <div className="sub-container">
                        <table>
                            <tr>
                                <th>Java</th>
                                <th>Python</th>
                            </tr>
                            <tr>
                                <td>Java es un lenguaje de programación de alto nivel y orientado a objetos, desarrollado originalmente por James Gosling en Sun Microsystems en 1995.</td>
                                <td>Python es un lenguaje de programación de alto nivel y orientado a objetos, un lenguaje de programación interpretado. Fue inventado en 1991 por Guido Van Rossum.</td>
                            </tr>
                            <tr>
                                <td>Java es un lenguaje estáticamente tipado y compilado.</td>
                                <td>Python es un lenguaje interpretado y de tipado dinámico.</td>
                            </tr>
                            <tr>
                                <td>Java tiene un gran número de Frameworks. Los más populares son Spring, Hibernate, etc.</td>
                                <td>Comparado con Java, Python tiene un menor numero de Freameworks. Los más populares son Django, Flask.</td>
                            </tr>
                            <tr>
                                <td>En Java, la sintaxis es compleja ya que arroja un error si se omite el ";" o "&#123; &#125;".</td>
                                <td>En Python, la sintaxis es fácil de recordar casi similar al lenguaje humano.</td>
                            </tr>
                            <tr>
                                <td>Java Database Connectivity es el más popular y ampliamente utilizado para conectar con la base de datos.</td>
                                <td>Las capas de acceso a la base de datos de Python son más débiles que JDBC de Java. Por eso se utiliza poco en las empresas.</td>
                            </tr>
                            <tr>
                                <td>En Java, cualquier ordenador o dispositivo móvil que sea capaz de ejecutar la máquina virtual Java puede ejecutar una aplicación Java.</td>
                                <td>En Python, los programas Python necesitan un intérprete instalado en la máquina de destino para traducir el código Python. En comparación con Java, Python es menos portable</td>
                            </tr>
                            <tr>
                                <td>Java es antiguo y todavía se utiliza mucho, por lo que también tiene muchas bibliotecas y una comunidad de apoyo.</td>
                                <td>Python ha ganado popularidad, en gran parte, debido a su comunicatividad; la gente lo entiende más fácilmente. Además, las bibliotecas para Python son inmensas, por lo que un nuevo programador no tendrá que empezar desde cero.</td>
                            </tr>
                            <tr>
                                <td>Java es lo mejor para aplicaciones de interfaz gráfica de escritorio, sistemas integrados, servicios de aplicaciones web, etc.</td>
                                <td>Python es excelente para la computación científica y numérica, aplicaciones de aprendizaje automático, etc.</td>
                            </tr>
                        </table>
                    </div>
                    <Signature/>
                </div>
            </div>
        </>
    )
}