import {Link} from "react-router-dom"
import { useEffect, useState } from "react";
import "./Blog.css";
import { Helmet } from "react-helmet";
export default function Blog() {
    const [data,setData]=useState([]);
    const getData=()=>{
        fetch('../topics.json',
        {
        headers : { 
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        }
        }
        )
        .then(function(response){
            console.log(response)
            return response.json();
        })
        .then(function(myJson) {
            console.log(myJson);
            setData(myJson)
        });
    }
    useEffect(()=>{
        getData()
    },[])
    return (
        <>
            <Helmet>
                <title>TBP | Topics</title>
            </Helmet>
            <div className="container-3">
                <div className="container-mix">
                    <div className="box-title">
                        <h1>Topics</h1>
                    </div>
                    <div className="blog-topics">
                    {data.map(item => ( 
                            <Link className="link" to={`/topics/${item.link}`}>
                                <div className="card-postx">
                                    <i className={`label-topic ${item.icon}`}></i>
                                        <h4 className="text-topic">{item.name}</h4>
                                    </div>
                            </Link>
                    ))}
                    </div>
                </div>
            </div>
        </>
    )
}