import {Link} from "react-router-dom"
import { Helmet } from "react-helmet";
import Signature from "../../signature/Signature";
export default function Identificacion_Activos_Sistema() {

    return (
        <>
            <Helmet>
                <title>TBP | Identificación de los Activos del Sistema</title>
            </Helmet>
            <div className="container-3">
                <div className="main-container">
                    <div className="box-title">
                        <h1>Identificación de los Activos del Sistema</h1>
                    </div>
                    <div className="topic-links">
                        <div className="card-topic-link">
                            <Link to="/topics/classnotes">
                                <h4><i className="fa-sharp fa-solid fa-circle"></i>&nbsp;&nbsp;&nbsp;Classnotes</h4>
                            </Link>
                        </div>
                        <div className="card-topic-link">
                            <Link to="">
                            <h4><i className="fa-sharp fa-solid fa-circle"></i>&nbsp;&nbsp;&nbsp;Jan 27, 2022</h4>
                            </Link>
                        </div>
                    </div>
                    <div className="sub-container">
                        <h2>Activos</h2>
                        <p>Son los recursos que pertenecen al propio sistema de información o que están relacionados con este. La presencia de los activos facilita el funcionamiento de la empresa u organización y la consecución de sus objetivos. Al hacer un estudio de los activos existentes hay que tener en cuenta la relación que guardan entre ellos y la influencia que se ejercen: cómo afectaría en uno de ellos un daño ocurrido a otro.</p>
                        <p>Podemos clasificarlos en los siguientes tipos:</p>
                        <h3>Datos</h3>
                        <p>Constituyen el núcleo de toda organización, hasta tal punto que se tiende a considerar que el resto de los activos están al servicio de la protección de los datos. Normalmente están organizados en bases de datos y almacenados en soportes de diferente tipo. El funcionamiento de una empresa u organización depende de sus datos, que pueden ser de todo tipo: económicos, fiscales, de recursos humanos, clientes o proveedores. Cada tipo de dato merece un estudio independiente de riesgo por la repercusión que su deterioro o pérdida pueda causar, como por ejemplo los relativos a la intimidad y honor de las personas u otros de índole confidencial.</p>
                        <h3>Software</h3>
                        <p>Constituido por los sistemas operativos y el conjunto de aplicaciones instaladas en los equipos de un sistema de información que reciben y gestionan o transforman los datos para darles el fin que se tenga establecido.</p>
                        <h3>Hardware</h3>
                        <p>Se trata de los equipos (servidores y terminales) que contienen las aplicaciones y permiten su funcionamiento, a la vez que almacenan los datos del sistema de información. Incluimos en este grupo los periféricos y elementos accesorios que sirven para asegurar el correcto funcionamiento de los equipos o servir de vía de transmisión de los datos (módem, router, instalación eléctrica o sistemas de alimentación ininterrumpida, destructores de soportes informáticos).</p>
                        <h3>Redes</h3>
                        <p>Desde las redes locales de la propia organización hasta las metropolitanas o internet. Representan la vía de comunicación y transmisión de datos a distancia.</p>
                        <h3>Soportes</h3>
                        <p>Los lugares en donde la información queda registrada y almacenada durante largos períodos o de forma permanente (DVD, CD, tarjetas de memoria, discos duros externos dedicados al almacenamiento, microfilms e incluso papel).</p>
                        <h3>Instalaciones</h3>
                        <p>Son los lugares que albergan los sistemas de información y de comunicaciones. Normalmente se trata de oficinas, despachos, locales o edificios, pero también pueden ser vehículos y otros medios de desplazamiento.</p>
                        <h3>Personal</h3>
                        <p>El conjunto de personas que interactúan con el sistema de información: administradores, programadores, usuarios internos y externos y resto de personal de la empresa. Los estudios calculan que se producen más fallos de seguridad por intervención del factor humano que por fallos en la tecnología.</p>
                        <h3>Servicios</h3>
                        <p>Que se ofrecen a clientes o usuarios: productos, servicios, sitios web, foros, correo electrónico y otros servicios de comunicaciones, información, seguridad, etc.</p>
                        <h2>Amenazas</h2>
                        <p>En sistemas de información se entiende por amenaza la presencia de uno o más factores de diversa índole (personas, máquinas o sucesos) que de tener la oportunidad atacarían al sistema produciéndole daños aprovechándose de su nivel de vulnerabilidad. Hay diferentes tipos de amenazas de las que hay que proteger al sistema, desde las físicas como cortes eléctricos, fallos del hardware o riesgos ambientales hasta los errores intencionados o no de los usuarios, la entrada de software malicioso (virus, troyanos, gusanos) o el robo, destrucción o modificación de la información.</p>
                        <p>En función del tipo de alteración, daño o intervención que podrían producir sobre la información, las amenazas se clasifican en cuatro grupos:</p>
                        <h3>De interrupción</h3>
                        <p>El objetivo de la amenaza es deshabilitar el acceso a la información; por ejemplo, destruyendo componentes físicos como el disco duro, bloqueando el acceso a los datos, o cortando o saturando los canales de comunicación.</p>
                        <h3>De interceptación</h3>
                        <p>Personas, programas o equipos no autorizados podrían acceder a un determinado recurso del sistema y captar información confidencial de la organización, como pueden ser datos, programas o identidad de personas.</p>
                        <h3>De modificación</h3>
                        <p>Personas, programas o equipos no autorizados no solamente accederían a los programas y datos de un sistema de información, sino que además los modificarían. Por ejemplo, modificar la respuesta enviada a un usuario conectado o alterar el comportamiento de una aplicación instalada.</p>
                        <h3>De fabricación</h3>
                        <p>Agregarían información falsa en el conjunto de información del sistema.</p>
                        <p>Según su origen las amenazas se clasifican en:</p>
                        <ul>
                            <li><b>Accidentales: </b>Accidentes meteorológicos, incendios, inundaciones, fallos en los equipos, en las redes, en los sistemas operativos o en el software, errores humanos.</li>
                            <li><b>Intencionadas: </b>Son debidas siempre a la acción humana, como la introducción de software malicioso malware (aunque este penetre en el sistema por algún procedimiento automático, su origen es siempre humano), intrusión informática (con frecuencia se produce previa la introducción de malware en los equipos), robos o hurtos. Las amenazas intencionadas pueden tener su origen en el exterior de la organización o incluso en el personal de la misma.</li>
                        </ul>
                        <h2>Riesgo</h2>
                        <p>Se denomina riesgo a la posibilidad de que se materialice o no una amenaza aprovechando una vulnerabilidad. No constituye riesgo una amenaza cuando no hay vulnerabilidad ni una vulnerabilidad cuando no existe amenaza para la misma.</p>
                        <p>Ante un determinado riesgo, una organización puede optar por tres alternativas distintas:</p>
                        <ul>
                            <li>Asumirlo sin hacer nada. Esto solamente resulta lógico cuando el perjuicio esperado no tiene valor alguno o cuando el coste de aplicación de medidas superaría al de la reparación del daño.</li>
                            <li>Aplicar medidas para disminuirlo o anularlo.</li>
                            <li>Transferirlo (por ejemplo, contratando un seguro).</li>
                        </ul>
                        <h2>Vulnerabilidad</h2>
                        <p>Probabilidades que existen de que una amenaza se materialice contra un activo. No todos los activos son vulnerables a las mismas amenazas. Por ejemplo, los datos son vulnerables a la acción de los hackers, mientras que una instalación eléctrica es vulnerable a un cortocircuito. Al hacer el análisis de riesgos hay que tener en cuenta la vulnerabilidad de cada activo.</p>
                        <h2>Ataques</h2>
                        <p>Se dice que se ha producido un ataque accidental o deliberado contra el sistema cuando se ha materializado una amenaza.</p>
                        <p>En función del impacto causado a los activos atacados, los ataques se clasifican en:</p>
                        <ul>
                            <li><b>Activas: </b>Si modifican, dañan, suprimen o agregan información, o bien bloquean o saturan los canales de comunicación.</li>
                            <li><b>Pasivos: </b>Solamente acceden sin autorización a los datos contenidos en el sistema. Son los más difíciles de detectar.</li>
                        </ul>
                        <p>Un ataque puede ser directo o indirecto, si se pro duce desde el atacante al elemento “víctima” directamente, o a través de recursos o personas intermediarias.</p>
                        <h2>Impactos</h2>
                        <p>Son la consecuencia de la materialización de una o más amenazas sobre uno o varios activos aprovechando la vulnerabilidad del sistema o, dicho de otra manera, el daño causado.</p>
                        <p>Los impactos pueden ser cuantitativos, si los perjuicios pueden cuantificarse económicamente, o cualitativos, si suponen daños no cuantificables, como los causados contra los derechos fundamentales de las personas.</p>
                    </div>
                    <Signature/>
                </div>
            </div>
        </>
    )
}