import { Helmet } from "react-helmet";

export default function Resume() {
    return (
        <div>
            <Helmet>
                <title>TBP | Resume</title>
            </Helmet>
            <div className="container">
                <div className="box-title">
                    <h1>Resume</h1>
                </div>
            </div>
        </div>
    )
}