import {Link} from "react-router-dom"
import { Helmet } from "react-helmet";
import pic1 from "../../pix/bpm/identificacion-proceso/niveles-arquitectura-procesos.png"
import Signature from "../../signature/Signature";
import "../Posts.css"
export default function Identificacion_proceso() {

    return (
        <>
            <Helmet>
                <title>TBP | Identificación del Proceso</title>
            </Helmet>
            <div className="container-3">
                <div className="main-container">
                    <div className="box-title">
                        <h1>Identificación del Proceso</h1>
                    </div>
                    <div className="topic-links">
                        <div className="card-topic-link">
                            <Link to="/topics/bpm">
                                <h4><i className="fa-sharp fa-solid fa-circle"></i>&nbsp;&nbsp;&nbsp;Bpm</h4>
                            </Link>
                        </div>
                        <div className="card-topic-link">
                            <Link to="">
                            <h4><i className="fa-sharp fa-solid fa-circle"></i>&nbsp;&nbsp;&nbsp;Mar 21, 2022</h4>
                            </Link>
                        </div>
                    </div>
                    <div className="sub-container">
                        <p>Definir sistemáticamente los procesos de negocio de una empresa y establecer criterios claros para priorizarlos.</p>
                            <p>Como salida → Arquitectura del proceso</p>
                            <p>Marco de definición de prioridades y alcance para proyectos de modelado y rediseño de procesos.</p>
                            <h2>Centrarse en los procesos clave</h2>
                            <h3>¿En qué enfocarse?</h3>
                            <p>No es rentable modelar / analizar / rediseñar / implementar / soportar / monitorizar (a través de BPM) toda la organización de procesos. Tiene que pagar.</p>
                            <ul>
                                <li>Centrarse en un subconjunto de procesos.</li>
                            </ul>
                            <p>¿Qué procesos se ejecutan? Y ¿En cuáles debería enfocarse la organización?</p>
                            <p>Un mapa que describe todos los procesos y los mantiene actualizados.</p>
                            <p>Criterios claros para determinar la importancia del proceso.</p>
                            <ul>
                                <li>Los procesos deben recibir prioridad en función de:</li>
                                <ul>
                                    <li>Procesos con importancia estratégica (para la supervivencia de una organización)</li>
                                    <ul>
                                        <li>Procesos donde es posible crear gran valor</li>
                                    </ul>
                                    <li>Procesos con problemas llamativos</li>
                                    <ul>
                                        <li>Procesos donde un problema significativo está presente</li>
                                    </ul>
                                </ul>
                                <li>Los procesos pueden cambiar de prioridad si es necesario</li>
                                <ul>
                                    <li>Los problemas pueden resolverse solos</li>
                                    <li>Surgen nuevos problemas</li>
                                    <li>Procesos que son estratégicamente importantes, se vuelven menos/más importantes</li>
                                </ul>
                            </ul>
                            <h3>La fase de designación</h3>
                            <p>Cuando una organización se está convirtiendo en una organización centrada en procesos, hay cosas que considerar:</p>
                            <ul>
                                <li>Qué cadena de operaciones es un proceso de negocio único.</li>
                                <li>¿Qué cadenas forman parte de otro proceso?</li>
                                <li>La idea de la gestión de procesos es gestionar activamente los procesos de negocio en la búsqueda de satisfacer a sus clientes específicos.</li>
                            </ul>
                            <p>Categorizar los procesos de negocio:</p>
                            <ul>
                                <li>Existencia de solo 2 procesos: Gestión de la línea de productos y Gestión del ciclo de pedidos</li>
                                <li>Categorías de procesos según Porter:</li>
                                <ul>
                                    <li>Procesos centrales (actividades primarias)</li>
                                    <ul>
                                        <li>Creación de valor esencial, producción de bienes/servicios por los que pagan los clientes.</li>
                                        <li>Logística de entrada, operaciones, logística de salida, marketing y ventas y servicios</li>
                                    </ul>
                                    <li>Procesos de apoyo (actividades de apoyo)</li>
                                    <ul>
                                        <li>Permitir la ejecución de procesos centrales</li>
                                        <li>Infraestructura, recursos humanos, desarrollo tecnológico y adquisiciones.</li>
                                    </ul>
                                    <li>Procesos de gestión (algunos autores)</li>
                                    <ul>
                                        <li>Proceso periódico para evaluar la fuerza de los competidores.</li>
                                    </ul>
                                </ul>
                                <li>La distinción de los procesos básicos, de apoyo y de gestión es de importancia estratégica para una empresa.</li>
                            </ul>
                            <p>Conclusión: El número de procesos que se identifican en la fase de designación debe representar un compromiso entre impacto y manejabilidad.</p>
                            <ul>
                                <li>Pequeño número de procesos == numerosas operaciones por proceso.</li>
                                <ul>
                                    <li>El impacto al cambiar un proceso es mayor.</li>
                                    <li>Más difícil de manejar.</li>
                                    <li>Los modelos realistas tardarían mucho tiempo en desarrollarse y serían extremadamente complejos.</li>
                                    <li>La participación de una gran cantidad de personal hará que la comunicación efectiva entre ellos sea problemática.</li>
                                    <li>Mantener los modelos actualizados sería muy difícil.</li>
                                    <li>Los proyectos de mejora que están relacionados con proyectos grandes son más complejos.</li>
                                </ul>
                                <li>Identificar procesos amplios y estrechos.</li>
                                <ul>
                                    <li>Procesos amplios = Donde una organización sintió que es importante revisar completamente las operaciones existentes. (ej. fuerzas competitivas)</li>
                                    <li>Procesos estrechos = No están destinados a revisiones importantes. Monitoreado activamente, continuamente actualizado y ajustado. (ej. sugerencias de mejora de los empleados)</li>
                                </ul>
                            </ul>
                            <p>Además de una visión detallada de los procesos de negocio existentes, también es necesario comprender las relaciones entre los procesos.</p>
                            <ul>
                                <li>En organizaciones con procesos amplios y estrechos, es importante mapear cómo estos procesos estrechos se relacionan con procesos amplios.</li>
                                <li>Relaciones jerárquicas entre procesos = Relaciones sobre relaciones amplias-estrechas de procesos.</li>
                                <ul>
                                    <li>(ejemplo) La gestión (amplia) consta de: reserva de pedidos, facturación, envío y entrega (todos acotados).</li>
                                </ul>
                                <li>Ilustra cómo los procesos pueden relacionarse secuencialmente.</li>
                                <ul>
                                    <li>Proceso aguas arriba = Si un proceso ocurre antes que otro pero son del mismo proceso amplio.</li>
                                    <ul>
                                        <li>(ejemplo) La facturación y el envío se encuentran en el mismo proceso amplio. Pero la facturación se produce antes del envío).</li>
                                    </ul>
                                    <li>Proceso aguas abajo = Si un proceso ocurre después de otro, pero son del mismo proceso amplio.</li>
                                    <ul>
                                        <li>(ejemplo) La facturación y el envío se encuentran en el mismo proceso amplio. Pero el envío se produce después de la facturación).</li>
                                    </ul>
                                </ul>
                                <li>Obtener comprensión sobre la importancia y el resultado de un proceso como entrada para otro proceso.</li>
                            </ul>
                            <p>Modelos de referencia</p>
                            <ul>
                                <li>Si bien los procesos están sujetos a diferentes elecciones de diseño y preferencias de una organización, se encuentran disponibles algunas pautas generales en forma de modelos de referencia.</li>
                                <li>Desarrollado por una variedad de organizaciones diferentes, desde organizaciones sin fines de lucro hasta programas gubernamentales de investigación.</li>
                                <li>Ejemplos más conocidos:</li>
                                <ul>
                                    <li>ITIL, Biblioteca de Infraestructura de Tecnologías de la Información.</li>
                                    <li>SCOR, Modelo de Referencia de Operaciones de la Cadena de Suministro.</li>
                                    <li>PCF, marco de clasificación de procesos.</li>
                                    <li>APQC, Centro Americano de Productividad y Calidad.</li>
                                    <li>VRM, Modelo de Referencia de Valor.</li>
                                    <li>Marco de rendimiento.</li>
                                </ul>
                                <li>Estos modelos estandarizan:</li>
                                <ul>
                                    <li>Lo que se puede ver como diferentes procesos con características únicas y entregando productos distinguibles.</li>
                                    <li>Cómo se puede medir el rendimiento de un proceso.</li>
                                </ul>
                            </ul>
                            <p>Arquitectura de procesos</p>
                            <ul>
                                <li>Organiza una visión general sobre los procesos que existen dentro de un contexto organizacional.</li>
                            </ul>
                            <h3>La fase de evaluación</h3>
                            <p>No todos los procesos son igualmente importantes y no reciben la misma atención.</p>
                            <p>La gestión de procesos implica: Compromiso, propiedad, inversión en mejora del rendimiento, optimización.</p>
                            <p>Procesos que generan demanda de pérdida o riesgo de: consolidación, desmantelamiento o eliminación.</p>
                            <p>Criterios para evaluar una evaluación de proceso:</p>
                            <ul>
                                <li>Importancia: Evaluar la relevancia estratégica de cada proceso.</li>
                                <ul>
                                    <li>Mayor impacto en los objetivos estratégicos de una empresa considerando rentabilidad, continuidad o contribución</li>
                                    <li>Los criterios asumen que hay cierta información disponible.</li>
                                    <ul>
                                        <li>Tales como: el rumbo estratégico de la empresa.</li>
                                    </ul>
                                </ul>
                                <li>Disfunción: Emitir un juicio de alto nivel sobre la "salud" de cada proceso</li>
                                <ul>
                                    <li>Qué procesos están en el problema más profundo. Estos son los que más se benefician.</li>
                                    <li>Mala salud: las organizaciones que no funcionan de forma centrada en los procesos no tienen una idea del rendimiento de los procesos.</li>
                                </ul>
                                <li>Viabilidad: ¿Es el proceso susceptible de iniciativas de gestión de procesos?</li>
                                <ul>
                                    <li>Lo más probable es que los obstáculos sean la cultura y la política.</li>
                                    <li>Concéntrese en los procesos en los que es razonable esperar beneficios.</li>
                                    <li>Las sensibilidades políticas dentro de una organización pueden tener un efecto en la tasa de éxito de los esfuerzos de gestión de procesos.</li>
                                </ul>
                            </ul>
                            <p>Evaluación de madurez de BPM</p>
                            <ul>
                                <li>Cuerpo de técnicas que definen el nivel de pensamiento de proceso sistemático.</li>
                                <li>Aspectos a evaluar:</li>
                                <ul>
                                    <li>¿Hasta qué punto la organización cubre la gama de procesos que idealmente se espera de ella?</li>
                                    <li>¿En qué medida se documentan y respaldan estos procesos?</li>
                                </ul>
                                <li>Niveles del marco CMMI (Capability Maturity Model Integrated):</li>
                                <ul>
                                    <li>Nivel 1 (Inicial)</li>
                                    <ul>
                                        <li>Ejecute procesos de manera ad-hoc. No hay una definición clara de los procesos. Sin control.</li>
                                    </ul>
                                    <li>Nivel 2 (Administrado)</li>
                                    <ul>
                                        <li>Se implementan proyectos de planificación, seguimiento y control, medición, análisis y aseguramiento de la calidad del producto.</li>
                                    </ul>
                                    <li>Nivel 3 (Definido)</li>
                                    <ul>
                                        <li>Centrarse en los procesos. Capacitación organizacional para que las partes interesadas participen en la documentación y el análisis del proceso.</li>
                                    </ul>
                                    <li>Nivel 4 (Gestionado Cuantitativamente)</li>
                                    <ul>
                                        <li>Se realiza un seguimiento del rendimiento de los procesos de la organización. La gestión de proyectos utiliza técnicas cuantitativas.</li>
                                    </ul>
                                    <li>Nivel 5 (Optimización)</li>
                                    <ul>
                                        <li>Gestión del desempeño organizacional establecida con análisis y resolución casuales.</li>
                                    </ul>
                                </ul>
                            </ul>
                            <p>Importante tener credibilidad en las iniciativas de gestión de procesos para garantizar el cambio en toda la organización.</p>
                            <ul>
                                <li>Puede lograrse enfocándose en procesos con menor importancia estratégica, pero con necesidad/deseo de cambio.</li>
                            </ul>
                            <div className="picinpost">
                                <img src={pic1} alt="" />
                            </div>
                    </div>
                    <Signature/>
                </div>
            </div>
        </>
    )
}