import {Link} from "react-router-dom"
import { Helmet } from "react-helmet";
import lifo from "../../pix/classnotes/stack/lifo.png"
import Signature from "../../signature/Signature";
export default function Stack() {

    return (
        <>
            <Helmet>
                <title>TBP | ¿Qué es Stack?</title>
            </Helmet>
            <div className="container-3">
                <div className="main-container">
                    <div className="box-title">
                        <h1>¿Qué es Stack?</h1>
                    </div>
                    <div className="topic-links">
                        <div className="card-topic-link">
                            <Link to="/topics/classnotes">
                                <h4><i className="fa-sharp fa-solid fa-circle"></i>&nbsp;&nbsp;&nbsp;Classnotes</h4>
                            </Link>
                        </div>
                        <div className="card-topic-link">
                            <Link to="">
                            <h4><i className="fa-sharp fa-solid fa-circle"></i>&nbsp;&nbsp;&nbsp;Oct 30, 2022</h4>
                            </Link>
                        </div>
                    </div>
                    <div className="sub-container">
                        <p>Stack es similar a una pila de platos apilados unos sobre otros. No se puede acceder directamente a cada plato por debajo del superior hasta que se retire el plato de arriba. Los platos sólo pueden añadirse y retirarse desde la parte superior.</p>
                        <h4>¿Qué es Stack?</h4>
                        <ul>
                            <li>Un stack representa una secuencia de objetos o elementos en un formato de estructura de datos lineal.</li>
                            <li>El stack consta de un fondo delimitado y todas las operaciones se realizan en la posición superior.</li>
                            <li>La estructura de datos del stack tiene las dos operaciones más importantes que son push y pop.</li>
                        </ul>
                        <h4>Last in, First out &#40;LIFO&#41;</h4>
                        <p>En el stack, los elementos se almacenan y se accede a ellos de la manera "último en entrar, primero en salir". Es decir, los elementos se añaden a la parte superior del stack y se eliminan de la parte superior del stack.</p>
                        <div className="picinpost">
                            <img src={lifo} alt="first in, first out" />
                        </div>
                        <h4>Operaciones de Stack</h4>
                        <ul>
                            <li>push&#40;&#41;: Añadir un elemento al stack.</li>
                            <li>pop&#40;&#41;: Quitar un elemento del stack.</li>
                            <li>isFull&#40;&#41;: Determina si el stack está lleno o no.</li>
                            <li>peek&#40;&#41;: Devuelve el elemento en la posición dada.</li>
                            <li>count&#40;&#41;: Devuelve el número total de elementos disponibles en el stack.</li>
                        </ul>
                        <h4>Ventajas de los Stacks</h4>
                        <ul>
                            <li>Los stacks ayudan a la gestión de datos aplicando la técnica LIFO.</li>
                            <li>Los stacks se utilizan para la gestión sistemática de la memoria.</li>
                            <li>Se utilizan en muchas máquinas virtuales como JVM.</li>
                            <li>Los stacks son más seguros y fiables ya que no se corrompen fácilmente.</li>
                            <li>Los stack permites controlar la asignación y desasignación de memoria.</li>
                        </ul>
                        <h4>Desventajas de los Stacks</h4>
                        <ul>
                            <li>La memoria de los stacks tienen un tamaño limitado.</li>
                            <li>Si se crean demasiados objetos, puede producirse un desbordamiento del stack.</li>
                            <li>El tamaño total de los stacks deben definirse de antemano.</li>
                            <li>Si los stacks se quedan sin memoria, puede provocar una terminación anormal.</li>
                            <li>El acceso aleatorio no es posible en stacks.</li>
                        </ul>
                    </div>
                    <Signature/>
                </div>
            </div>
        </>
    )
}