import './App.css';
import {BrowserRouter as Router, Routes, Route} from "react-router-dom";
import Home from './components/home/Home';
import SinglePage from './components/home/SinglePage';
import TopBar from './components/topbar/TopBar';
import FootBar from './components/footbar/FootBar';
import Blog from './components/blog/Blog';
import Pesti from './components/posts/classnotes/Pesti';
import About from './components/about/About';
import Portfolio from './components/portfolio/Portfolio';
import Resume from './components/resume/Resume';
import CiclovidaBpmstakeholders from './components/posts/bpm/CiclovidaBpmstakeholders';
import IdentificacionProceso from './components/posts/bpm/Identificacion_proceso';
import IntroduccionBpm from './components/posts/bpm/Introduccion_bpm';
import PrimerospasosBpm from './components/posts/bpm/Primerospasos_bpm';
import BiResumen from './components/posts/classnotes/BI_Resumen';
import ScrollToTop from './components/ScrollToTop';
import BSTEstructuraDatos from './components/posts/classnotes/BST_Estructura_Datos';
import CalidadProductoSoftware from './components/posts/classnotes/Calidad_Producto_Software';
import Cookies from './components/posts/classnotes/Cookies';
import EstructurasOrganizativas from './components/posts/classnotes/Estructuras_organizativas';
import Frontend from './components/posts/classnotes/Frontend';
import Hardware from './components/posts/classnotes/Hardware';
import IdentificacionActivosSistema from './components/posts/classnotes/Identificacion_Activos_Sistema';
import IntroSeguridadInformacion from './components/posts/classnotes/Intro_Seguridad_Informacion';
import JavaPython from './components/posts/classnotes/Java_Python';
import LenguajeC from './components/posts/classnotes/Lenguaje_c';
import LenguajesProgramacion from './components/posts/classnotes/Lenguajes_Programacion';
import Reactjs from './components/posts/classnotes/Reactjs';
import SqlJoin from './components/posts/classnotes/SQL_join';
import SQL from './components/posts/classnotes/SQL';
import Html5 from './components/posts/html-css/Html5';
import Cap1 from './components/posts/java/Cap1';
import Cap2 from './components/posts/java/Cap2';
import Cap3 from './components/posts/java/Cap3';
import ComputerScience from './components/posts/ics/Computer_Science';
import CompilandoC from './components/posts/ics/CompilandoC';
import JavaScriptFramework from './components/posts/javascript/JavaScript_Framework';
import JavaScriptCallStack from './components/posts/javascript/JavaScript_CallStack';
import JavaScriptQueEs from './components/posts/javascript/JavaScript_QueEs';
import JavaScriptFundamental from './components/posts/javascript/JavaScript_Fundamental';
import JavaScriptES6 from './components/posts/javascript/JavaScript_ES6';
import JavaScriptRestApi from './components/posts/javascript/JavaScript_Rest_Api';
import IntroPython from './components/posts/python/Intro_Python';
import Cap01ModulosPip from './components/posts/python/Cap01_ModulosPip';
import Listas from './components/posts/python/Listas';
import PreguntasPython from './components/posts/python/Preguntas_Python';
import PruebasTransicionEstados from './components/posts/testing/Pruebas_Transicion_Estados';
import PruebasCasosUso from './components/posts/testing/Pruebas_Casos_uso';
import IntroTesting from './components/posts/testing/Intro_Testing';
import CasosTest from './components/posts/testing/Casos_Test';
import HtmlAndCss from './components/posts/web-programming/Html_and_Css';
import Books from './components/books/Books';
import Bookss from './components/blog/Books';
import ErrorPage from './components/xtras/ErrorPage';
import Bpm from './components/blog/Bpm';
import Classnotes from './components/blog/Classnotes';
import HtmlCss from './components/blog/HtmlCss';
import Ics from './components/blog/Ics';
import Java from './components/blog/Java';
import JavaScript from './components/blog/JavaScript';
import Python from './components/blog/Python';
import Testing from './components/blog/Testing';
import WebProgramming from './components/blog/WebProgramming';
import Xtras from './components/blog/Xtras';
import All from './components/blog/All';
import Radio from './components/radio/Radio';
import BackToTop from './components/backtotop/BackToTop';
import Json from './components/posts/classnotes/Json';
import Stack from './components/posts/classnotes/Stack';
import Freebies from './components/freebies/Freebies';
import Booksread2022 from './components/posts/books/Booksreas2022';
import Me from './components/blog/Me';
import PreguntasParteII from './components/posts/python/Preguntas_ParteII';
import AprenderPython2023 from './components/posts/python/Aprender_Python_2023';
import Http from './components/posts/classnotes/Http';
import CurrentlyListening from './components/music/Currently_Listening';
import DireccionamientoIPv4 from './components/posts/classnotes/DireccionamientoIPv4';
import CloudComputing from './components/posts/classnotes/CloudComputing';
import ReactWebsites from './components/posts/javascript/React_Websites';
import Polimorfismo from './components/posts/classnotes/Polimorfismo';
import WWebProgramming from './components/posts/web-programming/WWebProgramming';
import FrontendBackend from './components/posts/web-programming/Frontend_Backend';
import InternetProtocol from './components/posts/classnotes/Internet_Protocol';
import ModeloOSI from './components/posts/classnotes/Modelo_OSI';
import ModeloTCPIP from './components/posts/classnotes/Modelo_TCPIP';
import FormularioHTML from './components/posts/html-css/Formulario_HTML';
import WDBMS from './components/posts/classnotes/W_DBMS';
import NpmW from './components/posts/classnotes/Npm_w';
import BigData from './components/posts/classnotes/BigData';
import Mistakes10 from './components/posts/classnotes/Mistakes10';
import AppReactNative from './components/posts/classnotes/AppReactNative';
import GitWebDev from './components/posts/web-programming/GitWebDev';
import Libraries10 from './components/posts/javascript/Libraries10';
import Mistakes5 from './components/posts/testing/Mistakes5';
import AlgorithmsStructures from './components/posts/ics/Algorithms_Structures';
import BasicsTesting from './components/posts/testing/BasicsTesting';
import CleanCode10 from './components/posts/javascript/CleanCode10';
import ApiRestful from './components/posts/java/ApiRestful';
import MachineLearning from './components/posts/java/MachineLearning';
import WebAppFlask from './components/posts/python/WebAppFlask';
import AjaxW from './components/posts/classnotes/AjaxW';
import IntroBPM from './components/posts/bpm/IntroBPM';
import ToMapBPM from './components/posts/bpm/ToMapBPM';
import RolBPM from './components/posts/bpm/RolBPM';
import Tools2023 from './components/posts/bpm/Tools2023';
import BuildBPM from './components/posts/bpm/BuildBPM';
import FlowBPM from './components/posts/bpm/FlowBPM';
import ModelSim from './components/posts/bpm/ModelSim';
import EfiBPM from './components/posts/bpm/EfiBPM';
import TendBPM from './components/posts/bpm/TendBPM';
import IntroSoftware from './components/posts/ics/IntroSoftware';
import RequiSoftware from './components/posts/ics/RequiSoftware';
import DesignSoftware from './components/posts/ics/DesignSoftware';
import PruSoftware from './components/posts/ics/PruSoftware';
import AgileSoftware from './components/posts/ics/AgileSoftware';
import SqlVsMongo from './components/posts/classnotes/SqlVsMongo';
import Axios from './components/posts/javascript/Axios';
import Fetch from './components/posts/javascript/Fetch';
import FullStack from './components/posts/web-programming/FullStack';
import Shortcuts from './components/posts/classnotes/Shortcuts';
import Async from './components/posts/javascript/Async';
import Styles from './components/posts/web-programming/Styles';
import Reducers from './components/posts/javascript/Reducers';
import ReturnNull from './components/posts/javascript/ReturnNull';
import UseEffect from './components/posts/javascript/UseEffect';
import Pattern from './components/posts/ics/Pattern';
import JsxReactjs from './components/posts/javascript/JsxReactjs';
import MayoWU from './components/posts/books/MayoWU';
import Interceptor from './components/posts/javascript/Interceptor';
import EventLoop from './components/posts/javascript/EventLoop';
import OperadoresJS from './components/posts/javascript/OperadoresJS';
import PrincipianteReactJS from './components/posts/javascript/PrincipianteReactJS';
import NodeJSP from './components/posts/javascript/NodeJSP';
import TypeScriptP from './components/posts/javascript/TypeScriptP';
import DominandoHooks from './components/posts/javascript/DominandoHooks';
import DominaJSON from './components/posts/classnotes/DominaJSON';
import DesmitificandoAPIs from './components/posts/classnotes/DesmitificandoAPIs';
import AWS from './components/posts/classnotes/AWS';
import UXUI from './components/posts/classnotes/UXUI';
import GitHubA from './components/posts/classnotes/GitHubA';

function App() {
  return (
    <div className="App">
      <Router>
        <TopBar/>
        <ScrollToTop/>
        <Routes>
          <Route path='/' element={<Home/>}/>
          <Route path="*" element={<ErrorPage/>} />
          <Route path='/about' element={<About/>}/>
          <Route path='/topics' element={<Blog/>}/>
          <Route path='/topics/books' element={<Bookss/>}/>
          <Route path='/topics/bpm' element={<Bpm/>}/>
          <Route path='/topics/classnotes' element={<Classnotes/>}/>
          <Route path='/topics/html-css' element={<HtmlCss/>}/>
          <Route path='/topics/computer-science' element={<Ics/>}/>
          <Route path='/topics/java' element={<Java/>}/>
          <Route path='/topics/javascript' element={<JavaScript/>}/>
          <Route path='/topics/python' element={<Python/>}/>
          <Route path='/topics/testing' element={<Testing/>}/>
          <Route path='/topics/web-programming' element={<WebProgramming/>}/>
          <Route path='/topics/xtras' element={<Xtras/>}/>
          <Route path='/topics/all' element={<All/>}/>
          <Route path='/topics/me' element={<Me/>}/>
          <Route path='/radio' element={<Radio/>}/>
          <Route path='/freebies' element={<Freebies/>}/>
          <Route path='/currently-listening' element={<CurrentlyListening/>}/>
          {/* BPM */}
          <Route path='/topics/ciclo-de-vida-bpm-y-stakeholders' element={<CiclovidaBpmstakeholders />}/>
          <Route path='/topics/identificacion-del-proceso' element={<IdentificacionProceso/>}/>
          <Route path="/topics/introduccion-a-bpm" element={<IntroduccionBpm/>} />
          <Route path="/topics/primeros-pasos-con-bpm" element={<PrimerospasosBpm/>} />
          <Route path="/topics/introduccion-al-bpm" element={<IntroBPM/>} />
          <Route path="/topics/como-mapear-y-analizar-los-procesos-empresariales" element={<ToMapBPM/>} />
          <Route path='/topics/rol-del-bpm-en-la-transformacion-digital' element={<RolBPM/>}/>
          <Route path='/topics/las-mejores-herramientas-y-software-para-bpm-en-2023' element={<Tools2023/>}/>
          <Route path="/topics/como-construir-con-exito-un-sistema-bpm" element={<BuildBPM/>} />
          <Route path="/topics/diferencia-entre-bpm-y-gestion-de-flujos-de-trabajo" element={<FlowBPM/>} />
          <Route path="/topics/modelado-y-simulacion-de-procesos-empresariales" element={<ModelSim/>} />
          <Route path="/topics/medicion-de-la-eficacia-de-la-estrategia-de-bpm" element={<EfiBPM/>} />
          <Route path="/topics/principales-tendencias-en-bpm-para-2023" element={<TendBPM/>} />
          {/* Classnotes */}
          <Route path="/topics/plan-estrategico-de-sistemas-y-ti" element={<Pesti/>} />
          <Route path="/topics/estructuras-de-datos-jerarquicos-arbol-binario-de-busqueda" element={<BSTEstructuraDatos/>} />
          <Route path="/topics/inteligencia-de-negocios-resumen" element={<BiResumen/>} />
          <Route path="/topics/calidad-de-producto-de-software" element={<CalidadProductoSoftware/>} />
          <Route path="/topics/almacenamiento-local-vs-cookies" element={<Cookies/>} />
          <Route path="/topics/tipos-de-estructuras-organizativas" element={<EstructurasOrganizativas/>} />
          <Route path="/topics/preguntas-esenciales-de-front-end" element={<Frontend/>} />
          <Route path="/topics/hardware" element={<Hardware/>} />
          <Route path="/topics/identificacion-de-los-activos-del-sistema" element={<IdentificacionActivosSistema/>} />
          <Route path="/topics/introduccion-a-la-seguridad-de-la-informacion" element={<IntroSeguridadInformacion/>} />
          <Route path="/topics/java-vs-python" element={<JavaPython/>} />
          <Route path="/topics/lenguaje-de-programacion-c" element={<LenguajeC/>} />
          <Route path="/topics/15-lenguajes-de-programacion-populares" element={<LenguajesProgramacion/>} />
          <Route path="/topics/que-es-reactjs" element={<Reactjs/>} />
          <Route path="/topics/que-son-sql-joins" element={<SqlJoin/>} />
          <Route path="/topics/que-es-sql" element={<SQL/>} />
          <Route path="/topics/que-es-json" element={<Json/>} />
          <Route path="/topics/que-es-stack" element={<Stack/>} />
          <Route path="/topics/que-es-http" element={<Http/>} />
          <Route path="/topics/direccionamiento-ip-v4" element={<DireccionamientoIPv4/>} />
          <Route path="/topics/que-es-cloud-computing" element={<CloudComputing/>} />
          <Route path="/topics/que-es-polimorfismo" element={<Polimorfismo/>} />
          <Route path="/topics/protocolos-de-internet" element={<InternetProtocol/>} />
          <Route path="/topics/modelo-osi" element={<ModeloOSI/>} />
          <Route path="/topics/modelo-tcp-ip" element={<ModeloTCPIP/>} />
          <Route path="/topics/que-es-dbms" element={<WDBMS/>} />
          <Route path="/topics/que-es-npm" element={<NpmW/>} />
          <Route path="/topics/que-es-big-data" element={<BigData/>} />
          <Route path="/topics/10-errores-comunes-cuando-aprendemos-a-programar" element={<Mistakes10/>} />
          <Route path="/topics/como-crear-una-app-react-native-en-5-pasos" element={<AppReactNative/>} />
          <Route path="/topics/que-es-ajax" element={<AjaxW/>} />
          <Route path="/topics/sql-vs-mongodb-base-de-datos-adecuada" element={<SqlVsMongo/>} />
          <Route path="/topics/dominio-de-los-atajos-de-macos-para-desarrolladores" element={<Shortcuts/>} />
          <Route path="/topics/desmitificando-javascript-async" element={<Async/>} />
          <Route path="/topics/domina-json" element={<DominaJSON/>} />
          <Route path="/topics/desmitificando-a-las-apis-significado-y-utilidad" element={<DesmitificandoAPIs/>} />
          <Route path="/topics/explorando-aws-descubre-el-poder-del-cloud-computing" element={<AWS/>} />
          <Route path="/topics/ux-y-ui-diferencias-y-roles-clave-en-el-diseno-digital" element={<UXUI/>} />
          <Route path="/topics/github-tu-aliado-esencial-para-la-colaboracion-y-el-desarrollo-de-codigo" element={<GitHubA/>} />
          {/* HTML & CSS */}
          <Route path="/topics/html5-css-and-js" element={<Html5/>} />
          <Route path="/topics/formularios-en-html" element={<FormularioHTML/>} />
          {/* ICS */}
          <Route path="/topics/que-es-computer-science" element={<ComputerScience/>}/>
          <Route path="/topics/compilando-en-c" element={<CompilandoC/>}/>
          <Route path="/topics/comprension-de-algoritmos-y-estructuras-de-datos" element={<AlgorithmsStructures/>}/>
          <Route path="/topics/introduccion-a-la-ingenieria-del-software" element={<IntroSoftware/>}/>
          <Route path="/topics/ingenieria-de-requisitos-tecnicas-y-mejores-practicas" element={<RequiSoftware/>}/>
          <Route path="/topics/diseno-y-arquitectura-de-software" element={<DesignSoftware/>}/>
          <Route path="/topics/pruebas-y-aseguramiento-de-la-calidad-del-software" element={<PruSoftware/>}/>
          <Route path="/topics/desarrollo-agil-de-software" element={<AgileSoftware/>}/>
          <Route path="/topics/principales-patrones-de-arquitectura-de-software" element={<Pattern/>}/>
          {/* Java */}
          <Route path="/topics/capitulo-01-introduccion-a-java" element={<Cap1/>}/>
          <Route path="/topics/capitulo-02-comentarios-para-documentacion-en-java" element={<Cap2/>}/>
          <Route path="/topics/capitulo-03-tipos-de-datos-variables" element={<Cap3/>}/>
          <Route path="/topics/como-crear-una-api-restful-con-java-spring-boot" element={<ApiRestful/>}/>
          <Route path="/topics/integracion-de-machine-learning-en-aplicaciones-java" element={<MachineLearning/>}/>
          {/* JavaScript */}
          <Route path="/topics/javascript-frameworks" element={<JavaScriptFramework/>} />
          <Route path="/topics/javascript-callstack" element={<JavaScriptCallStack/>} />
          <Route path="/topics/que-es-javascript" element={<JavaScriptQueEs/>} />
          <Route path="/topics/javascript-fundamental" element={<JavaScriptFundamental/>} />
          <Route path="/topics/javascript-es6" element={<JavaScriptES6/>} />
          <Route path="/topics/rest-api-en-javascript" element={<JavaScriptRestApi/>} />
          <Route path="/topics/react-la-mejor-opcion-para-websites" element={<ReactWebsites/>} />
          <Route path="/topics/5-librerias-javascript-esenciales" element={<Libraries10/>} />
          <Route path="/topics/tips-para-escribir-codigo-js-limpio-y-eficiente" element={<CleanCode10/>} />
          <Route path="/topics/que-es-axios" element={<Axios/>} />
          <Route path="/topics/que-es-fetch" element={<Fetch/>} />
          <Route path="/topics/desmitificando-los-reductores-de-reactjs" element={<Reducers/>} />
          <Route path="/topics/deja-de-usar-return-null-en-react" element={<ReturnNull/>} />
          <Route path="/topics/como-evitar-la-doble-ejecucion-de-useeffect-en-react" element={<UseEffect/>} />
          <Route path="/topics/entender-jsx-en-reactjs" element={<JsxReactjs/>} />
          <Route path="/topics/entendiendo-los-interceptores-http" element={<Interceptor/>} />
          <Route path="/topics/desmitificando-el-event-loop-en-javascript" element={<EventLoop/>} />
          <Route path="/topics/operadores-en-js-tipos-comparaciones-y-funciones-especiales" element={<OperadoresJS/>} />
          <Route path="/topics/react-js-para-principiantes" element={<PrincipianteReactJS/>} />
          <Route path="/topics/node-js-significado-y-utilidades" element={<NodeJSP/>} />
          <Route path="/topics/typescript-significado-y-utilidad" element={<TypeScriptP/>} />
          <Route path="/topics/dominando-hooks-en-react-para-potenciar-tus-componentes" element={<DominandoHooks/>} />
          {/* Python */}
          <Route path="/topics/introduccion-a-python" element={<IntroPython/>}/>
          <Route path="/topics/capitulo-01-modulos-comentarios-y-pip" element={<Cap01ModulosPip/>}/>
          <Route path="/topics/listas" element={<Listas/>}/>
          <Route path="/topics/preguntas-de-python" element={<PreguntasPython/>}/>
          <Route path="/topics/preguntas-de-python-parte-ii" element={<PreguntasParteII/>}/>
          <Route path="/topics/por-que-aprender-python-en-2023" element={<AprenderPython2023/>}/>
          <Route path="/topics/listas" element={<Listas/>}/>
          <Route path="/topics/como-hacer-una-web-app-sencilla-con-python-flask" element={<WebAppFlask/>}/>
          {/* Testing */}
          <Route path="/topics/pruebas-de-transicion-de-estados" element={<PruebasTransicionEstados/>}/>
          <Route path="/topics/pruebas-de-caso-de-uso" element={<PruebasCasosUso/>}/>
          <Route path="/topics/introduccion-al-testing" element={<IntroTesting/>}/>
          <Route path="/topics/casos-de-test" element={<CasosTest/>}/>
          <Route path="/topics/5-errores-comunes-en-el-testing-de-software" element={<Mistakes5/>}/>
          <Route path="/topics/aspectos-basicos-del-testing-en-el-desarrollo-de-software" element={<BasicsTesting/>}/>
          {/* Web Programming */}
          <Route path="/topics/html-y-css" element={<HtmlAndCss/>}/>
          <Route path="/topics/que-es-web-programming" element={<WWebProgramming/>}/>
          <Route path="/topics/diferencias-entre-frontend-y-backend" element={<FrontendBackend/>}/>
          <Route path="/topics/como-utilizar-git-para-el-desarrollo-web" element={<GitWebDev/>}/>
          <Route path="/topics/como-ser-un-buen-desarrollador-full-stack" element={<FullStack/>}/>
          <Route path="/topics/anadir-estilos-a-nuestro-sitio-web" element={<Styles/>}/>
          {/* Portfolio */}
          <Route path='/portfolio' element={<Portfolio/>}/>
          <Route path='/resume' element={<Resume/>}/>
          <Route path='/single-page' element={<SinglePage/>}/>
          <Route path='/books' element={<Books/>}/>
          {/* Books */}
          <Route path='/topics/libros-leidos-2022' element={<Booksread2022/>}/>
          <Route path='/topics/mayo-wrap-up' element={<MayoWU/>}/>
        </Routes>
        <BackToTop/>
        <FootBar/>
      </Router>
    </div>
  );
}

export default App;
