import {Link} from "react-router-dom";
import { Helmet } from 'react-helmet';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { paraisoLight } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import Signature from "../../signature/Signature";
import "../Posts.css";

export default function Reducers() {
    const code01 = `const initialState = {
        count: 0,
};
    
const counterReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'INCREMENT':
        return { ...state, count: state.count + 1 };
        case 'DECREMENT':
        return { ...state, count: state.count - 1 };
        case 'RESET':
        return { ...state, count: 0 };
        default:
        return state;
    }
};`;

    const code02 = `import React, { useReducer } from 'react';

const initialState = {
    count: 0,
};

const counterReducer = (state, action) => {
    // Reducer logic here
};

const Counter = () => {
    const [state, dispatch] = useReducer(counterReducer, initialState);

    const increment = () => {
    dispatch({ type: 'INCREMENT' });
    };

    const decrement = () => {
    dispatch({ type: 'DECREMENT' });
    };

    return (
    <div>
        <p>Count: {state.count}</p>
        <button onClick={increment}>Increment</button>
        <button onClick={decrement}>Decrement</button>
    </div>
    );
};
    
export default Counter;`;
    return (
        <>
            <Helmet>
                <title>TBP | Desmitificando los Reductores de ReactJS: Gestión de estados simplificada</title>
            </Helmet>
            <div className="container-3">
                <div className="main-container">
                    <div className="box-title">
                        <h1>Desmitificando los Reductores de ReactJS: Gestión de estados simplificada</h1>
                    </div>
                    <div className="topic-links">
                        <div className="card-topic-link">
                            <Link to="/topics/javascript">
                                <h4><i className="fa-sharp fa-solid fa-circle"></i>&nbsp;&nbsp;&nbsp;JavaScript</h4>
                            </Link>
                        </div>
                        <div className="card-topic-link">
                            <Link to="">
                            <h4><i className="fa-sharp fa-solid fa-circle"></i>&nbsp;&nbsp;&nbsp;May 21, 2023</h4>
                            </Link>
                        </div>
                    </div>
                    <div className="sub-container">
                        <p>ReactJS, una de las librerías JavaScript más populares para construir interfaces de usuario, introduce un concepto llamado "reductores" para gestionar el estado de tu aplicación. En este post profundizaremos en qué son los reductores de ReactJS, cómo funcionan y por qué son esenciales para una gestión eficiente del estado en las aplicaciones React.</p>
                        <p>Entendiendo los Reductores de ReactJS:</p>
                        <p>Los reductores son funciones puras en ReactJS que especifican cómo debe cambiar el estado de la aplicación en respuesta a las acciones enviadas. Reciben el estado actual y una acción como argumentos, y basándose en el tipo de acción, calculan y devuelven un nuevo estado. Los reductores son un concepto central de la librería Redux, que se utiliza comúnmente con React para la gestión del estado, pero también se pueden utilizar de forma independiente dentro de las aplicaciones React.</p>
                        <h2>Conceptos clave:</h2>
                        <ol>
                            <li><b>Funciones Puras: </b>Los reductores deben ser funciones puras, lo que significa que no deben modificar el estado existente directamente. En su lugar, crean y devuelven un nuevo objeto de estado basado en el estado anterior y la acción recibida.</li>
                            <li><b>Estado inmutanble: </b>ReactJS fomenta el estado inmutable, lo que significa que el objeto de estado no debe ser mutado directamente. En su lugar, los reductores crean un nuevo objeto de estado que refleja los cambios deseados.</li>
                        </ol>
                        <h2>Ejemplo: Contador reductor</h2>
                        <p>Veamos un ejemplo simple de contador para demostrar cómo funcionan los reductores:</p>
                        <div className="code-text">
                            <SyntaxHighlighter className="codes" language="jsx" style={paraisoLight} customStyle={{borderRadius: "1.5em", backgroundColor: "#f4e7e5", border: "2px solid #222222", padding: "2em"}}>
                            {code01}
                            </SyntaxHighlighter>
                        </div>
                        <p>En este ejemplo, la función <b>counterReducer</b> maneja diferentes acciones relacionadas con el estado del contador. El estado inicial se define como un objeto con la propiedad <b>count</b> establecida a 0. El reductor utiliza una sentencia <b>switch</b> para determinar cómo debe cambiar el estado en función del tipo de acción. Por ejemplo, cuando se envía la acción "INCREMENT", el reductor crea un nuevo objeto de estado con un recuento incrementado.</p>
                        <h2>Integración con componentes React:</h2>
                        <p>Para integrar reductores en una aplicación React, puedes usarlos en combinación con el hook <b>useReducer</b> o con una librería de gestión de estados como Redux. El hook `useReducer` permite gestionar el estado local dentro de un componente y simplifica el proceso de actualizaciones de estado.</p>
                        <h2>Ejemplo: Uso de <b>useReductor</b> en un componente React</h2>
                        <div className="code-text">
                            <SyntaxHighlighter className="codes" language="jsx" style={paraisoLight} customStyle={{borderRadius: "1.5em", backgroundColor: "#f4e7e5", border: "2px solid #222222", padding: "2em"}}>
                            {code02}
                            </SyntaxHighlighter>
                        </div>
                        <p>En este ejemplo, el hook <b>useReducer</b> se utiliza para gestionar el estado dentro del componente <b>Counter</b>. La función <b>dispatch</b> se utiliza para enviar acciones al reductor, desencadenando actualizaciones de estado.</p>
                        <hr />
                        <h2>Conclusión</h2>
                        <p>Los reductores de ReactJS son una potente herramienta para gestionar el estado en las aplicaciones React. Siguiendo los principios de funciones puras y estado inmutable, los reductores proporcionan una forma clara y predecible de actualizar y gestionar el estado de la aplicación. Tanto si se usan con el hook <b>useReducer</b> como en conjunción con una librería de gestión de estado como Redux, los reductores simplifican la gestión de estado, mejoran la organización del código y mejoran la mantenibilidad de las aplicaciones React. Experimenta con reductores en tus proyectos y aprovecha sus capacidades para construir aplicaciones React robustas y escalables.</p>
                    </div>
                    <Signature/>
                </div>
            </div>
        </>
    )
}