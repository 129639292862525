import {Link} from "react-router-dom"
import { Helmet } from "react-helmet";
import Signature from "../../signature/Signature";
export default function BI_Resumen() {

    return (
        <>
            <Helmet>
                <title>TBP | Inteligencia de Negocios: Resumen</title>
            </Helmet>
            <div className="container-3">
                <div className="main-container">
                    <div className="box-title">
                        <h1>Inteligencia de Negocios: Resumen</h1>
                    </div>
                    <div className="topic-links">
                        <div className="card-topic-link">
                            <Link to="/topics/classnotes">
                                <h4><i className="fa-sharp fa-solid fa-circle"></i>&nbsp;&nbsp;&nbsp;Classnotes</h4>
                            </Link>
                        </div>
                        <div className="card-topic-link">
                            <Link to="">
                            <h4><i className="fa-sharp fa-solid fa-circle"></i>&nbsp;&nbsp;&nbsp;Jan 27, 2022</h4>
                            </Link>
                        </div>
                    </div>
                    <div className="sub-container">
                        <p>Sistema de información → compuesto por 4 elementos (hardware, software, datos, personas) tipos (transaccionales, gestión, estratégicos)</p>
                        <h2>Necesidades de Información</h2>
                        <ul>
                            <li><b>Conocimiento: </b>Información más usada correctamente</li>
                            <li><b>Información: </b>Datos procesados</li>
                            <li><b>Datos: </b>Es un hecho conocido, alguien lo conoce</li>
                        </ul>
                        <p>Inteligencia de negocios utiliza la información y el conocimiento.</p>
                        <h2>Dos tipos de fuentes de datos para organizaciones</h2>
                        <ul>
                            <li><b>Internos: </b>relativamente más fáciles de obtener, se encuentran en diferentes locaciones y recursos de la organización (historial de balances, trabajadores, registro de ventas).</li>
                            <li><b>Externas: </b>relativamente más difíciles de obtener, se encuentran en internet, información pública de la competencia, información de entidades del estado (memorias de empresas, información del ministerio, información de la SUNAT).</li>
                        </ul>
                        <h2>¿Qué es la inteligencia de negocios?</h2>
                        <p>Es un conjunto de metodologías y herramientas orientadas a mejorar la toma de decisiones en una organización o empresa mediante el análisis de datos existentes.</p>
                        <p>Permite recopilar, almacenar, manipular y analizar los datos, tanto internos como externos, que permitan crear nuevo conocimiento.</p>
                        <h2>Tres fuentes de datos útiles para la inteligencia de negocios</h2>
                        <ul>
                            <li>Datos de los sistemas transaccionales</li>
                            <li>Competidores: presiones competitivas, condiciones de la industria</li>
                            <li>Información general: tendencias económicas, tecnológicas y culturales</li>
                        </ul>
                        <h2>Data Warehouse</h2>
                        <p>Base de datos que contiene información histórica y agregada de la información operacional (transaccional) de la organización.</p>
                        <p>Integración de distintas fuentes dentro de la organización.</p>
                        <p>Está diseñada para favorecer el análisis de los datos.</p>
                        <h2>Dashboard</h2>
                        <p>Proveen visibilidad respecto a indicares claves de rendimiento a través de herramientas gráficas simples, tales como gráficos, tablas e indicadores; usualmente a través del uso de un web browser.</p>
                        <p>Presentan varias métricas diferentes en una vista consolidada.</p>
                        <p>Presentan métricas de alto nivel, pero permiten acceder a los niveles más detallados de información.</p>
                        <h2>OLAP</h2>
                        <p>On-line Analytical Processing</p>
                        <p>Tipo de aplicación que pretende facilitar el análisis multidimensional de la información (datos que han sido agregados en varias categorías, o dimensiones).</p>
                        <p>Permite al usuario sintetizar información de la organización a través de vistas comparativas, personalizadas; y analizar información tanto histórica (pasado) como estimada (futura).</p>
                        <h2>Data Mining</h2>
                        <p>Es una técnica de descubrimiento de información en base de datos.</p>
                        <p>Combina técnicas de análisis estadístico, inteligencia artificial, y tecnologías de base de datos.</p>
                        <p>Encuentra patrones y relaciones útiles entre los datos, e infiere reglas que permiten la predicción de resultados futuros.</p>
                        <h2>Ventajas de Inteligencia de Negocios</h2>
                        <ul>
                            <li>Reducción de costos y liberación de recursos.</li>
                            <li>Mejora la capacidad de respuesta.</li>
                            <li>Mejora en la coordinación de la empresa.</li>
                            <li>Identificación pronta de necesidades de los clientes.</li>
                            <li>Aumenta la capacidad de toma de decisiones.</li>
                            <li>Mejora el poder de reacción ante los cambios del mercado.</li>
                            <li>Todos acceden a información estratégica.</li>
                            <li>Se pueden identificar las fallas y sus causas.</li>
                            <li>Incremento en las ganancias.</li>
                            <li>Mejorar las estrategias de marketing.</li>
                        </ul>
                        <h2>Componentes de Inteligencia de Negocios</h2>
                        <p><b>Fundamentales: </b>data warehouse, data marts, olap, minería d edatos y query & reporting.</p>
                        <p><b>Esenciales: </b>sistemas de información, plan estratégico, ETL.</p>
                        <h2>Ciclo de Inteligencia de Negocios</h2>
                        <ul>
                            <li>Definición de objetivos,</li>
                            <li>Análisis de proceso,</li>
                            <li>Adquisiciónde conocimiento (reflexión),</li>
                            <li>Toma de decisiones (actuar),</li>
                            <li>Medida de éxito.</li>
                        </ul>
                        <h2>Metodologías de Inteligencia de Negocios</h2>
                        <ul>
                            <li>Justificación (evaluar caso de negocio),</li>
                            <li>Planeamiento (evaluación de la infraestructura, planeación del proyecto),</li>
                            <li>Análisis del negocio (requerimientos del proyecto, análisis de datos, prototipado, análisis de metadata),</li>
                            <li>Diseño (diseño de la base de datos, ETL, repositorio)</li>
                            <li>Construcción (desarrollar ETL, aplicativos, minería de datos, repositorio),</li>
                            <li>Despliegue (implementación, mantenimiento)</li>
                        </ul>
                        <h2>El valor de la Información</h2>
                        <p>El principal activo de las empresas es la información, normalmente contenida en los sistemas de información.</p>
                        <h2>El Proceso ETL</h2>
                        <p>El sistema ETL se construye en forma ad-hoc para cada DW.</p>
                        <p>Aproximadamente 50% del esfuerzo.</p>
                        <p>Considera tanto la carga inicial como las actualizaciones periódicas inmediatas, diarias, mensuales, etc.)</p>
                        <p><b>Extract: </b>identificación de datos que han cambiado, extracción (lectura) de los datos, cálculo de agregaciones, mantenimiento de metadata.</p>
                        <p><b>Transform: </b>Limpieza y transformación de datos, integración de datos, creación de claves, cálculo de agregaciones, mantenimiento de metadata.</p>
                        <p><b>Load: </b>carga, indexación, obtención de datos agregados, pruebas de carga, gestión de errores, mantenimiento de metadata.</p>
                    </div>
                    <Signature/>
                </div>
            </div>
        </>
    )
}