import {Link} from "react-router-dom";
import { Helmet } from 'react-helmet';
import Signature from "../../signature/Signature";
import "../Posts.css";

export default function BasicsTesting() {
    return (
        <>
            <Helmet>
                <title>TBP | Aspectos básicos del testing en el desarrollo de software</title>
            </Helmet>
            <div className="container-3">
                <div className="main-container">
                    <div className="box-title">
                        <h1>Aspectos básicos del testing en el desarrollo de software</h1>
                    </div>
                    <div className="topic-links">
                        <div className="card-topic-link">
                            <Link to="/topics/testing">
                                <h4><i className="fa-sharp fa-solid fa-circle"></i>&nbsp;&nbsp;&nbsp;Testing</h4>
                            </Link>
                        </div>
                        <div className="card-topic-link">
                            <Link to="">
                            <h4><i className="fa-sharp fa-solid fa-circle"></i>&nbsp;&nbsp;&nbsp;Apr 19, 2023</h4>
                            </Link>
                        </div>
                    </div>
                    <div className="sub-container">
                        <h2>Tipos de testing</h2>
                        <p>Existen varios tipos de testing que pueden realizarse durante el desarrollo de software. He aquí algunos de los más comunes:</p>
                        <ol>
                            <li>Testing unitario: Este tipo de test consiste en testear unidades individuales de código de forma aislada. El objetivo es comprobar que cada unidad funciona como se espera.</li>
                            <li>Testing de integración: Este tipo de test consiste en comprobar la integración de diferentes unidades de código. El objetivo es garantizar que las unidades funcionan juntas como se espera.</li>
                            <li>Testing de sistema: Este tipo de test consiste en testear todo el sistema en su conjunto. El objetivo es verificar que el sistema funciona como se espera y cumple los requisitos.</li>
                            <li>Testing de aceptación: Este tipo de test consiste en testear el sistema en situaciones reales para garantizar que cumple los requisitos y las expectativas del usuario.</li>
                        </ol>
                        <h2>Estrategias de testing</h2>
                        <p>Hay dos estrategias de test principales: testing manual y testing automatizado.</p>
                        <ul>
                            <li>Testing manual: Consiste en testear el software manualmente, utilizando un plan de testing y casos de test. Puede llevar mucho tiempo, pero es útil para testear pequeños proyectos o funciones.</li>
                            <li>Testing automatizado: Consiste en utilizar herramientas informáticas para ejecutar los tests automáticamente. Es más rápido que los tests manuales y resulta útil para proyectos grandes o con actualizaciones frecuentes.</li>
                        </ul>
                        <h2>Buenas prácticas</h2>
                        <p>Estas son algunas de las mejores prácticas para realizar testing en el desarrollo de software:</p>
                        <ul>
                            <li>Empezar el testing pronto: Los tests deben comenzar lo antes posible en el proceso de desarrollo para detectar y corregir errores desde el principio.</li>
                            <li>Crear un plan de testing: Se debe crear un plan de testing para delinear la estrategia de tests, incluyendo qué tipos de testing se llevarán a cabo y cuándo.</li>
                            <li>Escribir casos de test: Los casos de test deben escribirse para asegurar que cada característica se testea a fondo.</li>
                            <li>Usar testing manual y automatizado: Se deben utilizar testeos tanto manuales como automatizados para garantizar que el software se testea a fondo.</li>
                            <li>Testear en diferentes entornos: Los testeos deben realizarse en diferentes entornos para garantizar que el software funciona como se espera en todos los entornos.</li>
                        </ul>
                        <p></p>
                    </div>
                    <Signature/>
                </div>
            </div>
        </>
    )
}