import {Link} from "react-router-dom"
import { Helmet } from "react-helmet";
import Signature from "../../signature/Signature";
export default function Modelo_OSI() {

    return (
        <>
            <Helmet>
                <title>TBP | Modelo OSI</title>
            </Helmet>
            <div className="container-3">
                <div className="main-container">
                    <div className="box-title">
                        <h1>Modelo OSI</h1>
                    </div>
                    <div className="topic-links">
                        <div className="card-topic-link">
                            <Link to="/topics/classnotes">
                                <h4><i className="fa-sharp fa-solid fa-circle"></i>&nbsp;&nbsp;&nbsp;Classnotes</h4>
                            </Link>
                        </div>
                        <div className="card-topic-link">
                            <Link to="">
                            <h4><i className="fa-sharp fa-solid fa-circle"></i>&nbsp;&nbsp;&nbsp;Oct 29, 2022</h4>
                            </Link>
                        </div>
                    </div>
                    <div className="sub-container">
                        <p>El modelo OSI (Interconexión de Sistemas Abiertos) es un modelo conceptual que define cómo se transmiten y reciben los datos entre los dispositivos de una red. Se compone de siete capas, cada una de las cuales tiene una función específica. En este post, exploraremos el modelo OSI y sus capas en detalle.</p>
                        <ol>
                            <li>Capa física: La capa física es la capa más baja del modelo OSI y se encarga de transmitir bits de datos sin procesar a través de un medio físico, como cables de cobre o fibra óptica. Esta capa define las características físicas del medio, como los niveles de tensión y las frecuencias de señal.</li>
                            <li>Capa de enlace de datos: La capa de enlace de datos es responsable de proporcionar una transmisión de datos fiable y sin errores entre los dispositivos de una red local. Esta capa divide los datos en tramas y añade información de direccionamiento y corrección de errores para garantizar que los datos se transmiten correctamente.</li>
                            <li>Capa de red: La capa de red es responsable del encaminamiento de datos entre diferentes redes. Esta capa añade información de direccionamiento a los datos y selecciona la mejor ruta para que los datos viajen basándose en la topología de la red.</li>
                            <li>Capa de transporte: La capa de transporte es responsable de garantizar que los datos se transmitan de forma fiable y eficiente entre dispositivos. Esta capa divide los datos en segmentos y añade información de secuenciación y recuperación de errores para garantizar que los datos se transmiten correctamente.</li>
                            <li>Capa de sesión: La capa de sesión es responsable de gestionar las sesiones entre aplicaciones que se ejecutan en diferentes dispositivos. Esta capa establece, mantiene y finaliza las conexiones entre aplicaciones y garantiza que los datos se transmiten de forma segura y eficiente.</li>
                            <li>Capa de presentación: La capa de presentación se encarga de que los datos se presenten en un formato fácilmente comprensible para la aplicación receptora. Esta capa realiza el cifrado de datos, la compresión y la conversión entre diferentes formatos de datos.</li>
                            <li>Capa de aplicación: La capa de aplicación es la capa superior en el modelo OSI y es responsable de proporcionar servicios a las aplicaciones que se ejecutan en diferentes dispositivos. Esta capa define los protocolos y estándares utilizados por las aplicaciones para comunicarse entre sí.</li>
                            <p>En conclusión, el modelo OSI es un concepto crucial en las redes informáticas que define cómo se transmiten y reciben los datos entre los dispositivos de una red. Cada capa del modelo OSI tiene una función específica, y al comprender estas funciones, los administradores de red pueden solucionar mejor los problemas y mantener sus redes. El modelo OSI es una herramienta importante para los ingenieros y técnicos de redes, y es un aspecto clave de las redes informáticas modernas.</p>
                        </ol>
                    </div>
                    <Signature/>
                </div>
            </div>
        </>
    )
}