import {Link} from "react-router-dom";
import { Helmet } from 'react-helmet';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { paraisoLight } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import Signature from "../../signature/Signature";
import "../Posts.css";

export default function PrincipianteReactJS() {
    const code01 = `import React from 'react';

class MiComponente extends React.Component {
    render() {
        return <h1>Hola, React JS</h1>;
    }
}
    
export default MiComponente;`;
    return (
        <>
            <Helmet>
                <title>TBP | React JS para principiantes</title>
            </Helmet>
            <div className="container-3">
                <div className="main-container">
                    <div className="box-title">
                        <h1>React JS para principiantes</h1>
                    </div>
                    <div className="topic-links">
                        <div className="card-topic-link">
                            <Link to="/topics/javascript">
                                <h4><i className="fa-sharp fa-solid fa-circle"></i>&nbsp;&nbsp;&nbsp;JavaScript</h4>
                            </Link>
                        </div>
                        <div className="card-topic-link">
                            <Link to="">
                            <h4><i className="fa-sharp fa-solid fa-circle"></i>&nbsp;&nbsp;&nbsp;Aug 13, 2023</h4>
                            </Link>
                        </div>
                    </div>
                    <div className="sub-container">
                        <h2>Introducción</h2>
                        <p>React JS se ha convertido en una de las bibliotecas de JavaScript más populares y ampliamente utilizadas para la creación de interfaces de usuario interactivas y eficientes. Si estás interesado en aprender esta tecnología, estás en el lugar correcto. En este post, te proporcionaremos una guía detallada para dar tus primeros pasos en el emocionante mundo de React JS.</p>
                        <h2>¿Qué se necesita para aprender React JS?</h2>
                        <p>Antes de sumergirte en React JS, es importante tener una comprensión básica de HTML, CSS y JavaScript. Si ya estás familiarizado con estos fundamentos web, estarás en una posición sólida para comenzar con React JS. Además, asegúrate de tener un entorno de desarrollo adecuado configurado en tu máquina, que incluya Node.js y npm (Node Package Manager) para la gestión de paquetes.</p>
                        <h2>¿Cuánto tiempo se tarda en aprender React JS?</h2>
                        <p>El tiempo necesario para aprender React JS varía según tu nivel de experiencia en programación y el tiempo que puedas dedicar al estudio. Para alguien nuevo en el desarrollo web, puede llevar varias semanas o incluso meses para adquirir una comprensión sólida. Sin embargo, la curva de aprendizaje puede acortarse si ya tienes experiencia en programación y conceptos web.</p>
                        <h2>¿Qué tan difícil es aprender React JS?</h2>
                        <p>React JS puede parecer intimidante al principio, pero su diseño modular y su enfoque en componentes hacen que el aprendizaje sea manejable. La dificultad depende en gran medida de tu familiaridad con los conceptos de programación y desarrollo web en general. Con recursos adecuados, como tutoriales, documentación oficial y ejemplos de código, puedes superar los desafíos con relativa facilidad.</p>
                        <h2>¿Qué aprender primero, React o JavaScript?</h2>
                        <p>Es altamente recomendable que tengas un conocimiento sólido de JavaScript antes de aventurarte en React JS. React es una biblioteca de JavaScript, por lo que entender los conceptos fundamentales de JavaScript te brindará una base sólida para comprender cómo React trabaja y cómo interactúa con el código JavaScript. Aprender JavaScript primero te permitirá escribir componentes React de manera más efectiva y comprender los conceptos avanzados.</p>
                        <h5>Ejemplo: Creando tu primer Componente React</h5>
                        <p>Aquí hay un ejemplo sencillo de cómo crear tu primer componente React, que renderizará un saludo en la pantalla:</p>
                        <div className="code-text">
                            <SyntaxHighlighter className="codes" language="jsx" style={paraisoLight} customStyle={{borderRadius: "1.5em", backgroundColor: "#f4e7e5", border: "2px solid #222222", padding: "2em"}}>
                            {code01}
                            </SyntaxHighlighter>
                        </div>
                        <p>En este ejemplo, estamos importando la biblioteca React, creando una clase de componente que hereda de `React.Component` y luego definimos el método `render()` que devuelve el elemento JSX a ser renderizado.</p>
                        <hr />
                        <h2>Conclusión</h2>
                        <p>Aprender React JS puede ser una experiencia gratificante para cualquier persona interesada en el desarrollo web moderno. Asegúrate de tener una base sólida en HTML, CSS y JavaScript, y luego sumérgete en React JS. Con recursos en línea, ejemplos de código y paciencia, estarás en el camino correcto para construir interfaces de usuario dinámicas y atractivas. Recuerda que la práctica constante y la exploración son esenciales para dominar cualquier nueva tecnología. ¡Empieza hoy mismo tu viaje de aprendizaje en React JS!</p>
                    </div>
                    <Signature/>
                </div>
            </div>
        </>
    )
}