import {Link} from "react-router-dom";
import { Helmet } from 'react-helmet';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { paraisoLight } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import Signature from "../../signature/Signature";
import "../Posts.css";

export default function Fetch() {
    const code01 = `fetch('https://api.example.com/users')
    .then(respuesta => respuesta.json())
    .then(datos => {
        console.log(data); // Accediendo a los datos de la respuesta
    })
    .catch(error => {
        console.error(error);
    });`;
    return (
        <>
            <Helmet>
                <title>TBP | ¿Qué es Fetch?</title>
            </Helmet>
            <div className="container-3">
                <div className="main-container">
                    <div className="box-title">
                        <h1>¿Qué es Fetch? Un enfoque moderno para realizar peticiones HTTP en JavaScript</h1>
                    </div>
                    <div className="topic-links">
                        <div className="card-topic-link">
                            <Link to="/topics/javascript">
                                <h4><i className="fa-sharp fa-solid fa-circle"></i>&nbsp;&nbsp;&nbsp;JavaScript</h4>
                            </Link>
                        </div>
                        <div className="card-topic-link">
                            <Link to="">
                            <h4><i className="fa-sharp fa-solid fa-circle"></i>&nbsp;&nbsp;&nbsp;May 17, 2023</h4>
                            </Link>
                        </div>
                    </div>
                    <div className="sub-container">
                        <p>En JavaScript, hacer peticiones HTTP es una parte fundamental de muchas aplicaciones web. Una de las formas más modernas de hacerlo es utilizando la API Fetch. En este post exploraremos qué es Fetch, cómo funciona, y algunos ejemplos para ayudarnos a entender su uso y beneficios.</p>
                        <h2>¿Qué es Fetch?</h2>
                        <p>Fetch es una API web integrada en los navegadores modernos que proporciona una interfaz potente y flexible para realizar peticiones HTTP. Permite enviar peticiones a un servidor y gestionar las respuestas de forma asíncrona. Fetch simplifica el proceso de trabajar con peticiones de red proporcionando una API coherente y fácil de usar.</p>
                        <h2>Características principales de Fetch:</h2>
                        <ol>
                            <li><b>Basado en promesas: </b>Fetch se basa en JavaScript Promises, lo que facilita la gestión de operaciones asíncronas y el manejo de escenarios de éxito y error de una manera más estructurada.</li>
                            <li><b>Sintaxis simplificada: </b>Fetch tiene una sintaxis simple e intuitiva, utilizando la función `fetch()` para iniciar peticiones HTTP. Acepta una URL y devuelve una Promise que resuelve la respuesta del servidor.</li>
                            <li><b>Soporte para diferentes tipos de peticiones: </b>Fetch soporta varios métodos HTTP como GET, POST, PUT, DELETE, etc. Puedes especificar el método de solicitud, las cabeceras y los datos del cuerpo utilizando las opciones proporcionadas en la función <b>fetch()</b>.</li>
                            <li><b>Gestión de la respuesta: </b>Fetch proporciona métodos para manejar los datos de respuesta, como el análisis sintáctico de JSON, la lectura de las cabeceras de respuesta y la extracción de los códigos de estado de respuesta. También admite la transmisión de respuestas mediante la interfaz <b>Body</b>.</li>
                        </ol>
                        <h2>Ejemplo de uso</h2>
                        <p>Veamos un ejemplo de cómo hacer una petición GET usando Fetch:</p>
                        <div className="code-text">
                            <SyntaxHighlighter className="codes" language="jsx" style={paraisoLight} customStyle={{borderRadius: "1.5em", backgroundColor: "#f4e7e5", border: "2px solid #222222", padding: "2em"}}>
                            {code01}
                            </SyntaxHighlighter>
                        </div>
                        <p>En el ejemplo anterior, usamos la función <b>fetch()</b> para enviar una petición GET al endpoint de la API especificado. A continuación, encadenamos el método <b>.json()</b> para analizar la respuesta como JSON. Por último, manejamos los datos de la respuesta en el segundo bloque <b>.then()</b> y detectamos cualquier error que pueda producirse.</p>
                        <h2>Fetch vs XMLHttpRequest</h2>
                        <p>Fetch proporciona un enfoque más moderno y racionalizado para realizar peticiones HTTP en comparación con el antiguo objeto XMLHttpRequest (XHR). Fetch ofrece una API más sencilla, admite promesas de forma nativa y proporciona una mejor gestión de errores y análisis sintáctico de las respuestas.</p>
                        <hr />
                        <h2>Conclusión</h2>
                        <p>Fetch es una API potente y práctica para realizar peticiones HTTP en JavaScript. Su estructura basada en promesas, su sintaxis simplificada y su compatibilidad con distintos tipos de solicitudes la convierten en una opción muy popular entre los desarrolladores. Usando Fetch, puedemos obtener datos de APIs, interactuar con servidores y manejar respuestas de una manera limpia e intuitiva. Tanto si se está trabajando en una aplicación web como en un proyecto basado en JavaScript, considerar la posibilidad de aprovechar las capacidades de Fetch para gestionar solicitudes HTTP de forma fluida y fiable. Pruébalo y experimenta el enfoque moderno de las solicitudes de red en JavaScript.</p>
                    </div>
                    <Signature/>
                </div>
            </div>
        </>
    )
}