import {Link} from "react-router-dom"
import { Helmet } from "react-helmet";
import Signature from "../../signature/Signature";
import pic1 from "../../pix/classnotes/http/pic-01.png"
import pic2 from "../../pix/classnotes/http/pic-02.png"
export default function Http() {

    return (
        <>
            <Helmet>
                <title>TBP | ¿Qué es HTTP?</title>
            </Helmet>
            <div className="container-3">
                <div className="main-container">
                    <div className="box-title">
                        <h1>¿Qué es Http?</h1>
                    </div>
                    <div className="topic-links">
                        <div className="card-topic-link">
                            <Link to="/topics/classnotes">
                                <h4><i className="fa-sharp fa-solid fa-circle"></i>&nbsp;&nbsp;&nbsp;Classnotes</h4>
                            </Link>
                        </div>
                        <div className="card-topic-link">
                            <Link to="">
                            <h4><i className="fa-sharp fa-solid fa-circle"></i>&nbsp;&nbsp;&nbsp;Jan 18, 2023</h4>
                            </Link>
                        </div>
                    </div>
                    <div className="sub-container">
                        <p>HTTP es un protocolo que permite la obtención de resursos, como documentos HTML. Es un protocolo cliente-servidor.</p>
                        <p>El servidor recibirá requests del navegador que sigue HTTP. Luego responde con un HTTP response que todos los navegadores son capaces de analizar.</p>
                        <div className="pic-post">
                            <img src={pic1} alt="que es http" />
                        </div>
                        <h4>Componentes de los sistemas basados en HTTP</h4>
                        <div className="pic-post">
                            <img src={pic2} alt="componentes de http" />
                        </div>
                        <h3>Client</h3>
                        <p>El navegador es la entidad que inicia la petición &#40;request&#41;. Para presentar una página web, el navegador envía una petición original para obtener el documento HTML que representa la página.</p>
                        <h3>Server</h3>
                        <p>El servidor sirve el documento solicitado por el cliente. Un servidor parece virtualmente una sola máquina, esto se debe a que en realidad puede ser un conjunto de servidores que comparten la carga &#40;equilibrio de carga&#41;.</p>
                        <h3>Proxi</h3>
                        <p>Entre el navegador web y el servidor, numerosos ordenadores y máquinas retransmiten los mensajes HTTP. Debido a la estructura en capas de la pila web, la mayoría de ellos operan en los niveles de transporte, red o físico, volviendose transparentes en la capa HTTP. Los que operan en las capas de aplicación suelen denominarse proxies.</p>
                        <h4>Tareas</h4>
                        <ul>
                            <li>Captura &#40;la caché puede ser pública o privada&#41;</li>
                            <li>Filtrado &#40;como análisis de antivirus o control parental&#41;</li>
                            <li>equilibrio de carga &#40;para permitir que varios servidores atiendan las distintas peticiones&#41;</li>
                            <li>autenticación &#40;para controlar el acceso a los recursos&#41;</li>
                            <li>registro &#40;para almacenar información histórica&#41;</li>
                        </ul>
                        <h4>Response</h4>
                        <p>Una vez que el servidor recibe la solicitud, la procesa  &#40;ejecuta el código que se escribió&#41; y envía una respuesta.</p>
                        <h4>Códigos de estado</h4>
                        <p>Cada vez que hay un response exitoso &#40;sabrás que es exitosa porque la página se cargará sin errores&#41;, es un código de estado de 200, pero hay otros códigos de estado.</p>
                        <ul>
                            <li>100 - informativo</li>
                            <li>200 - éxito</li>
                            <li>300 - redirigir</li>
                            <li>400 - error</li>
                            <li>500 - error del servidor</li>
                        </ul>
                    </div>
                    <Signature/>
                </div>
            </div>
        </>
    )
}