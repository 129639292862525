import {Link} from "react-router-dom"
import { Helmet } from "react-helmet";
import Signature from "../../signature/Signature";
import "../Posts.css"
export default function Formulario_HTML() {

    return (
        <>
            <Helmet>
                <title>TBP | Formularios en HTML</title>
            </Helmet>
            <div className="container-3">
                <div className="main-container">
                    <div className="box-title">
                        <h1>Formularios en HTML</h1>
                    </div>
                    <div className="topic-links">
                        <div className="card-topic-link">
                            <Link to="/topics/html-css">
                                <h4><i className="fa-sharp fa-solid fa-circle"></i>&nbsp;&nbsp;&nbsp;Html & Css</h4>
                            </Link>
                        </div>
                        <div className="card-topic-link">
                            <Link to="">
                            <h4><i className="fa-sharp fa-solid fa-circle"></i>&nbsp;&nbsp;&nbsp;Apr 21, 2023</h4>
                            </Link>
                        </div>
                    </div>
                    <div className="sub-container">
                        <p>Los formularios HTML son un aspecto fundamental del desarrollo web, ya que permiten a los usuarios interactuar con las páginas web enviando datos a los servidores. En esta entrada del blog, exploraremos los fundamentos de los formularios HTML, su estructura y algunos elementos de formulario comunes.</p>
                        <p>Los formularios HTML se crean utilizando el elemento <b>&#60;form&#62;</b>, que envuelve todos los elementos del formulario. The action attribute specifies where the form data is sent when the user submits the form, and the method attribute specifies the HTTP method used to submit the form data.</p>
                        <div className="snappie">
                            <iframe width="374" height="154" title="" src="https://snappify.com/embed/dcd0f368-7cd9-4d16-9962-c709cdd40b53?responsive" allow="clipboard-write" frameborder="0"></iframe>
                        </div>
                        <p>El elemento <b>&#60;input&#62;</b> es uno de los elementos de formulario más utilizados. Permite a los usuarios introducir datos, como texto, números o archivos. El atributo type especifica el tipo de entrada, y el atributo name se utiliza para identificar los datos de entrada cuando se envía el formulario.</p>
                        <div className="snappie">
                            <iframe width="481" height="133" title="" src="https://snappify.com/embed/1a25caff-f3c7-4379-8936-68b1ae941624?responsive" allow="clipboard-write" frameborder="0"></iframe>
                        </div>
                        <p>El elemento <b>&#60;textarea&#62;</b> permite a los usuarios introducir datos de texto de varias líneas, como comentarios o mensajes.</p>
                        <div className="snappie">
                            <iframe width="499" height="133" title="" src="https://snappify.com/embed/6e668068-3dd7-4020-9dd6-787eb1381a0a?responsive" allow="clipboard-write" frameborder="0"></iframe>
                        </div>
                        <p>El elemento <b>&#60;select&#62;</b> permite a los usuarios seleccionar una o varias opciones de una lista.</p>
                        <div className="snappie">
                            <iframe width="416" height="212" title="" src="https://snappify.com/embed/9a2d8295-2f9f-483f-a143-f153889a0a0d?responsive" allow="clipboard-write" frameborder="0"></iframe>
                        </div>
                        <p>El elemento <b>&#60;button&#62;</b> proporciona una forma para que los usuarios envíen los datos del formulario o activen una función JavaScript.</p>
                        <div className="snappie">
                            <iframe width="598" height="129" title="" src="https://snappify.com/embed/32c244eb-6ca9-4953-833d-c682b7fdde84?responsive" allow="clipboard-write" frameborder="0"></iframe>
                        </div>
                        <p>En conclusión, los formularios HTML son una parte esencial del desarrollo web, ya que permiten a los usuarios interactuar con las páginas web enviando datos a los servidores. El elemento <b>&#60;form&#62;</b> proporciona la estructura básica de un formulario, mientras que los elementos <b>&#60;input&#62;</b>, <b>&#60;textarea&#62;</b>, <b>&#60;select&#62;</b>, y <b>&#60;button&#62;</b> proporcionan a los usuarios diversas formas de introducir y enviar datos. Al comprender los conceptos básicos de los formularios HTML y sus elementos, los desarrolladores web pueden crear aplicaciones web potentes e interactivas.</p>
                    </div>
                    <Signature/>
                </div>
            </div>
        </>
    )
}