import React from "react";
import {Link} from "react-router-dom"
import { Helmet } from 'react-helmet';
import Signature from "../../signature/Signature";
import "../Posts.css"

export default function Intro_Python() {
    return (
        <>
            <Helmet>
                <title>TBP | Introducción a Python</title>
            </Helmet>
            <div className="container-3">
                <div className="main-container">
                    <div className="box-title">
                        <h1>Introducción a Python</h1>
                    </div>
                    <div className="topic-links">
                        <div className="card-topic-link">
                            <Link to="/topics/python">
                                <h4><i className="fa-sharp fa-solid fa-circle"></i>&nbsp;&nbsp;&nbsp;Python</h4>
                            </Link>
                        </div>
                        <div className="card-topic-link">
                            <Link to="">
                            <h4><i className="fa-sharp fa-solid fa-circle"></i>&nbsp;&nbsp;&nbsp;Feb 12, 2022</h4>
                            </Link>
                        </div>
                    </div>
                    <div className="sub-container">
                        <h2>¿Qué es la programación?</h2>
                        <p>Al igual que usamos el español o el inglés para comunicarnos entre nosotros, usamos un lenguaje de programación como Python para comunicarnos con el ordenador.</p>
                        <p>La programación es una forma de instruir al ordenador para que realice diversas tareas.</p>
                        <h2>¿Qué es Python?</h2>
                        <p>Python es un lenguaje simple y fácil de entender que se siente como leer inglés simple, esta naturaleza de pseudo código de Python hace que sea fácil de aprender y comprensible para los principiantes.</p>
                        <h2>Características de Python</h2>
                        <ul>
                            <li>Fácil de entender, necesita menos tiempo de desarrollo</li>
                            <li>Código abierto y gratuito</li>
                            <li>Lenguaje de alto nivel</li>
                            <li>Portátil, funciona en Linux / Windows / Mac</li>
                            <li>Divertido de trabajar</li>
                        </ul>
                        <h2>Instalación</h2>
                        <p>Python se puede instalar fácilmente desde python.org</p>
                        <p>Al hacer clic en el botón de descarga, Python se puede instalar justo después de completar la configuración ejecutando el archivo para su plataforma.</p>
                        <h2>¿Quién es el inventor de Python?</h2>
                        <p>Python fue creado por Guido Van Rossum y publicado en 1991.</p>
                        <p>Python se utiliza para:</p>
                        <ul>
                            <li>Desarrollo web (lado del servidor)</li>
                            <li>Desarrollo de software</li>
                            <li>Matemáticas</li>
                            <li>Sistemas de scripts</li>
                        </ul>
                    </div>
                    <Signature/>
                </div>
            </div>
        </>
    )
}