import {Link} from "react-router-dom"
import { Helmet } from "react-helmet";
import pic1 from "../../pix/html-css/html5-css-js/html-logo.png";
import pic2 from "../../pix/html-css/html5-css-js/css-logo.png";
import pic3 from "../../pix/html-css/html5-css-js/js-logo.png";
import Signature from "../../signature/Signature";
import "../Posts.css"
export default function Html5() {

    return (
        <>
            <Helmet>
                <title>TBP | HTML5, CSS and JS</title>
            </Helmet>
            <div className="container-3">
                <div className="main-container">
                    <div className="box-title">
                        <h1>HTML5, CSS and JS</h1>
                    </div>
                    <div className="topic-links">
                        <div className="card-topic-link">
                            <Link to="/topics/html-css">
                                <h4><i className="fa-sharp fa-solid fa-circle"></i>&nbsp;&nbsp;&nbsp;Html & Css</h4>
                            </Link>
                        </div>
                        <div className="card-topic-link">
                            <Link to="">
                            <h4><i className="fa-sharp fa-solid fa-circle"></i>&nbsp;&nbsp;&nbsp;Feb 01, 2022</h4>
                            </Link>
                        </div>
                    </div>
                    <div className="sub-container">
                        <h2>HTML5</h2>
                        <p>Cuando la gente dice 'HTML5', suele referirse a algo más que a la quinta versión del "HyperText Markup Language". Las páginas y aplicaciones web modernas suelen estar compuestas por al menos tres componentes, así que lo que la gente suele querer decir cuando dice "HTML5" es el trío de lenguajes: HTML5, CSS3 y JavaScript.</p>
                        <div className="picsminpost">
                            <img className="" src={pic1} alt="" />
                        </div>
                        <p>La parte "HTML" contiene todo el contenido, organizado en una estructura lógica.  Esta es la parte que más preocupa a un autor: las palabras, los títulos de los capítulos, las figuras, los diagramas, etc.</p>
                        <p>Aunque ha habido numerosas versiones de HTML desde su creación, este curso se centra en la versión más reciente, HTML5. HTML5 se desarrolló para ofrecer a los desarrolladores formas más potentes y flexibles de crear páginas web dinámicas.</p>
                        <h2>CSS</h2>
                        <div className="picsminpost">
                            <img className="" src={pic2} alt="" />
                        </div>
                        <p>La parte "CSS" (siendo la versión 3 la actual) se refiere a la presentación o estilo de la página; el aspecto que tiene sin tener demasiado en cuenta el contenido específico. Entraremos en más detalles sobre esto más adelante en este curso, pero por ahora, piensa en ello como la forma en que podrías especificar un "tema" en un documento de procesamiento de textos, estableciendo fuentes, tamaños, sangrías y cualquier otra cosa que pueda aplicarse a su aspecto.</p>
                        <h2>JavaScript</h2>
                        <div className="picsminpost">
                            <img className="" src={pic3} alt="" />
                        </div>
                        <p>La parte "JavaScript", o "JS" para abreviar, se refiere a las acciones que puede realizar una página, como la interacción con el usuario, y la personalización y modificación de la página según cualquier número de parámetros.  Esto es lo que permite que una página web sea algo más que un simple documento, sino potencialmente una aplicación web, con posibilidades casi ilimitadas.  No haremos mucho con JavaScript en este curso, pero debes saber que es una pata importante del taburete para las páginas web modernas.</p>
                    </div>
                    <Signature/>
                </div>
            </div>
        </>
    )
}