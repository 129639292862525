import {Link} from "react-router-dom"
import { Helmet } from "react-helmet";
import pic1 from "../../pix/classnotes/lenguaje-c/scratch-1.png";
import pic2 from "../../pix/classnotes/lenguaje-c/len-c2.png";
import pic3 from "../../pix/classnotes/lenguaje-c/len-c3.png";
import pic4 from "../../pix/classnotes/lenguaje-c/len-c4.png";
import Signature from "../../signature/Signature";
import "../Posts.css"
export default function Lenguaje_c() {

    return (
        <>
            <Helmet>
                <title>TBP | Lenguaje C</title>
            </Helmet>
            <div className="container-3">
                <div className="main-container">
                    <div className="box-title">
                        <h1>Lenguaje de Programación C</h1>
                    </div>
                    <div className="topic-links">
                        <div className="card-topic-link">
                            <Link to="/topics/classnotes">
                                <h4><i className="fa-sharp fa-solid fa-circle"></i>&nbsp;&nbsp;&nbsp;Classnotes</h4>
                            </Link>
                        </div>
                        <div className="card-topic-link">
                            <Link to="">
                            <h4><i className="fa-sharp fa-solid fa-circle"></i>&nbsp;&nbsp;&nbsp;Jan 28, 2021</h4>
                            </Link>
                        </div>
                    </div>
                    <div className="sub-container">
                        <p>C es un lenguaje de programación que tiene todas las características de Scratch y más, pero tal vez un poco menos amigable ya que está puramente en texto:</p>
                        {/* <div className="codeblock">
                            <CopyBlock
                            language="c"
                            text={`#include <stdio.h>;
        int main(void){
            printf("Hello, world\n");
    }`}
                            codeBlock
                            theme={zenburn}
                            showLineNumbers={false}
                            />
                        </div> */}
                        <p>Aunque las palabras son nuevas, las ideas son exactamente las mismas que los bloques "cuando se hace clic en la bandera verde" y "decir (hola, mundo)" en Scratch:</p>
                        <div className="picsminpost">
                            <img className="" src={pic1} alt="" />
                        </div>
                        <p>Aunque es críptico, no olvide que 2/3 de los estudiantes de CS50 nunca han tomado CS antes, ¡así que no se desanime! Y aunque al principio, para tomar prestada una frase del MIT, tratar de absorber todos estos nuevos conceptos puede sentirse como beber de una manguera contra incendios, tenga la seguridad de que al final del semestre tendremos el poder y la experiencia para aprender y aplicar estos conceptos.</p>
                        <p>Podemos comparar muchas de las construcciones en C, con bloques que ya hemos visto y usado en Scratch. La sintaxis es mucho menos importante que los principios, que ya nos han presentado.</p>
                        <h2>Hello World</h2>
                        <p>El bloque "cuando se hace clic en la bandera verde" en Scratch inicia el programa principal; Al hacer clic en la bandera verde, se inicia el conjunto correcto de bloques debajo. En C, la primera línea para lo mismo es int main(void), de la cual aprenderemos más en las próximas semanas, seguido de una llave abierta &#123;y una llave cerrada &#125;, envolviendo todo lo que debería estar en nuestro programa.</p>
                        {/* <div className="codeblock">
                            <CopyBlock
                            language="c"
                            text={`int main(void){

    }`}
                            codeBlock
                            theme={zenburn}
                            showLineNumbers={false}
                            />
                        </div> */}
                        <p>El bloque "decir (hola, mundo)" es una función y se asigna a printf("hello, world");. En C, la función para imprimir algo en la pantalla es printf, donde f significa "formato", lo que significa que podemos formatear la cadena impresa de diferentes maneras. Luego, usamos paréntesis para pasar lo que queremos imprimir. Tenemos que usar comillas dobles para rodear nuestro texto para que se entienda como texto, y finalmente, agregamos un punto y coma ;para finalizar esta línea de código.</p>
                        <p>Para que el programa funcione, también se necesita de otra línea en la parte superior, una línea de encabezado #include &#60;stdio.h&#62; que defina la printf función que se quiere usar. En alguna parte hay un archivo en nuestra computadora, stdio.h que incluye el código que nos permite acceder a la printf función, y la #include línea le dice a la computadora que incluya ese archivo con nuestro programa.</p>
                        <p>Para escribir un primer programa en Scratch, abrimos el sitio web de Scratch. Del mismo modo, se usará <a href="https://sandbox.cs50.io/">Sandbox CS50</a> para comenzar a escribir y ejecutar código de la misma manera. CS50 Sandbox es un entorno virtual basado en la nube con las bibliotecas y herramientas ya instaladas para escribir programas en varios idiomas. En la parte superior, hay un editor de código simple, donde podemos escribir texto. A continuación, tenemos una ventana de terminal, en la que podemos escribir comandos:</p>
                        <div className="picsminpost">
                            <img className="" src={pic2} alt="" />
                        </div>
                        <p>Escribiremos nuestro código desde arriba en la parte superior, después de usar el signo "+" para crear un nuevo archivo llamado hello.c:</p>
                        <div className="picsminpost">
                            <img className="" src={pic3} alt="" />
                        </div>
                        <p>Terminamos el archivo de nuestro programa .c por convención, para indicar que está destinado a ser un programa en C. Tenga en cuenta que nuestro código está coloreado, por lo que ciertas cosas son más visibles.</p>
                        <h2>Compilers</h2>
                        <p>Una vez que guardamos el código que escribimos, que se llama <u>código fuente</u>, necesitamos convertirlo a <u>código de máquina</u>, instrucciones binarias que la computadora entiende directamente.</p>
                        <p>Utilizamos un programa llamado <u>compilador</u> para compilar nuestro código fuente en código máquina.</p>
                        <p>  Para hacer esto, utilizamos el panel Terminal , que tiene un <u>símbolo del sistema</u>. El $ de la izquierda es un indicador, después del cual podemos escribir comandos.</p>
                        <p>Escribir <i>clang hello.c</i> (donde clang significa "lenguajes C", un compilador escrito por un grupo de personas). Pero antes de presionar enter, hacemos clic en el ícono de la carpeta en la parte superior izquierda de CS50 Sandbox. Vemos nuestro archivo, <i>hello.c</i>. Entonces presionamos enter en la ventana de terminal, y vemos que ahora tenemos otro archivo, llamado <i>a.out</i> (abreviatura de "salida de ensamblaje"). Dentro de ese archivo está el código de nuestro programa, en binario. Ahora, podemos escribir <i>./a.out</i> el indicador de terminal para ejecutar el programa <i>a.out</i> en nuestra carpeta actual. ¡Acabamos de escribir, compilar y ejecutar nuestro primer programa!</p>
                        <h2>String</h2>
                        <p>Pero después de ejecutar nuestro programa, vemos <i>hello, world$</i>, con el nuevo indicador en la misma línea que nuestra salida. Resulta que necesitamos especificar con precisión que necesitamos una nueva línea después de nuestro programa, para que podamos actualizar nuestro código para incluir un carácter especial de nueva línea <i>\n</i>:</p>
                        {/* <div className="codeblock">
                            <CopyBlock
                            language="c"
                            text={`#include <stdio.h>;
        int main(void){
            printf("Hello, world\\n");
    }`}
                            codeBlock
                            theme={zenburn}
                            showLineNumbers={false}
                            />
                        </div> */}
                        <p>Ahora debemos recordar recompilar nuestro programa <i>clang hello.c</i> antes de poder ejecutar esta nueva versión.</p>
                        <p>La línea 2 de nuestro programa está en blanco intencionalmente ya que queremos comenzar una nueva sección de código, al igual que comenzar nuevos párrafos en ensayos. No es estrictamente necesario que nuestro programa se ejecute correctamente, pero ayuda a los humanos a leer programas más largos con mayor facilidad.</p>
                        <p>También podemos cambiar el nombre de nuestro programa <i>a.out</i> a otro. Podemos pasar argumentos de la línea de comandos u opciones adicionales a los programas en la terminal, dependiendo de lo que el programa esté escrito para comprender. Por ejemplo, podemos escribir <i>clang -o hello hello.c</i>, y <i>-o hello</i> le está diciendo al programa <i>clang</i> que guarde la salida compilada como solo <i>hello</i>. Entonces, podemos simplemente correr <i>./hello</i>.</p>
                        <p>En nuestro símbolo del sistema, podemos ejecutar otros comandos, como ls (list), que muestra los archivos en nuestra carpeta actual:</p>
                        {/* <div className="codeblock">
                            <CopyBlock
                            language="shell"
                            text={`$ ls
    a.out* hello hello.c`}
                            codeBlock
                            theme={zenburn}
                            showLineNumbers={false}
                            />
                        </div> */}
                        <p>El asterisco, <i>*</i> indica que esos archivos son ejecutables o que nuestra computadora puede ejecutarlos.<br />Podemos usar el <i>rm</i> comando (eliminar) para eliminar un archivo:</p>
                        {/* <div className="codeblock">
                            <CopyBlock
                            language="shell"
                            text={`$ rm a.out
    rm: remove regular file 'a.out'?`}
                            codeBlock
                            theme={zenburn}
                            showLineNumbers={false}
                            />
                        </div> */}
                        <p>Escribir y o yes para confirmar, y usar ls de nuevo para ver que realmente se ha ido para siempre.</p>
                        <p>Ahora, tratar de obtener información del usuario, como en Scratch cuando se quería decir "hola, TBP":</p>
                        <div className="picsminpost">
                            <img className="" src={pic4} alt="" />
                        </div>
                        {/* <div className="codeblock">
                            <CopyBlock
                            language="c"
                            text={`int main(void){
    string answer = get_string("What's your name?\n");
    printf("Hello, %s\\n", answer);
    }`}
                            codeBlock
                            theme={zenburn}
                            showLineNumbers={false}
                            />
                        </div> */}
                        <p>En primer lugar, necesitamos una cadena , o un pedazo de texto (en concreto, cero o más caracteres en una secuencia entre comillas dobles, como <i>""</i>, <i>"ba"</i>, o “plátanos”), que podemos pedir al usuario, con la función <i>get_string</i>. Pasamos el aviso, o lo que queremos preguntar al usuario, a la función <i>"What is your name?\n"</i> dentro de los paréntesis. A la izquierda, queremos crear una variable, <i>answer</i> cuyo valor será lo que ingrese el usuario. (El signo igual = establece el valor de derecha a izquierda). Finalmente, el tipo de variable que queremos es <i>string</i>, por lo que especificamos eso a la izquierda de <i>answer</i>.</p>
                        <p>Luego, dentro de la <i>printf</i> función, queremos <i>answer</i> recuperar el valor de lo que imprimimos. Usamos un marcador de posición para nuestra variable de cadena <i>%s</i>, dentro de la frase que queremos imprimir, como <i>"hello, %s\n"</i>, y luego le damos <i>printf</i> otro argumento u opción para decirle que queremos que la variable <i>answer</i> sea sustituida.</p>
                        <p>Si cometimos un error, como escribir <i>printf("hello, world"\n);</i> con el \n exterior de las comillas dobles para nuestra cadena, veremos un error de nuestro compilador:</p>
                        {/* <div className="codeblock">
                            <CopyBlock
                            language="shell"
                            text={`$ clang -0 hello hello.c
    hello.c:5:26 error: expected ')'
        printf("Hello, world"\\n);
                                        ^
    hello.c:5:11: note: to match this '('
        printf("Hello, world"\\n);
                ^
    1 error generated.                                    
    `}
                            codeBlock
                            theme={zenburn}
                            showLineNumbers={false}
                            />
                        </div> */}
                        <p>La primera línea del error nos dice que miremos <i>hello.c</i>, línea 5, columna 26, donde el compilador esperaba un paréntesis de cierre, en lugar de una barra invertida.</p>
                        <p>Para simplificar las cosas (al menos al principio), incluiremos una biblioteca, o un conjunto de código, de CS50. La biblioteca nos proporciona el <i>string</i> tipo de variable, la <i>get_string</i> función y más. Solo tenemos que escribir una línea en la parte superior <i>include</i> del archivo <i>cs50.h</i>:</p>
                        {/* <div className="codeblock">
                            <CopyBlock
                            language="c"
                            text={`#include <cs50.h>
    #include <stdio.h>

    int main(void){
    string answer = get_string("What's your name?\n");
    printf("Hello, name\\n");
    }`}
                            codeBlock
                            theme={zenburn}
                            showLineNumbers={false}
                            />
                        </div> */}
                        <p>Hacer un nuevo archivo <i>string.c</i>, con este código:</p>
                        {/* <div className="codeblock">
                            <CopyBlock
                            language="c"
                            text={`#include <stdio.h>

    int main(void){
    string answer = get_string("What's your name?\n");
    printf("Hello, %s\\n", name);
    }`}
                            codeBlock
                            theme={zenburn}
                            showLineNumbers={false}
                            />
                        </div> */}
                        <p>Ahora, si tratamos de compilar ese código, obtenemos muchas líneas de errores. A veces, un error significa que el compilador comienza a interpretar el código correcto incorrectamente, generando más errores de los que realmente hay. Entonces comenzamos con nuestro primer error:</p>
                        {/* <div className="codeblock">
                            <CopyBlock
                            language="shell"
                            text={`$ clang -0 string string.c
    string.c:5:5: error: use of undeclarated identifier 'string'; did you mean 'stdin'?
        string name = get_string("What's your name?\\n");
        ^~~~~~
        stdin
    /usr/include/stdio.h:135:25: note: 'stdin' declared here
    extern struct _IO_FILE *stdin:     `}
                            codeBlock
                            theme={zenburn}
                            showLineNumbers={false}
                            />
                        </div> */}
                        <p>No queríamos decir <i>stdin</i> ("entrada estándar") en lugar de <i>string</i>, por lo que ese mensaje de error no fue útil. De hecho, necesitamos importar otro archivo que defina el tipo <i>string</i> (en realidad, una rueda de entrenamiento de CS50, como veremos en las próximas semanas).</p>
                        <p>Entonces se puede incluir otro archivo, <i>cs50.h</i> que también incluye la función <i>get_string</i>, entre otros.</p>
                        {/* <div className="codeblock">
                            <CopyBlock
                            language="c"
                            text={`#include <cs50.h>
    #include <stdio.h>
    int main(void){
    string answer = get_string("What's your name?\n");
    printf("Hello, %s\\n", name);
    }`}
                            codeBlock
                            theme={zenburn}
                            showLineNumbers={false}
                            />
                        </div> */}
                        <p>Ahora, cuando intentamos compilar nuestro programa, solo tenemos un error:</p>
                        {/* <div className="codeblock">
                            <CopyBlock
                            language="shell"
                            text={`$ clang -0 string string.c
    /tmp/string-aca94d.o: In function 'main':
    string.c:(.text+0x19): undefined reference to 'get_string'
    clang-7: error: linker command failed with code 1 (use -v to see invocation)
    `}
                            codeBlock
                            theme={zenburn}
                            showLineNumbers={false}
                            />
                        </div> */}
                        <p>Resulta que también tenemos que decirle a nuestro compilador que agregue nuestro archivo especial de biblioteca CS50, con <i>clang -o string string.c -lcs50</i>, con -l para "enlace".</p>
                        <p>Incluso podemos abstraer esto y simplemente escribir <i>make string</i>. Vemos que, de manera predeterminada en el CS50 Sandbox, se <i>make</i> utiliza <i>clang</i> para compilar nuestro código desde <i>string.c</i> adentro <i>string</i>, con todos los argumentos necesarios, o indicadores, pasados.</p>
                    </div>
                    <Signature/>
                </div>
            </div>
        </>
    )
}