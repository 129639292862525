import {Link} from "react-router-dom"
import { Helmet } from 'react-helmet';
import Signature from "../../signature/Signature";
import "../Posts.css"

export default function Casos_Test() {
    return (
        <>
            <Helmet>
                <title>TBP | Casos de Test</title>
            </Helmet>
            <div className="container-3">
                <div className="main-container">
                <div className="box-title">
                        <h1>Casos de Test</h1>
                    </div>
                    <div className="topic-links">
                        <div className="card-topic-link">
                            <Link to="/topics/testing">
                                <h4><i className="fa-sharp fa-solid fa-circle"></i>&nbsp;&nbsp;&nbsp;Testing</h4>
                            </Link>
                        </div>
                        <div className="card-topic-link">
                            <Link to="">
                            <h4><i className="fa-sharp fa-solid fa-circle"></i>&nbsp;&nbsp;&nbsp;May 24, 2020</h4>
                            </Link>
                        </div>
                    </div>
                    <div className="sub-container">
                        <p>Un CASO DE TEST es un conjunto de acciones ejecutadas para verificar una característica o funcionalidad determinada de su aplicación de software. Un caso de prueba contiene pasos de prueba, datos de prueba, condición previa, condición posterior desarrollada para un escenario de prueba específico para comprobar cualquier requisito.</p>
                        <p>El caso de prueba incluye variables o condiciones específicas, con las que un ingeniero de pruebas puede comparar los resultados esperados y reales para determinar si un producto de software funciona según los requisitos del cliente.</p>
                        <h2>Test Scenario Vs Test Case</h2>
                        <p>Los escenarios de prueba son bastante vagos y cubren una amplia gama de posibilidades. Las pruebas se trata de ser muy específicas.</p>
                        <p>Para un escenario de prueba: Compruebe la funcionalidad de inicio de sesión hay muchos casos de prueba posibles son:</p>
                        <p><b>Caso de Test 01: </b>Comprobar los resultados al introducir el ID de usuario y la contraseña válidos.</p>
                        <p><b>Caso de test 02: </b>Compruebe los resultados al introducir un ID de usuario y una contraseña no válidos.</p>
                        <p><b>Caso de test 03: </b>Compruebe la respuesta cuando un ID de usuario está vacío y se presiona el botón de inicio de sesión, y muchos más.</p>
                        <h2>Escenario de test</h2>
                        <p>Los escenarios de prueba son bastante vagos y cubren una amplia gama de posibilidades.</p>
                        <p>Las pruebas se trata de ser muy específicas.</p>
                        <h2>Dato de Test</h2>
                        <table>
                            <tr>
                                <th>Escenario de Test</th>
                                <th>Caso de Test</th>
                                <th>Dato de Test</th>
                            </tr>
                            <tr>
                                <td>Comprobar funcionalidad de Inicio de Sesión</td>
                                <td>Comprobar la respuesta al introducir el nombre del agente y la contraseña válidos.</td>
                                <td>Agent Name: theblinkpanda<br />Password: BLINK<br />Agent Name theblink<br />Password: BLink<br />Agent Name: 123<br />Password: blink</td>
                            </tr>
                        </table>
                        <ul>
                            <li>Identificar los datos de prueba puede llevar mucho tiempo y algunas veces también puede requerir la creación de datos de prueba.</li>
                        </ul>
                        <p><b>Resultado Esperado: Inicio de Sesión debe ser exitoso.</b></p>
                        <ul>
                            <li>If expected results are not documented we may miss out on small differences in calculations in results which otherwise look OK.</li>
                        </ul>
                        <h2>Pasos de Test</h2>
                        <ol>
                            <li>Cargar la aplicación</li>
                            <li>Ingresar el nombre del agente</li>
                            <li>Ingresar la contraseña</li>
                            <li>Clic en el botón OK</li>
                        </ol>
                    </div>
                    <Signature/>
                </div>
            </div>
        </>
    )
}