import {Link} from "react-router-dom"
import { Helmet } from "react-helmet";
import bpmn from "../../pix/bpm/primerospasos-bpm/BPMN.png"
import Signature from "../../signature/Signature";
import "../Posts.css"
export default function Primerospasos_bpm() {

    return (
        <>
            <Helmet>
                <title>TBP | Primeros pasos con BPM</title>
            </Helmet>
            <div className="container-3">
                <div className="main-container">
                    <div className="box-title">
                        <h1>Modelado de Procesos Esenciales: Primeros pasos con BPM</h1>
                    </div>
                    <div className="topic-links">
                        <div className="card-topic-link">
                            <Link to="/topics/bpm">
                                <h4><i className="fa-sharp fa-solid fa-circle"></i>&nbsp;&nbsp;&nbsp;Bpm</h4>
                            </Link>
                        </div>
                        <div className="card-topic-link">
                            <Link to="">
                            <h4><i className="fa-sharp fa-solid fa-circle"></i>&nbsp;&nbsp;&nbsp;Apr 02, 2022</h4>
                            </Link>
                        </div>
                    </div>
                    <div className="sub-container">
                        <p>Razones para modelar un proceso:</p>
                            <ul>
                                <li>Entender el proceso.</li>
                                <li>Identificar y prevenir problemas.</li>
                                <li>Compartir nuestra comprensión del proceso con los demás.</li>
                            </ul>
                            <h2>Primeros pasos con BPM</h2>
                            <div className="picinpost">
                                <img className="" src={bpmn} alt="" />
                            </div>
                            <p><b>Eventos → Cosas que suceden instantáneamente.</b></p>
                            <ul>
                                <li>Eventos de inicio → Cuando se inicia una instancia del proceso</li>
                                <ul>
                                    <li>Representado por un círculo con un borde delgado</li>
                                    <li>ej. Orden de compra recibida.</li>
                                </ul>
                                <li>Evento de finalización → Cuando se completa una instancia del proceso.</li>
                                <ul>
                                    <li>Representado por un círculo con borde grueso.</li>
                                    <li>ej. Pedido cumplido</li>
                                </ul>
                            </ul>
                            <p><b>Actividades → Unidades de trabajo que tienen una duración.</b></p>
                            <ul>
                                <li>Representado por rectángulos con esquinas redondeadas.</li>
                                <li>ej. Confirmar pedido / Emitir factura, etc.</li>
                            </ul>
                            <p><b>Relación → Secuencia: La actividad A es seguida por la actividad B.</b></p>
                            <ul>
                                <li>Representado por una flecha con punta llena.</li>
                                <li>ej. La flecha entre Confirmar pedido y Obtener dirección de envío.</li>
                            </ul>
                            <p><b>Token → Identificar el progreso/estado de una instancia de proceso.</b></p>
                            <ul>
                                <li>Representado por un punto de color en la parte superior de un modelo de proceso.</li>
                                <li>ej. Punto rojo sobre la actividad Enviar producto.</li>
                                <li>ej. Punto negro dentro del evento de inicio.</li>
                                <li>ej. Punto verde entre (comienza con la siguiente actividad) Recibir pago y Archivar pedido.</li>
                            </ul>
                            <p><b>Etiqueta → Un nombre para un evento o actividad.</b></p>
                            <p>Usar para:</p>
                            <ul>
                                <li>Evento de inicio: comunica lo que desencadena/inicia una instancia del proceso.</li>
                                <li>Evento final: comunicar el resultado del proceso.</li>
                                <li>Actividad: Nombre lo que hace la actividad</li>
                                <ul>
                                    <li>Sintaxis: &#123;VERBO&#125;+&#123;ADJETIVO&#125;+&#123;SUSTANCIA&#125;+&#123;COMPLEMENTO&#125;</li>
                                    <ul>
                                        <li>(Verbo, Adjetivo, Sustantivo, Adición)</li>
                                        <li>Se puede usar el adjetivo y el complemento, pero no es necesario.</li>
                                        <li>Las etiquetas largas disminuirán la legibilidad del modelo.</li>
                                        <li>Regla del pulgar: máximo 5 palabras (excluyendo las palabras "pegamento" (el, la, un, una, etc.))</li>
                                        <li>Use palabras consistentemente (sin sinónimos)</li>
                                        <li>Use verbos significativos y no ambiguos (para procesar &gt; cumplimiento de pedidos)</li>
                                    </ul>
                                    <li>ex. &#123;Aprobar&#125;+&#123; &#125;+&#123;Pedido&#125;+&#123; &#125; = Aprobar pedido</li>
                                    <li>ex. &#123;Emitir&#125;+&#123;Conductor&#125;+&#123;Licencia&#125;+&#123; &#125; = Emitir licencia de conducir</li>
                                    <li>ex. &#123;Renovar&#125;+&#123;Conductor&#125;+&#123;Licencia&#125;+&#123;a través de agencias fuera de línea&#125; = Renovar licencia de conducir a través de agencias fuera de línea</li>
                                </ul>
                            </ul>
                            <h2>Teoría del modelado</h2>
                            <p>Características del modelo:</p>
                            <ul>
                                <li>Mapeo: crea un modelo del sujeto.</li>
                                <li>Abstracción: Utilizar únicamente aspectos relevantes del tema.</li>
                                <li>Apto para el propósito: Creación de un plano para mostrar el propósito del tema.</li>
                            </ul>
                            <p>Público objetivo (para quién se está haciendo el modelo).</p>
                            <ul>
                                <li>ex. Un comprador puede querer ver un modelo de madera, pero un ingeniero eléctrico no puede usarlo para diseñar un sistema eléctrico.</li>
                            </ul>
                            <p>Propósito del modelado:</p>
                            <ul>
                                <li>Diseño organizacional:</li>
                                <ul>
                                    <li>Orientado a los negocios, utilizado principalmente para la comprensión y la comunicación.</li>
                                    <li>También benchmarking y mejora.</li>
                                    <li>Para: Gerentes, Dueños de procesos y analistas de negocios.</li>
                                </ul>
                                <li>Diseño del sistema de aplicación:</li>
                                <ul>
                                    <li>Orientado a TI</li>
                                    <li>Para → Ingenieros de sistemas y desarrolladores utilizados para la automatización.</li>
                                    <li>Planos para el desarrollo de software.</li>
                                </ul>
                            </ul>
                    </div>
                    <Signature/>
                </div>
            </div>
        </>
    )
}