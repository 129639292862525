import {Link} from "react-router-dom"
import { Helmet } from "react-helmet";
import SyntaxHighlighter from 'react-syntax-highlighter';
import { paraisoLight } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import Signature from "../../signature/Signature";
export default function GitWebDev() {
    const code01 = `git init`;
    const code02 = `git add filename`;
    const code03 = `git commit -m "Commit message"`;
    const code04 = `git remote add origin <remote repository URL>`;
    const code05 = `git push origin master`;
    const code06 = `git pull origin master`;

    return (
        <>
            <Helmet>
                <title>TBP | ¿Cómo utilizar Git para el desarrollo web?</title>
            </Helmet>
            <div className="container-3">
                <div className="main-container">
                    <div className="box-title">
                        <h1>¿Cómo utilizar Git para el desarrollo web?</h1>
                    </div>
                    <div className="topic-links">
                        <div className="card-topic-link">
                            <Link to="/topics/web-programming">
                                <h4><i className="fa-sharp fa-solid fa-circle"></i>&nbsp;&nbsp;&nbsp;Web Programming</h4>
                            </Link>
                        </div>
                        <div className="card-topic-link">
                            <Link to="">
                            <h4><i className="fa-sharp fa-solid fa-circle"></i>&nbsp;&nbsp;&nbsp;Apr 15, 2023</h4>
                            </Link>
                        </div>
                    </div>
                    <div className="sub-container">
                        <p>El desarrollo web implica gestionar mucho código, desde HTML y CSS hasta JavaScript y varios lenguajes de programación back-end. Con tantos archivos y líneas de código, puede ser difícil hacer un seguimiento de los cambios y colaborar con los demás. Ahí es donde entra en juego el control de versiones, y Git es uno de los sistemas de control de versiones más populares que existen.</p>
                        <p>En este post, te guiaremos a través de los conceptos básicos del uso de Git para el control de versiones en el desarrollo web, desde la creación de un repositorio hasta la colaboración con otros desarrolladores.</p>
                        <h2>Paso 1: Instalar Git</h2>
                        <p>El primer paso para utilizar Git es instalarlo en tu ordenador. Puedes descargar Git desde el sitio web oficial en <a className="textlinks" href="http://git-scm.com/downloads">https://git-scm.com/downloads</a>.</p>
                        <h2>Paso 2: Crear un repositorio</h2>
                        <p>Una vez instalado Git, puedes crear un nuevo repositorio para tu proyecto. Un repositorio es como una carpeta que contiene todos los archivos y el código de tu proyecto, así como un historial de todos los cambios realizados en esos archivos a lo largo del tiempo.</p>
                        <p>Para crear un nuevo repositorio, abra su terminal o símbolo del sistema y navegue hasta el directorio donde desea almacenar su proyecto. A continuación, ejecuta el siguiente comando</p>
                        <div className="code-text">
                            <SyntaxHighlighter className="codes" language="script" style={paraisoLight} customStyle={{borderRadius: "1.5em", backgroundColor: "#f4e7e5", border: "2px solid #222222", padding: "2em"}}>
                            {code01}
                            </SyntaxHighlighter>
                        </div>
                        <p>Esto inicializará un nuevo repositorio Git en el directorio actual.</p>
                        <h2>Paso 3: Añadir archivos al repositorio</h2>
                        <p>Una vez que tengas un repositorio configurado, puedes empezar a añadirle archivos. Para añadir un archivo al repositorio, utiliza el siguiente comando:</p>
                        <div className="code-text">
                            <SyntaxHighlighter className="codes" language="script" style={paraisoLight} customStyle={{borderRadius: "1.5em", backgroundColor: "#f4e7e5", border: "2px solid #222222", padding: "2em"}}>
                            {code02}
                            </SyntaxHighlighter>
                        </div>
                        <p>Esto añadirá el archivo al área de preparación, que es donde Git realiza el seguimiento de los cambios que quieres confirmar.</p>
                        <h2>Paso 4: Confirmar cambios</h2>
                        <p>Una vez que hayas añadido tus archivos al área de preparación, puedes confirmar los cambios en el repositorio. Para ello, utilice el siguiente comando:</p>
                        <div className="code-text">
                            <SyntaxHighlighter className="codes" language="script" style={paraisoLight} customStyle={{borderRadius: "1.5em", backgroundColor: "#f4e7e5", border: "2px solid #222222", padding: "2em"}}>
                            {code03}
                            </SyntaxHighlighter>
                        </div>
                        <p>El mensaje de confirmación debe ser una breve descripción de los cambios realizados. Es importante escribir mensajes de confirmación claros y concisos, ya que te ayudarán a ti y a otros desarrolladores a entender qué cambios se han hecho y por qué.</p>
                        <h2>Paso 5: Enviar los cambios a un repositorio remoto</h2>
                        <p>Si estás trabajando en un proyecto con otros desarrolladores, puede que quieras enviar tus cambios a un repositorio remoto, como uno alojado en GitHub o GitLab. Para hacer esto, necesitarás añadir el repositorio remoto a tu repositorio local usando el siguiente comando:</p>
                        <div className="code-text">
                            <SyntaxHighlighter className="codes" language="script" style={paraisoLight} customStyle={{borderRadius: "1.5em", backgroundColor: "#f4e7e5", border: "2px solid #222222", padding: "2em"}}>
                            {code04}
                            </SyntaxHighlighter>
                        </div>
                        <p>Una vez que hayas añadido el repositorio remoto, puedes enviar tus cambios a él usando el siguiente comando:</p>
                        <div className="code-text">
                            <SyntaxHighlighter className="codes" language="script" style={paraisoLight} customStyle={{borderRadius: "1.5em", backgroundColor: "#f4e7e5", border: "2px solid #222222", padding: "2em"}}>
                            {code05}
                            </SyntaxHighlighter>
                        </div>
                        <p>Esto hará todos tus cambios confirmados vayan al repositorio remoto, donde otros desarrolladores pueden acceder a ellos.</p>
                        <h2>Paso 6: Colabora con otros desarrolladores</h2>
                        <p>Git facilita la colaboración con otros desarrolladores en un proyecto. Si estás trabajando en un proyecto con otras personas, necesitarás incorporar sus cambios a tu repositorio local utilizando el siguiente comando:</p>
                        <div className="code-text">
                            <SyntaxHighlighter className="codes" language="script" style={paraisoLight} customStyle={{borderRadius: "1.5em", backgroundColor: "#f4e7e5", border: "2px solid #222222", padding: "2em"}}>
                            {code06}
                            </SyntaxHighlighter>
                        </div>
                        <p>Esto traerá todos los cambios realizados por otros desarrolladores al repositorio remoto.</p>
                        <p>Si realizas cambios en el mismo archivo que otro desarrollador, puedes encontrarte con un conflicto de fusión. Git te avisará de ello y te pedirá que resuelvas el conflicto antes de que puedas confirmar tus cambios.</p>
                        <hr />
                        <p>En conclusión, el uso de Git para el control de versiones en el desarrollo web es esencial para gestionar el código y colaborar con otros desarrolladores. Siguiendo estos pasos, puedes configurar fácilmente un repositorio, añadir archivos, confirmar cambios, enviar a un repositorio remoto y colaborar con otros desarrolladores. Happy coding!</p>
                    </div>
                    <Signature/>
                </div>
            </div>
        </>
    )
}