import {Link} from "react-router-dom"
import { Helmet } from "react-helmet";
import Signature from "../../signature/Signature";
export default function DireccionamientoIPv4() {

    return (
        <>
            <Helmet>
                <title>TBP | Direccionamiento IP v4</title>
            </Helmet>
            <div className="container-3">
                <div className="main-container">
                    <div className="box-title">
                        <h1>Direccionamiento IP v4</h1>
                    </div>
                    <div className="topic-links">
                        <div className="card-topic-link">
                            <Link to="/topics/classnotes">
                                <h4><i className="fa-sharp fa-solid fa-circle"></i>&nbsp;&nbsp;&nbsp;Classnotes</h4>
                            </Link>
                        </div>
                        <div className="card-topic-link">
                            <Link to="">
                            <h4><i className="fa-sharp fa-solid fa-circle"></i>&nbsp;&nbsp;&nbsp;Feb 08, 2023</h4>
                            </Link>
                        </div>
                    </div>
                    <div className="sub-container">
                        <h3>Introducción</h3>
                        <p>Las direcciones IP se usan para identificar de forma exclusiva a las redes y hosts &#40;computadores e impresoras&#41; TCP/IP individuales en sus redes para que los dispositivos se puedan comunicar. Las estaciones de trabajo y los servidores de una red TCP/IP se denominan "HOSTS" y cada uno de ellos tiene una dirección IP exclusiva, denominada dirección "HOST". TCP/IP es el protocolo que se utiliza más ampliamente a nivel mundial. Internet o la World Wide Web usan sólo direccionamiento IP. Para que un host pueda acceder a Internet, debe tener una dirección IP.</p>
                        <p>En su forma básica, la dirección IP se divide en dos partes: una dirección de red y una dirección de host. El Internet Network Information Center &#40;InterNIC&#41; Centro de Informaciones de la Red de Internet asigna la parte de red de la dirección IP a una empresa u organización. Los routers usan la dirección IP para desplazar paquetes de datos entre redes. Las direcciones IP tienen una longitud de 32 bits &#40;con la versión actual IPv4&#41; y se dividen en 4 octetos de 8 bits cada uno. Operan en la capa de red, la Capa 3 del modelo OSI, &#40;la capa de internetwork del modelo TCP/IP&#41; y son asignadas de forma estática &#40;manualmente&#41; por un administrador de red o de forma dinámica &#40;automáticamente&#41; por un Servidor de Protocolo de configuración dinámica del host &#40;DHCP&#41;. La dirección IP de una estación de trabajo &#40;host&#41; es una "dirección lógica", lo que significa que se puede modificar. La dirección MAC de la estación de trabajo es una "dirección física" de 48 bits que se graba en el NIC y que no se puede modificar a menos que se reemplace la NIC. La combinación de la dirección IP lógica y de la dirección MAC física ayuda a enrutar paquetes hacia el destino correcto.</p>
                        <h3>Clases de direcciones IP</h3>
                        <p>Hay tres clases de direcciones IP que una organización puede recibir de parte del Registro Americano de Números de Internet &#40;ARIN&#41; &#40;o ISP de la organización&#41;: Clase A, B y C. En la actualidad, ARIN reserva las direcciones Clase A para los gobiernos de todo el mundo &#40;aunque en el pasado se le hayan otorgado a empresas de gran envergadura como, por ejemplo, Hewlett Packard&#41; y las direcciones Clase B para las medianas empresas. Se otorgan direcciones Clase C para todos los demás solicitantes.</p>
                        <h4>Clase A</h4>
                        <p>El primer bit de una dirección clase "A" siempre es un cero, lo que significa que el Bit de primer nivel &#40;HOB&#41; o bit 128 no se puede usar Un ejemplo de una dirección IP Clase A es 124.95.44.15. El primer octeto, 124, identifica el número de red asignado por ARIN. Los administradores internos de la red asignan los 24 bits restantes. Una manera fácil de reconocer si un dispositivo forma parte de una red Clase A es verificar el primer octeto de su dirección IP, cuyo valor debe estar entre 0 y 126. &#40;127 comienza con un bit 0, pero está reservado para fines especiales&#41;.</p>
                        <p>Todas las direcciones IP Clase A utilizan solamente los primeros 8 bits para identificar la parte de red de la dirección. Los tres octetos restantes se pueden utilizar para la parte de host de la dirección. A cada una de las redes que utilizan una dirección IP Clase A se les pueden asignar hasta 2 elevado a la 24 potencia &#40;224&#41; &#40;menos 2&#41;, o 16.777.214 direcciones IP posibles para los dispositivos que están conectados a la red.</p>
                        <h4>Clase B</h4>
                        <p>Los primeros 2 bits de una dirección Clase B siempre son 10 &#40;uno y cero&#41;. Un ejemplo de una dirección IP Clase B es 151.10.13.28. Los dos primeros octetos identifican el número de red asignado por ARIN. Los administradores internos de la red asignan los 16 bits restantes. Una manera fácil de reconocer si un dispositivo forma parte de una red Clase B es verificar el primer octeto de su dirección IP. Las direcciones IP Clase B siempre tienen valores que van del 128 al 191 en su primer octeto.</p>
                        <p>Todas las direcciones IP Clase B utilizan los primeros 16 bits para identificar la parte de red de la dirección. Los dos octetos restantes de la dirección IP se encuentran reservados para la porción del host de la dirección. Cada red que usa un esquema de direccionamiento IP Clase B puede tener asignadas hasta 2 a la 16ta potencia &#40;216&#41; &#40;menos 2 otra vez&#41;, o 65.534 direcciones IP posibles a dispositivos conectados a su red.</p>
                        <h4>Clase C</h4>
                        <p>Los 3 primeros bits de una dirección Clase C siempre son 110 &#40;uno, uno y cero&#41;. Un ejemplo de dirección IP Clase C es 201.110.213.28. Los tres primeros octetos identifican el número de red asignado por ARIN. Los administradores internos de la red asignan los 8 bits restantes. Una manera fácil de reconocer si un dispositivo forma parte de una red Clase C es verificar el primer octeto de su dirección IP. Las direcciones IP Clase C siempre tienen valores que van del 192 al 223 en su primer octeto.</p>
                        <p>Todas las direcciones IP Clase C utilizan los primeros 24 bits para identificar la porción de red de la dirección. Sólo se puede utilizar el último octeto de una dirección IP Clase C para la parte de la dirección que corresponde al host. A cada una de las redes que utilizan una dirección IP Clase C se les pueden asignar hasta 28 &#40;menos 2&#41;, o 254, direcciones IP posibles para los dispositivos que están conectados a la red.</p>
                        <p>Nota: <mark>La dirección 127 Clase A no se puede utilizar y está reservada para funciones de evaluación del loop de prueba y diagnóstico</mark></p>
                        <h4>Direcciones IP como números decimales</h4>
                        <p>Las direcciones IP identifican un dispositivo en una red y la red a la cual se encuentra conectado. Para que sean más fáciles de recordar, las direcciones IP se escriben generalmente con notación decimal punteada. Por lo tanto, las direcciones IP se componen de 4 números decimales separados por puntos. Un ejemplo es la dirección 166.122.23.130. Recuerde que un número decimal es un número de base 10, del tipo que utilizamos diariamente</p>
                        <h4>Máscara de subred</h4>
                        <p>La máscara de subred &#40;término formal: prefijo de red extendida&#41;, no es una dirección, sin embargo determina qué parte de la dirección IP corresponde al campo de red y qué parte corresponde al campo de host. Una máscara de subred tiene una longitud de 32 bits y tiene 4 octetos, al igual que la dirección IP.</p>
                        <p>Para determinar la máscara de subred para una dirección IP de subred particular, seguir los pasos:</p>
                        <ol>
                            <li>Expresar la dirección IP de subred en forma binaria.</li>
                            <li>Cambiar la porción de red y subred de la dirección por todos unos.</li>
                            <li>Cambiar la porción del host de la dirección por todos ceros.</li>
                            <li>Como último paso, convertir la expresión en números binarios nuevamente a la notación decimal punteada.</li>
                        </ol>
                        <p>Nota: <mark>El prefijo de red extendida incluye el número de red clase A, B o C y el campo de subred &#40;o número de subred&#41; que se utiliza para ampliar la información de enrutamiento que de otro modo es simplemente el número de red</mark></p>
                        <p>El valor de cualquiera de los octetos nunca puede ser mayor que 255 decimal o 11111111 binario.</p>
                    </div>
                    <Signature/>
                </div>
            </div>
        </>
    )
}