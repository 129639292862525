import "./Home.css";
import { Helmet } from "react-helmet";
import {Link} from "react-router-dom";
import { useEffect, useState } from "react";
import panda from "../pix/tbp-icon.png";
export default function Home() {
    const [data,setData]=useState([]);
    const [book,setBook]=useState([])
    
    const getData=()=>{
        fetch('../posts.json',
        {
        headers : { 
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        }
        }
        )
        .then(function(response){
            return response.json();
        })
        .then(function(myJson) {
            setData(myJson.slice(0,3))
        });
    }
    const getBook=()=>{
        fetch('../topic-books.json',
        {
        headers : { 
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        }
        }
        )
        .then(function(response){
            return response.json();
        })
        .then(function(myJson) {
            setBook(myJson[0].books.slice(-3).reverse())
        });
    }
    useEffect(()=>{
        getData()
        getBook()
    },[])
    
    return (
        <>
            <Helmet>
                <title>The Blink Panda</title>
            </Helmet>
            <div className="container-3">
                <div className="main-container">
                    <div className="sub-cont">
                        <div className="welcome-logo">
                            <img src={panda} alt="" />
                        </div>
                        <h1>Hi there! I'm The Blink Panda</h1>
                        <div className="welcome">
                            <div className="welcome-text">
                                <h1>Today is a good day to start reading a new post</h1>
                            </div>
                        </div>
                    </div>
                    <div className="extras">
                        <Link className="link link-page" to={`/about`}>More about me</Link>
                        <Link className="link link-page" to={`/currently-listening`}>Currently listening</Link>
                    </div>
                </div>
            </div>
            <div className="container-2">
                <div className="main-container">
                    <div className="cont-text">
                            <h1>Last Posts</h1>
                        </div>
                    <div className="lastposts">
                        <div className="last-posts">
                        {data.map(item => ( 
                            <Link className="link" to={`/topics/${item.link}`}>
                            <div className="card-post" key={item.category}>
                                <div className="date">
                                    <a>{item.date}</a>
                                </div>
                                <h4 className="text-post">{item.title}</h4>
                            </div>
                        </Link>
                        ))}
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-3">
                <div className="main-container">
                    <h1>Last Books Read</h1>
                    <div className="pix-books">
                    {book.map(item => (
                        <div className="book-card">
                            <img className="book-pic" src={`${process.env.PUBLIC_URL}/books/${item.pix}`} alt="" />
                            <p className="book-title"><b>{item.title}</b></p>
                            <p className="book-author">{item.author}</p>
                        </div>
                    ))}
                    </div>
                </div>
            </div>
        </>
    )
}