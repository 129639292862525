import {Link} from "react-router-dom"
import { Helmet } from "react-helmet";
import Signature from "../../signature/Signature";
export default function CiclovidaBpmstakeholders() {

    return (
        <>
            <Helmet>
                <title>TBP | Ciclo de vida BPM y Stakeholders</title>
            </Helmet>
            <div className="container-3">
                <div className="main-container">
                    <div className="box-title">
                        <h1>Ciclo de vida BPM y Stakeholders</h1>
                    </div>
                    <div className="topic-links">
                        <div className="card-topic-link">
                            <Link to="/topics/bpm">
                                <h4><i className="fa-sharp fa-solid fa-circle"></i>&nbsp;&nbsp;&nbsp;Bpm</h4>
                            </Link>
                        </div>
                        <div className="card-topic-link">
                            <Link to="">
                            <h4><i className="fa-sharp fa-solid fa-circle"></i>&nbsp;&nbsp;&nbsp;Mar 21, 2022</h4>
                            </Link>
                        </div>
                    </div>
                    <div className="sub-container">
                    <h2>Ciclo de vida de BPM</h2>
                        <p>Tiene seis pasos:</p>
                        <h3>Paso 01: Identificación del proceso</h3>
                        <p> <b> ¿Con qué procesos estamos tratando y cómo podemos medir el resultado?</b></p>
                        <p>Definir el inicio y el final de un proceso para eliminar confusiones. (proceso as-is)</p>
                        <ul>
                            <li>Si la empresa ha participado en iniciativas de BPM en el pasado (partes) del proceso ya estarán definidas y documentadas.</li>
                            <li>Si la empresa no se ha involucrado en iniciativas de BPM, necesita identificar los procesos que son relevantes para el problema/asunto y definir qué relaciones tienen.</li>
                            <ul>
                                <li>Medir el valor es un paso crucial en BPM, porque de lo contrario no se puede ver la mejora que ha hecho el proceso.</li>
                            </ul>
                            <li>Un proceso se puede medir a través de:</li>
                            <ul>
                                <li>Métricas con los costos, costos decrecientes manteniendo la misma/mejor calidad.</li>
                                <li>Métricas con el tiempo, mejorando el tiempo de ciclo (tiempo para hacer un proceso), manteniendo la misma/mejor calidad.</li>
                                <li>Métricas con la calidad, reducción de las tasas de error (veces que los procesos terminan en un resultado negativo)</li>
                            </ul>
                        </ul>
                        <h3>Paso 02: Descubrimiento del proceso</h3>
                        <p><b>¿Cuál es el proceso en detalle?</b></p>
                        <p>Un resultado de esta fase es crear un modelo de proceso as-is. Lo cual refleja la comprensión que tienen las personas de la organización sobre cómo se realiza el trabajo.</p>
                        <p>Las descripciones textuales son difíciles de leer y fáciles de malinterpretar. Los diagramas hacen que sea mucho más fácil de entender para todos los involucrados. También limitan los malentendidos.</p>
                        <p>En un diagrama se puede encontrar lo siguiente:</p>
                        <ul>
                            <li>Carriles de natación: un diagrama de flujo se divide en varios carriles horizontales. Cada uno de ellos representa una unidad organizativa/persona.</li>
                            <li>Nodos de actividad: unidades de trabajo que representan una aplicación humana o de software que realiza una tarea específica.</li>
                            <li>Nodos de control (gateways): Captura el flujo de ejecución entre actividades. (decisión/elección/verificación)</li>
                            <li>Nodos de evento: requiere una acción de un actor para continuar con el proceso.</li>
                        </ul>
                        <h3>Paso 03: Análisis del proceso</h3>
                        <p><b>¿Cuáles son los problemas y cómo podemos resolverlos?</b></p>
                        <p>Odentificar y analizar problemas.</p>
                        <p>Se deben considerar varios problemas porque un cambio en el proceso puede causar otros problemas en el futuro.</p>
                        <p>Cambiar un proceso no es tan fácil como parece, porque la gente está acostumbrada a trabajar de cierta manera.</p>
                        <p>A veces, para cambiar un proceso en su propia organización, se requiere un cambio en otra organización. (por ejemplo, con el suministro de más datos para hacer una mejor elección).</p>
                        <h3>Paso 04: Rediseño del proceso</h3>
                        <p><b>¿Cuál es el proceso mejorado?</b></p>
                        <p>Se propone un nuevo diagrama de proceso basado en problemas y soluciones analizadas para mostrar a la organización. (proceso to-be)</p>
                        <p>En el nuevo diagrama se resuelven los problemas.</p>
                        <p>Podría haber múltiples conceptos de procesos nuevos. Cada uno varía en la cantidad de trabajo requerido para cambiar, el tiempo que lleva implementar los cambios o lo que cuesta realizar los cambios.</p>
                        <h3>Paso 05: Implementación del proceso</h3>
                        <p><b>¿Qué debe cambiarse?</b></p>
                        <p>Por ejemplo, los sistemas de TI deben cambiar y las personas deben recibir capacitación para utilizar el nuevo sistema de la mejor manera posible.</p>
                        <p>Facetas complementarias del cambio:</p>
                        <ul>
                            <li>Gestión del cambio organizacional</li>
                            <ul>
                                <li>Explicar los cambios en el proceso a los participantes.</li>
                                <li>Explicar qué cambios se están realizando a los participantes.</li>
                                <li>Explicar por qué los cambios son beneficiosos para la empresa, para los participantes.</li>
                                <li>Establecer un plan de gestión del cambio en el que usted:</li>
                                <ul>
                                    <li>Describir cuándo entrarán en vigor los cambios.</li>
                                    <li>Describir qué arreglos de transición se emplearán para abordar los problemas durante la transición al proceso futuro.</li>
                                </ul>
                            </ul>
                            <li>Automatización de procesos</li>
                            <ul>
                                <li>Configuración de un sistema de TI existente O implementación de un nuevo sistema.</li>
                                <li>El sistema debe apoyar a los participantes del proceso en el desempeño de las tareas del proceso.</li>
                                <li>Incluye:</li>
                                <ul>
                                    <li>Asignación de tareas a los participantes del proceso.</li>
                                    <li>Ayudar a los participantes del proceso a priorizar su trabajo.</li>
                                    <li>Proporcionar a los participantes del proceso la información que necesitan para realizar una tarea.</li>
                                    <li>Realizar verificaciones cruzadas automatizadas y otras tareas automatizadas cuando sea posible.</li>
                                </ul>
                            </ul>
                        </ul>
                        <h3>Paso 06: Seguimiento y control del proceso</h3>
                        <p><b>¿Es necesario realizar algunos ajustes en el proceso?</b></p>
                        <p>Los procesos necesitan ser monitoreados y analizados para identificar más ajustes/mejoras al proceso para controlar mejor la ejecución del proceso.</p>
                        <p>BPM debe verse como un círculo porque los resultados del monitoreo del proceso exigen más cambios. También porque el panorama de las necesidades de los clientes, la tecnología y la competencia está en constante cambio.</p>
                        <h2>Stakeholders en el ciclo de vida de BPM</h2>
                        <h3>Equipo directivo:</h3>
                        <ul>
                            <li>CEO (Chief Executive Officer), responsable del desempeño general del negocio.</li>
                            <li>COO (Chief Operations Officer), responsable de la forma en que se configuran las operaciones.</li>
                            <li>CPO (Chief Process Officer), responsable del desempeño de los procesos. (de lo contrario, responsabilidad del COO)</li>
                            <li>CIO (Chief Information Officer), responsable de la operación eficiente y eficaz de la infraestructura del sistema de información.</li>
                            <li>CFO (Chief Financial Officer), responsable del desempeño financiero general de la empresa.</li>
                            <li>HRD (Director de Recursos Humanos), responsable cuando se trata de un gran número de participantes del proceso.</li>
                        </ul>
                        <h3>Propietarios del proceso:</h3>
                        <ul>
                            <li>Responsable de operaciones eficientes y efectivas de un proceso dado.</li>
                            <li>Planificación, organización, control, seguimiento, obtención de recursos, orientación (para resolver errores)</li>
                            <li>Una persona podría ser responsable de múltiples procesos.</li>
                        </ul>
                        <h3>Participantes del proceso</h3>
                        <ul>
                            <li>Actores humanos que realizan las actividades de un proceso de negocio en el día a día.</li>
                            <li>Realización del trabajo rutinario de acuerdo con las normas y directrices.</li>
                            <li>Apoyar las actividades de rediseño y los esfuerzos de implementación.</li>
                        </ul>
                        <h3>Analistas de procesos:</h3>
                        <ul>
                            <li>Llevar a cabo actividades de identificación, descubrimiento, análisis y rediseño de procesos.</li>
                            <li>Coordinar la implementación de procesos, el seguimiento de procesos y el control de procesos.</li>
                            <li>Informe a la gerencia.</li>
                            <li>Interactuar de cerca con los participantes del proceso.</li>
                            <li>Tener experiencia en negocios o TI según la faceta de cambio en la que se centren.</li>
                        </ul>
                        <h3>Ingenieros de sistemas:</h3>
                        <ul>
                            <li>Involucrado en el rediseño e implementación de procesos.</li>
                            <li>Interactuar con los analistas de procesos para capturar los requisitos del sistema.</li>
                            <li>Puede ser un contratista externo.</li>
                        </ul>
                        <h3>Grupo BPM</h3>
                        <ul>
                            <li>No todas las organizaciones tienen un grupo BPM dedicado.</li>
                            <li>Preservación de la documentación del proceso.</li>
                            <li>Conocimiento de cómo planificar y ejecutar un proyecto BPM.</li>
                            <li>Responsable de mantener la arquitectura de procesos, priorizar proyectos de rediseño y dar soporte a otros stakeholders de un proyecto BPM.</li>
                        </ul>
                    </div>
                    <Signature/>
                </div>
            </div>
        </>
    )
}