import {Link} from "react-router-dom"
import { Helmet } from "react-helmet";
import SyntaxHighlighter from 'react-syntax-highlighter';
import { paraisoLight } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import Signature from "../../signature/Signature";
export default function DominaJSON() {
    const code01 = `{
    "nombre": "The Blink Panda",
    "edad": 30,
    "correo": "tbp@theblinkpanda.com",
    "hobbies": ["programación", "senderismo", "lectura"],
    "direccion": {
        "calle": "Calle Principal",
        "ciudad": "Ciudad Ejemplo",
        "pais": "País Ejemplo"
    }
}`;
    return (
        <>
            <Helmet>
                <title>TBP | Domina JSON</title>
            </Helmet>
            <div className="container-3">
                <div className="main-container">
                    <div className="box-title">
                        <h1>Domina JSON</h1>
                    </div>
                    <div className="topic-links">
                        <div className="card-topic-link">
                            <Link to="/topics/classnotes">
                                <h4><i className="fa-sharp fa-solid fa-circle"></i>&nbsp;&nbsp;&nbsp;Classnotes</h4>
                            </Link>
                        </div>
                        <div className="card-topic-link">
                            <Link to="">
                            <h4><i className="fa-sharp fa-solid fa-circle"></i>&nbsp;&nbsp;&nbsp;Aug 13, 2023</h4>
                            </Link>
                        </div>
                    </div>
                    <div className="sub-container">
                        <h2>Introducción</h2>
                        <p>En el mundo de la programación y el intercambio de datos, JSON ha emergido como un formato esencial para estructurar y transmitir información de manera eficiente. Si estás interesado en aprender cómo funciona JSON y por qué es tan importante, estás en el lugar correcto. En este post, te guiaré a través de los fundamentos de JSON, su lenguaje de uso, cómo crearlo y cómo aprovecharlo para tus proyectos de desarrollo.</p>
                        <h2>¿Por qué aprender JSON?</h2>
                        <p>JSON (JavaScript Object Notation) se ha convertido en un estándar ampliamente utilizado para el intercambio de datos en aplicaciones web y móviles. Al aprender JSON, adquieres la habilidad de estructurar y representar datos de manera clara y sencilla, lo que es fundamental para la comunicación entre sistemas y el desarrollo de aplicaciones modernas.</p>
                        <h2>¿Qué lenguaje usa JSON?</h2>
                        <p>Aunque su nombre incluye "JavaScript", JSON es un formato de datos independiente de cualquier lenguaje de programación en particular. Si bien JSON tiene una sintaxis similar a la de los objetos y arrays en JavaScript, se ha vuelto popular en muchos otros lenguajes debido a su simplicidad y facilidad de uso.</p>
                        <h2>¿Qué es JSON y cómo se usa?</h2>
                        <p>JSON es un formato de datos que se utiliza para representar información estructurada en forma de objetos y arrays. Se compone de pares clave-valor, donde las claves son cadenas y los valores pueden ser cadenas, números, booleanos, objetos o arrays. JSON es legible tanto por humanos como por máquinas, lo que lo convierte en una elección ideal para el intercambio de datos.</p>
                        <h5>Ejemplo</h5>
                        <div className="code-text">
                            <SyntaxHighlighter className="codes" language="json" style={paraisoLight} customStyle={{borderRadius: "1.5em", backgroundColor: "#f4e7e5", border: "2px solid #222222", padding: "2em"}}>
                            {code01}
                            </SyntaxHighlighter>
                        </div>
                        <h2>¿Cómo se hace un JSON?</h2>
                        <p>Crear un JSON es sencillo. Puedes definir objetos y arrays utilizando llaves <b>`{}`</b> y corchetes <b>`[]`</b>, respectivamente. Los pares clave-valor se separan con dos puntos <b>`:`</b> y los elementos dentro de arrays se separan con comas <b>`,`</b>.</p>
                        <hr />
                        <h2>Conclusión</h2>
                        <p>Aprender JSON es esencial para cualquier desarrollador que trabaje en la construcción de aplicaciones web, móviles o cualquier otro sistema que implique el intercambio de datos. Con su sintaxis simple y legible, JSON se ha convertido en el formato preferido para estructurar y transmitir información. Al comprender cómo crear, manipular y utilizar JSON, estarás preparado para diseñar sistemas más eficientes y robustos. Ya sea que estés almacenando configuraciones, transmitiendo datos entre servidores y clientes, o trabajando con APIs, JSON será una herramienta poderosa en tu arsenal de desarrollo.</p>
                    </div>
                    <Signature/>
                </div>
            </div>
        </>
    )
}