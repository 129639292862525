import {Link} from "react-router-dom";
import { Helmet } from 'react-helmet';
import Signature from "../../signature/Signature";
import "../Posts.css";

export default function AgileSoftware() {
    return (
        <>
            <Helmet>
                <title>TBP | Desarrollo ágil de software: Principios, prácticas y beneficios</title>
            </Helmet>
            <div className="container-3">
                <div className="main-container">
                    <div className="box-title">
                        <h1>Desarrollo ágil de software: Principios, prácticas y beneficios</h1>
                    </div>
                    <div className="topic-links">
                        <div className="card-topic-link">
                            <Link to="/topics/computer-science">
                                <h4><i className="fa-sharp fa-solid fa-circle"></i>&nbsp;&nbsp;&nbsp;Computer Science</h4>
                            </Link>
                        </div>
                        <div className="card-topic-link">
                            <Link to="">
                            <h4><i className="fa-sharp fa-solid fa-circle"></i>&nbsp;&nbsp;&nbsp;May 11, 2023</h4>
                            </Link>
                        </div>
                    </div>
                    <div className="sub-container">
                        <p>El desarrollo ágil de software es una metodología utilizada en el desarrollo de software que hace hincapié en el valor de la flexibilidad, la colaboración y el progreso iterativo. Se trata de un enfoque iterativo del desarrollo de software, en el que los requisitos y las soluciones evolucionan a través del esfuerzo colaborativo de equipos autoorganizados y multifuncionales. En esta entrada de blog, exploraremos los principios, prácticas y beneficios del desarrollo ágil de software.</p>
                        <h2>Principios del desarrollo ágil de software:</h2>
                        <ol>
                            <li>Personas e interacciones por encima de procesos y herramientas</li>
                            <li>Software de trabajo frente a documentación exhaustiva</li>
                            <li>Colaboración con el cliente frente a negociación de contratos</li>
                            <li>Responder al cambio antes que seguir un plan</li>
                        </ol>
                        <h2>Manifiesto Ágil</h2>
                        <p>Los principios del desarrollo ágil de software se formalizaron en el Manifiesto Ágil en 2001. El Manifiesto Ágil consta de cuatro valores:</p>
                        <ol>
                            <li>Personas e interacciones por encima de procesos y herramientas</li>
                            <li>Software funcional frente a documentación exhaustiva</li>
                            <li>Colaboración con el cliente frente a negociación de contratos</li>
                            <li>Responder a los cambios antes que seguir un plan</li>
                        </ol>
                        <h2>Prácticas ágiles</h2>
                        <p>Existen varias prácticas utilizadas en el desarrollo ágil de software, entre las que se incluyen:</p>
                        <ol>
                            <li><b>Historias de usuario:</b> El desarrollo ágil se centra en desarrollar software basado en historias de usuario, que son breves descripciones de una característica contadas desde la perspectiva de un usuario final.</li>
                            <li><b>Desarrollo iterativo:</b> El desarrollo ágil es iterativo, lo que significa que el software se desarrolla en iteraciones cortas, normalmente de dos a cuatro semanas de duración.</li>
                            <li><b>Integración continua:</b> La integración continua es la práctica de fusionar los cambios de código en un repositorio compartido varias veces al día. Esto garantiza que el código esté siempre actualizado y que cualquier problema se identifique y resuelva rápidamente.</li>
                            <li><b>Desarrollo basado en pruebas:</b> El desarrollo basado en pruebas consiste en escribir pruebas antes de escribir el código. Así se garantiza que el código cumpla los requisitos y que cualquier error se detecte y resuelva en las primeras fases del proceso de desarrollo.</li>
                            <li><b>Programación en parejas:</b> La programación en parejas consiste en que dos programadores trabajen juntos en el mismo código. Esto ayuda a mejorar la calidad del código y garantiza que ambos programadores tengan un conocimiento profundo del mismo.</li>
                        </ol>
                        <h2>Beneficios del desarrollo ágil de software</h2>
                        <ol>
                            <li><b>Mejora de la colaboración:</b> El desarrollo ágil de software hace hincapié en la colaboración entre los miembros del equipo, lo que conduce a una mejor comunicación y una dinámica de equipo más fuerte.</li>
                            <li><b>Mayor flexibilidad:</b> El desarrollo ágil de software es flexible, lo que significa que se pueden realizar cambios en el proyecto según sea necesario. Esto permite al equipo responder a requisitos cambiantes o a nueva información.</li>
                            <li><b>Tiempo de comercialización más rápido:</b> El desarrollo de software ágil es iterativo, lo que significa que el software se desarrolla en iteraciones cortas. Esto permite al equipo entregar el software más rápidamente y comercializarlo antes.</li>
                            <li><b>Mejora de la calidad:</b> El desarrollo ágil de software hace hincapié en las pruebas y la integración continua, lo que ayuda a mejorar la calidad del código y reducir el número de errores.</li>
                            <li><b>Satisfacción del cliente:</b> El desarrollo de software ágil hace hincapié en la colaboración con el cliente, lo que garantiza que el software satisface sus necesidades y conduce a una mayor satisfacción del cliente.</li>
                        </ol>
                        <hr />
                        <p>En conclusión, el desarrollo ágil de software es una metodología que hace hincapié en la flexibilidad, la colaboración y el progreso iterativo. Los principios, prácticas y beneficios del desarrollo ágil de software lo convierten en una opción popular para los proyectos de desarrollo de software. Mediante el desarrollo ágil de software, los equipos pueden entregar software más rápidamente, mejorar la calidad y aumentar la satisfacción del cliente.</p>
                    </div>
                    <Signature/>
                </div>
            </div>
        </>
    )
}