import {Link} from "react-router-dom";
import { Helmet } from 'react-helmet';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { paraisoLight } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import Signature from "../../signature/Signature";
import "../Posts.css";

export default function TypeScriptP() {
    const code01 = `let number = 9;
number = "Hola"; // No produce un error en tiempo de compilación`;
    const code02 = `let number: number = 9;
number = "Hola"; // Produce un error en tiempo de compilación`;
    const code03 = `// Definición de tipo para un objeto persona
interface Person {
    firstName: string;
    lastName: string;
    age: number;
    }
    
// Uso del tipo definido
const person: Person = {
    firstName: "Blink",
    lastName: "Panda",
    age: 26
    };`;
    return (
        <>
            <Helmet>
                <title>TBP | Typescript: significado y utilidad</title>
            </Helmet>
            <div className="container-3">
                <div className="main-container">
                    <div className="box-title">
                        <h1>Typescript: significado y utilidad</h1>
                    </div>
                    <div className="topic-links">
                        <div className="card-topic-link">
                            <Link to="/topics/javascript">
                                <h4><i className="fa-sharp fa-solid fa-circle"></i>&nbsp;&nbsp;&nbsp;JavaScript</h4>
                            </Link>
                        </div>
                        <div className="card-topic-link">
                            <Link to="">
                            <h4><i className="fa-sharp fa-solid fa-circle"></i>&nbsp;&nbsp;&nbsp;Aug 13, 2023</h4>
                            </Link>
                        </div>
                    </div>
                    <div className="sub-container">
                        <h2>Introducción</h2>
                        <p>En el mundo del desarrollo web y de aplicaciones, TypeScript ha ganado popularidad como un superset de JavaScript que ofrece beneficios adicionales. Si deseas conocer más acerca de qué es TypeScript y cómo puede mejorar tus proyectos de desarrollo, estás en el lugar indicado. En este post, te llevaré a través de los fundamentos de TypeScript, sus diferencias con JavaScript, cuándo y por qué usarlo, y ejemplos para ilustrar sus conceptos clave.</p>
                        <h2>¿Qué es y para qué sirve TypeScript?</h2>
                        <p>TypeScript es un lenguaje de programación de código abierto desarrollado por Microsoft que se basa en JavaScript. Aporta tipos estáticos opcionales, interfaces, clases y otros conceptos de programación orientada a objetos a la experiencia de desarrollo de JavaScript. TypeScript se compila a código JavaScript y se puede utilizar en el lado del cliente y del servidor.</p>
                        <p>La principal ventaja de TypeScript es que permite detectar errores en tiempo de compilación en lugar de en tiempo de ejecución, lo que ayuda a evitar problemas y aumentar la calidad del código.</p>
                        <h2>¿Cuál es la diferencia entre TypeScript y JavaScript?</h2>
                        <p>La principal diferencia entre TypeScript y JavaScript radica en la inclusión de tipos estáticos en TypeScript. Mientras que JavaScript es un lenguaje de tipado dinámico, lo que significa que los tipos se determinan en tiempo de ejecución, TypeScript permite declarar y verificar tipos en tiempo de compilación.</p>
                        <h5>JavaScript:</h5>
                        <div className="code-text">
                            <SyntaxHighlighter className="codes" language="jsx" style={paraisoLight} customStyle={{borderRadius: "1.5em", backgroundColor: "#f4e7e5", border: "2px solid #222222", padding: "2em"}}>
                            {code01}
                            </SyntaxHighlighter>
                        </div>
                        <h5>TypeScript:</h5>
                        <div className="code-text">
                            <SyntaxHighlighter className="codes" language="tsx" style={paraisoLight} customStyle={{borderRadius: "1.5em", backgroundColor: "#f4e7e5", border: "2px solid #222222", padding: "2em"}}>
                            {code02}
                            </SyntaxHighlighter>
                        </div>
                        <h2>¿Cuándo se usa TypeScript?</h2>
                        <p>Se recomienda usar TypeScript en situaciones donde la robustez y la detección temprana de errores son esenciales. Algunas circunstancias en las que es beneficioso utilizar TypeScript son:</p>
                        <ol>
                            <li><b>Proyectos Grandes: </b>En proyectos grandes y complejos, TypeScript puede ayudar a prevenir errores y facilitar el mantenimiento.</li>
                            <li><b>Equipo de Desarrollo: </b>Cuando trabajas en un equipo de desarrollo, TypeScript puede mejorar la colaboración al proporcionar una definición clara de los tipos y estructuras de datos.</li>
                            <li><b>Aplicaciones de Misión Crítica: </b>Para aplicaciones en las que la integridad de los datos y la seguridad son primordiales, TypeScript puede ser una elección sólida.</li>
                        </ol>
                        <h2>¿Quién usa TypeScript?</h2>
                        <p>TypeScript es utilizado por numerosas empresas y proyectos, incluyendo Microsoft, Google, Airbnb, Slack y Angular (un popular framework de desarrollo web). Su adopción continua refleja su utilidad y los beneficios que aporta al desarrollo de software en diversos entornos.</p>
                        <h5>Ejemplo: Definiendo tipos en TypeScript</h5>
                        <p>Aquí tienes un ejemplo de cómo definir tipos en TypeScript:</p>
                        <div className="code-text">
                            <SyntaxHighlighter className="codes" language="tsx" style={paraisoLight} customStyle={{borderRadius: "1.5em", backgroundColor: "#f4e7e5", border: "2px solid #222222", padding: "2em"}}>
                            {code03}
                            </SyntaxHighlighter>
                        </div>
                        <hr />
                        <h2>Conclusión</h2>
                        <p>TypeScript ofrece una experiencia de desarrollo más sólida y segura al incorporar tipos estáticos en el mundo de JavaScript. Su capacidad para detectar errores en tiempo de compilación, su facilidad de uso y su adopción generalizada por parte de empresas y proyectos líderes en la industria lo convierten en una herramienta valiosa para los desarrolladores modernos. Si buscas mejorar la calidad y la confiabilidad de tu código, considera explorar TypeScript y descubre cómo puede llevar tus proyectos al siguiente nivel.</p>
                    </div>
                    <Signature/>
                </div>
            </div>
        </>
    )
}