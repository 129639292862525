import {Link} from "react-router-dom"
import { Helmet } from "react-helmet";
import SyntaxHighlighter from 'react-syntax-highlighter';
import { paraisoLight } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import Signature from "../../signature/Signature";
export default function AppReactNative() {
    const code01 = `npm install -g react-native-cli`;
    const code02 = `react-native init MyApp`;
    const code03 = `cd MyApp \nnpm start`;
    const code04 = `cd MyApp \nreact-native run-ios`;
    const code05 = `cd MyApp \nreact-native run-android`;

    return (
        <>
            <Helmet>
                <title>TBP | ¿Cómo crear una app React Native en 5 pasos?</title>
            </Helmet>
            <div className="container-3">
                <div className="main-container">
                    <div className="box-title">
                        <h1>¿Cómo crear una app React Native en 5 pasos?</h1>
                    </div>
                    <div className="topic-links">
                        <div className="card-topic-link">
                            <Link to="/topics/classnotes">
                                <h4><i className="fa-sharp fa-solid fa-circle"></i>&nbsp;&nbsp;&nbsp;Classnotes</h4>
                            </Link>
                        </div>
                        <div className="card-topic-link">
                            <Link to="">
                            <h4><i className="fa-sharp fa-solid fa-circle"></i>&nbsp;&nbsp;&nbsp;Apr 15, 2023</h4>
                            </Link>
                        </div>
                    </div>
                    <div className="sub-container">
                        <p>React Native es un potente framework que permite a los desarrolladores crear aplicaciones móviles nativas para iOS y Android utilizando el mismo código base. Si estás interesado en crear una aplicación React Native, puede que te preguntes por dónde empezar. En este post, te guiaremos a través de los pasos para crear una aplicación React Native en 5 sencillos pasos.</p>
                        <h2>Paso 1: Instalar React Native</h2>
                        <p>Antes de empezar a crear una aplicación React Native, tienes que asegurarte de que tienes todo el software necesario instalado en tu computador. Para instalar React Native, necesitarás tener instalado Node.js y NPM (Node Package Manager). Puedes descargarlos desde los sitios web oficiales.</p>
                        <p>Una vez que tengas Node.js y NPM instalados, puedes instalar React Native usando NPM ejecutando el siguiente comando en tu terminal:</p>
                        <div className="code-text">
                            <SyntaxHighlighter className="codes" language="script" style={paraisoLight} customStyle={{borderRadius: "1.5em", backgroundColor: "#f4e7e5", border: "2px solid #222222", padding: "2em"}}>
                            {code01}
                            </SyntaxHighlighter>
                        </div>
                        <p>Esto instalará la interfaz de línea de comandos de React Native globalmente en tu computador.</p>
                        <h2>Paso 2: Crear un nuevo proyecto React Native</h2>
                        <p>Ahora que tienes React Native instalado, es hora de crear un nuevo proyecto. Para crear un nuevo proyecto React Native, abre tu terminal y navega hasta el directorio donde quieres crear el proyecto. A continuación, ejecuta el siguiente comando:</p>
                        <div className="code-text">
                            <SyntaxHighlighter className="codes" language="script" style={paraisoLight} customStyle={{borderRadius: "1.5em", backgroundColor: "#f4e7e5", border: "2px solid #222222", padding: "2em"}}>
                            {code02}
                            </SyntaxHighlighter>
                        </div>
                        <p>Esto creará un nuevo proyecto React Native llamado "MyApp" en el directorio actual. Puede tardar unos minutos en descargar todas las dependencias necesarias.</p>
                        <h2>Paso 3: Ejecutar la aplicación en el teléfono</h2>
                        <p>Para ejecutar la aplicación en su teléfono, tendrá que instalar la aplicación Expo en su dispositivo iOS o Android. Una vez que tengas la aplicación Expo instalada, puedes ejecutar el siguiente comando en tu terminal para iniciar la aplicación:</p>
                        <div className="code-text">
                            <SyntaxHighlighter className="codes" language="script" style={paraisoLight} customStyle={{borderRadius: "1.5em", backgroundColor: "#f4e7e5", border: "2px solid #222222", padding: "2em"}}>
                            {code03}
                            </SyntaxHighlighter>
                        </div>
                        <p>Esto iniciará el servidor de desarrollo y abrirá una nueva ventana en su navegador con un código QR. Escanee el código QR con la aplicación Expo de su teléfono para abrir la aplicación.</p>
                        <h2>aso 4: Editar la aplicación</h2>
                        <p>Ahora que ya tienes la aplicación funcionando, es hora de editarla. Abre el archivo "App.js" en tu editor de texto y haz algunos cambios. Por ejemplo, puedes cambiar el texto para que diga "Hello World!" en lugar de "Welcome to React Native!".</p>
                        <p>Guarda el archivo y la aplicación se recargará automáticamente con tus cambios. Puedes hacer tantos cambios como quieras y ver los resultados en tiempo real.</p>
                        <h2>Paso 5: Construir y distribuir la aplicación</h2>
                        <p>Una vez que estés satisfecho con tu aplicación, es hora de crearla y distribuirla. Para crear la aplicación para iOS, necesitarás un Mac y Xcode instalado. Para crear la aplicación para Android, necesitarás tener instalado Android Studio.</p>
                        <p>Para crear la aplicación para iOS, ejecuta el siguiente comando en tu terminal:</p>
                        <div className="code-text">
                            <SyntaxHighlighter className="codes" language="script" style={paraisoLight} customStyle={{borderRadius: "1.5em", backgroundColor: "#f4e7e5", border: "2px solid #222222", padding: "2em"}}>
                            {code04}
                            </SyntaxHighlighter>
                        </div>
                        <p>Esto creará la aplicación y la abrirá en el simulador de iOS.</p>
                        <p>Para crear la aplicación para Android, ejecuta el siguiente comando en tu terminal:</p>
                        <div className="code-text">
                            <SyntaxHighlighter className="codes" language="script" style={paraisoLight} customStyle={{borderRadius: "1.5em", backgroundColor: "#f4e7e5", border: "2px solid #222222", padding: "2em"}}>
                            {code05}
                            </SyntaxHighlighter>
                        </div>
                        <p>Esto creará la aplicación y la instalará en tu dispositivo Android o emulador.</p>
                        <p>Felicidades! Acabas de crear una aplicación React Native en 5 sencillos pasos. Por supuesto, esto es sólo el principio. Hay mucho más que aprender sobre React Native, pero esto debería darte una buena base sobre la que construir. Happy coding!</p>
                    </div>
                    <Signature/>
                </div>
            </div>
        </>
    )
}