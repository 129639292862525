import {Link} from "react-router-dom";
import { Helmet } from 'react-helmet';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { paraisoLight } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import Signature from "../../signature/Signature";
import "../Posts.css";

export default function ReturnNull() {
    const code01 = `function MyComponent({ condition }) {
    return condition ? 
    <div>Hello, World!</div> : <div>Not rendered.</div>;}`;
    const code02 = `function MyComponent({ condition }) {
    return condition && <div>Hello, World!</div>;}`;
    const code03 = `function MyComponent({ condition }) {
    return (
        <div>
            {condition ? <ChildComponent /> : <AlternateComponent />}
        </div>
    );
}
`;
    return (
        <>
            <Helmet>
                <title>TBP | Deja de Usar "return null" en React: Un mejor enfoque para el renderizado condicional</title>
            </Helmet>
            <div className="container-3">
                <div className="main-container">
                    <div className="box-title">
                        <h1>Deja de Usar "return null" en React: Un mejor enfoque para el renderizado condicional</h1>
                    </div>
                    <div className="topic-links">
                        <div className="card-topic-link">
                            <Link to="/topics/javascript">
                                <h4><i className="fa-sharp fa-solid fa-circle"></i>&nbsp;&nbsp;&nbsp;JavaScript</h4>
                            </Link>
                        </div>
                        <div className="card-topic-link">
                            <Link to="">
                            <h4><i className="fa-sharp fa-solid fa-circle"></i>&nbsp;&nbsp;&nbsp;May 22, 2023</h4>
                            </Link>
                        </div>
                    </div>
                    <div className="sub-container">
                        <p>El renderizado condicional es un aspecto crucial en la construcción de interfaces de usuario dinámicas en React. Sin embargo, el uso del patrón "return null" para el renderizado condicional se ha convertido en una práctica común en muchas aplicaciones React. En estpost discutiremos por qué usar "return null" puede no ser el mejor enfoque y exploraremos métodos alternativos para el renderizado condicional que promueven un código más limpio y un mejor diseño de componentes. También veremos ejemplos para ayudarnos a entender los inconvenientes de "return null" y adoptar alternativas más eficaces.</p>
                        <h2>Los inconvenientes de "return null":</h2>
                        <ol>
                            <li><b>Efectos secundarios no intencionados: </b>Devolver `null` desde un componente no impide que el componente sea renderizado. Sólo significa que el componente no renderiza nada. Esto puede llevar a efectos secundarios no intencionados si el componente contiene lógica o código de efectos secundarios que no debería ejecutarse cuando el componente no se renderiza.</li>
                            <li><b>Jerarquía de componentes inconsistentes: </b>El uso de "return null" puede dar lugar a incoherencias en la jerarquía de componentes. Cuando la renderización condicional está dispersa por todo el componente, se hace difícil mantener y razonar sobre la estructura del componente. Esto puede dificultar la legibilidad y el mantenimiento del código.</li>
                            <li><b>Desafios de depuración: </b>Cuando los componentes devuelven `null` condicionalmente, puede hacer que la depuración sea más difícil. Se hace más difícil rastrear qué componente es responsable de no renderizar y por qué.</li>
                        </ol>
                        <h2>Un enfoque mejor: Técnicas de renderizado condicional</h2>
                        <h3>Operador ternario:</h3>
                        <p>En lugar de utilizar "return null", puede aprovechar el operador ternario para renderizar condicionalmente elementos JSX. Este enfoque proporciona más claridad y control sobre la lógica de renderizado. Considere el siguiente ejemplo:</p>
                        <div className="code-text">
                            <SyntaxHighlighter className="codes" language="jsx" style={paraisoLight} customStyle={{borderRadius: "1.5em", backgroundColor: "#f4e7e5", border: "2px solid #222222", padding: "2em"}}>
                            {code01}
                            </SyntaxHighlighter>
                        </div>
                        <p>En este ejemplo, el elemento JSX se muestra condicionalmente en función de la propiedad `condition`. Si la condición es verdadera, el componente muestra el mensaje "Hello, World!" En caso contrario, muestra el mensaje "Not rendered".</p>
                        <h3>Operador lógico &&:</h3>
                        <p>El operador lógico AND (`&&`) puede utilizarse para escenarios de renderizado condicional más sencillos. Si la condición se evalúa como `false`, el componente no se renderizará. He aquí un ejemplo:</p>
                        <div className="code-text">
                            <SyntaxHighlighter className="codes" language="jsx" style={paraisoLight} customStyle={{borderRadius: "1.5em", backgroundColor: "#f4e7e5", border: "2px solid #222222", padding: "2em"}}>
                            {code02}
                            </SyntaxHighlighter>
                        </div>
                        <p>En este caso, si la proposición <b>condition</b> es <b>true</b>, el componente muestra el mensaje "Hello, World!". En caso contrario, no muestra nada.</p>
                        <h3>Composición de componentes:</h3>
                        <p>Si el renderizado condicional se vuelve más complejo, considere dividir la lógica en componentes separados. Este enfoque mejora la modularidad del código y facilita la comprensión y gestión de las condiciones de renderizado. He aquí un ejemplo:</p>
                        <div className="code-text">
                            <SyntaxHighlighter className="codes" language="jsx" style={paraisoLight} customStyle={{borderRadius: "1.5em", backgroundColor: "#f4e7e5", border: "2px solid #222222", padding: "2em"}}>
                            {code03}
                            </SyntaxHighlighter>
                        </div>
                        <p>En este ejemplo, <b>MyComponent</b> muestra el <b>ChildComponent</b> o el <b>AlternateComponent</b> en función de la propiedad <b>condition</b>.</p>
                        <hr />
                        <h2>Conclusión</h2>
                        <p>Aunque el uso de "return null" para la representación condicional puede parecer conveniente, tiene inconvenientes que pueden afectar a la claridad y la facilidad de mantenimiento del código. Adoptando técnicas alternativas como el operador ternario, el operador lógico AND o la composición de componentes, puede mejorar la legibilidad de su código y reducir los efectos secundarios no deseados. Adopte estos enfoques alternativos y haga que su código sea más limpio y robusto. Recuerda, un renderizado condicional limpio y explícito conduce a un mejor diseño de componentes y a una aplicación React más fácil de mantener.</p>
                    </div>
                    <Signature/>
                </div>
            </div>
        </>
    )
}