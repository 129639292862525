import { Helmet } from "react-helmet";

export default function Portfolio() {
    return (
        <div>
            <Helmet>
                <title>TBP | Portfolio</title>
            </Helmet>
            <div className="container-3">
                <div className="main-container">
                    <div className="box-title">
                        <h1>Portfolio</h1>
                    </div>
                </div>
            </div>
        </div>
    )
}