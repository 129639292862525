import {Link} from "react-router-dom";
import { Helmet } from 'react-helmet';
import Signature from "../../signature/Signature";
import "../Posts.css"

export default function Listas() {
    return (
        <>
            <Helmet>
                <title>TBP | Listas</title>
            </Helmet>
            <div className="container-3">
                <div className="main-container">
                    <div className="box-title">
                        <h1>Listas</h1>
                    </div>
                    <div className="topic-links">
                        <div className="card-topic-link">
                            <Link to="/topics/python">
                                <h4><i className="fa-sharp fa-solid fa-circle"></i>&nbsp;&nbsp;&nbsp;Python</h4>
                            </Link>
                        </div>
                        <div className="card-topic-link">
                            <Link to="">
                            <h4><i className="fa-sharp fa-solid fa-circle"></i>&nbsp;&nbsp;&nbsp;Apr 11, 2022</h4>
                            </Link>
                        </div>
                    </div>
                    <div className="sub-container">
                        <p>Una lista almacena una serie de items en un orden determinado. Las listas permiten almacenar conjuntos de información en un solo lugar, tanto si se trata de unos pocos items como de millones de ellos.</p>
                        <p>Se utiliza corchetes para definir una lista, y comas para separar los items individuales de la lista.</p>
                        <p>Hacer una lista</p>
                        <ul>
                            <li>abc = ['Val', 'Cal', 'Bal']</li>
                        </ul>
                        <h2>Acceso a los elementos</h2>
                        <p>Se accede a los items según su posición llamada índice. El índice del primer item es 0.</p>
                        <ul>
                            <li>Obtener el primer elemento:</li>
                            <ul>
                                <li>first = abc[0]</li>
                            </ul>
                            <li>Obtener el segundo elemento:</li>
                            <ul>
                                <li>first = abc[1]</li>
                            </ul>
                            <li>Obtener el último elemento:</li>
                            <ul>
                                <li>first = abc[-1]</li>
                            </ul>
                        </ul>
                        <h2>Modificación de items individuales</h2>
                        <p>Para ello, se remite al índice de los items que desea modificar.</p>
                        <ul>
                            <li>Cambiando elemento:</li>
                            <ul>
                                <li>users[0] = "panda"</li>
                                <li>users[-2] = "the"</li>
                            </ul>
                        </ul>
                        <h2>Añadiendo elemento</h2>
                        <p>Se puede insertar un elemento donde uno quiera.</p>
                        <ul>
                            <li>Añadir un elemento al final de la lista</li>
                            <ul>
                                <li>abc.append("blink")</li>
                            </ul>
                            <li>Insertar un elemento en una posición determinada</li>
                            <ul>
                                <li>abc.insert(0, 'panda')</li>
                            </ul>
                        </ul>
                        <h2>Eliminación de elementos</h2>
                        <p>Se pueden eliminar elementos por su posición en una Lista o por el valor de los elementos.</p>
                        <ul>
                            <li>Eliminar un elemento por su posición</li>
                            <ul>
                                <li>del abc[-1]</li>
                            </ul>
                            <li>Eliminar un elemento por su valor</li>
                            <ul>
                                <li>abc.remove("the")</li>
                            </ul>
                            <li>Sacar el último elemento de la lista</li>
                            <ul>
                                <li>abc.pop()</li>
                            </ul>
                            <li>Sacar el primer elemento de la lista</li>
                            <ul>
                                <li>abc.pop(0)</li>
                            </ul>
                            <li>Longitud de la lista</li>
                            <ul>
                                <li>len() → la función devuelve el número de elementos de la lista</li>
                            </ul>
                            <li>Encuentra la longitud</li>
                            <ul>
                                <li>lnum = len(abc)</li>
                            </ul>
                        </ul>
                        <h2>Ordenar la lista</h2>
                        <p>El método Sort() cambia el orden de la lista de forma permanente. La función sorted() devuelve una copia de la lista.</p>
                        <ul>
                            <li>Ordenar una lista</li>
                            <ul>
                                <li>abc.sort()</li>
                            </ul>
                            <li>Ordenar una lista en sentido inverso</li>
                            <ul>
                                <li>abc.sort(reverse = True)</li>
                            </ul>
                            <li>Ordenar una lista temporalmente</li>
                            <ul>
                                <li>ordenar(abc)</li>
                            </ul>
                            <li>Invertir un orden</li>
                            <ul>
                                <li>abc.reverse()</li>
                            </ul>
                        </ul>
                        <h2>Tuplas</h2>
                        <p>Una tupla es como una lista, excepto que no puedes cambiar el valor de la tupla, los que se definen</p>
                        <ul>
                            <li>Definir una tupla</li>
                            <ul>
                                <li>abc = (200,300)</li>
                            </ul>
                            <li>Sobreescribiendo una tupla</li>
                            <ul>
                                <li>abc= (200,300)</li>
                            </ul>
                            <li>Slicing</li>
                            <ul>
                                <li>abc = (400,300) &#62;&#62; sobrescribiendo</li>
                            </ul>
                        </ul>
                        <p>Se puede trabajar sobre cualquier parte de la lista llamada slice.</p>
                    </div>
                    <Signature/>
                </div>
            </div>
        </>
    )
}