import {Link} from "react-router-dom"
import { Helmet } from "react-helmet";
import Signature from "../../signature/Signature";
import pic1 from "../../pix/classnotes/estructuras-organizativas/organigrama-bultzaki.png"
import pic2 from "../../pix/classnotes/estructuras-organizativas/departamentalizacion-territorio-1.png"
import pic3 from "../../pix/classnotes/estructuras-organizativas/departamentalizacion-territorio-2.png"
import pic4 from "../../pix/classnotes/estructuras-organizativas/departamentalizacion-clientes.png"
import "../Posts.css"
export default function Estructuras_organizativas() {

    return (
        <>
            <Helmet>
                <title>TBP | Tipos de Estructuras Organizativas</title>
            </Helmet>
            <div className="container-3">
                <div className="main-container">
                    <div className="box-title">
                        <h1>Tipos de Estructuras Organizativas</h1>
                    </div>
                    <div className="topic-links">
                        <div className="card-topic-link">
                            <Link to="/topics/classnotes">
                                <h4><i className="fa-sharp fa-solid fa-circle"></i>&nbsp;&nbsp;&nbsp;Classnotes</h4>
                            </Link>
                        </div>
                        <div className="card-topic-link">
                            <Link to="">
                            <h4><i className="fa-sharp fa-solid fa-circle"></i>&nbsp;&nbsp;&nbsp;May 24, 2020</h4>
                            </Link>
                        </div>
                    </div>
                    <div className="sub-container">
                        <h2>Estructura Lineal</h2>
                        <ul>
                            <li>Usada por pequeñas empresas.</li>
                            <li>Empresas que generan pocos productos en un campo específico del mercado.</li>
                            <li>Empresas donde el dueño y el gerente son el mismo.</li>
                            <li>Es rápida, flexible, de bajo costo y con una contabilidad clara.</li>
                            <li>Cercana relación entre superiores y subordinados.</li>
                            <li>La toma de decisiones es ágil.</li>
                            <li>La toma de decisiones la realiza normalmente una sola persona.</li>
                        </ul>
                        <h2>Estructura Matricial</h2>
                        <ul>
                            <li>Agrupación de los RRHH y materiales asignados de forma temporal a los diferentes proyectos que se realizan.</li>
                            <li>Equipos con integrantes de varias áreas de la organización.</li>
                            <li>Los empleados poseen dos jefes: el de función y el del proyecto.</li>
                            <li>Se requiere:</li>
                            <ul>
                                <li>Capacidad de organización.</li>
                                <li>Contar con buen capital.</li>
                                <li>Equilibrio de poder.</li>
                            </ul>
                        </ul>
                        <h2>Estructura de departamentos</h2>
                        <p>Crea departamentos dentro de una organización.<br/>Se crean los departamentos por funcionalidad, por producto o por territorio geográfico. </p>
                        <h3>Departamentalización Funcional</h3>
                        <p>Separa el trabajo en base a procesos o actividades.</p>
                        <p><b>Ventajas:</b></p>
                        <ul>
                            <li>Identifica y asigna responsabilidades.</li>
                            <li>Agrupa a las personas y unidades en base al trabajo que realizan.</li>
                            <li>Permite la cooperación en trabajos similares.</li>
                            <li>Reduce la duplicación del esfuerzo.</li>
                            <li>Forma flexible de organización.</li>
                            <li>Se entiende fácilmente la organización.</li>
                        </ul>
                        <p><b>Desventajas:</b></p>
                        <ul>
                            <li>Suboptimización organizacional.</li>
                            <li>Separación de personas con funciones diferentes.</li>
                        </ul>
                        <p><i>Organigrama de Bultzaki</i></p>
                        <div className="picinpost">
                            <img className="" src={pic1} alt="" />
                        </div>
                        <h3>Departamentalización por Producto</h3>
                        <ul>
                            <li>Se organiza de acuerdo a lo que se produce.</li>
                            <li>Usada en las grandes empresas.</li>
                            <li>Cada unidad se le llama "divisiones"</li>
                        </ul>
                        <p><b>Ventajas:</b></p>
                        <ul>
                            <li>Centra la atención en el producto.</li>
                            <li>Detecta los problemas de coordinación e integración.</li>
                            <li>Aísla problemas relacionados al producto.</li>
                            <li>Empleo de equipo especializado.</li>
                        </ul>
                        <p><b>Desventajas:</b></p>
                        <ul>
                            <li>El doble flujo de autoridad puede generar conflictos.</li>
                            <li>Puede generar tensión en los miembros al rendir cuentas a 2 jefes.</li>
                            <li>Costos burocráticos altos.</li>
                            <li>A veces puede no estar bien definida la autoridad.</li>
                        </ul>
                        <h3>Departamentalización por territorio</h3>
                        <ul>
                            <li>La empresa se divide en base a su ubicación.</li>
                            <li>Presente en empresas cuyas actividades son principalmente las ventas.</li>
                            <li>La organización en cada área forma sus propios departamentos.</li>
                        </ul>
                        <p><b>Ventajas:</b></p>
                        <ul>
                            <li>Se adapta a las necesidades de la región.</li>
                            <li>Provee mayor control.</li>
                            <li>Toma rápida de decisiones.</li>
                        </ul>
                        <p><b>Desventajas:</b></p>
                        <ul>
                            <li>Dificulta la integración entre las diferentes divisiones geográficas.</li>
                        </ul>
                        <div className="picinpost">
                            <img className="" src={pic2} alt="" />
                        </div>
                        <div className="picinpost">
                            <img className="" src={pic3} alt="" />
                        </div>
                        <h3>Departamentalización por clientes</h3>
                        <ul>
                            <li>La organización se subdivide para atender las necesidades de cada tipo de cliente.</li>
                        </ul>
                        <p><b>Ventajas:</b></p>
                        <ul>
                            <li>Rapidez y eficacia del vendedor.</li>
                            <li>Mayor especialización en el vendedor.</li>
                            <li>Disminuye los costos en comunicaciones.</li>
                        </ul>
                        <p><b>Desventajas:</b></p>
                        <ul>
                            <li>Dificultad de coordinación.</li>
                            <li>Reducción o incremento de ciertos tipos de clientes.</li>
                        </ul>
                        <div className="picinpost">
                            <img className="" src={pic4} alt="" />
                        </div>
                        <h2>Estructura Circular</h2>
                        <ul>
                            <li>Los niveles de autoridad son representados en circulas concéntricos.</li>
                            <li>En el centro va la autoridad máxima.</li>
                            <li>En los circulas van los jefes inmediatos.</li>
                        </ul>
                        <p><b>Ventajas:</b></p>
                        <ul>
                            <li>Señala los niveles jerárquicos.</li>
                            <li>Disminuye la idea de status.</li>
                            <li>Mayor número de puestos en el mismo nivel.</li>
                        </ul>
                        <p><b>Desventajas:</b></p>
                        <ul>
                            <li>Confusas y difíciles de entender.</li>
                            <li>Dificultad en colocar niveles con un solo funcionario.</li>
                            <li>Fuerza demasiado los niveles.</li>
                        </ul>
                        <h2>Estructura Híbrida</h2>
                        <ul>
                            <li>Enfoque múltiple.</li>
                            <li>Usada cuando las empresas crecen y tienen varios productos o mercados.</li>
                        </ul>
                        <p><b>Ventajas:</b></p>
                        <ul>
                            <li>Adaptabilidad y eficacia de las divisiones de productos o mercados.</li>
                            <li>Alineación entre la división de productos y los objetivos corporativos.</li>
                            <li>Las unidades centrales coordinan todas las divisiones.</li>
                        </ul>
                        <p><b>Desventajas:</b></p>
                        <ul>
                            <li>Acumulación de personal corporativo para superar las divisiones.</li>
                            <li>Centralización de decisiones y pérdida de respuesta a los cambios del mercado.</li>
                            <li>Conflictos entre el personal corporativo y el de divisiones.</li>
                        </ul>
                    </div>
                    <Signature/>
                </div>
            </div>
        </>
    )
}