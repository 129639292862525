import {Link} from "react-router-dom";
import { Helmet } from 'react-helmet';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { paraisoLight } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import Signature from "../../signature/Signature";
import "../Posts.css";

export default function Async() {
    const code01 = `fetch('https://api.example.com/data')
    .then(respuesta => respuesta.json())
    .then(datos => {
        // Procesa los datos recuperados
        console.log(datos);
    })
    .catch(error => {
        // Maneja cualquier error que ocurra durante la petición
        console.error(error);
    });`;
    const code02 = `fetch('https://api.example.com/data')
    .then(respuesta => respuesta.json())
    .then(datos => {
        console.log(datos);
    })
    .catch(error => {
        console.error(error);
    });`;
    const code03 = `función asíncrona fetchData() {
        try {
            const response = await fetch('https://api.example.com/data');
            const data = await response.json();
            console.log(datos);
        } catch (error) {
            console.error(error);
        }
    }
      
    fetchData();`;
    return (
        <>
            <Helmet>
                <title>TBP | Desmitificando JavaScript Async: Entendiendo la Programación Asíncrona</title>
            </Helmet>
            <div className="container-3">
                <div className="main-container">
                    <div className="box-title">
                        <h1>Desmitificando JavaScript Async: Entendiendo la Programación Asíncrona</h1>
                    </div>
                    <div className="topic-links">
                        <div className="card-topic-link">
                            <Link to="/topics/javascript">
                                <h4><i className="fa-sharp fa-solid fa-circle"></i>&nbsp;&nbsp;&nbsp;JavaScript</h4>
                            </Link>
                        </div>
                        <div className="card-topic-link">
                            <Link to="">
                            <h4><i className="fa-sharp fa-solid fa-circle"></i>&nbsp;&nbsp;&nbsp;May 17, 2023</h4>
                            </Link>
                        </div>
                    </div>
                    <div className="sub-container">
                        <p>JavaScript es un potente lenguaje de programación que permite a los desarrolladores crear aplicaciones web interactivas y dinámicas. Un aspecto clave de JavaScript es su capacidad para manejar tareas asíncronas de manera eficiente. En este post veremos el concepto de JavaScript Async, nos sumergiremos en la programación asíncrona y comprenderemos cómo permite la ejecución de operaciones sin bloqueo. También  explicaciones detalladas y ejemplos para ayudarte a comprender este concepto fundamental.</p>
                        <h2>Comprensión de la programación asíncrona</h2>
                        <p>En JavaScript, la programación asíncrona permite la ejecución de múltiples tareas simultáneamente, sin bloquear el hilo de ejecución principal. Esto es particularmente útil cuando se trata de operaciones que requieren mucho tiempo, como la obtención de datos de API externas, la lectura de archivos o la realización de solicitudes de red. En lugar de esperar a que se completen estas operaciones para pasar a la siguiente tarea, la programación asíncrona permite que el motor JavaScript gestione estas operaciones en segundo plano, mientras el subproceso principal sigue ejecutando otras tareas.</p>
                        <h2>Devoluciones de llamada y bucle de eventos</h2>
                        <p>La programación asíncrona en JavaScript se consigue normalmente utilizando callbacks y el mecanismo de bucle de eventos. Las funciones de devolución de llamada se pasan como argumentos a las funciones asíncronas y se ejecutan una vez finalizada la operación asíncrona. El bucle de eventos se encarga de gestionar la ejecución de estas retrollamadas y de garantizar que se ejecuten en el orden adecuado.</p>
                        <h2>Ejemplo: Obtención de datos de una API</h2>
                        <p>Veamos un ejemplo en el que obtenemos datos de una API externa utilizando la función `fetch` en JavaScript:</p>
                        <div className="code-text">
                            <SyntaxHighlighter className="codes" language="jsx" style={paraisoLight} customStyle={{borderRadius: "1.5em", backgroundColor: "#f4e7e5", border: "2px solid #222222", padding: "2em"}}>
                            {code01}
                            </SyntaxHighlighter>
                        </div>
                        <p>En este ejemplo, la función <b>fetch</b> inicia una petición de red asíncrona para recuperar datos del endpoint API especificado. El siguiente método <b>.then</b> encadena una función callback que procesa los datos de la respuesta. Si se produce algún error durante la solicitud, el método <b>.catch</b> lo gestiona con elegancia.</p>
                        <h2>Promesas y Async/Await</h2>
                        <p>Aunque las retrollamadas son un método habitual para gestionar operaciones asíncronas, JavaScript introdujo las promesas y la más reciente sintaxis Async/Await para simplificar y mejorar la programación asíncrona.</p>
                        <p>Las promesas proporcionan una forma más estructurada de manejar las operaciones asíncronas, permitiendo una mejor gestión de errores y el encadenamiento de múltiples tareas asíncronas. El siguiente ejemplo muestra el uso de promesas con la API <b>fetch</b>:</p>
                        <div className="code-text">
                            <SyntaxHighlighter className="codes" language="jsx" style={paraisoLight} customStyle={{borderRadius: "1.5em", backgroundColor: "#f4e7e5", border: "2px solid #222222", padding: "2em"}}>
                            {code02}
                            </SyntaxHighlighter>
                        </div>
                        <p>Async/Await, introducido en ECMAScript 2017, proporciona un enfoque aún más legible y síncrono a la programación asíncrona. Permite a los desarrolladores escribir código asíncrono de una manera más lineal y secuencial, por lo que es más fácil de entender y mantener:</p>
                        <div className="code-text">
                            <SyntaxHighlighter className="codes" language="jsx" style={paraisoLight} customStyle={{borderRadius: "1.5em", backgroundColor: "#f4e7e5", border: "2px solid #222222", padding: "2em"}}>
                            {code03}
                            </SyntaxHighlighter>
                        </div>
                        <hr />
                        <h2>Conclusión</h2>
                        <p>Las capacidades de programación asíncrona de JavaScript son esenciales para la construcción de aplicaciones web modernas que manejan tareas complejas de manera eficiente. Comprender la programación asíncrona, los callbacks, las promesas y Async/Await permite a los desarrolladores escribir código con mayor rendimiento y capacidad de respuesta. Si aprovechas estos conceptos, podrás aprovechar toda la potencia de JavaScript y crear aplicaciones que destaquen por su capacidad de respuesta y su experiencia de usuario. Sigue explorando las técnicas de programación asíncrona y practica su uso en tus proyectos para convertirte en un desarrollador de JavaScript competente.</p>
                    </div>
                    <Signature/>
                </div>
            </div>
        </>
    )
}