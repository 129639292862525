import {Link} from "react-router-dom"
import { Helmet } from "react-helmet";
import pic1 from "../../pix/classnotes/bst/bst-1.png";
import pic2 from "../../pix/classnotes/bst/bst-2.png";
import Signature from "../../signature/Signature";
import "../Posts.css"
export default function BST_Estructura_Datos() {

    return (
        <>
            <Helmet>
                <title>TBP | Estructuras de Datos Jerárquicos Árbol Binario de Búsqueda</title>
            </Helmet>
            <div className="container-3">
                <div className="main-container">
                    <div className="box-title">
                        <h1>Estructuras de Datos Jerárquicos Árbol Binario de Búsqueda</h1>
                    </div>
                    <div className="topic-links">
                        <div className="card-topic-link">
                            <Link to="/topics/classnotes">
                                <h4><i className="fa-sharp fa-solid fa-circle"></i>&nbsp;&nbsp;&nbsp;Classnotes</h4>
                            </Link>
                        </div>
                        <div className="card-topic-link">
                            <Link to="">
                            <h4><i className="fa-sharp fa-solid fa-circle"></i>&nbsp;&nbsp;&nbsp;Jan 27, 2022</h4>
                            </Link>
                        </div>
                    </div>
                    <div className="sub-container">
                        <p>Dado un conjunto de elementos, todos distintos entre sí, se desea almacenarlos en una estructura de datos que permita la implementación eficiente de las operaciones:</p>
                        <ul>
                            <li><b>Búsqueda(x): </b>Dado un elemento x, conocido como llave de búsqueda, encontrarlo dentro del conjunto o decir que no está.</li>
                            <li><b>Inserción: </b>Agregar un nuevo elemento x al conjunto.</li>
                            <li><b>Eliminación: </b>Eliminar el elemento x del conjunto.</li>
                        </ul>
                        <p>Estas operaciones son más eficientes en una estructura jerárquica.</p>
                        <h2>Árboles</h2>
                        <p>Un árbol se define como una colección de nodos organizados en forma recursiva.</p>
                        <ul>
                            <li><b>Árbol vacío: </b>0 nodos</li>
                            <li><b>Hojas: </b>nodos sin hijos</li>
                        </ul>
                        <div className="picinpost">
                            <img className="" src={pic1} alt="" />
                        </div>
                        <h3>Árboles: Definiciones</h3>
                        <p>Camino de n1 y nk: secuencia de nodos n1, n2, ..., nk tal que ni es padre de ni+1, para 1&#60;=i&#60;k.</p>
                        <p><b>Largo del camino: </b>Número de referencias que componen el camino.</p>
                        <p><b>Ancestro: </b>Un nodo n es ancestro de un nodo m si existe un camino desde n a m.</p>
                        <p>Profundidad del nodo nk: Largo del camino entre la raiz y el nodo nk.</p>
                        <ul>
                            <li>Profundidad de la raíz es 0.</li>
                        </ul>
                        <p><b>Altura de un nodo nk: </b>Máximo largo de camino desde nk hasta alguna hoja.</p>
                        <ul>
                            <li>Altura de toda hoja es 0</li>
                            <li>Altura de un árbol = Altura de la raíz</li>
                            <li>Altura de un árbol vacío = -1</li>
                        </ul>
                        <div className="picinpost">
                            <img className="" src={pic2} alt="" />
                        </div>
                        <p>A es la raíz del árbol.</p>
                        <p>A es padre de B, C y D.</p>
                        <p>E y F son hermanos, puesto que ambos son hijos de B.</p>
                        <p>E, J, K, L, C, P, Q, H, N y O son las hojas del árbol.</p>
                        <p>El camino desde A a J es único, lo conforma los nodos A-B-F-J y es de largo 3.</p>
                        <p>D es ancestro de P, y por lo tanto P es descendiente de D.</p>
                        <p>L no es descendiente de C, puesto que no existe un camino desde C a L.</p>
                        <p>La profundidad de C es 0, de F es 1 y de D es 3.</p>
                        <p>La altura del árbol es 4 (largo del camino entre la raíz A y la hoja más profunda, P o Q).</p>
                    </div>
                    <Signature/>
                </div>
            </div>
        </>
    )
}