import {Link} from "react-router-dom";
import { Helmet } from 'react-helmet';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { paraisoLight } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import Signature from "../../signature/Signature";
import "../Posts.css";

export default function EventLoop() {
    const code01 = `console.log('Start');

setTimeout(() => {
    console.log('setTimeout');
}, 0);

Promise.resolve().then(() => {
    console.log('Promise');
});

console.log('End');`;

    return (
        <>
            <Helmet>
                <title>TBP | Desmitificando el Event Loop en JavaScript</title>
            </Helmet>
            <div className="container-3">
                <div className="main-container">
                    <div className="box-title">
                        <h1>Desmitificando el Event Loop en JavaScript</h1>
                    </div>
                    <div className="topic-links">
                        <div className="card-topic-link">
                            <Link to="/topics/javascript">
                                <h4><i className="fa-sharp fa-solid fa-circle"></i>&nbsp;&nbsp;&nbsp;JavaScript</h4>
                            </Link>
                        </div>
                        <div className="card-topic-link">
                            <Link to="">
                            <h4><i className="fa-sharp fa-solid fa-circle"></i>&nbsp;&nbsp;&nbsp;Jun 02, 2023</h4>
                            </Link>
                        </div>
                    </div>
                    <div className="sub-container">
                        <p>En JavaScript, la programación asíncrona juega un papel crucial en la construcción de aplicaciones web responsivas y eficientes. En el corazón de este comportamiento asíncrono se encuentra el event loop. Entender cómo funciona el event loop es esencial para escribir código JavaScript de alto rendimiento. En este post profundizaremos en el concepto de bucle de eventos, su propósito y cómo permite a JavaScript manejar operaciones asíncronas. Exploraremos los detalles, las explicaciones y los ejemplos para desmitificar el event loop y capacitarte para escribir código JavaScript más eficiente.</p>
                        <h2>¿Qué es el Event Loop?</h2>
                        <p>El bucle de eventos es un componente crítico del modelo de concurrencia de JavaScript. Gestiona la ejecución de código mediante tareas síncronas y asíncronas. Su propósito principal es asegurar que JavaScript no se bloquee y pueda manejar operaciones concurrentes eficientemente.</p>
                        <h2>¿Cómo funciona el Event Loop?</h2>
                        <p>Para entender el event loop, necesitamos comprender algunos conceptos clave:</p>
                        <ol>
                            <li><b>Call Stack: </b>La pila de llamadas es una estructura de datos que realiza un seguimiento de las llamadas a funciones en JavaScript. Cuando se invoca una función, se coloca en la pila de llamadas, y cuando se completa, se retira de la pila.</li>
                            <li><b>Heap: </b>Es donde se asignan los objetos y se gestiona la memoria.</li>
                            <li><b>CallBack Queue: </b>El queue de callbacks es una cola que contiene callbacks de operaciones asíncronas completadas. Estos callbacks están listas para ser ejecutadas y esperan a que la call stack esté vacía.</li>
                            <li><b>Event Loop: </b>Event loop es un proceso continuo que comprueba el call stack y el queue de callback. Si el call stack está vacía, toma un callback del callback de queue y la coloca en la call stack para su ejecución.</li>
                        </ol>
                        <h2>Ejemplo</h2>
                        <p>Consideremos un ejemplo para ilustrar cómo el event loop maneja las operaciones asíncronas:</p>
                        <div className="code-text">
                            <SyntaxHighlighter className="codes" language="js" style={paraisoLight} customStyle={{borderRadius: "1.5em", backgroundColor: "#f4e7e5", border: "2px solid #222222", padding: "2em"}}>
                            {code01}
                            </SyntaxHighlighter>
                        </div>
                        <p>En este ejemplo, tenemos una función <b>setTimeout</b> con un retardo de 0 milisegundos y un <b>Promise</b> que se resuelve inmediatamente.</p>
                        <h2>Explicación</h2>
                        <ol>
                            <li>La sentencia console.log inicial se ejecuta, imprimiendo "Inicio" en la consola.</li>
                            <li>Se encuentran las funciones <b>setTimeout</b> y <b>Promise</b>. En lugar de bloquear la ejecución, se colocan en la cola de eventos para su futura ejecución.</li>
                            <li>Se ejecuta la sentencia console.log <b>End</b>.</li>
                            <li>Cuando la pila de llamadas se vacía, el bucle de eventos comprueba el callback y encuentra el call back <b>Promise</b>. Se coloca en la call stack y se imprime <b>Promise</b> en la consola.</li>
                            <li>A continuación, el event loop comprueba de nuevo el call stack y encuentra el callback <b>setTimeout</b>. Se coloca en la pila de llamadas y setTimeout se imprime en la consola.</li>
                        </ol>
                        <hr />
                        <h2>Conclusión</h2>
                        <p>El event loop es un concepto fundamental en JavaScript que permite la programación asíncrona y garantiza un comportamiento no bloqueante. Si comprende la mecánica del event loop, podrá escribir código JavaScript que gestione eficazmente las tareas asíncronas, evitando operaciones de bloqueo y mejorando la capacidad de respuesta de sus aplicaciones.</p>
                        <p>Recuerde que el event loop de JavaScript es monohilo, por lo que es fundamental escribir código no bloqueante para evitar bloquear la ejecución de otras tareas. Esto implica utilizar funciones asíncronas, callbacks, promesas y async/await para garantizar un funcionamiento fluido y eficaz.</p>
                        <p>Si dominas el Event loop y la programación asíncrona en JavaScript, podrás aprovechar toda la potencia del lenguaje y crear aplicaciones web de alto rendimiento que ofrezcan una experiencia de usuario fluida.</p>
                        <p>Así pues, sumérgete en el mundo del event loop, experimenta con operaciones asíncronas y descubre el verdadero potencial del modelo de concurrencia de JavaScript.</p>
                    </div>
                    <Signature/>
                </div>
            </div>
        </>
    )
}