import {Link} from "react-router-dom";
import { Helmet } from 'react-helmet';
import Signature from "../../signature/Signature";
import "../Posts.css";

export default function JavaScript_CallStack() {
    return (
        <>
            <Helmet>
                <title>TBP | JavaScript CallStack</title>
            </Helmet>
            <div className="container-3">
                <div className="main-container">
                    <div className="box-title">
                        <h1>JavaScript Call Stack</h1>
                    </div>
                    <div className="topic-links">
                        <div className="card-topic-link">
                            <Link to="/topics/javascript">
                                <h4><i className="fa-sharp fa-solid fa-circle"></i>&nbsp;&nbsp;&nbsp;JavaScript</h4>
                            </Link>
                        </div>
                        <div className="card-topic-link">
                            <Link to="">
                            <h4><i className="fa-sharp fa-solid fa-circle"></i>&nbsp;&nbsp;&nbsp;Jan 26, 2022</h4>
                            </Link>
                        </div>
                    </div>
                    <div className="sub-container">
                        <h5>Mecanismo para realizar un seguimiento de las llamadas a funciones.</h5>
                        <p>
                            JavaScript es un lenguaje de programación monoproceso
                            <br />
                            → Solamente ejecuta una cosa a la vez
                            <br />
                            → Tiene un único Call Stack
                            <br />
                            Call Stack es una estructura de datos que registra en que momento se encuentra la ejecución
                            <br />
                            → Guarda los scope/contextos de ejecución
                            <br />
                            Call → llamada
                            <br />
                            Stack → pila
                            <br />
                            LIFO
                            <br />
                            LI → último en entrar
                            <br />
                            FO → primero en salir
                        </p>
                        <h2>Introducción a JavaScript Call Stack</h2>
                        <p>El motor de JavaScript utiliza una pila de llamadas(callstack) para administrar los contextos de ejecución: el contexto de ejecución global y los contextos de ejecución de funciones.</p>
                        <p>La pila de llamadas funciona según el principio LIFO, es decir, el último en entrar, primero en salir.</p>
                        <p>Cuando ejecuta una secuencia de comandos, el motor de JavaScript crea un contexto de ejecución global y lo coloca en la parte superior de la pila de llamadas.</p>
                        <p>Cada vez que se llama a una función, el motor de JavaScript crea un contexto de ejecución de función para la función, lo coloca en la parte superior de la pila de llamadas y comienza a ejecutar la función.</p>
                        <p>Si una función llama a otra función, el motor de JavaScript crea un nuevo contexto de ejecución de función para la función que se llama y lo coloca en la parte superior de la pila de llamadas.</p>
                        <p>Si una función llama a otra función, el motor de JavaScript crea un nuevo contexto de ejecución de función para la función que se llama y lo coloca en la parte superior de la pila de llamadas.</p>
                        <p>Cuando se completa la función actual, el motor de JavaScript la saca de la pila de llamadas y reanuda la ejecución donde la dejó.</p>
                        <p>El script se detendrá cuando la pila de llamadas esté vacía.</p>
                        <h2>Desbordamiento de pila</h2>
                        <p>La pila de llamadas tiene un tamaño fijo, según la implementación del entorno de host, ya sea el navegador web o Node.js.</p>
                        <p>Si el número de contextos de ejecución supera el tamaño de la pila, se producirá un error de desbordamiento de pila.</p>
                        <p>Por ejemplo, cuando ejecuta una función recursiva que no tiene una condición de salida, el motor de JavaScript generará un error de desbordamiento de pila.</p>
                        <h2>JavaScript Asíncrono</h2>
                        <p>JavaScript es el lenguaje de programación de un solo subproceso. Esto significa que el motor de JavaScript solo tiene una pila de llamadas. Por lo tanto, solo puede hacer una cosa a la vez.</p>
                        <p>Al ejecutar un script, el motor de JavaScript ejecuta el código de arriba a abajo, línea por línea. En otras palabras, es sincrónico.</p>
                        <p>Asíncrono significa que el motor de JavaScript puede ejecutar otras tareas mientras espera que se complete otra tarea. Por ejemplo, el motor JavaScript puede:</p>
                        <div>
                            <ul>
                                <li>Solicitud de datos de un servidor remoto.</li>
                                <li>Mostrar una ruleta</li>
                                <li>Cuando los datos estén disponibles, mostrarlos en la pantalla</li>
                                <li>Para ello, el motor de JavaScript utiliza un bucle de eventos.</li>
                            </ul>
                        </div>
                        <h3>Resumen</h3>
                        <div>
                            <ul>
                                <li>El motor de JavaScript utiliza una pila de llamadas para administrar los contextos de ejecución.</li>
                                <li>La pila de llamadas utiliza la estructura de datos de la pila que funciona según el principio LIFO (último en entrar, primero en salir)</li>
                            </ul>
                        </div>
                    </div>
                    <Signature/>
                </div>
            </div>
        </>
    )
}