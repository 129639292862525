import {Link} from "react-router-dom";
import SyntaxHighlighter from 'react-syntax-highlighter';
import { paraisoLight } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import { Helmet } from 'react-helmet';
import Signature from "../../signature/Signature";
import "../Posts.css";

export default function Libraries10() {
    const code01 = `$(document).ready(function(){
    $("p").text("Hello World!");
});`;
const code02 = `import React from 'react';

function Message(props) {
    return <h1>{props.text}</h1>;
}

ReactDOM.render(
    <Message text="Hello, world!" />,
    document.getElementById('root')
);`;
const code03 = `new Vue({
    el: '#app',
    data: {
        message: 'Hello Vue.js!'
    }
})`;
const code04 = `import { Component } from '@angular/core';

@Component({
    selector: 'app-message',
    template: '<h1>{{message}}</h1>'
})
export class MessageComponent {
    message = 'Hello, world!';
}`;
const code05 = `var data = [4, 8, 15, 16, 23, 42];

var svg = d3.select("body").append("svg")
    .attr("width", 420)
    .attr("height", 120);

svg.selectAll("rect")
    .data(data)
  .enter().append("rect")
    .attr("x", function(d, i) { return i * 70; })
    .attr("y", function(d) { return 120 - d; })
    .attr("width", 65)
    .attr("height", function(d) { return d; });`;

    return (
        <>
            <Helmet>
                <title>TBP | 5 librerías javascript esenciales</title>
            </Helmet>
            <div className="container-3">
                <div className="main-container">
                    <div className="box-title">
                        <h1>5 librerías JavaScript esenciales</h1>
                    </div>
                    <div className="topic-links">
                        <div className="card-topic-link">
                            <Link to="/topics/javascript">
                                <h4><i className="fa-sharp fa-solid fa-circle"></i>&nbsp;&nbsp;&nbsp;JavaScript</h4>
                            </Link>
                        </div>
                        <div className="card-topic-link">
                            <Link to="">
                            <h4><i className="fa-sharp fa-solid fa-circle"></i>&nbsp;&nbsp;&nbsp;Apr 21, 2023</h4>
                            </Link>
                        </div>
                    </div>
                    <div className="sub-container">
                        <p>Como desarrollador web, elegir la biblioteca JavaScript adecuada puede ser crucial para el éxito de tu proyecto. Con tantas opciones disponibles, puede resultar abrumador decidir qué librerías utilizar. En este post conoceremos las 5 librerías JavaScript esenciales que todo desarrollador web debería conocer.</p>
                        <h2>1. jQuery</h2>
                        <p>jQuery es una de las librerías de JavaScript más populares. Simplifica la navegación y manipulación de documentos HTML, el manejo de eventos y la animación. Con su sencilla sintaxis, jQuery facilita la manipulación del Modelo de Objetos del Documento (DOM) de un documento HTML, el manejo de eventos y la creación de animaciones. Por ejemplo, puedes utilizar jQuery para cambiar el texto de un elemento de párrafo, mostrar un cuadro de alerta al pulsar un botón o animar la opacidad de un elemento.</p>
                        <p>Ejemplo: Cambiar el texto de un elemento de párrafo utilizando jQuery</p>
                        <div className="code-text">
                            <SyntaxHighlighter className="codes" language="js" style={paraisoLight} customStyle={{borderRadius: "1.5em", backgroundColor: "#f4e7e5", border: "2px solid #222222", padding: "2em"}}>
                            {code01}
                            </SyntaxHighlighter>
                        </div>
                        <h2>2. React</h2>
                        <p>React es una biblioteca JavaScript para crear interfaces de usuario. Permite crear componentes de interfaz de usuario reutilizables y gestionar el estado de la aplicación mediante un DOM virtual. React es utilizado por muchas grandes empresas, como Facebook, Instagram y Netflix.</p>
                        <p>Ejemplo: Creación de un componente React simple que muestra un mensaje</p>
                        <div className="code-text">
                            <SyntaxHighlighter className="codes" language="js" style={paraisoLight} customStyle={{borderRadius: "1.5em", backgroundColor: "#f4e7e5", border: "2px solid #222222", padding: "2em"}}>
                            {code02}
                            </SyntaxHighlighter>
                        </div>
                        <h2>3. Vue.js</h2>
                        <p>Vue.js es un framework JavaScript progresivo para construir interfaces de usuario. Es ligero y fácil de aprender, por lo que es una gran opción para proyectos pequeños y medianos. Vue.js proporciona una capa de vista reactiva y componible, y también se puede utilizar para crear aplicaciones complejas de una sola página.</p>
                        <p>Ejemplo: Creación de una sencilla aplicación Vue.js que muestra un mensaje</p>
                        <div className="code-text">
                            <SyntaxHighlighter className="codes" language="js" style={paraisoLight} customStyle={{borderRadius: "1.5em", backgroundColor: "#f4e7e5", border: "2px solid #222222", padding: "2em"}}>
                            {code03}
                            </SyntaxHighlighter>
                        </div>
                        <h2>4. Angular</h2>
                        <p>Angular es un framework JavaScript para crear aplicaciones web complejas. Proporciona un potente conjunto de herramientas para crear componentes de interfaz de usuario reutilizables, gestionar el estado de la aplicación y administrar el enrutamiento y la navegación. Angular es utilizado por muchas grandes empresas, como Google y Microsoft.</p>
                        <p>Ejemplo: Creación de un componente Angular simple que muestra un mensaje</p>
                        <div className="code-text">
                            <SyntaxHighlighter className="codes" language="js" style={paraisoLight} customStyle={{borderRadius: "1.5em", backgroundColor: "#f4e7e5", border: "2px solid #222222", padding: "2em"}}>
                            {code04}
                            </SyntaxHighlighter>
                        </div>
                        <h2>5. D3.js</h2>
                        <p>D3.js es una potente librería JavaScript para crear visualizaciones de datos en la web. Proporciona una serie de herramientas para crear diagramas, gráficos y otros tipos de visualizaciones, y permite manipular el DOM y los datos en tiempo real.</p>
                        <p>Ejemplo: Creación de un simple gráfico de barras utilizando D3.js</p>
                        <div className="code-text">
                            <SyntaxHighlighter className="codes" language="js" style={paraisoLight} customStyle={{borderRadius: "1.5em", backgroundColor: "#f4e7e5", border: "2px solid #222222", padding: "2em"}}>
                            {code05}
                            </SyntaxHighlighter>
                        </div>
                        <p>En general, estas 5 librerías JavaScript pueden ayudarte a acelerar el desarrollo, escribir mejor código y crear aplicaciones más complejas. Ya se trate de interfaces de usuario, visualizaciones de datos o gráficos en 3D, estas librerías ofrecen un potente conjunto de herramientas para realizar el trabajo.</p>
                    </div>
                    <Signature/>
                </div>
            </div>
        </>
    )
}