import "./Home.css";
import SyntaxHighlighter from 'react-syntax-highlighter';
import { paraisoLight } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import Signature from "../signature/Signature";
import {Link} from "react-router-dom";
import { Helmet } from "react-helmet";
import React from "react";
export default function SinglePage() {

    const code01 = ``;
    return (
        <>
            <Helmet>
                <title>TBP | Single Page</title>
            </Helmet>
            <div className="container-3">
                <div className="main-container">
                    <div className="box-title">
                        <h1>¿Qué es Stack?</h1>
                    </div>
                    <div className="sub-container">
                        <h1>Typography</h1>
                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
                        <h1>Headings 1</h1>
                        <h2>Headings 2</h2>
                        <h3>Headings 3</h3>
                        <h4>Headings 4</h4>
                        <h5>Headings 5</h5>
                        <h2>Syntax Highlight</h2>
                        <div className="code-text">
                            <SyntaxHighlighter className="codes" language="jsx" style={paraisoLight} customStyle={{borderRadius: "1.5em", backgroundColor: "#f4e7e5", border: "2px solid #222222", padding: "2em"}}>
                            {code01}
                            </SyntaxHighlighter>
                        </div>
                        <h2>Quote</h2>
                        <blockquote>REST es un conjunto de directrices esencialmente, mientras que Fetch API es una API para realizar peticiones web.</blockquote>
                    </div>
                    <Signature/>
                </div>
            </div>
            <div className="container-3">
                <div className="main-container">
                    <div className="cont-text">
                        <h1>Random</h1> {/* Me in Random */}
                    </div>
                    <div className="min-container">
                        <div className="lit-container">
                            <h2>Lectura 2022</h2>
                            <ol>
                                <li>Leer 100 libros ✔️</li>
                                <li>De sangre y cenizas &#40;físico&#41;✔️</li>
                                <li>Un reino de carne y fuego &#40;físico&#41;✔️</li>
                                <li>Una corona de huesos dorados &#40;físico&#41;✔️</li>
                                <li>Una sombra en las brasas &#40;físico&#41;✔️</li>
                                <li>La guerra de las dos reinas &#40;físico&#41;✔️</li>
                            </ol>                            
                        </div>
                        <div className="lit-container">
                            <h2>Otros</h2>
                            <ol>
                                <li>Phone ✔️</li>
                                <li>Lalisa Black Version ✔️</li>
                                <li>Lalisa Gold Version ✔️</li>
                                <li>Lalisa Kit Version ✔️</li>
                                <li>Digital Lalisa ✔️</li>
                                <li>Digital Born Pink by Blackpink ✔️</li>
                                <li>Updated ID ✔️</li>
                                <li>Health ❌</li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}