import React from "react";
import {Link} from "react-router-dom"
import { Helmet } from 'react-helmet';
import Signature from "../../signature/Signature";
import "../Posts.css"

export default function Preguntas_ParteII() {
    return (
        <>
            <Helmet>
                <title>TBP | Preguntas de Python - II</title>
            </Helmet>
            <div className="container-3">
                <div className="main-container">
                    <div className="box-title">
                        <h1>Preguntas de Python - II</h1>
                    </div>
                    <div className="topic-links">
                        <div className="card-topic-link">
                            <Link to="/topics/python">
                                <h4><i className="fa-sharp fa-solid fa-circle"></i>&nbsp;&nbsp;&nbsp;Python</h4>
                            </Link>
                        </div>
                        <div className="card-topic-link">
                            <Link to="">
                            <h4><i className="fa-sharp fa-solid fa-circle"></i>&nbsp;&nbsp;&nbsp;Jan 13, 2023</h4>
                            </Link>
                        </div>
                    </div>
                    <div className="sub-container">
                        <p><i>Respuestas a preguntas sobre Python</i></p>
                        <h3>¿Python es un lenguaje de programación?</h3>
                        <p>Esta pregunta se realiza para saber que tan familiarizados estamos con los fundamentos de Python. Las respuestas a esta deberían indicar que aunque se pueden hacer scripts en Python, se considera un lenguaje de programación.</p>
                        <h3>Principales características de Python</h3>
                        <p>Python es un lenguaje interpretado, es diferente de C++, que requiere que el usuario lo compile antes de ejecutarlo. En Python no es necesario especificar las variables en particular, ya que Python está clasificado como lenguaje de tipado dinámico. Las funciones que son objetos de primera clase en Python, permiten al usuario asignarles una variable.</p>
                        <h3>¿Qué son los módulos en Python?</h3>
                        <p>Los módulos en Python se describen mejor como archivos. Estos archivos contienen código que, en Python puede tomar la forma de una clase o una variable.</p>
                        <h3>Doferencia entre tuplas y listas en Python</h3>
                        <p>Las listas se pueden editar ya que son mutables, contrario a las tuplas que son inmutables y por lo tanto no pueden ser editadas. Las listas no son tan rápidas como las tuplas también la sintaxis de ambas es diferente.</p>
                        <h3>¿Qué método se puede utilizar para eliminar los espacios en blanco de los strings en Python?</h3>
                        <p>Con la función strip&#40;&#41; puede eliminar los espacios en blanco de un string. También los métodos Istrip&#40;&#41; o rstrip&#40;&#41; se pueden utilizar para eliminar los espacios en blanco al principio o al final de un string.</p>
                        <h3>¿Qué significa PEP?</h3>
                        <p>PEP significa propuesta de mejora de Python, PEP comprende varias reglas o estipulaciones que ayudan a los ingenieros a codificar eficientemente. Las reglas PEP contenidas en un documento de diseño también garantizan que el código que los programadores escriben tiene el formato correcto.</p>
                        <h3>¿Cuáles son los principales usos de Python?</h3>
                        <p>Python se utiliza para crear software y sitios web, pero también en proyectos de machine learning. También se utiliza para proyectos de inteligencia artificial, análisis de datos, visualización de datos y programación así como el desarrollo de juegos. Python puede utilizarse para la optimización y el diseño de motores de búsqueda.</p>
                        <h3>¿Cuáles son los conocimientos básicos necesarios para utilizar Python de forma eficaz?</h3>
                        <p>El conocimiento de algoritmos y la analítica son esenciales y también un conocimiento sólido de matrices objeto-relacional y tecnologías front-end. El conocimiento de structuras de datos es esencial al igual que la comprensión de objetos.</p>
                        <h3>¿Cuáles son las habilidades interpersonales necesarias para utilizar Python con eficacia?</h3>
                        <p>Habilidades de comunicación y la gestión de tiempo son cruciales a la hora de completar proyectos en Python. Si se trabaja en equipo, la empatía también es esencial y también es crucial la organización.</p>
                    </div>
                    <Signature/>
                </div>
            </div>
        </>
    )
}