import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import './Books.css';

export default function Books() {
    const [data, setData] = useState([]);
    const getData=()=>{
        fetch('../topic-books.json',
        {
        headers : { 
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        }
        }
        )
        .then(function(response){
            console.log(response)
            return response.json();
        })
        .then(function(myJson) {
            console.log(myJson);
            setData(myJson)
        });
    }
    useEffect(()=>{
        getData()
    },[])

    return (
        <>
            <Helmet>
                <title>TBP | Books</title>
            </Helmet>
            <div className="container-3">
                <div className="container-mix">
                    <div className="box">
                        <div className="box-title">
                            <h1>Books</h1>
                        </div>
                    </div>
                    <div className="subcontainer">
                    {data.map(item => (
                        <div className="month-card" key={item.id}>
                            <div className="title-month">
                                <h3>{item.id}</h3>
                            </div>
                            <div className="pix-books">
                                {item.books.map(book => (
                                    <div className="book-card">
                                        <img className="book-pic" src={`${process.env.PUBLIC_URL}/books/${book.pix}`} alt="" />
                                        <p className="book-title"><b>{book.title}</b></p>
                                        <p className="book-author">{book.author}</p>
                                    </div>
                                ))}
                            </div>
                            <hr />
                        </div>
                    ))}
                    </div>
                </div>
            </div>
        </>
    )
}