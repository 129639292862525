import {Link} from "react-router-dom";
import { Helmet } from 'react-helmet';
import Signature from "../../signature/Signature";
import "../Posts.css";

export default function DesignSoftware() {
    return (
        <>
            <Helmet>
                <title>TBP | Diseño y arquitectura de software: Principios, patrones y marcos de trabajo</title>
            </Helmet>
            <div className="container-3">
                <div className="main-container">
                    <div className="box-title">
                        <h1>Diseño y arquitectura de software: Principios, patrones y marcos de trabajo</h1>
                    </div>
                    <div className="topic-links">
                        <div className="card-topic-link">
                            <Link to="/topics/computer-science">
                                <h4><i className="fa-sharp fa-solid fa-circle"></i>&nbsp;&nbsp;&nbsp;Computer Science</h4>
                            </Link>
                        </div>
                        <div className="card-topic-link">
                            <Link to="">
                            <h4><i className="fa-sharp fa-solid fa-circle"></i>&nbsp;&nbsp;&nbsp;May 09, 2023</h4>
                            </Link>
                        </div>
                    </div>
                    <div className="sub-container">
                        <p>Cuando se trata de desarrollo de software, el diseño y la arquitectura son componentes cruciales que pueden hacer o deshacer el éxito de un proyecto. Un buen diseño y una buena arquitectura pueden mejorar el mantenimiento, la escalabilidad y la calidad general del software.</p>
                        <p>En este post, vamos a discutir los principios clave, patrones y marcos que se utilizan comúnmente en el diseño de software y arquitectura.</p>
                        <h2>Principios del diseño de software</h2>
                        <p>Hay varios principios que guían el diseño de software, incluyendo:</p>
                        <h3>Principios SOLID</h3>
                        <p>Este acrónimo significa Responsabilidad Única, Abierto-Cerrado, Sustitución de Liskov, Segregación de Interfaces e Inversión de Dependencias. Estos principios pretenden que los componentes de software sean más fáciles de mantener y modificar a lo largo del tiempo.</p>
                        <h3>DRY (Note repitas)</h3>
                        <p>Este principio establece que cada pieza de conocimiento o lógica debe tener una representación única y autorizada en un sistema. Esto ayuda a reducir la duplicación de código y mejorar la capacidad de mantenimiento.</p>
                        <h3>KISS (Keep it Simple, Stupid)</h3>
                        <p>Este principio aboga por la simplicidad en el diseño y por evitar la complejidad innecesaria. Esto ayuda a mejorar la claridad, la legibilidad y la facilidad de mantenimiento.</p>
                        <h3>YAGNI (You Ain't Gonna Need It - No lo vas a necesitar)</h3>
                        <p>Este principio aconseja no añadir funcionalidad hasta que realmente se necesite. Esto ayuda a evitar complejidades innecesarias y a mantener una base de código más ágil.</p>
                        <h2>Patrones de diseño de Software</h2>
                        <p>Los patrones de diseño de software son soluciones reutilizables a problemas comunes de diseño de software. Algunos de los patrones de diseño más utilizados son:</p>
                        <h3>Patrón Singleton</h3>
                        <p>Este patrón restringe la instanciación de una clase a un único objeto, garantizando que sólo haya una instancia de la clase en toda la aplicación.</p>
                        <h3>Patrón de fábrica</h3>
                        <p>Este patrón proporciona una interfaz para crear objetos en una superclase, al tiempo que permite a las subclases modificar el tipo de objetos que se crearán.</p>
                        <h3>Patrón observador</h3>
                        <p>Este patrón define una dependencia de uno a muchos entre los objetos, de modo que cuando un objeto cambia de estado, todos sus dependientes son notificados y actualizados automáticamente.</p>
                        <h3>Patrón decorador</h3>
                        <p>Este patrón permite añadir comportamiento a un objeto individual, ya sea de forma estática o dinámica, sin afectar al comportamiento de otros objetos de la misma clase.</p>
                        <h2>Frameworks de diseño de software</h2>
                        <p>Los frameworks de diseño de software proporcionan un enfoque estructurado para diseñar sistemas de software. Algunos de los frameworks más populares son:</p>
                        <h3>Modelo Vista Controlador (MVC)</h3>
                        <p>Este framework separa una aplicación en tres partes interconectadas: el modelo (datos y lógica), la vista (interfaz de usuario) y el controlador (maneja la entrada del usuario).</p>
                        <h3>Arquitectura orientada a servicios (SOA)</h3>
                        <p>Este framework define un conjunto de principios para diseñar y desarrollar software en forma de servicios reutilizables.</p>
                        <h3>Diseño orientado al dominio (DDD)</h3>
                        <p>Este framework hace hincapié en la importancia de comprender el dominio en el que se utilizará el software y alinear el diseño del software con los conceptos y principios del dominio.</p>
                        <hr />
                        <h2>Conclusión</h2>
                        <p>El diseño y la arquitectura son componentes críticos para el éxito del desarrollo de software. Siguiendo principios, patrones y marcos clave, los desarrolladores pueden crear software más fácil de mantener, escalar y actualizar con el tiempo. Con el enfoque adecuado, el diseño y la arquitectura de software pueden ayudar a garantizar el éxito de cualquier proyecto.</p>
                    </div>
                    <Signature/>
                </div>
            </div>
        </>
    )
}