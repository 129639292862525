import {Link} from "react-router-dom"
import { Helmet } from 'react-helmet';
import Signature from "../../signature/Signature";
import "../Posts.css"

export default function Pruebas_Transicion_Estados() {
    return (
        <>
            <Helmet>
                <title>TBP | Pruebas de Transición de Estados</title>
            </Helmet>
            <div className="container-3">
                <div className="main-container">
                    <div className="box-title">
                        <h1>Pruebas de Transición de Estados</h1>
                    </div>
                    <div className="topic-links">
                        <div className="card-topic-link">
                            <Link to="/topics/testing">
                                <h4><i className="fa-sharp fa-solid fa-circle"></i>&nbsp;&nbsp;&nbsp;Testing</h4>
                            </Link>
                        </div>
                        <div className="card-topic-link">
                            <Link to="">
                            <h4><i className="fa-sharp fa-solid fa-circle"></i>&nbsp;&nbsp;&nbsp;May 24, 2020</h4>
                            </Link>
                        </div>
                    </div>
                    <div className="sub-container">
                        <p>La prueba de transición de estado se define como la técnica de prueba de software en la que los cambios en las condiciones de entrada provocan cambios de estado en la aplicación bajo prueba (AUT).</p>
                        <p>Es una técnica de prueba de caja negra en la que el probador analiza el comportamiento de una aplicación bajo prueba para diferentes condiciones de entrada en una secuencia. En esta técnica, el probador proporciona valores de prueba de entrada positivos y negativos y registra el comportamiento del sistema.</p>
                        <p>Es el modelo en el que se basan el sistema y las pruebas. Cualquier sistema en el que obtenga una salida diferente para la misma entrada, dependiendo de lo que haya sucedido antes, es un sistema de estado finito.</p>
                        <p>La técnica de prueba de transición de estado es útil cuando necesita probar diferentes transiciones del sistema.</p>
                        <h2>¿Cuándo usar la transición de estados?</h2>
                        <ul>
                            <li>Esto se puede usar cuando un probador está probando la aplicación para un conjunto finito de valores de entrada.</li>
                            <li>Cuando el probador intenta probar la secuencia de eventos que ocurren en la aplicación bajo prueba. Es decir, esto permitirá que el probador pruebe el comportamiento de la aplicación para una secuencia de valores de entrada.</li>
                            <li>Cuando el sistema bajo prueba depende de los eventos / valores del pasado.</li>
                        </ul>
                        <h2>¿Cuándo no confiar en la transición de estados?</h2>
                        <ul>
                            <li>Cuando la prueba no se realiza para combinaciones de entrada secuenciales.</li>
                            <li>Si la prueba se va a realizar para diferentes funcionalidades, como las pruebas exploratorias.</li>
                        </ul>
                    </div>
                    <Signature/>
                </div>
            </div>
        </>
    )
}