import { Helmet } from "react-helmet";
import error from "../pix/404.png"
import "./Xtras.css";

export default function ErrorPage() {
    return (
        <div>
            <Helmet>
                <title>TBP | Error Page</title>
            </Helmet>
            <div className="container-mix">
                <div className="main-container">
                    <div className="box-title">
                        <h1>Error Page</h1>
                    </div>
                    <div className="sub-container">
                        <div className="pic-error">
                            <img src={error} alt="error page" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
