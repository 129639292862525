import { Helmet } from "react-helmet";
import mypic from "../pix/me.jpg"
import bannercat from "../pix/banner-cat.png"
import pic1 from "../pix/bottom/radio-2.jpg"
import pic2 from "../pix/bottom/radio-1.jpg"
import pic3 from "../pix/bottom/radio-4.JPG"
import pic4 from "../pix/bottom/radio-3.JPG"
import lalisa from "../pix/lalisa-1.jpg"
import michele from "../pix/michele.PNG"
import "./About.css";

export default function About() {
    return (
        <>
            <Helmet>
                <title>TBP | About</title>
            </Helmet>
            <div className="container-3">
                <div className="container-mix">
                    <div className="box-title">
                        <h1>About Me</h1>
                    </div>
                    <div className="about-me">
                        <div className="pic-about">
                            <img className="pic-me" src={mypic} alt="yesenia medina" />
                        </div>
                        <div className="about-text">
                            <div>
                                <h1>Hey there, I'm Yess!</h1>
                                <p className="upper">I'm the blink panda</p>
                                <p className="upper"><b>front end developer</b></p>
                                <p className="upper">Just a simple person with a complicated mind</p>
                            </div>
                        </div>
                    </div>
                    <div className="social-network">
                        <a className="icontype" target="_blank" href="https://www.instagram.com/theblinkpanda/">
                            <i className="iconx fa-brands fa-instagram"></i>
                        </a>
                        <a className="icontype" target="_blank" href="https://t.me/+xfDJitnin8xkZDhh">
                            <i className="iconx fa-brands fa-telegram"></i>
                        </a>
                        <a className="icontype" target="_blank" href="https://music.apple.com/profile/m2_nam">
                            <i className="iconx fa-brands fa-itunes-note"></i>
                        </a>
                    </div>
                    <hr />
                    <div className="about-paragraph">
                        <p>I'm a readerholic &#40;romance, dark romance and polyamory&#41; but I'm also a developer &#40;websites and apps&#41;.</p>
                        <p>I always loved designing and programming websites. So, this website is one of my personal projects.</p>
                        <p>At the beginning I had an anonymous blog with a compilation of some topics but I decided to start this website to get rid of my shyness.</p>
                        <p>First of all, this site is a hobby, I'm trying to blog, it's not perfect as I do a lot of interface changes. So I often tend to change the style of the website such as colours, fonts and effects. So one day it can be a pink website and another day it will be a blue one.</p>
                    </div>
                    <div className="banner">
                        <img className="banner-cat" src={bannercat} alt="yesenia medina" />
                    </div>
                </div>
            </div>
            <div className="container-2">
                <div className="container-mix">                    
                    <h1>I'm a cat mom 🐱</h1>
                    <div className="about-radio">
                        <div className="radio-pic">
                            <div className="pic1">
                                <img className="" src={pic3} alt="radio" />
                            </div>
                            <div className="pic2">
                                <img className="" src={pic4} alt="radio" />
                            </div>
                        </div>
                        <div className="radio-pic">
                            <div className="pic3">
                                <img className="" src={pic2} alt="radio" />
                            </div>
                            <div className="pic4">
                                <img className="" src={pic1} alt="radio" />
                            </div>
                        </div>
                    </div>
                    <div className="radio-text">
                        <h1>This is Radio</h1>
                        <p> My baby is 2&nbsp;y/o, she was adopted at 2 months old. I named her Radio 'cause she hurt her vocal cords and couldn't meow properly, she made sounds like a broken radio. Now she can meow well but she kept that name.</p>
                        <p>She sleeps in my bed or my sister's, leaving hairs everywhere, sometimes I hate having to remove so many hairs but I love her.</p>
                    </div>
                    <div className="banner">
                        <img className="banner-cat" src={bannercat} alt="yesenia medina" />
                    </div>
                </div>
            </div>
            <div className="container-3">
                <div className="container-mix">
                    <div className="about-music">
                        <h1>Music I love 🎵</h1>
                        <p>I'm in love with kpop, my fav band is BLACKPINK and the love of my life is LALISA. Since her debut she captured my ♡</p>
                        <p>There's nothing I don't love 'bout her, whatever she does goes viral as she is the most popular of the group.</p>
                        <div className="music-pic">
                            <img className="" src={lalisa} alt="Lalisa" />
                        </div>
                        <p>And the other love of my life is Michele Morrone. And yes, I knew him from the movie '365 DNI', since the song 'Hard for me' was played at the beginning of the movie I looked for the singer and he turned out to be the actor of that movie, I loved him more. I must say that thanks to him I started to listen to Italian music and I loved it, now I have several Italian artists I love.</p>
                        <p>So ... Thank u Michele! ♡</p>
                        <div className="music-pic">
                            <img className="" src={michele} alt="Lalisa" />
                        </div>
                    </div>
                    <div className="banner">
                        <img className="banner-cat" src={bannercat} alt="yesenia medina" />
                    </div>
                </div>
            </div>
        </>
    )
}