import "./Signature.css";
import signature from "../pix/theblinkpanda-signature.png";

export default function Signature() {
    return(
        <>
            <div className="container-signature">
                {/* <div className="signature">
                    <p>The Blink Panda</p>
                </div> */}
                <img src={signature} alt="signature the blink panda" />
            </div>
        </>
    )
}