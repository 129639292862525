import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import {Link} from "react-router-dom"
export default function Languages() {
    const [data,setData]=useState([]);
    const [search, setSearch] = useState('');
    const getData=()=>{
        fetch('../posts.json',
        {
            headers : { 
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        })
        .then(function(response){
            return response.json();
        })
        .then(function(myJson) {
            setData(myJson)
        });
    }
    function handleSearchClick() {
        
    }
    useEffect(()=>{
        getData()
    },[])
    return (
        <>
            <Helmet>
                <title>TBP | All Posts</title>
            </Helmet>
            <div className="container-2">
                <div className="container-mix">
                    <div className="box-title">
                        <h1>All posts</h1>
                    </div>
                    <div className="search">
                        <input type="text" onClick={handleSearchClick} onChange={e => setSearch(e.target.value)} placeholder="Search by title here!" />
                    </div>
                    <div className="feed-blog">
                        {data.map((item => {
                            if (item.title.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase().includes(search.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase()) == true){
                                return <Link className="link" to={`/topics/${item.link}`}>
                                <div className="card-post" key={item.topic}>
                                    <div className="date">
                                        <a>{item.date}</a>
                                    </div>
                                    <h4 className="text-post">{item.title}</h4>
                                </div>
                            </Link>
                            }
                        }))}
                    </div>
                </div>
            </div>
            
        </>
    )
}