import {Link} from "react-router-dom"
import { Helmet } from "react-helmet";
import json1 from "../../pix/classnotes/jsons/example-json.png"
import Signature from "../../signature/Signature";
export default function Json() {

    return (
        <>
            <Helmet>
                <title>TBP | ¿Qué es Json?</title>
            </Helmet>
            <div className="container-3">
                <div className="main-container">
                    <div className="box-title">
                        <h1>¿Qué es Json?</h1>
                    </div>
                    <div className="topic-links">
                        <div className="card-topic-link">
                            <Link to="/topics/classnotes">
                                <h4><i className="fa-sharp fa-solid fa-circle"></i>&nbsp;&nbsp;&nbsp;Classnotes</h4>
                            </Link>
                        </div>
                        <div className="card-topic-link">
                            <Link to="">
                            <h4><i className="fa-sharp fa-solid fa-circle"></i>&nbsp;&nbsp;&nbsp;Oct 29, 2022</h4>
                            </Link>
                        </div>
                    </div>
                    <div className="sub-container">
                        <p>JavaScript Object Notation &#40;Json&#41; es un formato de datos estándar basado en texto que se utiliza en el desarrollo web para enviar y recibir los datos.</p>
                        <h4>Ventajas de usar Json</h4>
                        <ul>
                            <li>Es compatible con todos los lenguajes de programación.</li>
                            <li>Puede utilizarse en todas las plataformas.</li>
                            <li>Es un estándar de base de datos ligero, por lo que la transmisión de datos es rápida.</li>
                        </ul>
                        <h4>¿Donde se utiliza Json?</h4>
                        <ul>
                            <li>Aplicaciones web</li>
                            <li>APIs</li>
                            <li>Aplicaciones móviles</li>
                            <li>Gaming API's</li>
                            <li>IoT</li>
                        </ul>
                        <h4>Tipos de datos admitidos</h4>
                        <ul>
                            <li>String</li>
                            <li>Number</li>
                            <li>Arrays</li>
                            <li>null</li>
                            <li>Boolean</li>
                            <li>Object</li>
                        </ul>
                        <h4>Métodos Json en JavaScript</h4>
                        <ul>
                            <li>JSON.parse&#40;&#41;: Este método toma una cadena Json y la transforma en un objeto JavaScript.</li>
                            <li>JSON.stringify&#40;&#41;: Este método convierte un valor de JavaScript en una representación de cadena Json &#40;útil mientras se envía a través de la red&#41;</li>
                        </ul>
                        <h4>Sintaxis y ejemplo</h4>
                        <div className="code-pix">
                            <img src={json1} alt="ejemplo de sintaxis json" />
                            </div>
                    </div>
                    <Signature/>
                </div>
            </div>
        </>
    )
}