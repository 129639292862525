import "./Freebies.css";
import { Helmet } from "react-helmet";
import pack2 from "../pix/freebies/Daisy-Background.png"
import pack1 from "../pix/freebies/Smiley-Background.png"
export default function Freebies() {
    return (
        <div>
            <Helmet>
                <title>TBP | Freebies</title>
            </Helmet>
            <div className="container-3">
                <div className="container-mix">
                    <div className="box-title">
                        <h1>Freebies</h1>
                    </div>
                    <div className="subcontainer">
                        <h1>Backgrounds & Icons</h1>
                        <div className="container-kit">
                            <div className="pack">
                                <img src={pack1} alt="" />
                                <a className="iconx dw fa-solid fa-circle-down" href="/freebies/SmileyFaceBackground.rar" download></a>
                            </div>
                            <div className="pack">
                                <img src={pack2} alt="" />
                                <a className="iconx dw fa-solid fa-circle-down" href="/freebies/DaisyBackground.rar" download></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-2">
                <div className="container-mix">
                    <h1>Classnotes</h1>
                    <div className="container-classnotes">
                        <a className="classnotes-item" target="_blank" href="https://drive.google.com/file/d/1T4kF2p_Vio8_fclqC5yMecyWoPFxQkMj/view?usp=sharing">
                            <i class="fa-solid fa-book"></i><br />
                            ¿Qué es AJAX?
                        </a>
                        <a className="classnotes-item" target="_blank" href="https://drive.google.com/file/d/1T4kF2p_Vio8_fclqC5yMecyWoPFxQkMj/view?usp=sharing">
                            <i class="fa-solid fa-book"></i><br />
                            ¿Qué es AJAX?
                        </a>
                        <a className="classnotes-item" target="_blank" href="https://drive.google.com/file/d/1T4kF2p_Vio8_fclqC5yMecyWoPFxQkMj/view?usp=sharing">
                            <i class="fa-solid fa-book"></i><br />
                            ¿Qué es AJAX?
                        </a>
                        <a className="classnotes-item" target="_blank" href="https://drive.google.com/file/d/1T4kF2p_Vio8_fclqC5yMecyWoPFxQkMj/view?usp=sharing">
                            <i class="fa-solid fa-book"></i><br />
                            ¿Qué es AJAX?
                        </a>
                        <a className="classnotes-item" target="_blank" href="https://drive.google.com/file/d/1T4kF2p_Vio8_fclqC5yMecyWoPFxQkMj/view?usp=sharing">
                            <i class="fa-solid fa-book"></i><br />
                            ¿Qué es AJAX?
                        </a>
                        <a className="classnotes-item" target="_blank" href="https://drive.google.com/file/d/1T4kF2p_Vio8_fclqC5yMecyWoPFxQkMj/view?usp=sharing">
                            <i class="fa-solid fa-book"></i><br />
                            ¿Qué es AJAX?
                        </a>
                    </div>
                </div>
            </div>
        </div>
    )
}