import {Link} from "react-router-dom"
import { Helmet } from "react-helmet";
import Signature from "../../signature/Signature";
export default function Modelo_TCPIP() {

    return (
        <>
            <Helmet>
                <title>TBP | Modelo TCP/IP</title>
            </Helmet>
            <div className="container-3">
                <div className="main-container">
                    <div className="box-title">
                        <h1>Modelo TCP/IP</h1>
                    </div>
                    <div className="topic-links">
                        <div className="card-topic-link">
                            <Link to="/topics/classnotes">
                                <h4><i className="fa-sharp fa-solid fa-circle"></i>&nbsp;&nbsp;&nbsp;Classnotes</h4>
                            </Link>
                        </div>
                        <div className="card-topic-link">
                            <Link to="">
                            <h4><i className="fa-sharp fa-solid fa-circle"></i>&nbsp;&nbsp;&nbsp;Oct 29, 2022</h4>
                            </Link>
                        </div>
                    </div>
                    <div className="sub-container">
                        <p>El modelo TCP/IP (Protocolo de Control de Transmisión/Protocolo de Internet) es un modelo de red ampliamente utilizado que proporciona la base para la transmisión y recepción de datos a través de Internet. En este post, exploraremos el modelo TCP/IP y sus capas en detalle.</p>
                        <p>El modelo TCP/IP se compone de cuatro capas, cada una de las cuales tiene su propia función específica:</p>
                        <ol>
                            <li>Capa de acceso a la red: La capa de acceso a la red es responsable de proporcionar la conexión física entre el dispositivo y la red. Esta capa define las características físicas del medio, como los niveles de tensión, las frecuencias de señal y los esquemas de codificación. La capa de acceso a la red también proporciona el direccionamiento y el encuadre de los paquetes de datos.</li>
                            <li>Capa de Internet: La capa de Internet se encarga de encaminar los paquetes de datos entre distintas redes. Esta capa añade información de direccionamiento a los datos y selecciona la mejor ruta para que los datos viajen basándose en la topología de la red. La capa de Internet también se encarga de fragmentar y reensamblar los paquetes de datos.</li>
                            <li>Capa de transporte: La capa de transporte es responsable de garantizar que los datos se transmitan de forma fiable y eficiente entre dispositivos. Esta capa divide los datos en segmentos y añade información de secuenciación y recuperación de errores para garantizar que los datos se transmiten correctamente. La capa de transporte también define los protocolos utilizados para la comunicación, como TCP y UDP.</li>
                            <li>Capa de aplicación: La capa de aplicación es responsable de proporcionar servicios a las aplicaciones que se ejecutan en diferentes dispositivos. Esta capa define los protocolos y estándares utilizados por las aplicaciones para comunicarse entre sí. Algunos ejemplos de protocolos utilizados en la capa de aplicación son HTTP, FTP y SMTP.</li>
                        </ol>
                        <p>Una de las principales ventajas del modelo TCP/IP es que es un modelo escalable y flexible que puede adaptarse a diferentes topologías y configuraciones de red. También es un modelo ampliamente utilizado, lo que significa que es compatible con un gran número de dispositivos y aplicaciones de software.</p>
                        <p>En conclusión, el modelo TCP/IP es un concepto crucial en las redes informáticas que define cómo se transmiten y reciben los datos a través de Internet. Cada capa del modelo TCP/IP tiene una función específica, y al entender estas funciones, los administradores de red pueden solucionar mejor los problemas y mantener sus redes. El modelo TCP/IP es una herramienta importante para los ingenieros y técnicos de redes, y es un aspecto clave de las redes informáticas modernas.</p>
                    </div>
                    <Signature/>
                </div>
            </div>
        </>
    )
}