import {Link} from "react-router-dom";
import { Helmet } from 'react-helmet';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { paraisoLight } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import Signature from "../../signature/Signature";
import "../Posts.css";

export default function OperadoresJS() {
    const code01 = `const array1 = [1, 2, 3];
const array2 = [...array1, 4, 5, 6];`;
    return (
        <>
            <Helmet>
                <title>TBP | Operadores en JavaScript: Tipos, Comparaciones y Funciones Especiales</title>
            </Helmet>
            <div className="container-3">
                <div className="main-container">
                    <div className="box-title">
                        <h1>Operadores en JavaScript: Tipos, Comparaciones y Funciones Especiales</h1>
                    </div>
                    <div className="topic-links">
                        <div className="card-topic-link">
                            <Link to="/topics/javascript">
                                <h4><i className="fa-sharp fa-solid fa-circle"></i>&nbsp;&nbsp;&nbsp;JavaScript</h4>
                            </Link>
                        </div>
                        <div className="card-topic-link">
                            <Link to="">
                            <h4><i className="fa-sharp fa-solid fa-circle"></i>&nbsp;&nbsp;&nbsp;Aug 13, 2023</h4>
                            </Link>
                        </div>
                    </div>
                    <div className="sub-container">
                        <h2>Introducción</h2>
                        <p>En el vasto mundo de la programación en JavaScript, los operadores desempeñan un papel crucial. Son herramientas fundamentales que permiten realizar diversas operaciones en los datos, desde matemáticas hasta comparaciones y asignaciones. En este post, exploraremos los diferentes tipos de operadores en JavaScript, centrándonos en los operadores de comparación y abordando preguntas frecuentes sobre puntos suspensivos y su relación con Java.</p>
                        <h2>Tipos de operadores en JavaScript</h2>
                        <p>En JavaScript, los operadores se pueden clasificar en varios tipos, cada uno con un propósito específico. Aquí están los tipos más comunes:</p>
                        <h3>1. Operadores Aritméticos</h3>
                        <p>Estos operadores se utilizan para realizar operaciones matemáticas en variables. Algunos ejemplos son:</p>
                        <ul>
                            <li>Suma (+): a + b</li>
                            <li>Resta (-): a-b</li>
                            <li>Multiplicación (*): a * b</li>
                            <li>División (/): a / b</li>
                            <li>Módulo (%): a % b</li>
                        </ul>
                        <h3>2. Operadores de asignación</h3>
                        <p>Estos operadores se utilizan para asignar valores a variables. Un ejemplo es el operador de asignación básico (=): `let x = 10;`</p>
                        <h3>3. Operadores de comparación</h3>
                        <p>Se utilizan para comparar dos valores y devuelven un valor booleano (true o false). Ahora profundicemos más en esta categoría.</p>
                        <h2>Operadores de comparación en JavaScript</h2>
                        <p>Los operadores de comparación son esenciales para evaluar condiciones y tomar decisiones en un programa. Aquí hay algunos de los operadores de comparación más comunes en JavaScript:</p>
                        <h3>1. Igual (==)</h3>
                        <p>Comprueba si dos valores son iguales, realizando una conversión de tipo si es necesario.<br />Ejemplo: <b>`5 == "5"`</b> devuelve <b>`true`</b>.</p>
                        <h3>2. Estrictamente igual (===)</h3>
                        <p>Comprueba si dos valores son iguales y del mismo tipo.<br />Ejemplo: <b>`5 === "5"`</b> devuelve <b>`false`</b>.</p>
                        <h3>3. No igual (!=)</h3>
                        <p>Comprueba si dos valores no son iguales, realizando una conversión de tipo si es necesario. <br />Ejemplo: <b>`5 != "6"`</b> devuelve <b>`true`</b>.</p>
                        <h3>4. Estrictamente No igual (!==)</h3>
                        <p>Comprueba si dos valores no son iguales o no son del mismo tipo. <br />Ejemplo: <b>`5 !== "6"`</b> devuelve <b>`true`</b>.</p>
                        <h3>5. Mayor que (&#62;), menor que (&#60;), mayor o igual (&#62;=), menor o igual (&#60;=)</h3>
                        <p>Estos operadores comparan números y devuelven un valor booleano según la relación entre ellos.</p>
                        <h2>Los Tres Puntos en JavaScript (Spread Operator)</h2>
                        <p>Los tres puntos (...) en JavaScript se conocen como el "Spread Operator" o "Operador de Expansión". Se utiliza para descomponer elementos en arrays, objetos y funciones. Este operador facilita la creación de copias y combinaciones de datos.</p>
                        <div className="code-text">
                            <SyntaxHighlighter className="codes" language="javascript" style={paraisoLight} customStyle={{borderRadius: "1.5em", backgroundColor: "#f4e7e5", border: "2px solid #222222", padding: "2em"}}>
                            {code01}
                            </SyntaxHighlighter>
                        </div>
                        <h2>Operadores en Java</h2>
                        <p>En cuanto a Java, otro lenguaje de programación popular, también existen diversos tipos de operadores, incluyendo aritméticos, de asignación, de comparación, lógicos, entre otros. Aunque tiene similitudes con los operadores de JavaScript, Java tiene su propia sintaxis y particularidades.</p>
                        <hr />
                        <h2>Conclusión</h2>
                        <p>Los operadores en JavaScript son herramientas esenciales que permiten a los desarrolladores realizar diversas operaciones en sus programas. Los operadores de comparación son especialmente importantes para evaluar condiciones y tomar decisiones. Además, el Spread Operator proporciona una manera eficiente de manipular arrays, objetos y funciones. Aunque JavaScript y Java comparten algunos conceptos, es importante recordar que son lenguajes distintos con sus propias características únicas.</p>
                    </div>
                    <Signature/>
                </div>
            </div>
        </>
    )
}