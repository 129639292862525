import {Link} from "react-router-dom"
import { Helmet } from 'react-helmet';
import Signature from "../../signature/Signature";
import "../Posts.css"

export default function Styles() {
    return (
        <>
            <Helmet>
                <title>TBP | Añadir estilos a nuestro sitio web</title>
            </Helmet>
            <div className="container-3">
                <div className="main-container">
                    <div className="box-title">
                        <h1>Añadir estilos a nuestro sitio web: ¿Deberíamos usar los nuestros desde cero o adoptar frameworks?</h1>
                    </div>
                    <div className="topic-links">
                        <div className="card-topic-link">
                            <Link to="/topics/web-programming">
                                <h4><i className="fa-sharp fa-solid fa-circle"></i>&nbsp;&nbsp;&nbsp;Web Programming</h4>
                            </Link>
                        </div>
                        <div className="card-topic-link">
                            <Link to="">
                                <h4><i className="fa-sharp fa-solid fa-circle"></i>&nbsp;&nbsp;&nbsp;May 17, 2023</h4>
                            </Link>
                        </div>
                    </div>
                    <div className="sub-container">
                        <p>El estilo es un aspecto crucial del desarrollo web, ya que mejora el atractivo visual y la experiencia de usuario de un sitio web. A la hora de añadir estilos, los desarrolladores web se enfrentan a menudo a una decisión: ¿Deben crear sus propios estilos desde cero o aprovechar los frameworks existentes? En este post vveremos las ventajas y consideraciones de ambos enfoques, proporcionándote ideas para ayudarte a tomar una decisión informada para tus proyectos de desarrollo web.</p>
                        <h2>1. Crear estilos desde cero</h2>
                        <p>El desarrollo de estilos personalizados permite un control total sobre el diseño y la estética de su sitio web. Aquí, profundizamos en los beneficios y consideraciones de crear estilos desde cero:</p>
                        <ul>
                            <li><b>Flexibilidad y singularidad: </b>Al crear sus propios estilos, usted tiene la libertad de diseñar un sitio web que es verdaderamente único y adaptado a sus necesidades específicas. Puede experimentar con diferentes diseños, esquemas de color, tipografía y animaciones para crear una identidad visual distintiva.</li>
                            <li><b>Ligero y optimizado: </b>Las hojas de estilo personalizadas pueden optimizarse para mejorar el rendimiento, lo que garantiza una base de código más ligera y tiempos de carga más rápidos. Mediante la creación de estilos específicos para su proyecto, puede eliminar la hinchazón innecesaria y racionalizar el CSS, lo que resulta en un mejor rendimiento del sitio web.</li>
                            <li><b>Aprendizaje y crecimiento: </b>Crear estilos desde cero ofrece la oportunidad de mejorar tus conocimientos de CSS y profundizar en tu comprensión del lenguaje. Te permite explorar técnicas avanzadas, experimentar con nuevos conceptos y convertirte en un desarrollador front-end más competente.</li>
                        </ul>
                        <h2>2. Adoptar marcos de trabajo</h2>
                        <p>Los frameworks CSS, como Bootstrap, Foundation o Bulma, ofrecen estilos y componentes prediseñados que pueden agilizar considerablemente el proceso de desarrollo. Estas son las ventajas y consideraciones de utilizar frameworks:</p>
                        <ul>
                            <li><b>Desarrollo rápido: </b>Los frameworks proporcionan un conjunto de estilos, componentes y sistemas de diseño ya preparados que pueden acelerar el proceso de desarrollo. Ofrecen un enfoque estandarizado, lo que permite a los desarrolladores crear rápidamente sitios web adaptables y aptos para dispositivos móviles sin empezar desde cero.</li>
                            <li><b>Coherencia y capacidad de respuesta: </b>Los marcos CSS siguen las mejores prácticas establecidas y proporcionan estilos coherentes en diferentes dispositivos y navegadores. Ofrecen cuadrículas con capacidad de respuesta, clases predefinidas y estilos de utilidad que simplifican la creación de diseños con capacidad de respuesta, garantizando una experiencia fluida para los usuarios en distintos tamaños de pantalla.</li>
                            <li><b>Comunidad y soporte: </b>Los frameworks suelen contar con una gran comunidad de desarrolladores que contribuyen activamente a su mejora y proporcionan soporte a través de documentación, foros y tutoriales. Esta red de apoyo puede ser valiosa, especialmente para los desarrolladores que están empezando o trabajando en proyectos complejos.</li>
                        </ul>
                        <hr />
                        <h2>Conclusión</h2>
                        <p>La decisión de crear estilos desde cero o utilizar frameworks depende en última instancia de las necesidades y objetivos específicos de tu proyecto de desarrollo web. Ambos enfoques tienen sus ventajas, y la elección debe basarse en factores como la complejidad del proyecto, las limitaciones de tiempo, el nivel de personalización deseado y tu dominio de CSS.</p>
                        <p>Si buscas un control total, singularidad y una experiencia de aprendizaje profunda, crear tus propios estilos desde cero puede ser la ruta preferida. Por otro lado, si valoras el desarrollo rápido, la coherencia y la comodidad de los componentes prediseñados, adoptar marcos CSS puede agilizar significativamente tu flujo de trabajo.</p>
                        <p>Recuerda que no existe una solución única para todos. También vale la pena señalar que puedes encontrar un término medio utilizando un marco de trabajo como base y personalizándolo para adaptarlo a los requisitos específicos de tu proyecto.</p>
                        <p>En última instancia, tanto si decides empezar desde cero como si utilizas frameworks, la clave es dar prioridad a un código CSS limpio y bien organizado que garantice el mantenimiento y la escalabilidad a largo plazo. ¡Feliz diseño!</p>
                    </div>
                    <Signature/>
                </div>
            </div>
        </>
    )
}