import { Helmet } from "react-helmet";
import song06 from "../pix/music/song-06.jpg"
import song08 from "../pix/music/song-08.jpg"
import song09 from "../pix/music/song-09.jpg"
import song10 from "../pix/music/song-10.jpg"
import "./Currently_Listening.css";

export default function Currently_Listening() {
    return (
        <>
            <Helmet>
                <title>TBP | Currently Listening</title>
            </Helmet>
            <div className="container-2">
                <div className="container-mix">
                    <div className="box-title">
                        <h1>Currently Listening</h1>
                    </div>
                    <div className="music-container">
                        <iframe className="music-player" allow="autoplay *; encrypted-media *;" src="https://embed.music.apple.com/pe/playlist/currently-listening/pl.u-55D6XEVF8De983E" frameborder="0" ></iframe>
                        <div className="second-welcome">
                            <p>Música que actualmente estoy escuchando, suelo cambiar las canciones dependiendo del mood, mis favoritos que salieron este 2023 son:</p>
                            <ul>
                                <li>Shooting Start by XG</li>
                                <li>Left Right by XG</li>
                                <li>Me he pillao por ti by Ana Mena & Natalia Lacunza</li>
                                <li>I Am by IVE</li>
                                <li>Flower by Jisoo</li>
                                <li>Shoong! by Taeyang & Lisa</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-3">
                <div className="container-mix">
                    <div className="canciones-grid">
                        <div className="song-card">
                            <img src={song06} alt="Shooting Star by XG" />
                        </div>
                        <div className="song-card">
                            <img src={song08} alt="Me he pillao por ti by Ana Mena & Natalia Lacunza" />
                        </div>
                        <div className="song-card">
                            <img src={song09} alt="Kitsch by IVE" />
                        </div>
                        <div className="song-card">
                            <img src={song10} alt="Flower by Jisoo" />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}