import {Link} from "react-router-dom"
import { Helmet } from "react-helmet";
import innerjoin from "../../pix/classnotes/sql-joins/inner-join.png";
import leftjoin from "../../pix/classnotes/sql-joins/left-join.png";
import rightjoin from "../../pix/classnotes/sql-joins/right-join.png";
import fulljoin from "../../pix/classnotes/sql-joins/full-join.png";
import Signature from "../../signature/Signature";
export default function SQL_join() {

    return (
        <>
            <Helmet>
                <title>TBP | ¿Qué son SQL joins?</title>
            </Helmet>
            <div className="container-3">
                <div className="main-container">
                    <div className="box-title">
                        <h1>¿Qué son SQL joins?</h1>
                    </div>
                    <div className="topic-links">
                        <div className="card-topic-link">
                            <Link to="/topics/classnotes">
                                <h4><i className="fa-sharp fa-solid fa-circle"></i>&nbsp;&nbsp;&nbsp;Classnotes</h4>
                            </Link>
                        </div>
                        <div className="card-topic-link">
                            <Link to="">
                            <h4><i className="fa-sharp fa-solid fa-circle"></i>&nbsp;&nbsp;&nbsp;Aug 09, 2022</h4>
                            </Link>
                        </div>
                    </div>
                    <div className="sub-container">
                        <p>Las sentencias SQL joins nos permiten acceder a la información de dos o más tablas a la vez. También mantienen nuestra base de datos normalizada.</p>
                        <p>Los diferentes tipos de Joins son:</p>
                        <ul>
                            <li>INNER JOIN</li>
                            <li>LEFT JOIN</li>
                            <li>RIGHT JOIN</li>
                            <li>FULL JOIN</li>
                        </ul>
                        <h2>Inner join</h2>
                        <p>Devuelve el dataset que tienen valores coincidentes en ambas tablas.</p>
                        <div className="picxinpost">
                            <img src={innerjoin} alt="inner join" />
                        </div>
                        {/* <div className="codeblock">
                            <CopyBlock
                            language="sql"
                            text={`SELECT column_name(s)
    FROM tabla1
    INNER JOIN tabla2
    ON tabla1.column_name = tabla2.column_name;
    `}
                            codeBlock
                            theme={zenburn}
                            showLineNumbers={false}
                            />
                        </div> */}
                        <h2>Left join</h2>
                        <p>Devuelve todos los registros de la tabla izquierda y los registros coincidientes de la derecha.</p>
                        <div className="picxinpost">
                            <img src={leftjoin} alt="left join" />
                        </div>
                        {/* <div className="codeblock">
                            <CopyBlock
                            language="sql"
                            text={`SELECT column_name(s)
    FROM tabla1
    LEFT JOIN tabla2
    ON tabla1.column_name = tabla2.column_name;
    `}
                            codeBlock
                            theme={zenburn}
                            showLineNumbers={false}
                            />
                        </div> */}
                        <h2>right join</h2>
                        <p>Devuelve todos los registros de la tabla derecha y los registros coincidientes de la izquierda.</p>
                        <div className="picxinpost">
                            <img src={rightjoin} alt="right join" />
                        </div>
                        {/* <div className="codeblock">
                            <CopyBlock
                            language="sql"
                            text={`SELECT column_name(s)
    FROM tabla1
    RIGHT JOIN tabla2
    ON tabla1.column_name = tabla2.column_name;
    `}
                            codeBlock
                            theme={zenburn}
                            showLineNumbers={false}
                            />
                        </div> */}
                        <h2>full join</h2>
                        <p>Devuelve todos los registros en los que hay una coincidencia en la tabla izquierda o en la tabla derecha.</p>
                        <div className="picxinpost">
                            <img src={fulljoin} alt="full join" />
                        </div>
                        {/* <div className="codeblock">
                            <CopyBlock
                            language="sql"
                            text={`SELECT column_name(s)
    FROM tabla1
    FULL OUTER JOIN tabla2
    ON tabla1.column_name = tabla2.column_name;
    WHERE condition;`}
                            codeBlock
                            theme={zenburn}
                            showLineNumbers={false}
                            />
                        </div> */}
                    </div>
                    <Signature/>
                </div>
            </div>
        </>
    )
}