import {Link} from "react-router-dom"
import { Helmet } from "react-helmet";
import Signature from "../../signature/Signature";
import "../Posts.css"
export default function Cap2() {

    return (
        <>
            <Helmet>
                <title>TBP | Capítulo 02: Comentarios para Documentación en Java</title>
            </Helmet>
            <div className="container-3">
                <div className="main-container">
                    <div className="box-title">
                        <h1>Capítulo 02: Comentarios para Documentación en Java</h1>
                    </div>
                    <div className="topic-links">
                        <div className="card-topic-link">
                            <Link to="/topics/java">
                                <h4><i className="fa-sharp fa-solid fa-circle"></i>&nbsp;&nbsp;&nbsp;Java</h4>
                            </Link>
                        </div>
                        <div className="card-topic-link">
                            <Link to="">
                            <h4><i className="fa-sharp fa-solid fa-circle"></i>&nbsp;&nbsp;&nbsp;Feb 05, 2022</h4>
                            </Link>
                        </div>
                    </div>
                    <div className="sub-container">
                        <h2>Comentarios sobre Java</h2>
                        <p>Los comentarios de Java son las declaraciones que no son ejecutadas por el compilador y el intérprete. Los comentarios se pueden utilizar para proporcionar información o explicación sobre la variable, método, clase o cualquier declaración. También se puede utilizar para ocultar el código del programa durante un tiempo determinado.</p>
                        <h2>Comentarios de una sola línea en Java</h2>
                        <p>Los comentarios de una sola línea es usado para comentar una sola vez.</p>
                        <h3>Ejemplo</h3>
                        <p>Sintaxis:</p>
                        <p>// Este es un comentario de una sola línea</p>
                        {/* <div className="codeblock">
                            <CopyBlock
                            language="java"
                            text={`public class CommentSample {
                                public static void main(String[] args) {
                                    int i = 50; // La letra i es una variable
                                    System.out.print(i);
                                }
    }`}
                            codeBlock
                            theme={zenburn}
                            showLineNumbers={false}
                            />
                        </div> */}
                        <h2>Comentarios multilínea en Java</h2>
                        <p>Sintaxis:</p>
                        <p>/* <br />Este <br />es un <br />comentario <br />multilínea <br />*/</p>
                        <h3>Ejemplo</h3>
                        {/* <div className="codeblock">
                            <CopyBlock
                            language="java"
                            text={`public class CommentSample {
                                public static void main(String[] args) {
                                    
                                    int i = 50;
                                    System.out.print(i);
                                }
    }
    ---
    output: 50`}
                            codeBlock
                            theme={zenburn}
                            showLineNumbers={false}
                            />
                        </div> */}
                    </div>
                    <Signature/>
                </div>
            </div>
        </>
    )
}