import {Link} from "react-router-dom"
import { Helmet } from "react-helmet";
import Signature from "../../signature/Signature";
import pic1 from "../../pix/classnotes/cloud-computing/computing-resources.png"
import pic2 from "../../pix/classnotes/cloud-computing/CaaS.png"
import pic3 from "../../pix/classnotes/cloud-computing/service-models.png"
export default function CloudComputing() {

    return (
        <>
            <Helmet>
                <title>TBP | ¿Qué es Cloud Computing?</title>
            </Helmet>
            <div className="container-3">
                <div className="main-container">
                    <div className="box-title">
                        <h1>¿Qué es Cloud Computing?</h1>
                    </div>
                    <div className="topic-links">
                        <div className="card-topic-link">
                            <Link to="/topics/classnotes">
                                <h4><i className="fa-sharp fa-solid fa-circle"></i>&nbsp;&nbsp;&nbsp;Classnotes</h4>
                            </Link>
                        </div>
                        <div className="card-topic-link">
                            <Link to="">
                            <h4><i className="fa-sharp fa-solid fa-circle"></i>&nbsp;&nbsp;&nbsp;Feb 20, 2023</h4>
                            </Link>
                        </div>
                    </div>
                    <div className="sub-container">
                        <p>Cloud Computing también conocida como "la nube", es el suministro de recursos informáticos a la carta &#40;desde aplicaciones hasta centros de datos&#41; a través de Internet mediante un sistema de pago por uso.</p>
                        <p>Para comprender mejor la computación en nube, empecemos por el Instituto Nacional de Estándares y Tecnología de EE.UU. &#40;NIST&#41;.</p>
                        <p>El NIST define la computación en nube como un modelo que permite un acceso a la red cómodo y a la carta a un conjunto compartido de recursos informáticos configurables que pueden ser y liberados con un mínimo esfuerzo de gestión o interacción con el proveedor de servicios.</p>
                        <p>Algunos ejemplos de recursos informáticos son:</p>
                        <div className="pic-post">
                            <img src={pic1} alt="recursos informáticos" />
                        </div>
                        <ul>
                            <li>Redes</li>
                            <li>Servidores</li>
                            <li>Almacenamiento</li>
                            <li>Aplicaciones</li>
                            <li>Servicios</li>
                        </ul>
                        <h2>Cloud Model</h2>
                        <p>Este modelo de nube se compone de cinco características esenciales, tres modelos de despliegue y tres modelos de servicio.</p>
                        <h3>5 Características esenciales</h3>
                        <p>Empecemos por entender las cinco características esenciales de la nube que incluyen:</p>
                        <ul>
                            <li>Autoservicio bajo demanda &#40;On-demand Self-service&#41;</li>
                            <li>Amplio acceso a la red &#40;Broad Network Access&#41;</li>
                            <li>Agrupación de recursos &#40;Resourcing Pooling&#41;</li>
                            <li>Rápida elasticidad &#40;Rapid Elasticity&#41;</li>
                            <li>Servicio medido &#40;Measured Service&#41;</li>
                        </ul>
                        <h4>Autoservicio bajo demanda &#40;On-demand Self-Service&#41;</h4>
                        <p>La primera característica, significa que se accede a los recursos de la nube como la potencia de procesamiento, el almacenamiento y la red que necesita, mediante una interfaz sencilla, sin necesidad de interacción humana con cada proveedor de servicios.</p>
                        <h4>Amplio acceso a la red &#40;Broad Network Access&#41;</h4>
                        <p>La segunda característica, significa que se puede acceder a los recursos de computación en nube a través de la red mediante mecanismos y plataformas estándar como teléfonos móviles, tabletas, ordenadores portátiles y estaciones de trabajo.</p>
                        <h4>Agrupación de recursos &#40;Resourcing Pooling&#41;</h4>
                        <p>La tercera característica, es la que proporciona a los proveedores de servicios en nube economías de escala, que repercuten en sus clientes, haciendo que la nube sea rentable.</p>
                        <p>Utilizando un modelo multiarrendatario, los recursos informáticos se ponen en común para servir a múltiples consumidores. Los recursos de la nube se asignan y reasignan dinámicamente en función de la demanda, sin que los clientes tengan que preocuparse por la ubicación física de los servidores</p>
                        <h4>Rápida elasticidad &#40;Rapid Elasticity&#41;</h4>
                        <p>La cuarta característica, implica que se puede acceder a más recursos cuando los necesite, y reducirlos cuando no los necesite, porque los recursos se elásticos.</p>
                        <h4>Servicio medido &#40;Measured Service&#41;</h4>
                        <p>La quinta característica, significa que sólo paga por lo que utiliza o reserva sobre la marcha. Si no utiliza recursos, no paga.</p>
                        <p>El uso de los recursos se controla, mide y notifica de forma transparente en función de su utilización.</p>
                        <h5>CaaS &#40;Cloud Computing as a Service&#41;</h5>
                        <p>Como vemos, la computación en nube consiste realmente en utilizar la tecnología "como un servicio" aprovechando sistemas remotos bajo demanda a través de Internet, aumentando y reduciendo la escala y pagando por lo que se utiliza. y pagando por lo que se usa.</p>
                        <div className="pic-post">
                            <img src={pic2} alt="Cloud Computing as a Service" />
                        </div>
                        <p>Es una revolución porque ha cambiado la forma en que el mundo consume servicios informáticos haciéndolos más rentables y haciendo que las organizaciones respondan con mayor agilidad a los cambios de sus mercados. a los cambios en sus mercados.</p>
                        <h3>3 modelos de despliegue</h3>
                        <p>Existen tres tipos de modelos de despliegue de la nube: pública, privada e híbrida.</p>
                        <h4>Pública</h4>
                        <p>La nube pública es cuando se aprovechan los servicios de la nube a través de Internet abierto en hardware propiedad del proveedor de la nube, pero su uso es compartido por otras empresas.</p>
                        <h4>Privada</h4>
                        <p>Nube privada significa que la infraestructura de la nube se aprovisiona para uso exclusivo de una sola organización.</p>
                        <p>Puede funcionar in situ o ser propiedad de un proveedor de servicios, que también se encarga de gestionarla y explotarla.</p>
                        <h4>Híbrido</h4>
                        <p>Cuando se utiliza una mezcla de nubes públicas y privadas, trabajando juntas a la perfección, se clasifica como modelo híbrido.</p>
                        <h3>3 modelos de servicio</h3>
                        <p>Ahora los tres modelos de servicio que se basan en las tres capas de una pila informática: infraestructura, plataforma y aplicaciones.</p>
                        <div className="pic-post">
                            <img src={pic3} alt="modelos de servcio" />
                        </div>
                        <p>Estos modelos de computación en nube se denominan acertadamente Infraestructura como Servicio (Iaas), Plataforma como Servicio (PaaS) y Software como Servicio (SaaS).</p>
                        <h4>IaaS</h4>
                        <p>En un modelo de Infraestructura como Servicio, se obtiene acceso a la infraestructura y a los recursos informáticos físicos, como servidores, redes, almacenamiento y espacio en centros de datos, sin la necesidad de gestionarlos u operarlos.</p>
                        <h4>PaaS</h4>
                        <p>En un modelo de Plataforma como Servicio, se obtiene acceso a la plataforma, es decir, al hardware y a las herramientas de software, normalmente las necesarias para desarrollar y desplegar aplicaciones a los usuarios a través de usuarios a través de Internet.</p>
                        <h4>SaaS</h4>
                        <p>El software como servicio es un modelo de licencia y entrega de software en el que el software y las aplicaciones se alojan de forma centralizada y se licencian mediante suscripción "bajo demanda".</p>
                    </div>
                    <Signature/>
                </div>
            </div>
        </>
    )
}