import {Link} from "react-router-dom"
import { Helmet } from "react-helmet";
import Signature from "../../signature/Signature";
export default function Internet_Protocol() {

    return (
        <>
            <Helmet>
                <title>TBP | Protocolos de internet</title>
            </Helmet>
            <div className="container-3">
                <div className="main-container">
                    <div className="box-title">
                        <h1>Protocolos de internet</h1>
                    </div>
                    <div className="topic-links">
                        <div className="card-topic-link">
                            <Link to="/topics/classnotes">
                                <h4><i className="fa-sharp fa-solid fa-circle"></i>&nbsp;&nbsp;&nbsp;Classnotes</h4>
                            </Link>
                        </div>
                        <div className="card-topic-link">
                            <Link to="">
                            <h4><i className="fa-sharp fa-solid fa-circle"></i>&nbsp;&nbsp;&nbsp;Mar 31, 2023</h4>
                            </Link>
                        </div>
                    </div>
                    <div className="sub-container">
                        <p>Los protocolos de Internet son un conjunto de reglas y normas que rigen la comunicación entre dispositivos en Internet. Estos protocolos sientan las bases de la transmisión y recepción de datos en Internet. En este post, exploraremos algunos de los protocolos de Internet más importantes y sus características clave.</p>
                        <p>TCP/IP (Protocolo de Control de Transmisión/Protocolo de Internet) es la base de Internet y se encarga de garantizar que los datos se transmitan de forma fiable y precisa entre dispositivos. El TCP divide los datos en paquetes y garantiza que éstos se transmitan en el orden correcto y que los paquetes perdidos se retransmitan. IP se encarga de encaminar los paquetes a través de Internet hasta su destino final.</p>
                        <p>HTTP (Protocolo de Transferencia de Hipertexto) se utiliza para transferir datos entre servidores y navegadores web. Este protocolo define cómo se comunican entre sí los servidores web y los navegadores, y se utiliza para solicitar y transmitir páginas web, imágenes y otros contenidos.</p>
                        <p>HTTPS (Hypertext Transfer Protocol Secure) es una versión más segura de HTTP que cifra los datos para protegerlos de la interceptación por piratas informáticos y otros agentes malintencionados. HTTPS se utiliza para transmitir datos sensibles como contraseñas, números de tarjetas de crédito y otra información personal.</p>
                        <p>FTP (Protocolo de Transferencia de Archivos) se utiliza para transferir archivos entre dispositivos en Internet. El FTP se utiliza habitualmente para cargar y descargar archivos a y desde servidores web, y es un método popular para el mantenimiento y las actualizaciones de sitios web.</p>
                        <p>SMTP (Simple Mail Transfer Protocol) se utiliza para transmitir mensajes de correo electrónico a través de Internet. SMTP define cómo se transmiten los mensajes de correo electrónico entre servidores de correo electrónico, y es responsable de entregar los mensajes a su destino final.</p>
                        <p>POP3 (Post Office Protocol 3) e IMAP (Internet Message Access Protocol) son protocolos utilizados para recibir mensajes de correo electrónico. POP3 es un protocolo sencillo que descarga mensajes de correo electrónico en un dispositivo local, mientras que IMAP permite a los usuarios acceder a mensajes de correo electrónico almacenados en un servidor remoto.</p>
                        <p>DNS (Domain Name System) se utiliza para traducir nombres de dominio en direcciones IP. El DNS es responsable de resolver nombres de dominio como google.com en las correspondientes direcciones IP que los dispositivos utilizan para conectarse a los servidores web.</p>
                        <p>En conclusión, los protocolos de internet son un aspecto crucial de internet que definen cómo se transmiten y reciben los datos entre dispositivos. TCP/IP, HTTP, HTTPS, FTP, SMTP, POP3, IMAP y DNS son sólo algunos de los muchos protocolos de Internet que se utilizan a diario para hacer funcionar Internet. Al comprender estos protocolos y sus características clave, podemos entender mejor cómo funciona Internet y cómo construir y mantener sitios web y otros servicios en línea.</p>
                    </div>
                    <Signature/>
                </div>
            </div>
        </>
    )
}