import {Link} from "react-router-dom"
import { Helmet } from "react-helmet";
import pic1 from "../../pix/ics/computer-science/cs-1.PNG";
import pic2 from "../../pix/ics/computer-science/cs-2.PNG";
import pic3 from "../../pix/ics/computer-science/cs-3.PNG";
import pic4 from "../../pix/ics/computer-science/cs-4.PNG";
import pic5 from "../../pix/ics/computer-science/cs-5.jpg";
import pic6 from "../../pix/ics/computer-science/cs-6.png";
import pic7 from "../../pix/ics/computer-science/cs-7.PNG";
import pic8 from "../../pix/ics/computer-science/cs-8.PNG";
import pic9 from "../../pix/ics/computer-science/cs-9.PNG";
import pic10 from "../../pix/ics/computer-science/cs-10.PNG";
import pic11 from "../../pix/ics/computer-science/cs-11.PNG";
import Signature from "../../signature/Signature";
import "../Posts.css"
export default function Computer_Science() {

    return (
        <>
            <Helmet>
                <title>TBP | ¿Qué es Computer Science?</title>
            </Helmet>
            <div className="container-3">
                <div className="main-container">
                    <div className="box-title">
                        <h1>¿Qué es Computer Science?</h1>
                    </div>
                    <div className="topic-links">
                        <div className="card-topic-link">
                            <Link to="/topics/computer-science">
                                <h4><i className="fa-sharp fa-solid fa-circle"></i>&nbsp;&nbsp;&nbsp;Computer Science</h4>
                            </Link>
                        </div>
                        <div className="card-topic-link">
                            <Link to="">
                            <h4><i className="fa-sharp fa-solid fa-circle"></i>&nbsp;&nbsp;&nbsp;Jan 25, 2021</h4>
                            </Link>
                        </div>
                    </div>
                    <div className="sub-container">
                        <p>La informática es fundamentalmente la resolución de problemas.
                        Podemos pensar en la resolución de problemas como el proceso de tomar algunas aportaciones (detalles sobre nuestro problema) y generar algo de salida (la solución a nuestro problema). La "caja negra" en el medio es la informática.</p>
                        <div className="picsminpost">
                            <img className="" src={pic1} alt="" />
                        </div>
                        <p>Necesitamos una manera de representar las entradas, de tal manera que podamos almacenar y trabajar con información de una manera estándar.</p>
                        <h2>Binary</h2>
                        <p>Una computadora, en el nivel más bajo, almacena datos en binario, un sistema de numeración en el que solo hay dos dígitos, 0 y 1.</p>
                        <p>Cuando aprendimos a contar por primera vez, podríamos haber usado un dedo para representar una cosa. Ese sistema se llama unario. Cuando aprendimos a escribir números con los dígitos del 0 al 9, aprendimos a usar el decimal.</p>
                        <p>Por ejemplo, sabemos que lo siguiente representa ciento veintitrés.</p>
                        <p>1 2 3</p>
                        <p>El 3 está en la columna de las unidades, el 2 está en la columna de las decenas y el 1 está en la columna de las centenas.</p>
                        <p>Entonces 123 es 100 × 1 + 10 × 2 + 1 × 3 = 100 + 20 + 3 = 123.</p>
                        <p>Cada lugar para un dígito representa una potencia de diez, ya que hay diez dígitos posibles para cada lugar.</p>
                        <p>En binario, con solo dos dígitos, tenemos potencias de dos para cada valor posicional:</p>
                        <p>4 2 1</p>
                        <p><b>0 0 0</b></p>
                        <p>Esto todavía sería igual a 0.</p>
                        <p>Ahora, si cambiamos el valor binario a, digamos 0 1 1, el valor decimal sería 3.</p>
                        <p>4 2 1</p>
                        <p><b>0 1 1</b></p>
                        <p>Si quisiéramos representar 8, necesitaríamos otro dígito:</p>
                        <p>8 4 2 1</p>
                        <p><b>1 0 0 0</b></p>
                        <p>Y el binario tiene sentido para las computadoras porque las alimentamos con electricidad, que puede estar encendida o apagada, por lo que cada bit solo necesita estar encendido o apagado. En una computadora, hay millones o miles de millones de interruptores llamados transistores que pueden almacenar electricidad y representar un poco al estar "encendidos" o "apagados".</p>
                        <p>Con suficientes bits o dígitos binarios, las computadoras pueden contar hasta cualquier número.</p>
                        <p>8 bits forman un byte.</p>
                        <h2>Representing Data</h2>
                        <p>Para representar letras, todo lo que tenemos que hacer es decidir cómo se asignan los números a las letras. Algunos humanos, hace muchos años, decidieron colectivamente un mapeo estándar llamado ASCII . La letra "A", por ejemplo, es el número 65, y "B" es 66, y así sucesivamente. El mapeo también incluye signos de puntuación y otros símbolos. Otros caracteres, como letras con acento y emoji, son parte de un estándar llamado Unicode que usa más bits que ASCII para acomodar todos estos caracteres.</p>
                        <p>Cuando recibimos un emoji, nuestra computadora en realidad solo recibe un número decimal como 128514 ( 11111011000000010 en binario, si puede leerlo más fácilmente) que luego se asigna a la imagen del emoji.</p>
                        <p>Una imagen también se compone de muchos puntos cuadrados más pequeños, o píxeles, cada uno de los cuales se puede representar en binario con un sistema llamado RGB, con valores de luz roja, verde y azul en cada píxel. Al mezclar diferentes cantidades de cada color, podemos representar millones de colores:</p>
                        <div className="picsminpost">
                            <img className="" src={pic2} alt="" />
                        </div>
                        <p>Los valores rojo, verde y azul se combinan para obtener un color amarillo claro:</p>
                        <div className="picsminpost">
                            <img className="" src={pic3} alt="" />
                        </div>
                        <p>Podemos ver esto en un emoji si nos acercamos lo suficiente:</p>
                        <div className="picsminpost">
                            <img className="" src={pic4} alt="" />
                        </div>
                        <h2>Algorithms</h2>
                        <p>Entonces ahora podemos representar entradas y salidas. El cuadro negro anterior contendrá algoritmos, instrucciones paso a paso para resolver un problema:</p>
                        <div className="picsminpost">
                            <img className="" src={pic5} alt="" />
                        </div>
                        <p>Digamos que queríamos encontrar a un amigo, Mike Smith, en una guía telefónica.</p>
                        <p>Podríamos comenzar hojeando el libro, una página a la vez, hasta encontrar a Mike Smith o llegar al final del libro.</p>
                        <p>También podríamos pasar dos páginas a la vez, pero si vamos demasiado lejos, tendremos que saber volver atrás.</p>
                        <p>Pero una forma aún más eficiente sería abrir la guía telefónica en el medio, decidir si Mike estará en la mitad izquierda o la mitad derecha del libro (porque el libro está alfabetizado) e inmediatamente tirar la mitad del problema. Podemos repetir esto, dividiendo el problema a la mitad cada vez. Con 1024 páginas para comenzar, solo necesitaríamos 10 pasos de dividir por la mitad antes de que solo nos quede una página para verificar.</p>
                        <p>De hecho, podemos representar la eficiencia de cada uno de esos algoritmos con un gráfico:</p>
                        <div className="picsminpost">
                            <img className="" src={pic6} alt="" />
                        </div>
                        <p>Nuestra primera solución, una página a la vez, es como la línea roja: nuestro tiempo para resolver aumenta linealmente a medida que aumenta el tamaño del problema.</p>
                        <p>La segunda solución, dos páginas a la vez, es como la línea amarilla: nuestra pendiente es menos empinada, pero aún lineal.</p>
                        <p>Nuestra solución final es como la línea verde: logarítmica, ya que nuestro tiempo para resolver aumenta cada vez más lentamente a medida que aumenta el tamaño del problema. En otras palabras, si la guía telefónica pasara de 1000 a 2000 páginas, necesitaríamos un paso más para encontrar a Mike. Si el tamaño se duplicara nuevamente de 2000 a 4000 páginas, solo necesitaríamos un paso más.</p>
                        <h2>Pseudocode</h2>
                        <p>Podemos escribir pseudocódigo , una sintaxis informal que es solo una versión más específica del inglés (u otro lenguaje humano) que representa nuestro algoritmo:</p>
                        <div className="picsminpost">
                            <img className="" src={pic7} alt="" />
                        </div>
                        <p>Algunas de estas líneas comienzan con verbos o acciones. Comenzaremos llamando a estas funciones:</p>
                        <div className="picsminpost">
                            <img className="" src={pic8} alt="" />
                        </div>
                        <p>También tenemos sucursales que conducen a diferentes caminos, como bifurcaciones en el camino, que llamaremos condiciones:</p>
                        <div className="picsminpost">
                            <img className="" src={pic9} alt="" />
                        </div>
                        <p>Y las preguntas que deciden a dónde vamos se llaman expresiones booleanas , que finalmente resultan en un valor de verdadero o falso:</p>
                        <div className="picsminpost">
                            <img className="" src={pic10} alt="" />
                        </div>
                        <p>Finalmente, tenemos palabras que conducen a ciclos, donde podemos repetir partes de nuestro programa, llamadas bucles:</p>
                        <div className="picsminpost">
                            <img className="" src={pic11} alt="" />
                        </div>
                    </div>
                    <Signature/>
                </div>
            </div>
        </>
    )
}