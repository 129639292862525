import React from "react";
import {Link} from "react-router-dom";
import { Helmet } from "react-helmet"
import Signature from "../../signature/Signature";
import "../Posts.css"

export default function JavaScript_QueEs() {
    return (
        <>
            <Helmet>
                <title>TBP | ¿Que es JavaScript?</title>
            </Helmet>
            <div className="container-3">
                <div className="main-container">
                    <div className="box-title">
                        <h1>¿Qué es JavaScript?</h1>
                    </div>
                    <div className="topic-links">
                        <div className="card-topic-link">
                            <Link to="/topics/javascript">
                                <h4><i className="fa-sharp fa-solid fa-circle"></i>&nbsp;&nbsp;&nbsp;JavaScript</h4>
                            </Link>
                        </div>
                        <div className="card-topic-link">
                            <Link to="">
                            <h4><i className="fa-sharp fa-solid fa-circle"></i>&nbsp;&nbsp;&nbsp;Jan 26, 2022</h4>
                            </Link>
                        </div>
                    </div>
                    <div className="sub-container">
                        <p>JavaScript es un lenguaje de programación inicialmente diseñado para interactuar con elementos de páginas web. En los navegadores web, JavaScript consta de tres partes principales:</p>
                        <div>
                            <ul>
                                <li>ECMAScript proporciona la funcionalidad principal.</li>
                                <li>El modelo de objeto de documento (DOM)  proporciona interfaces para interactuar con elementos en páginas web.</li>
                                <li>El modelo de objetos del navegador (BOM) proporciona la API del navegador para interactuar con el navegador web.</li>
                            </ul>
                        </div>
                        <p>JavaScript le permite agregar interactividad a una página web. Por lo general, utiliza JavaScript con HTML y CSS para mejorar la funcionalidad de una página web, como validar formularios , crear mapas interactivos y mostrar gráficos animados.</p>
                        <p>Cuando se carga una página web, es decir, después de que se hayan descargado HTML y CSS, el motor JavaScript del navegador web ejecuta el código JavaScript. Luego, el código JavaScript modifica el HTML y el CSS para actualizar la interfaz de usuario de forma dinámica.</p>
                        <p>El motor JavaScript es un programa que ejecuta código JavaScript. Al principio, los motores de JavaScript se implementaron como intérpretes.</p>
                        <p>Sin embargo, los motores de JavaScript modernos generalmente se implementan como compiladores justo a tiempo que compilan código de JavaScript en código de bytes para mejorar el rendimiento.</p>
                        <h2>JavaScript del lado del cliente vs del lado del servidor</h2>
                        <p>Cuando se utiliza JavaScript en una página web, se ejecuta en los navegadores web. En este caso, JavaScript funciona como un lenguaje del lado del cliente.</p>
                        <p>JavaScript puede ejecutarse tanto en navegadores web como en servidores. Un entorno popular del lado del servidor de JavaScript es Node.js. A diferencia del JavaScript del lado del cliente, el JavaScript del lado del servidor se ejecuta en el servidor que le permite acceder a bases de datos, sistemas de archivos, etc.</p>
                        <h2>Historia de JavaScript</h2>
                        <p>En 1995, JavaScript fue creado por un desarrollador de Netscape llamado Brendan Eich . Primero, su nombre era Mocha. Y luego, su nombre fue cambiado a LiveScript.</p>
                        <p>Netscape decidió cambiar LiveScript a JavaScript para aprovechar la fama de Java, que era popular. La decisión se tomó justo antes de que Netscape lanzara su navegador web Netscape Navigator 2. Como resultado, JavaScript ingresó a la versión 1.0.</p>
                        <p>Netscape lanzó JavaScript 1.1 en Netscape Navigator 3. Mientras tanto, Microsoft presentó un navegador web llamado Internet Explorer 3 (IE 3), que competía con Netscape. Sin embargo, IE vino con su propia implementación de JavaScript llamada JScript . Microsoft usó el nombre JScript para evitar posibles problemas de licencia con Netscape.</p>
                        <p>Por lo tanto, dos versiones diferentes de JavaScript estaban en el mercado:</p>
                        <div className="unordered-list">
                            <ul>
                                <li>JavaScript en el navegador de Netscape</li>
                                <li>JavaScript en Internet Explorer</li>
                            </ul>
                        </div>
                        <p>JavaScript no tenía estándares que rigieran su sintaxis y características. Y la comunidad decidió que era hora de estandarizar el lenguaje.</p>
                        <p>En 1997, JavaScript 1.1 se presentó a la Asociación Europea de Fabricantes de Ordenadores (ECMA) como propuesta. Se asignó al Comité Técnico n.º 39 (TC39) para estandarizar el lenguaje a fin de convertirlo en un lenguaje de secuencias de comandos de propósito general, multiplataforma y neutral para el proveedor.</p>
                        <p>TC39 ideó ECMA-262, un estándar para definir un nuevo lenguaje de secuencias de comandos llamado ECMAScript (a menudo pronunciado Ek-ma-script).</p>
                        <p>Después de eso, la Organización Internacional de Normalización y las Comisiones Electrotécnicas Internacionales (ISO/IEC) adoptaron ECMAScript (ISO/IEC-16262).</p>
                        <h2>Descripción general de JavaScript</h2>
                        <p>Para definir una variable en JavaScript, se usa "var". Por ejemplo</p>
                        <pre className="codes">
                            <code>var x = 10; <br />
                                var y = 20;
                            </code>
                        </pre>
                        <p>ES6 agregó una nueva forma de declarar una variable con "let":</p>
                        <pre className="codes">
                            <code>let x = 10; <br />
                                let y = 20;
                            </code>
                        </pre>
                        <p>Hay diferencias entre "var" y "let" . Y es una buena práctica usar let para declarar variables.</p>
                        <p>Para declarar una función , utilizar "function". El siguiente ejemplo define una función que calcula la suma de dos argumentos:</p>
                        <pre className="codes">
                            <code>function add(a, b) <br />
                                <span/>    return a + b;
                            </code>
                        </pre>
                        <p>Para llamar a la función "add()", se utiliza la siguiente sintaxis:</p>
                        <pre className="codes">
                            <code>let result = add(x, y);
                            </code>
                        </pre>
                        <p>Para registrar el resultado en la ventana de la consola del navegador web, se utiliza "console.log():"</p>
                        <pre className="codes">
                            <code>console.log(result);
                            </code>
                        </pre>
                        <p>Ahora, se debería ver 30 en la ventana de la consola.</p>
                        <p>JavaScript le proporciona declaraciones de condición como "if-else" y  "switch".</p>
                        <p>En la función "divide()", verifica si el numerador (b) es cero. Si es así, se lanza una excepción. De lo contrario, se devuelve el resultado de a/b.</p>
                        <pre className="codes">
                            <code>let items = [];
                            </code>
                        </pre>
                        <p>Para declarar una matriz con algunos elementos iniciales, se especifica los elementos entre corchetes:</p>
                        <pre className="codes">
                            <code>let items = [a, 2, 3];
                            </code>
                        </pre>
                        <p>Puede acceder a la cantidad de los elementos en items de la matriz a través de su propiedad "length":</p>
                        <pre className="codes">
                            <code>console.log(items.length); // 3
                            </code>
                        </pre>
                        <p>Para iterar sobre los elementos de la matriz "items", se utiliza la declaración de bucle "for".</p>
                        <p>O usar el bucle for...of en ES6:</p>
                        <p>JavaScript es un lenguaje en evolución. Tiene muchas otras características que se pueden ir aprendiendo.</p>
                    </div>
                    <Signature/>
                </div>
            </div>
        </>
    )
}