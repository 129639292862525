import "./FootBar.css";
import {Link} from "react-router-dom";
import pic1 from "../pix/bottom/radio-1.jpg";
import pic2 from "../pix/bottom/book-2.jpg";
import pic3 from "../pix/bottom/book-1.jpg";
import pic6 from "../pix/bottom/radio-2.jpg";

export default function FootBar() {
    var today = new Date();
    var year = today.getFullYear();
    return (
        <div className="foot-bar">
            <hr />
            <div className="foot-links">
                <Link className="link" to={`/`}>
                    <div className="foot-link-item">Home</div>
                </Link>
                <Link className="link" to={`/about`}>
                    <div className="foot-link-item">About</div>
                </Link>
                <Link className="link" to={`/radio`}>
                    <div className="foot-link-item">Radio</div>
                </Link>
                <Link className="link" to={`/topics`}>
                    <div className="foot-link-item">Topics</div>
                </Link>
                <Link className="link" to={`/freebies`}>
                    <div className="foot-link-item">Freebies</div>
                </Link>
                <Link className="link" to={`/books`}>
                    <div className="foot-link-item">Books</div>
                </Link>
            </div>
            <hr />
            <div className="bottom-container">
                <div className="instafeed-container">
                    <img className="" src={pic1} alt="Radio" />
                    <img className="" src={pic2} alt="De sangre y cenizas" />
                    <img className="" src={pic3} alt="De sangre y cenizas" />
                    <img className="" src={pic6} alt="Radio" />
                </div>
                <div className="bottom">
                    <div className="copyr">
                        <h3>The Blink Panda © {year}</h3>
                    </div>
                </div>
            </div>
        </div>
    )
}