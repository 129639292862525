import {Link} from "react-router-dom"
import { Helmet } from "react-helmet";
import Signature from "../../signature/Signature";
export default function Frontend() {

    return (
        <>
            <Helmet>
                <title>TBP | Preguntas Esenciales de Front-End</title>
            </Helmet>
            <div className="container-3">
                <div className="main-container">
                    <div className="box-title">
                        <h1>Preguntas Esenciales de Front-End</h1>
                    </div>
                    <div className="topic-links">
                        <div className="card-topic-link">
                            <Link to="/topics/classnotes">
                                <h4><i className="fa-sharp fa-solid fa-circle"></i>&nbsp;&nbsp;&nbsp;Classnotes</h4>
                            </Link>
                        </div>
                        <div className="card-topic-link">
                            <Link to="">
                            <h4><i className="fa-sharp fa-solid fa-circle"></i>&nbsp;&nbsp;&nbsp;Apr 012, 2022</h4>
                            </Link>
                        </div>
                    </div>
                    <div className="sub-container">
                        <h2>¿Por qué se utilizan doctypes en HTML?</h2>
                        <p>Los doctypes de HTML no son elementos o etiquetas, sino una declaración de tipo de documento. Este documento se utiliza para indicar al navegador web la versión de HTML utilizada o sobre cualquier otro lenguaje de marcado que se utilice en la página actual.</p>
                        <h2>¿Cuáles son las distintas formas de obtener elementos del dom?</h2>
                        <ul>
                            <li>getelementsbyid</li>
                            <li>getelementsbyclassname</li>
                            <li>getelementsbytagname</li>
                            <li>queryselector</li>
                            <li>queryselectorall</li>
                        </ul>
                        <h2>¿Qué es HTML semántico?</h2>
                        <p>El HTML semántico o semánticamente correcto se utiliza para estructurar el contenido de forma adecuada para que se muestre correctamente.</p>
                        <p>La estructura del contenido es realmente importante ya que es lo que hace que las páginas sean legibles. Además, un contenido correctamente estructurado ayuda a los motores de búsqueda a entender mejor su página y esto hace que se posicione mejor.</p>
                        <h2>Enumerar las ventajas de usar preprocesadores CSS</h2>
                        <ol>
                            <li>Los preprocesadores CSS facilitan la adición de variables y funciones aumentando la reutilización del código, lo que facilita el desarrollo.</li>
                            <li>Los preprocesadores CSS facilitan la gestión del código, ya que permiten agrupar los elementos padre e hijo en bloques modulares.</li>
                            <li>Se pueden unir varias hojas de estilo en una sola utilizando los preprocesadores CSS. Una vez hecho esto, sólo hay que importar los archivos principales al sitio web; esto reduce significativamente el número de llamadas al servidor para los archivos CSS.</li>
                        </ol>
                        <h2>Enumera algunos casos de uso comunes para las funciones anónimas</h2>
                        <ol>
                            <li>Se utilizan como argumentos en otras funciones.</li>
                            <li>Se utilizan para crear e invocar expresiones de funciones inmediatamente invocables (iife)</li>
                        </ol>
                        <h2>Describir sessionstorage, localstorage</h2>
                        <p><b>Almacenamiento de sesión: </b>Como su nombre indica, estos datos se almacenan hasta que se cierra la sesión o la pestaña, sin embargo, no se borran durante las recargas.</p>
                        <p><b>Almacenamiento local: </b>Estos datos se almacenan en el ordenador del cliente. Estos datos no tienen caducidad, sin embargo, están limitados sólo a datos de cadena. Se puede acceder a los datos locales mediante JavaScript y HTML, pero no se pueden transferir al servidor.</p>
                        <h2>¿Qué son las funciones de orden superior?</h2>
                        <p>Las funciones de orden superior son funciones que operan sobre otras funciones. Estas funciones toman otras funciones como argumentos o las devuelven, por lo que se denominan funciones de orden superior.</p>
                        <h2>¿Qué son las pseudoclases? Proporcione algunos casos de uso en el mundo real</h2>
                        <p>En CSS, una pseudoclase se utiliza para especificar un estado especial para un elemento. En base a este estado se pueden aplicar diferentes estilos.</p>
                        <p>Algunos casos de uso de las pseudoclases son:</p>
                        <ul>
                            <li>Cambiar el color de un elemento cuando el usuario pasa por encima.</li>
                            <li>Estilizar un botón cuando se hace clic en él.</li>
                        </ul>
                    </div>
                    <Signature/>
                </div>
            </div>
        </>
    )
}