import {Link} from "react-router-dom";
import { Helmet } from 'react-helmet';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { paraisoLight } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import Signature from "../../signature/Signature";
import "../Posts.css";

export default function DominandoHooks() {
    const code01 = `import React, { useState, useEffect } from 'react';

function App() {
    const [data, setData] = useState(null);
    
    useEffect(() => {
        fetch('https://api.example.com/data')
            .then(response => response.json())
            .then(data => setData(data));
    }, []);
    
    return (
        <div>
            {data ? <p>{data.message}</p> : <p>Cargando...</p>}
        </div>
    );
}
    
export default App;`;
    return (
        <>
            <Helmet>
                <title>TBP | Dominando Hooks en React para potenciar tus componentes</title>
            </Helmet>
            <div className="container-3">
                <div className="main-container">
                    <div className="box-title">
                        <h1>Dominando Hooks en React para potenciar tus componentes</h1>
                    </div>
                    <div className="topic-links">
                        <div className="card-topic-link">
                            <Link to="/topics/javascript">
                                <h4><i className="fa-sharp fa-solid fa-circle"></i>&nbsp;&nbsp;&nbsp;JavaScript</h4>
                            </Link>
                        </div>
                        <div className="card-topic-link">
                            <Link to="">
                            <h4><i className="fa-sharp fa-solid fa-circle"></i>&nbsp;&nbsp;&nbsp;Aug 13, 2023</h4>
                            </Link>
                        </div>
                    </div>
                    <div className="sub-container">
                        <h2>Introducción</h2>
                        <p>React, como biblioteca de JavaScript para construir interfaces de usuario, ha evolucionado con el tiempo para brindar a los desarrolladores herramientas más poderosas y eficientes. Una de estas mejoras es la introducción de los hooks, una forma elegante de administrar el estado y el ciclo de vida en componentes funcionales. En este post, exploraremos los conceptos fundamentales de los hooks en React, cuándo y cómo usarlos, y profundizaremos en el hook useEffect para un control avanzado del ciclo de vida.</p>
                        <h2>¿Cuáles son los Hooks de React?</h2>
                        <p>Los hooks son funciones especiales proporcionadas por React que permiten a los desarrolladores "engancharse" o conectarse a características de React como el estado, el ciclo de vida y el contexto, en componentes funcionales. Antes de la introducción de los hooks, estos aspectos solo eran accesibles en componentes de clase.</p>
                        <h2>¿Cuántos Hooks hay en React?</h2>
                        <p>React proporciona varios hooks, cada uno con una función específica. Algunos de los hooks más comunes son:</p>
                        <ol>
                            <li><b>useState: </b>Para agregar estado a componentes funcionales.</li>
                            <li><b>useEffect: </b>Para ejecutar efectos secundarios en el componente.</li>
                            <li><b>useContext: </b>Para acceder al contexto de React.</li>
                            <li><b>useReducer: </b>Alternativa a useState para administrar estados más complejos.</li>
                            <li><b>useCallback y useMemo: </b>Optimización de rendimiento al memorizar funciones y valores calculados.</li>
                            <li><b>useRef: </b>Para acceder al DOM o mantener valores entre renderizados.</li>
                            <li><b>useLayoutEffect: </b> Similar a useEffect, pero se ejecuta de manera síncrona después de todas las mutaciones del DOM.</li>
                        </ol>
                        <h2>¿Cuándo usar un Hook?</h2>
                        <p>Los hooks son especialmente útiles cuando trabajas con componentes funcionales y necesitas acceso a características como el estado o el ciclo de vida. Siempre que estés creando un componente funcional y sientas la necesidad de una funcionalidad que solía estar disponible solo en componentes de clase, es probable que un hook sea la solución.</p>
                        <h2>¿Qué hace el Hook useEffect?</h2>
                        <p>El hook useEffect es uno de los más poderosos en React, ya que permite ejecutar efectos secundarios en tu componente funcional. Los efectos secundarios pueden incluir solicitudes de red, manipulación del DOM, suscripciones a eventos y más. El hook useEffect se ejecuta después de cada renderizado y tiene la capacidad de manejar limpieza cuando el componente se desmonta.</p>
                        <h5>Ejemplo: Usando el Hook useEffect</h5>
                        <p>Aquí tienes un ejemplo básico de cómo usar el hook useEffect para realizar una solicitud de red:</p>
                        <div className="code-text">
                            <SyntaxHighlighter className="codes" language="jsx" style={paraisoLight} customStyle={{borderRadius: "1.5em", backgroundColor: "#f4e7e5", border: "2px solid #222222", padding: "2em"}}>
                            {code01}
                            </SyntaxHighlighter>
                        </div>
                        <hr />
                        <h2>Conclusión</h2>
                        <p>Los hooks han revolucionado la forma en que construimos componentes en React, permitiendo un enfoque más funcional y simplificado para administrar el estado y el ciclo de vida. Con hooks como useState y useEffect, puedes crear componentes funcionales potentes y eficientes que son fáciles de leer y mantener. A medida que continúas explorando React y su ecosistema, los hooks se convertirán en una herramienta esencial para llevar tus habilidades de desarrollo al siguiente nivel.</p>
                    </div>
                    <Signature/>
                </div>
            </div>
        </>
    )
}