import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import './Radio.css';

export default function Radio() {
    const [data, setData] = useState([]);
    const getData=()=>{
        fetch('../topic-radio.json',
        {
        headers : { 
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        }
        }
        )
        .then(function(response){
            console.log(response)
            return response.json();
        })
        .then(function(myJson) {
            console.log(myJson);
            setData(myJson)
        });
    }
    useEffect(()=>{
        getData()
    },[])

    return (
        <div>
            <Helmet>
                <title>TBP | Radio</title>
            </Helmet>
            <div className="container-3">
                <div className="container-mix">
                    <div className="box-title">
                        <h1>Radio</h1>
                    </div>
                    <div className="subcontainer">
                        <div className="grid-radio">
                        {data.map(item => ( 
                            <div className="card-radio" key={item.id}>
                                <img src={`${process.env.PUBLIC_URL}/radio/${item.id}`} alt="" />
                            </div>
                        ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}