import {Link} from "react-router-dom";
import { Helmet } from 'react-helmet';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { paraisoLight } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import Signature from "../../signature/Signature";
import "../Posts.css";

export default function NodeJSP() {
    const code01 = `const http = require('http');

const server = http.createServer((req, res) => {
    res.writeHead(200, { 'Content-Type': 'text/plain' });
    res.end('Hola desde Node.js');
});
    
server.listen(3000, () => {
    console.log('Servidor escuchando en el puerto 3000');
});`;
    return (
        <>
            <Helmet>
                <title>TBP | Node JS: Significado y utilidades</title>
            </Helmet>
            <div className="container-3">
                <div className="main-container">
                    <div className="box-title">
                        <h1>Node JS: Significado y utilidades</h1>
                    </div>
                    <div className="topic-links">
                        <div className="card-topic-link">
                            <Link to="/topics/javascript">
                                <h4><i className="fa-sharp fa-solid fa-circle"></i>&nbsp;&nbsp;&nbsp;JavaScript</h4>
                            </Link>
                        </div>
                        <div className="card-topic-link">
                            <Link to="">
                            <h4><i className="fa-sharp fa-solid fa-circle"></i>&nbsp;&nbsp;&nbsp;Aug 13, 2023</h4>
                            </Link>
                        </div>
                    </div>
                    <div className="sub-container">
                        <h2>Introducción</h2>
                        <p>En el mundo de la programación, Node.js ha emergido como una herramienta poderosa y versátil. Si te has preguntado qué es Node.js y cómo puede beneficiarte, estás en el lugar correcto. En este post, exploraremos en detalle qué es Node.js, para qué se utiliza, si es un framework, las posibilidades que ofrece y cuándo es apropiado utilizarlo.</p>
                        <h2>¿Qué es Node.js y para qué sirve?</h2>
                        <p>Node.js es un entorno de ejecución de JavaScript basado en el motor V8 de Google Chrome. A diferencia del uso tradicional de JavaScript en el navegador, Node.js permite ejecutar código JavaScript en el servidor. Esto significa que puedes crear aplicaciones de servidor, manipular archivos, realizar solicitudes de red y mucho más, todo utilizando JavaScript.</p>
                        <h2>¿Es Node.js un framework?</h2>
                        <p>No, Node.js no es un framework en sí mismo. En cambio, es un entorno que te permite ejecutar código JavaScript en el servidor. Sin embargo, Node.js tiene una gran cantidad de bibliotecas y módulos que puedes utilizar para construir aplicaciones de servidor eficientes y escalables. Express.js es un ejemplo de un framework web popular que se basa en Node.js.</p>
                        <h2>¿Qué se puede hacer con Node?</h2>
                        <p>Node.js es increíblemente versátil y se puede utilizar para una amplia variedad de tareas, que incluyen:</p>
                        <ol>
                            <li><b>Desarrollo de Aplicaciones Web:</b> Puedes construir aplicaciones web de extremo a extremo utilizando bibliotecas y frameworks como Express.js.</li>
                            <li><b>Aplicaciones en Tiempo Real:</b> Node.js es una excelente opción para crear aplicaciones en tiempo real como chats en línea y juegos multijugador.</li>
                            <li><b>Microservicios:</b> Puedes construir arquitecturas de microservicios escalables utilizando Node.js para cada servicio.</li>
                            <li><b>APIs y Servidores:</b> Node.js es ideal para construir APIs RESTful y servidores que manejan solicitudes y respuestas.</li>
                            <li><b>Automatización:</b> Puedes utilizar Node.js para automatizar tareas repetitivas, como procesar archivos, tareas de compilación y más.</li>
                        </ol>
                        <h2>¿Cuándo usar Node?</h2>
                        <p>Node.js es especialmente adecuado en situaciones donde se requiere manejo de solicitudes concurrentes y en tiempo real. Algunos ejemplos son:</p>
                        <ol>
                            <li><b>Aplicaciones de Chat en Tiempo Real: </b>Node.js es ideal para construir aplicaciones de chat donde la comunicación en tiempo real es esencial.</li>
                            <li><b>Aplicaciones de Streaming: </b>Si necesitas transmitir datos en tiempo real, como videos o música, Node.js puede manejar eficientemente esta tarea.</li>
                            <li><b>APIs y Microservicios: </b>Node.js es una opción sólida para construir APIs y microservicios debido a su naturaleza no bloqueante y su capacidad para manejar muchas conexiones simultáneas.</li>
                        </ol>
                        <h5>Ejemplo: Creando un Servicio HTTP Básico con Node.js</h5>
                        <p>Aquí hay un ejemplo simple de cómo crear un servidor HTTP básico utilizando Node.js:</p>
                        <div className="code-text">
                            <SyntaxHighlighter className="codes" language="jsx" style={paraisoLight} customStyle={{borderRadius: "1.5em", backgroundColor: "#f4e7e5", border: "2px solid #222222", padding: "2em"}}>
                            {code01}
                            </SyntaxHighlighter>
                        </div>
                        <hr />
                        <h2>Conclusión</h2>
                        <p>Node.js es un entorno de ejecución de JavaScript que ha revolucionado la forma en que desarrollamos aplicaciones en el lado del servidor. No es un framework, pero ofrece una amplia gama de posibilidades y bibliotecas que facilitan la creación de aplicaciones web en tiempo real, APIs, microservicios y más. Con una comunidad activa y una amplia adopción, Node.js sigue siendo una herramienta esencial para los desarrolladores modernos. Ahora que comprendes qué es Node.js y cómo se utiliza, estás listo para explorar sus infinitas posibilidades. ¡Empieza tu viaje en Node.js y descubre el poder del desarrollo del lado del servidor con JavaScript!</p>
                    </div>
                    <Signature/>
                </div>
            </div>
        </>
    )
}