import { useEffect, useState } from "react";
import {Link} from "react-router-dom"
import { Helmet } from "react-helmet";

export default function Xtras() {
    const [data,setData]=useState([]);
    const [post,setPost]=useState([]);
    const getData=()=>{
        fetch('../topics.json',
        {
        headers : { 
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        }
        }
        )
        .then(function(response){
            console.log(response)
            return response.json();
        })
        .then(function(myJson) {
            console.log(myJson);
            setData(myJson)
            setPost(myJson[9].posts)
        });
    }
    useEffect(()=>{
        getData()
    },[])
    return (
        <>
            <Helmet>
                <title>TBP | Xtras</title>
            </Helmet>
            <div className="container-2">
                <div className="container-mix">
                    <div className="box-title">
                        <h1>Xtras</h1>
                    </div>
                    {/* <h2 className="label-title">Xtras</h2>
                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Maxime mollitia, molestiae quas vel sint commodi repudiandae consequuntur voluptatum laborum numquam blanditiis harum quisquam eius sed odit fugiat iusto fuga praesentium optio, eaque rerum! Provident similique accusantium nemo autem.</p> */}
                    <div className="feed-blog">
                        {post.map(item => ( 
                            <Link className="link" to={`/topics/${item.link}`}>
                                <div className="card-post" key={item.id}>
                                    <div className="date">
                                        <a>{item.fecha}</a>
                                    </div>
                                    <h4 className="text-post">{item.title}</h4>
                                </div>
                            </Link>
                        ))}
                    </div>
                </div>
            </div>
            
        </>
    )
}