import {Link} from "react-router-dom"
import { Helmet } from "react-helmet";
import Signature from "../../signature/Signature";
export default function AjaxW() {

    return (
        <>
            <Helmet>
                <title>TBP | ¿Qué es AJAX?</title>
            </Helmet>
            <div className="container-3">
                <div className="main-container">
                    <div className="box-title">
                        <h1>¿Qué es AJAX?</h1>
                    </div>
                    <div className="topic-links">
                        <div className="card-topic-link">
                            <Link to="/topics/classnotes">
                                <h4><i className="fa-sharp fa-solid fa-circle"></i>&nbsp;&nbsp;&nbsp;Classnotes</h4>
                            </Link>
                        </div>
                        <div className="card-topic-link">
                            <Link to="">
                            <h4><i className="fa-sharp fa-solid fa-circle"></i>&nbsp;&nbsp;&nbsp;Apr 29, 2023</h4>
                            </Link>
                        </div>
                    </div>
                    <div className="sub-container">
                        <p>AJAX son las siglas de Asynchronous JavaScript and XML, y es una técnica para crear páginas web dinámicas e interactivas. Con AJAX, se puede actualizar una página web sin recargarla entera. Esta técnica permite a los desarrolladores web crear aplicaciones interactivas y con capacidad de respuesta que proporcionan una mejor experiencia al usuario.</p>
                        <h2>¿Cómo funciona AJAX?</h2>
                        <p>AJAX funciona mediante una combinación de tecnologías como JavaScript, XML y HTTP. He aquí un breve resumen de cómo funciona AJAX:</p>
                        <ol>
                            <li>Un usuario interactúa con una página web, por ejemplo enviando un formulario o pulsando un botón.</li>
                            <li>JavaScript envía una solicitud al servidor mediante el objeto XMLHttpRequest (XHR).</li>
                            <li>El servidor procesa la petición y devuelve una respuesta en formato XML, JSON o texto plano.</li>
                            <li>JavaScript actualiza la página web con el nuevo contenido, sin necesidad de recargarla.</li>
                        </ol>
                        <h2>Ventajas del uso de AJAX</h2>
                        <p>Utilizar AJAX tiene varias ventajas, entre ellas:</p>
                        <ol>
                            <li>Mejora de la experiencia del usuario: Al actualizar sólo las partes relevantes de una página web, AJAX puede hacer que las aplicaciones web parezcan más rápidas y receptivas.</li>
                            <li>Reducción de la carga del servidor: Dado que AJAX permite a las páginas web actualizar sólo partes específicas de una página, puede ayudar a reducir la carga del servidor y mejorar la escalabilidad.</li>
                            <li>Mayor accesibilidad: AJAX puede mejorar la accesibilidad proporcionando actualizaciones en tiempo real a los usuarios con problemas de visión u otras discapacidades.</li>
                        </ol>
                        <h2>Ejemplos de AJAX</h2>
                        <p>He aquí algunos ejemplos de cómo se utiliza AJAX en el desarrollo web:</p>
                        <ol>
                            <li>Validación de formularios: AJAX puede utilizarse para validar campos de formularios a medida que los usuarios escriben, proporcionando información en tiempo real sobre si la entrada es válida.</li>
                            <li>Búsqueda en directo: AJAX puede utilizarse para actualizar los resultados de búsqueda a medida que los usuarios escriben, lo que permite una experiencia de búsqueda más fluida.</li>
                            <li>Desplazamiento infinito: AJAX puede utilizarse para cargar nuevos contenidos a medida que los usuarios se desplazan por una página, lo que permite una experiencia de navegación más fluida.</li>
                            <li>Formularios dinámicos: AJAX puede utilizarse para añadir o eliminar dinámicamente campos de formulario en función de las entradas del usuario.</li>
                        </ol>
                        <hr />
                        <h2>Conclusión</h2>
                        <p>AJAX es una potente técnica para crear páginas web dinámicas e interactivas. Al actualizar sólo las partes relevantes de una página web, AJAX puede mejorar la experiencia del usuario, reducir la carga del servidor y mejorar la accesibilidad. Con AJAX, los desarrolladores web pueden crear aplicaciones web receptivas y atractivas que proporcionen una mejor experiencia de usuario.</p>
                    </div>
                    <Signature/>
                </div>
            </div>
        </>
    )
}