import { useEffect, useState } from "react";
import "./BackToTop.css";

export default function BackToTop() {
    const [showTopBtn, setShowTopBtn] = useState(false);
    useEffect(() => {
        window.addEventListener("scroll", () => {
            if (window.scrollY > 400) {
                setShowTopBtn(true);
            } else {
                setShowTopBtn(false);
            }
        });
    }, []);
    const goToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    };
    
    return (
        <div className="back-to-top">
            {" "}
            {showTopBtn && (
                <i className="fa-solid fa-circle-up" onClick={goToTop}></i>
            )}{" "}
        </div>
    )
}