import { useEffect, useState } from "react";
import {Link} from "react-router-dom"
import { Helmet } from "react-helmet";

export default function WebProgramming() {
    const [data,setData]=useState([]);
    const getData=()=>{
        fetch('../posts.json',
        {
            headers : { 
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        })
        .then(function(response){
            return response.json();
        })
        .then(function(myJson) {
            setData(myJson)
        });
    }
    useEffect(()=>{
        getData()
    },[])
    return (
        <>
            <Helmet>
                <title>TBP | Web Programming</title>
            </Helmet>
            <div className="container-2">
                <div className="container-mix">
                    <div className="box-title">
                        <h1>Web Programming</h1>
                    </div>
                    <div className="feed-blog">
                        {data.map((item => {
                            if (item.topic === 'web-programming'){
                                return <Link className="link" to={`/topics/${item.link}`}>
                                <div className="card-post" key={item.topic}>
                                    <div className="date">
                                        <a>{item.date}</a>
                                    </div>
                                    <h4 className="text-post">{item.title}</h4>
                                </div>
                            </Link>
                            }
                        }))}
                    </div>
                </div>
            </div>
            
        </>
    )
}