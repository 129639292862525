import {Link} from "react-router-dom";
import { Helmet } from "react-helmet";
import Signature from "../../signature/Signature";
import "../Posts.css";

export default function JavaScript_Fundamental() {
    return (
        <>
            <Helmet>
                <title>TBP | JavaScript Fundamental</title>
            </Helmet>
            <div className="container-3">
                <div className="main-container">
                    <div className="box-title">
                        <h1>JavaScript Fundamental</h1>
                    </div>
                    <div className="topic-links">
                        <div className="card-topic-link">
                            <Link to="/topics/javascript">
                                <h4><i className="fa-sharp fa-solid fa-circle"></i>&nbsp;&nbsp;&nbsp;JavaScript</h4>
                            </Link>
                        </div>
                        <div className="card-topic-link">
                            <Link to="">
                            <h4><i className="fa-sharp fa-solid fa-circle"></i>&nbsp;&nbsp;&nbsp;Jan 26, 2022</h4>
                            </Link>
                        </div>
                    </div>
                    <div className="sub-container">
                        <h2>JavaScript Intro</h2>
                        <p>→ Se utiliza para hacer que las páginas web sean interactivas.</p>
                        <p>Enlace con HTML: Dos maneras de enlazar</p>
                        <div>
                            <ul>
                                <li>JS externo</li>
                                <li>Js Interno, usando el tag &lt;script&gt;.</li>
                            </ul>
                        </div>
                        <h2>Variable</h2>
                        <p>→ Contenedor usado para almacenar datos (de diferentes tipos).</p>
                        <p>3 maneras de crear:</p>
                        <div>
                            <ul>
                                <li>var Name = dato-de-cualquier-tipo-de-dato;</li>
                                <li>let Name = dato-de-cualquier-tipo-de-dato;</li>
                                <li>const Name = dato-de-cualquier-tipo-de-dato;</li>
                            </ul>
                        </div>
                        <h2>Tipos de Datos</h2>
                        <p>→ Tipo de un dato particular se llama tipo de dato.</p>
                        <p>7 tipos de datos en JS:</p>
                        <div>
                            <ul>
                                <li>String: var x = "Hello";</li>
                                <li>Number: var x = 10;</li>
                                <li>Boolean: var x = true/false;</li>
                                <li>Array: var x = [1, "a", true];</li>
                            </ul>
                        </div>
                    </div>
                    <Signature/>
                </div>
            </div>
        </>
    )
}