import {Link} from "react-router-dom"
import { Helmet } from "react-helmet";
import Signature from "../../signature/Signature";
export default function Introduccion_bpm() {

    return (
        <>
            <Helmet>
                <title>TBP | Introducción a BPM</title>
            </Helmet>
            <div className="container-3">
                <div className="main-container">
                    <div className="box-title">
                        <h1>Introducción a BPM</h1>
                    </div>
                    <div className="topic-links">
                        <div className="card-topic-link">
                            <Link to="/topics/bpm">
                                <h4><i className="fa-sharp fa-solid fa-circle"></i>&nbsp;&nbsp;&nbsp;Bpm</h4>
                            </Link>
                        </div>
                        <div className="card-topic-link">
                            <Link to="">
                            <h4><i className="fa-sharp fa-solid fa-circle"></i>&nbsp;&nbsp;&nbsp;Jan 07, 2022</h4>
                            </Link>
                        </div>
                    </div>
                    <div className="sub-container">
                    <h3>¿Qué es BPM?</h3>
                        <p>Arte y ciencia para gestionar el cómo se trabaja en las organizaciones, para asegurar resultados consistentes, y para aprovechar oportunidades de mejora como:</p>
                        <ul>
                            <li>Reducir costos</li>
                            <li>Reducir tiempos de ejecución</li>
                            <li>Reducir tasas de error</li>
                        </ul>
                        <h3>BPM no se centra en mejorar actividades individuales</h3>
                        <p>Gestiona cadenas de eventos, actividades y decisiones</p>
                        <ul>
                            <li>Procesos</li>
                        </ul>
                        <p>Por esto, agrega valor al negocio</p>
                        <h3>Los procesos de negocio están en todo tipo de organización</h3>
                        <ul>
                            <li>Orden de venta</li>
                            <li>Orden de compra</li>
                            <li>Plantillas</li>
                            <li>Matrócula</li>
                        </ul>
                        <h3>Como se modelan y ejecutan los procesos, influye en dos aspectos fuindamentales</h3>
                        <ul>
                            <li>La calidad de servicio que perciben los clientes</li>
                            <li>La eficiencia con la cual se entregan</li>
                        </ul>
                        <h3>Ingredientes principales de un proceso de negocio: Eventos y Actividades</h3>
                        <p>Evento: Cosa que sucede atómicamente, no tienen duración</p>
                        <ul>
                            <li>La llegada de un equipo</li>
                            <li>Los eventos pueden disparar la ejecución de una o más actividades</li>
                        </ul>
                        <p>Actividad (o tarea): Una unidad de trabajo</p>
                        <ul>
                            <li>Revisar el equipo</li>
                        </ul>
                        <h3>Otros ingredientes de un proceso de negocio</h3>
                        <ul>
                            <li>Puntos de decisión, que afectan la forma como se ejecuta el proceso</li>
                            <li>Actores: Personas, organizaciones, software, objetos físicos, etc.</li>
                            <li>Salidas, las cuales deberían agregar valor al actor</li>
                            <li>Entradas, necesarias para la correcta ejecución del proceso</li>
                        </ul>
                        <h3>No solo BPM mejora el funcionamiento de las organizaciones</h3>
                        <p>Existen otras disciplinas relacionadas a BPM</p>
                        <ul>
                            <li>Gestión Total de la Calidad (o TQP, Total Quality Management)</li>
                            <li>Gestióin de Operaciones</li>
                            <li>Lean o eliminación de desperdicios</li>
                            <li>Six Sigma</li>
                        </ul>
                        <h4>TQM</h4>
                        <p>Meora continua y sostenimiento de la calidad de los productos/sevicios</p>
                        <ul>
                            <li>TQM centrado en productos y servicios</li>
                            <li>BPM centrado en el proceso, cuya salida será un producto o un servicio</li>
                        </ul>
                        <h4>Gestión de Operaciones</h4>
                        <p>Relacionado a la gestión de funciones físicas y técnicas</p>
                        <ul>
                            <li>Producción y manufactura</li>
                        </ul>
                        <p>Teoría de probabilidades, teoría de colas, análisis de decisiones, modelamiento matemático y simulación</p>
                        <p>Orientado al control de los procesos, sin necesariamente cambiarlos</p>
                        <ul>
                            <li>BPM hace los cambios con el objetivo de mejorarlo</li>
                        </ul>
                        <h4>Lean</h4>
                        <p>Basado en filosofía de manufactura de Toyota, Lean ha sido absorbido por BPM</p>
                        <p>Principio fundamental: Eliminación de desperdicios</p>
                        <ul>
                            <li>Actividades que no entregan valor al cliente</li>
                        </ul>
                        <h4>Six Sigma</h4>
                        <p>Prácticas de manufactura de Motorola, minimización de defectos o errores, foco en la medida de los resultados de los procesos o actividades en términos de calidad.</p>
                        <h3>Disciplinas relacionadas</h3>
                        <ul>
                            <li>BPM hereda de TQM la filosofía de la mejora continua, adopta los principios y técnicas de la gestión de operaciones, Lean y Six Sigma.</li>
                            <li>BPM combina todas estas disciplinas con las capacidades que ofrecen las TI, para alinear óptimamente el proceso de negocio con los objetivos de la organización.</li>
                        </ul>
                        <h3>El enfoque tradicional origina islas de información</h3>
                        <ul>
                            <li>No está orientada al cliente</li>
                            <li>Maneja sus propios sistemas de información</li>
                            <li>Pueden estar automatizadas o no</li>
                        </ul>
                    </div>
                    <Signature/>
                </div>
            </div>
        </>
    )
}