import React from "react";
import {Link} from "react-router-dom";
import { Helmet } from "react-helmet";
import Signature from "../../signature/Signature";
import "../Posts.css";

export default function JavaScript_ES6() {
    return (
        <>
            <Helmet>
                <title>TBP | JavaScript ES6</title>
            </Helmet>
            <div className="container-3">
                <div className="main-container">
                    <div className="box-title">
                        <h1>JavaScript ES6</h1>
                    </div>
                    <div className="topic-links">
                        <div className="card-topic-link">
                            <Link to="/topics/javascript">
                                <h4><i className="fa-sharp fa-solid fa-circle"></i>&nbsp;&nbsp;&nbsp;JavaScript</h4>
                            </Link>
                        </div>
                        <div className="card-topic-link">
                            <Link to="">
                            <h4><i className="fa-sharp fa-solid fa-circle"></i>&nbsp;&nbsp;&nbsp;Jan 26, 2022</h4>
                            </Link>
                        </div>
                    </div>
                    <div className="sub-container">
                        <p>Es la nueva versión de JavaScript que se introdujo en 2015.</p>
                        <h2>Clases de JavaScript</h2>
                        <div>
                            <ul>
                                <li>La clase de JavaScript se utiliza para crear un objeto.</li>
                                <li>La clase es similar a una función constructora</li>
                                <li>La clase Keyboard se utiliza para crear una clase.</li>
                                <li>Las propiedades se asignan en una función constructora.</li>
                            </ul>
                        </div>
                        <h2>Parámetros</h2>
                        <div>
                            <ul>
                                <li>En la versión ES6, se pueden pasar valores predeterminados en los parámetros de la función.</li>
                                <li>Si no pasas el parámetro para y, tomará 5 por defecto.</li>
                            </ul>
                        </div>
                        <h2>Literales de plantilla</h2>
                        <div>
                            <ul>
                                <li>Los literales de plantilla han facilitado la inclusión de variables dentro de una cadena.</li>
                                <li>Antes tenías que hacer:</li>
                                <li>Usando el literal de plantilla por:</li>
                            </ul>
                        </div>
                        <h2>Destructing</h2>
                        <div>
                            <ul>
                                <li>La sintaxis de desestructuración facilita la asignación de valores a una nueva variable.</li>
                                <li>Antes se hacía algo así</li>
                                <li>Usando la sintaxis de desestructuración de ES6:</li>
                            </ul>
                        </div>
                        <h2>Importar y Exportar</h2>
                        <div>
                            <ul>
                                <li>Puedes exportar un programa y utilizarlo en otro programa importándolo.</li>
                                <li>Por ejemplo, si tiene dos archivos JavaScript llamados contact.js y home.js.</li>
                                <li>Puedes exportar la función contact()</li>
                                <li>Para usar la función contact() en otro archivo, simplemente importa la función</li>
                            </ul>
                        </div>
                        <h2>Parámetro Rest y Operador Spread</h2>
                        <div>
                            <ul>
                                <li>Puede utilizar el parámetro resto para representar un número indefinido de argumentos como una matriz.</li>
                                <li>Utiliza la sintaxis de propagación "..." para copiar a los elementos en una sola matriz.</li>
                            </ul>
                        </div>
                    </div>
                    <Signature/>
                </div>
            </div>
        </>
    )
}