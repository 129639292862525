import {Link} from "react-router-dom"
import { Helmet } from "react-helmet";
import Signature from "../../signature/Signature";
export default function Intro_Seguridad_Informacion() {

    return (
        <>
            <Helmet>
                <title>TBP | Introducción a la Seguridad de la Información</title>
            </Helmet>
            <div className="container-3">
                <div className="main-container">
                    <div className="box-title">
                        <h1>Introducción a la Seguridad de la Información</h1>
                    </div>
                    <div className="topic-links">
                        <div className="card-topic-link">
                            <Link to="/topics/classnotes">
                                <h4><i className="fa-sharp fa-solid fa-circle"></i>&nbsp;&nbsp;&nbsp;Classnotes</h4>
                            </Link>
                        </div>
                        <div className="card-topic-link">
                            <Link to="">
                            <h4><i className="fa-sharp fa-solid fa-circle"></i>&nbsp;&nbsp;&nbsp;Jan 27, 2022</h4>
                            </Link>
                        </div>
                    </div>
                    <div className="sub-container">
                        <h2>Sistemas de Información y Sistemas Informáticos</h2>
                        <p>Un sistema de información (SI) es un conjunto de elementos organizados, relacionados y coordinados entre sí, encargados de facilitar el funcionamiento global de una empresa o de cualquier otra actividad humana para conseguir sus objetivos.</p>
                        <p>Estos elementos son:</p>
                        <p><b>Recursos: </b>Pueden ser físicos, como ordenadores, componentes, periféricos y conexiones, recursos no informáticos y lógicos, como sistemas operativos y aplicaciones informáticas.</p>
                        <p><b>Equipo humano: </b>Compuesto por las personas que trabajan para la organización.</p>
                        <p><b>Información: </b>Conjunto de datos organizados que tienen un significado. La información puede estar contenida en cualquier tipo de soporte.</p>
                        <p><b>Actividades: </b>Que se realizan en la organización relacionadas o no con la informática.</p>
                        <p>Un sistema informático está constituido por un conjunto de elementos físicos (hardware, dispositivos, periféricos y conexiones), lógicos (sistemas operativos, aplicaciones, protocolos …) y con frecuencia se incluyen también los elementos humanos (personal experto que maneja el software y el hardware).</p>
                        <p>Un sistema informático puede ser un subconjunto del sistema de información, pero en principio un sistema de información no tiene por qué contener elementos informáticos, aunque en la actualidad es difícil imaginar cualquier actividad humana en la que no se utilice la informática.</p>
                        <h2>Seguridad</h2>
                        <p>La seguridad informática es la disciplina que se ocupa de diseñar las normas, procedimientos, métodos y técnicas destinados a conseguir un sistema de información seguro y confiable.</p>
                        <h3>Tipos de Seguridad</h3>
                        <p><b>Activa: </b>Activa: Comprende el conjunto de defensas o medidas cuyo objetivo es evitar o reducir los riesgos que amenazan al sistema. Ejemplos: impedir el acceso a la información a usuarios no autorizados mediante introducción de nombres de usuario y contraseñas; evitar la entrada de virus instalando un antivirus; impedir, mediante encriptación, la lectura no autorizada de mensajes.</p>
                        <p><b>Pasiva: </b>Está formada por las medidas que se implantan para, una vez producido el incidente de seguridad, minimizar su repercusión y facilitar la recuperación del sistema; por ejemplo, teniendo siempre al día copias de seguridad de los datos.</p>
                        <h2>Propiedades de un Sistema de Información Seguro</h2>
                        <p>Los datos producidos por falta de seguridad pueden causar pérdidas económicas o de credibilidad y prestigio a una organización.</p>
                        <p>Su origen puede ser:</p>
                        <p><b>Fortuito: </b>Errores cometidos accidentalmente por los usuarios, accidentes, cortes de fluido eléctrico, averías del sistema, catástrofes naturales.</p>
                        <p><b>Fraudulento: </b>Daños causados por software malicioso, intrusos o por la mala voluntad de algún miembro del personal con acceso al sistema, robo o accidentes provocados.</p>
                    </div>
                    <Signature/>
                </div>
            </div>
        </>
    )
}