import {Link} from "react-router-dom"
import { Helmet } from 'react-helmet';
import pic1 from "../../pix/web-programming/hyc/hc-1.PNG";
import Signature from "../../signature/Signature";
import "../Posts.css"

export default function Html_and_Css() {
    return (
        <>
            <Helmet>
                <title>TBP | HTML y CSS</title>
            </Helmet>
            <div className="container-3">
                <div className="main-container">
                    <div className="box-title">
                        <h1>HTML y CSS</h1>
                    </div>
                    <div className="topic-links">
                        <div className="card-topic-link">
                            <Link to="/topics/web-programming">
                                <h4><i className="fa-sharp fa-solid fa-circle"></i>&nbsp;&nbsp;&nbsp;Web Programming</h4>
                            </Link>
                        </div>
                        <div className="card-topic-link">
                            <Link to="">
                                <h4><i className="fa-sharp fa-solid fa-circle"></i>&nbsp;&nbsp;&nbsp;Mar 07, 2021</h4>
                            </Link>
                        </div>
                    </div>
                    <div className="sub-container">
                        <h2>HTML (Hypertext Markup Language)</h2>
                        <p>HTML es un lenguaje de marcado que define la estructura de una página web. Es interpretado por su navegador web (Safari, Google Chrome, Firefox, etc.) para mostrar contenido en su pantalla.</p>
                        ¡Comenzar escribiendo un archivo HTML simple!
                        {/* <div className="codeblock">
                                <CopyBlock
                                language="html"
                                text={`<!DOCOTYPE html>
    <html lang="en">
        <head>
            <title>Hello!</title>
        </head>
        <body>
            Hello, world!
        </body>
    </html>`}
                                codeBlock
                                theme={zenburn}
                                showLineNumbers={false}
                                />
                            </div> */}
                            <p>Cuando abrimos el archivo en nuestro navegador, obtenemos:</p>
                            <div className="picsminpost">
                                <img className="" src={pic1} alt="" />
                            </div>
                            <ul>
                                <li>Después de eso, la página consta de elementos HTML anidados (como html y body), cada uno con una etiqueta de apertura y cierre marcada con <b>&#60;element&#62;</b> una apertura y <b>&#60;/element&#62;</b> un cierre.</li>
                                <li>Observe cómo cada uno de los elementos internos está sangrado un poco más que el anterior. Si bien esto no es necesariamente requerido por el navegador, será muy útil mantenerlo en su propio código.</li>
                                <li>Los elementos HTML pueden incluir atributos, que brindan al navegador información adicional sobre el elemento. Por ejemplo, cuando incluimos <b>lang="en"</b> en nuestra etiqueta inicial, le estamos diciendo al navegador que estamos usando el inglés como nuestro idioma principal.</li>
                                <li>Dentro del elemento HTML, normalmente queremos incluir tanto <b>head</b> y <b>body</b> etiqueta como una. El elemento de cabecera incluirá información sobre su página que no se muestra necesariamente, y el elemento de cuerpo contendrá lo que es realmente visible para los usuarios que visitan el sitio.</li>
                                <li>Dentro del encabezado, hemos incluido un <b>title</b> para nuestra página web, que notará que se muestra en la pestaña en la parte superior de nuestro navegador web.</li>
                                <li>Por último, hemos incluido el texto "¡Hola, mundo!" en el cuerpo, que es la parte visible de nuestra página.</li>
                            </ul>
                            <h2>Más elementos HTML</h2>
                            <ul>
                                <li>Es posible que desee utilizar muchos elementos HTML para personalizar su página, incluidos títulos, listas y secciones en negrita. En el siguiente ejemplo, veremos algunos de estos en acción.</li>
                                <li>Una cosa más a tener en cuenta: &#60;!-- --&#62; nos da un comentario en HTML, así que lo usaremos a continuación para explicar algunos de los elementos.</li>
                            </ul>
                    </div>
                    <Signature/>
                </div>
            </div>
        </>
    )
}